import { Grid, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'src/contexts/user.context';
import { APIServiceManager, PermissionService } from '../../../services';
import './profile.page.css';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
        height: '100%'
    },
    text: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    version: {
        marginRight: 10,
        textAlign: 'right'
    },
}));


function ProfilePage() {
    const { t } = useTranslation(['pablo']);
    const classes = useStyles();
    const _permissionService: PermissionService = APIServiceManager.getService('PermissionService') as PermissionService;

    const userContext = useContext(UserContext);
    const [permissions, setPermissions] = useState<Array<any>>([]);

    useEffect(() => {
        loadData();
    }, [userContext]);

    const loadData = async () => {
        if (userContext.user.id) {
            const perms = await _permissionService.getPermissions(userContext.user.id, undefined, false, undefined, false);
            setPermissions(perms);
        }

    };

    const projects = permissions.map((perm: any) => {
        return (
            <Grid item container key={perm.projectId}>
                <Grid item xs={3}>
                    <Typography className={classes.text}>{`${perm.project.code} - ${perm.project.name}`}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        className={classes.text}
                        fullWidth
                        multiline
                        rows={perm.permissions.length}
                        InputProps={{
                            readOnly: true,
                        }}
                        defaultValue={perm.permissions.map((s: string) => s.replace('-', ' ')).join('\n')} />
                </Grid>
                {/* <Row>
                    <Col sm="3">
                        <Form.Label>{`${perm.project.code} - ${perm.project.name}`}</Form.Label>
                    </Col>
                    <Col sm="8">
                        <Form.Control as="textarea" rows={perm.permissions.length} style={{ textTransform: "capitalize", whiteSpace: "pre-wrap" }}
                            readOnly value={perm.permissions.map((s: string) => s.replace('-', ' ')).join('\n')} />
                    </Col>

                </Row> */}
            </Grid>
        );
    });

    return (
        <Stack className={classes.root}>
            <h4 >{t('pablo:pages.profile.myProfile')}</h4>


            <Grid container >
                <Grid item container >
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Name</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant='standard'
                            className={classes.text}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            defaultValue={
                                userContext.user.firstname && userContext.user.lastname ?
                                    `${userContext.user.firstname} ${userContext.user.lastname}` :
                                    userContext.user.name

                            } />
                    </Grid>
                </Grid>
                <Grid item container >
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Email address</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant='standard'
                            className={classes.text}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            defaultValue={userContext.user.email} />
                    </Grid>
                </Grid>
                <Grid item container >
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Access rights</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant='standard'
                            className={classes.text}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            defaultValue={userContext.user.access_right} />
                    </Grid>
                </Grid>
            </Grid>


            <h4>{t('pablo:pages.profile.myProject')}</h4>


            <Grid container rowSpacing={2} sx={{ overflow: 'scroll' }}>
                {projects}
            </Grid>


        </Stack>
    );
}

export default ProfilePage;
