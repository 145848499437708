import { Dialog } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CloseDialogTitle, SpinnerText } from 'src/components';
import { UserContext } from 'src/contexts/user.context';
import { APIServiceManager } from 'src/services';

function RequireManage({ children }: { children: JSX.Element }) {

    const { t } = useTranslation(['pablo']);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (userContext.user && userContext.user.id) {
                try {
                    const perms = await APIServiceManager.PermissionService.getPermissions(userContext.user.id, undefined, false, ["project-manager", "user-manager", "model-manager"]);
                    if (perms.length > 0) {
                        setIsLoggedIn(true);
                        setIsLoading(false);
                    } else {
                        setShowAlert(true);
                        setIsLoggedIn(false);
                    }
                } catch (error) {
                    setShowErrorAlert(true);
                    setIsLoggedIn(false);
                } finally {
                    setIsLoading(false);
                }
            }

        };

        fetchPermissions();
    }, [userContext.user]);


    if (isLoading) {
        return (
            <SpinnerText text="Loading ..." />
        );
    } else if (isLoggedIn) {
        return children
    } else {
        if (isRedirect) {
            return (
                <Navigate to='/profile' state={{ from: location }} />
            )
        } else {
            setTimeout(() => {
                setIsRedirect(true)
            }, 5000);
            if (showErrorAlert) {
                return (
                    <Dialog open={showErrorAlert} onClose={() => setIsRedirect(true)}>

                        <CloseDialogTitle onClose={() => setIsRedirect(true)} title={'Errors'} />
                        {t('pablo:router.message.permissionError')}
                    </Dialog >
                )
            }

            return (
                <Dialog open={showAlert} onClose={() => setIsRedirect(true)}>

                    <CloseDialogTitle onClose={() => setIsRedirect(true)} title={'Restricted Access'} />
                    You must be granted project administration rights to enter this page.
                </Dialog >

            )
        }
    }
}

export default RequireManage;