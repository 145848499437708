import { API, APIServiceManager } from '../..';
import { unzipJson } from '../../../core/helpers/zip'
import { prepareUri } from '../../../core/helpers/requests'

class StructureSnapshotApiService {
    api: API = APIServiceManager.API
    name() {
        return 'StructureSnapshotApiService'
    }

    async postSnapshot(name: string): Promise<any> {
        try {
            const req = await this.api.axios.post(
                '/project/structuresnapshot',
                { name: name }
            )
            return req.data.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getSnapshotSummaries(): Promise<any> {
        try {
            const req = await this.api.axios.get('/project/structuresnapshot/summaries')
            return req.data.data
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    async exportDataModel(options: { separator?: string, repeatRoot?: boolean }): Promise<any> {
        try {
            const req = await this.api.axios.get(
                prepareUri('/project/structuresnapshot/exportdatamodel', options),
                { responseType: 'blob' },
            );
            return req.data
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async exportSnapshot(id: string, options: { separator?: string, repeatRoot?: boolean }): Promise<any> {
        try {
            const req = await this.api.axios.get(
                prepareUri(`/project/structuresnapshot/export/${id}`, options),
                { responseType: 'blob' },
            );
            return req.data;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async exportDiff(newSnapshotId: string, oldSnapshotId: string = '', options: { separator?: string, repeatRoot?: boolean }): Promise<any> {
        try {
            const params: { oldSnapshotId: string, newSnapshotId?: string, separator?: string, repeatRoot?: boolean } = { ...options, oldSnapshotId };
            if (newSnapshotId) {
                params['newSnapshotId'] = newSnapshotId;
            }
            const req = await this.api.axios.get(
                prepareUri('/project/structuresnapshot/exportdiff', params),
                { responseType: 'blob' },
            );
            return req.data;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async deleteSnapshot(id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(
                `/project/structuresnapshot/${id}`
            );
            return req.data.data;
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    async getSnapshot(id: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/project/structuresnapshot/${id}`)
            if (req.data.data) {
                return unzipJson(req.data.data.data)
            } else {
                return {}
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    }
}

export { StructureSnapshotApiService }
