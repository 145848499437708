
import React from 'react';
import { SingleAsset } from 'src/components';

export default function SingleAssetView() {
    return (
        <SingleAsset />

    )
}
