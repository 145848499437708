import { ObjectID } from 'bson';
import EditableTree, { cloneNode, TreeData } from 'editable-tree';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-table/react-table.css';
import { ModalStructureNode } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { FrontServiceManager } from 'src/services';
import './structure-tree.component.css';

interface StructureTreeProps {
    data: any;
    defaultSelectedNodeId: string;
    showNodeRootCode: boolean;
    showNodeLevel: boolean;
    showNodeFullCode: boolean;
    separator: string;
    editable: boolean;
    onTreeChanged?: (tree: TreeData) => void;
    onNodeClick: (node: TreeData) => void;
    onNodeBeforeAdd?: (parent: TreeData, newNode: TreeData) => void;
    onNodeAdd?: (tree: TreeData, newNode: TreeData, parentId: string, order?: number) => void;
    onNodeBeforeEdit?: (tree: TreeData, newNode: TreeData) => void;
    onNodeEdit?: (tree: TreeData, editedNode: TreeData) => void;
    onNodeBeforeDelete?: (tree: TreeData, deletedNode: TreeData) => boolean;
    onNodeDelete?: (tree: TreeData, deletedNode: TreeData, parentId: string) => void;
    onNodeBeforeDrop?: (newTree: TreeData, sourceNode: TreeData, parentNode: TreeData, newOrder?: number) => void;
    onNodeDrop?: (tree: TreeData, nodeId: string, parentId: string, newOrder?: number) => void;
    onNodeDropAfter?: (tree: TreeData, nodeId: string, parentId: string, previousId: string, newOrder?: number) => void;
    calculateCanDeleteNode?: (node: TreeData) => boolean;
}

export default function StructureTree(props: StructureTreeProps) {
    const projectContext = useContext(ProjectContext);
    const { t } = useTranslation('pablo');

    const [expanded, setExpanded] = useState<Array<string>>([]);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        setSelected(props.defaultSelectedNodeId);
    }, [props.defaultSelectedNodeId]);

    useEffect(() => {
        if (projectContext && projectContext.currentProject && projectContext.currentProject._id) {
            const exp = localStorage.getItem(`aimspainter_structure_tree_page_expanded_nodes_${projectContext.currentProject._id}`);
            if (exp) {
                const list = JSON.parse(exp);
                if (Array.isArray(list)) {
                    setExpanded(list);
                }
            }
        }
    }, [projectContext]);

    const labelRender = useCallback((node: TreeData, level: number): JSX.Element => {
        const nodeLabel = FrontServiceManager.StructureQueryService.getLabel(node, level,
            props.separator, props.showNodeFullCode, props.showNodeLevel, props.showNodeRootCode);
        node.label = nodeLabel;
        return (<span>{nodeLabel}</span>);
    }, [
        props.showNodeFullCode,
        props.showNodeLevel,
        props.separator,
        props.showNodeRootCode,
        props.data.extendedData.code,
    ]);

    return (
        <div style={{ height: 'calc(100% - 70px)' }}>
            <EditableTree
                selected={selected}
                defaultExpanded={expanded}
                searchOnFields={['code', 'fullCode']}
                localisation={{
                    searchPlaceholder: t('pablo:components.tree.search'),
                    filterText: t('pablo:components.tree.filter'),
                    searchFieldsLabels: [t('pablo:table.headers.code'), t('pablo:table.headers.fullCode')]
                }}
                orderNode={true}
                guidGenerator={() => `${new ObjectID()}`}
                editable={{
                    canAddNode: props.editable,
                    canDeleteNode: props.editable,
                    canDragAndDrop: props.editable,
                    canEditNode: props.editable,
                    onNodeBeforeAdd: props.onNodeBeforeAdd,
                    onNodeAdd: props.onNodeAdd,
                    onNodeBeforeEdit: props.onNodeBeforeEdit,
                    onNodeEdit: props.onNodeEdit,
                    onNodeBeforeDelete: props.onNodeBeforeDelete,
                    onNodeDelete: props.onNodeDelete,
                    onNodeBeforeDrop: props.onNodeBeforeDrop,
                    onNodeDrop: props.onNodeDrop,
                    onNodeDropAfter: props.onNodeDropAfter,
                }}
                onNodeExpanded={
                    (event: any, nodeIds: Array<string>) => {
                        localStorage.setItem(`aimspainter_structure_tree_page_expanded_nodes_${projectContext.currentProject._id}`, JSON.stringify(nodeIds));
                    }
                }
                onNodeSelect={(event: any, id: string, node: TreeData) => {
                    setSelected(id);
                    props.onNodeClick(node);
                }}
                calculateCanDeleteNode={props.calculateCanDeleteNode}
                components={{
                    label: labelRender,
                    addNode: (node: TreeData, level: number, onSubmitNode: (node: TreeData) => void, onCancel: () => void) => {
                        const tempNode = cloneNode(node);
                        tempNode.extendedData.code = node.extendedData.code ? `${t('pablo:components.tree.childOf')}-${node.extendedData.code}` : '';
                        tempNode.extendedData.name = node.extendedData.name ? `${t('pablo:components.tree.childOf')} ${node.extendedData.name}` : '';
                        return (
                            <div>
                                {labelRender(node, level)}
                                <ModalStructureNode
                                    data={tempNode}
                                    onSubmit={(event: any, oldData: TreeData, newData: TreeData) => {
                                        onSubmitNode(newData);
                                    }}
                                    onCancel={onCancel}
                                />
                            </div>
                        );
                    },
                    labelEditing: (node: TreeData, level: number, onSubmitNode: (node: TreeData) => void, onCancel: () => void) => {
                        return (
                            <div>
                                {labelRender(node, level)}
                                <ModalStructureNode
                                    data={node}
                                    onSubmit={(event: any, oldData: TreeData, newData: TreeData) => {
                                        onSubmitNode(newData);
                                    }}
                                    onCancel={onCancel}
                                />
                            </div>
                        );
                    }
                }}
                data={props.data}
            />
        </div >

    );
}