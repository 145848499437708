
function getExtId(viewer: any, id: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        try {
            viewer.getProperties(id, (r: Autodesk.Viewing.PropertyResult) => {
                if (r && r.externalId) {
                    resolve(r.externalId!);
                } else {
                    resolve('')
                }
            });
        } catch (error) {
            reject(error)
        }
    });
}

function getIFCGuid(externalId: string): string {
    var elementID = parseInt(externalId.slice(37), 16);

    var last_32_bits = parseInt(externalId.slice(28, 36), 16);

    var xor = (last_32_bits ^ elementID) >>> 0;

    var str = xor.toString(16);
    if (str.length < 8) {
        var miss = 8 - str.length;
        for (var j = 0; j < miss; j++) str = '0' + str;
    }
    var long_IFCGUID = externalId.slice(0, 28) + str; //IFC long ID

    return guid_to_base64(long_IFCGUID); //IFC short ID
}

function guid_to_base64(guid: string): string {
    var b64list = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_$';

    var g_m = guid.split('-');

    var base16_tab = [g_m[0], g_m[1] + g_m[2] + g_m[3], g_m[4]];

    var v = '';

    for (var i = 0; i < base16_tab.length; i++) {
        var base16number = base16_tab[i];
        var base10 = parseInt(base16number, 16);

        var base = b64list.length;

        var str = '';
        var r = 1;

        var pow = 0;
        while (r >= 1) {
            r = base10 / base ** pow;
            pow++;
        }
        pow -= 2;

        while (pow !== -1) {
            var x = Math.trunc(base10 / base ** pow);
            str += b64list.charAt(x);
            base10 = base10 - x * base ** pow;
            pow--;
        }
        var miss = 0
        if (i === 0) {
            if (str.length === 6) {
                v += str;
            } else {
                miss = 6 - str.length;

                for (var j = 0; j < miss; j++) {
                    str = '0' + str;
                }

                v += str;
            }
        } else if (i === 1 || i === 2) {
            if (str.length === 8) {
                v += str;
            } else {
                miss = 8 - str.length;

                for (var k = 0; k < miss; k++) {
                    str = '0' + str;
                }

                v += str;
            }
        }
    }

    return v;
}


export {
    getExtId,
    getIFCGuid
}