import React from "react"
import { SpinnerText } from "src/components"
import { usePromise } from "src/core";

export function LoadingComponent(props: any) {
    const [value, error, loading] = usePromise(props.fetchState, props.initialState, props.updateContext);
    return (
        <div>
            {loading ? <SpinnerText text="Loading"></SpinnerText> :
                error ? <h4>Something went wrong</h4> : props.children(value)}
        </div>
    )
}