import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle, CTA, DropFileZone } from 'src/components';

interface DropFileDialogProps {
    show: boolean;
    title: string;
    acceptExtension?: Array<string>;
    templateFileName?: string;
    onFileSelected: (file: any) => void
    getTemplateBlob?: (lng: string) => any
    disabled?: boolean;
    onImport: () => void;
    onClose: () => void;
    onUndo?: () => void;
    onSubmit?: () => void;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullWidth?: boolean;
};

export default function DropFileDialog(props: DropFileDialogProps) {
    const { t } = useTranslation(['pablo']);

    return (
        <Dialog onClose={props.onClose} fullWidth={props.fullWidth}
            open={props.show} onSubmit={props.onSubmit ? props.onSubmit : props.onClose}
            maxWidth={props.maxWidth ? props.maxWidth : "md"}>
            <CloseDialogTitle onClose={props.onClose} title={props.title} />
            <DialogContent dividers>
                <DropFileZone
                    acceptExtensions={props.acceptExtension}
                    onFileSelected={props.onFileSelected}
                    templateFileName={props.templateFileName}
                    getTemplateBlob={props.getTemplateBlob}
                />
            </DialogContent>
            <DialogActions>
                <CTA autoFocus onClick={props.onUndo ? props.onUndo : props.onClose} color="inherit">
                    {t('pablo:button.undo')}
                </CTA>
                <CTA variant='filled' autoFocus disabled={props.disabled} onClick={props.onImport} color="inherit">
                    {t('pablo:button.import')}
                </CTA>
            </DialogActions>
        </Dialog>
    );
}
