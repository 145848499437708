import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Chip, Grid, IconButton, Link as MuiLink, Paper, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGridPro,
    enUS,
    frFR,
    GridColumns,
    GridPagination,
    GridRenderCellParams,
    GridSelectedRowCount,
    GridSelectionModel,
    GridSortDirection,
    GridSortModel,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueGetterParams,
    useGridApiContext
} from '@mui/x-data-grid-pro';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ProjectContext } from 'src/contexts/project.context';
import { APIServiceManager } from 'src/services';
import { CTA } from '../ui/CTA/call-to-action.component';
import { CTAGroup, CTAGroupProps } from '../ui/CTAGroup/call-to-action-group.component';
import { RenderCellExpand } from '../ui/grid-cell-expand';
import PropertyHolder from './propertyholders.component';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        minWidth: 200,
        marginLeft: 10,
    },
    title: {
        paddingLeft: 60,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    row: {

    },
    disableRow: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[500],
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    toolbar: {
        minWidth: 200,
        margin: '5px 20px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    linkCell: {
        paddingTop: 8,
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            overflowY: 'hidden',
            overflowX: 'hidden',
            '&:hover': {
                overflowX: 'scroll',
            }
        },
    },
    cell: {
        outline: 'unset !important',
    },
    header: {
        outline: 'unset !important',
    },
    searchToolbar: {
        padding: theme.spacing(0.5, 0.5, 0),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    searchToolbarSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        width: '100%',
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: '8px 16px 0px 0px !important',
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
    ctaRight: {
        marginRight: '16px !important',
        marginTop: '8px !important',
    },

}));

function FooterWithAction(props: {
    deleteSelection?: (selection: GridSelectionModel) => void;
    editSelection?: (selection: GridSelectionModel) => void;
}) {
    const apiRef = useGridApiContext();
    return (
        <Grid container style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
            {apiRef.current.state.selection.length > 0 ?
                <Grid container item xs={6} spacing={2}>
                    <Grid item xs={10}>
                        <GridSelectedRowCount selectedRowCount={apiRef.current.state.selection.length} />
                    </Grid>
                </Grid>
                : null
            }
            <Grid item xs={6} style={{ justifyContent: 'flex-end' }}>
                <GridPagination />
            </Grid>
        </Grid>
    );
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface LibraryToolbarProps {
    clearSearch: () => void;
    onSearchChange: () => void;
    onAdd: () => void;
    onImport: () => void;
    onExport: () => void;
    onEdit: (_: GridSelectionModel) => void;
    onDelete: (_: GridSelectionModel) => void;

    value: string;
    title: string;
}


function LibraryToolbar(props: LibraryToolbarProps) {
    const classes = useStyles();
    const { t } = useTranslation(['pablo']);
    const apiRef = useGridApiContext();
    const args: any = {};
    const options: CTAGroupProps['options'] = [];

    if (props.onEdit) {
        options.push({
            name: t('pablo:button.edit'),
            icon: 'edit',
            onClick: () => props.onEdit(apiRef.current.state.selection),
            disabled: !apiRef.current.state.selection.length,
        });
    }
    if (props.onDelete) {
        options.push({
            name: t('pablo:button.remove'),
            icon: 'trash',
            onClick: () => props.onDelete(apiRef.current.state.selection),
            disabled: !apiRef.current.state.selection.length,
        });
    }
    return (
        <div className={classes.searchToolbar} >
            <div className={classes.searchToolbarSubContainer} >
                <h2>
                    {props.title}
                </h2>
                <GridToolbarContainer>
                    <TextField
                        variant="standard"
                        value={props.value}
                        onChange={props.onSearchChange}
                        placeholder="Search…"
                        className={classes.textField}
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                    onClick={props.clearSearch}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />

                    <CTA>
                        <GridToolbarFilterButton
                            componentsProps={{
                                button: {
                                    disableRipple: true,
                                    disableElevation: true,
                                    disableFocusRipple: true,
                                    disableTouchRipple: true,
                                },
                            }}
                            sx={{
                                color: 'inherit',
                                padding: 0,
                            }}
                            {...args}
                        />
                    </CTA>
                </GridToolbarContainer>
                <div />
            </div>
            <div className={classes.searchToolbarSubContainer} >
                <CTAGroup
                    label={t('pablo:button.groupedActions')}
                    options={options}
                />

                <div>
                    <CTA
                        icon='file-download'
                        size="small"
                        onClick={props.onExport}
                        className={classes.ctaRight}
                    >
                        {t('pablo:button.export')}
                    </CTA>
                    <CTA
                        icon='file-upload'
                        size="small"
                        onClick={props.onImport}
                        className={classes.ctaRight}
                    >
                        {t('pablo:button.import')}
                    </CTA>
                    <CTA
                        icon={{
                            name: 'plus',
                            type: 'fas',
                        }}
                        size="small"
                        variant='filled'
                        onClick={props.onAdd}
                        className={classes.ctaRight}
                    >
                        {t('pablo:button.add')}
                    </CTA>
                </div>
            </div>
        </div>
    );
}


function PropertiesLibraries(props: any) {
    const classes = useStyles();

    const projectContext = useContext(ProjectContext);
    const location = useLocation();
    const state = location.state as any;

    const { t, i18n } = useTranslation(['pablo']);

    const [data, setData] = useState<Array<any>>([]);
    const [filteredData, setFilteredData] = useState<Array<any>>([]);
    const [openProperyHolder, setOpenProperyHolder] = useState(false);
    const [propIdForHolderModal, setPropIdForHolderModal] = useState<string>('');

    const [loading, setLoading] = useState(true);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [pageSize, setPageSize] = React.useState<number>(25);

    const [localeText, setLocaleText] = useState<any>(enUS);

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'key',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const [searchText, setSearchText] = React.useState('');

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field] ? row[field].toString() : undefined);
            });
        });
        setFilteredData(filteredRows);
    };

    useEffect(() => {
        const fetchProps = async () => {
            setLoading(true);
            const properties = await APIServiceManager.PropertyService.getProperties({
                libraryId: props.libraryId,
                includepset: true
            }, 0, -1);
            const propWithId = properties.data.map((r: any) => { r.id = r._id; return r; });
            setData(propWithId);
            setFilteredData(propWithId);
            setLoading(false);
        };
        fetchProps();
    }, [props.libraryId, props.refresh]);

    const handleClose = () => {
        setOpenProperyHolder(false);
    };

    const columns: GridColumns = [
        { field: 'key', headerName: t('pablo:table.headers.code'), editable: false, flex: 0.5 },
        { field: 'name', headerName: t('pablo:table.headers.name'), editable: false, flex: 0.5 },
        { field: 'description', headerName: t('pablo:table.headers.description'), editable: false, flex: 1.0, renderCell: RenderCellExpand },
        {
            field: 'propertySets', headerName: t('pablo:table.headers.propertyset'), editable: false, flex: 0.8,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div className={classes.linkCell}>
                        <div className="cellValue" >
                            {
                                cellValues.row.propertySets.map((propSet: any) => (
                                    < Chip key={propSet.name} style={{ marginLeft: 5 }}
                                        label={<MuiLink style={{ color: '#1976d2' }} component='div'><Link
                                            to={{
                                                pathname: `/prop/set/${propSet._id}/properties`,
                                            }}
                                            state={{ propSetName: propSet.name, ...state }}
                                        >{propSet.name}</Link></MuiLink>} />
                                ))}
                        </div>
                    </div >
                );
            },
            valueGetter: (params: GridValueGetterParams) => {
                const cellValue = params.row[params.field];
                if (Array.isArray(cellValue)) {
                    return cellValue.map(pset => pset.name).join(' ');
                }
                else {
                    return undefined;
                }
            }
        },
        { field: 'isTechProperty', headerName: t('pablo:table.headers.technicalProp'), type: 'boolean', editable: false, minWidth: 150, flex: 0.3, hide: true },
        {
            field: 'category',
            headerName: t('pablo:table.headers.category'),
            editable: true,
            minWidth: 150,
            flex: 0.3,
            hide: true,
            valueGetter: (params: GridValueGetterParams) => {
                let categoryValue;
                if (params.row && params.row.category && params.row.category._id) {
                    categoryValue = i18n.language === 'fr' ? params.row.category.frName : params.row.category.name;
                } else if (params.row && params.row.categoryText) {
                    categoryValue = params.row.categoryText;
                }
                return categoryValue;
            }
        },
        {
            field: 'unit',
            headerName: t('pablo:table.headers.unit'),
            editable: false,
            minWidth: 150,
            flex: 0.3,
            hide: true,
            valueGetter: (params: GridValueGetterParams) => {
                let unitValue;
                if (params.row && params.row.unit && params.row.unit._id) {
                    unitValue = i18n.language === 'fr' ? params.row.unit.frName : params.row.unit.name;
                } else if (params.row && params.row.categoryText) {
                    unitValue = params.row.unitText;
                }
                return unitValue;
            }
        },
        { field: 'format', headerName: t('pablo:table.headers.format'), editable: false, minWidth: 150, flex: 0.3, hide: true },
        { field: 'type', headerName: t('pablo:table.headers.type'), editable: false, minWidth: 150, flex: 0.3, hide: true },
        {
            field: 'holder', headerName: t('pablo:table.headers.propertyHolders'), editable: false, minWidth: 150, flex: 0.5, hide: false,
            renderCell: (params: GridValueGetterParams) => (
                <div >
                    <div className="cellValue" >
                        {
                            Array.isArray(params.row.structureIds) && params.row.structureIds.length > 0 ?
                                < Chip style={{ marginLeft: 5 }}
                                    label={<MuiLink onClick={(e: any) => {
                                        setPropIdForHolderModal(params.row._id);
                                        setOpenProperyHolder(true);
                                        e.stopPropagation();
                                    }}
                                        style={{ color: '#1976d2' }} component='div'>
                                        {t('pablo:components.properties.whereUsed')}

                                    </MuiLink>}
                                />
                                : null
                        }
                    </div>
                </div>
            ),
        },
    ];

    useEffect(() => {
        switch (i18n.language) {
            case 'fr':
                setLocaleText(frFR.components.MuiDataGrid.defaultProps.localeText);
                break;
            case 'en':
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
            default:
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
        }
    }, [i18n.language]);

    const getFooter = useCallback(() => {
        return (
            <FooterWithAction editSelection={props.editSelection} deleteSelection={props.deleteSelection} />
        );
    }, [props.editSelection, props.deleteSelection]);

    return (
        <>
            <Paper style={{ height: '100%' }} className="noselect">
                <DataGridPro
                    localeText={localeText}
                    classes={{
                        cell: classes.cell,
                        columnHeader: classes.header
                    }}
                    rows={filteredData}
                    columns={columns}
                    pagination
                    loading={loading}

                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}

                    sortModel={sortModel}
                    onSortModelChange={(model: GridSortModel) => setSortModel(model)}

                    components={{
                        Toolbar: LibraryToolbar,
                        Footer: getFooter
                    }}

                    componentsProps={{
                        toolbar: {
                            title: props.title,
                            value: searchText,
                            onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                            onAdd: props.onAdd,
                            onImport: props.onImport,
                            onExport: props.onExport,
                            onEdit: props.editSelection,
                            onDelete: props.deleteSelection,
                        },
                    }}

                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    checkboxSelection
                    disableSelectionOnClick
                    onRowClick={props.onRowClick}
                />
            </Paper>
            <PropertyHolder open={openProperyHolder}
                onClose={handleClose}
                projectId={
                    projectContext.currentProject && projectContext.currentProject._id ?
                        projectContext.currentProject._id
                        :
                        location.state && (location.state as any).projectId ?
                            (location.state as any).projectId :
                            ''
                }
                propId={propIdForHolderModal} />
        </>
    );

}

export default PropertiesLibraries;
