import { Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, TextField } from '@mui/material';
import { ObjectId } from 'bson';
import { EditableField } from 'editable-field';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from 'src/contexts/user.context';
import { APIServiceManager } from 'src/services';

export default function StructureConfigView() {
    const params = useParams();
    const userContext = useContext(UserContext);
    const { t, i18n } = useTranslation(['pablo']);

    const [hasEditorRole, setHasEditorRole] = useState(false);

    const defaultData: any = { displayLevel: false, displayFullCode: false, displayRootCode: false, codeSeparator: '' };
    const [structureConfiguration, setStructureConfiguration] = useState(defaultData);
    const [extendedCodes, setExtendedCodes] = useState<Array<any>>([]);
    const lastCreatedId = useRef<string>('');

    const loadExtendedCodes = useCallback(() => {
        APIServiceManager.ExtendedCodeService.getExtendedCodesForProject((params as any).id).then((extCodes: any) => {
            setExtendedCodes(extCodes);
        });
    }, [params]);

    const loadStructureConfiguration = useCallback(() => {
        APIServiceManager.ProjectService.getProject((params as any).id).then((projects: any) => {
            const structureConfiguration = projects && projects[0] && projects[0].structureConfiguration ?
                projects[0].structureConfiguration : defaultData;
            setStructureConfiguration(structureConfiguration);
        });
    }, [params]);

    const handleConfigurationChange = useCallback(async ({ type, value }: { type: string, value: any }) => {
        let newState = { ...structureConfiguration };
        switch (type) {
            case 'setDisplayLevel':
                newState.displayLevel = value;
                break;
            case 'setDisplayFullCode':
                newState.displayFullCode = value;
                break;
            case 'setDisplayRootCode':
                newState.displayRootCode = value;
                break;
            case 'setCodeSeparator':
                newState.codeSeparator = value;
                break;

            case 'setState':
                newState = value;
                break;
        }
        const pId = (params as any).id;
        await APIServiceManager.ProjectService.updateProject(pId, { structureConfiguration: newState });
        loadStructureConfiguration();
    }, [params, structureConfiguration]);

    const handleExtendedCodeChange = useCallback(async ({ type, value }: { type: string, value: any }) => {
        switch (type) {
            case 'addExtCode': {
                const newExtCode = { _id: (new ObjectId()).toString(), name: value, projectId: (params as any).id };
                lastCreatedId.current = newExtCode._id;
                await APIServiceManager.ExtendedCodeService.addExtendedCode(newExtCode);
                break;
            }
            case 'removeExtCode':
                await APIServiceManager.ExtendedCodeService.removeExtendedCodeForProject((params as any).id, value.id);
                break;
            case 'setExtCodeName':
                await APIServiceManager.ExtendedCodeService.updateExtendedCode(value.id, { name: value.newName });
                break;
        }
        loadExtendedCodes();
    }, [params]);

    useEffect(() => {
        lastCreatedId.current = '';
    }, [params]);

    useEffect(() => {
        loadStructureConfiguration();
        loadExtendedCodes();
    }, [loadStructureConfiguration, loadExtendedCodes]);

    useEffect(() => {
        const fetchPerm = async () => {
            if (userContext && userContext.user && userContext.user.id) {
                const pId = (params as any).id;
                const projectPerms = await APIServiceManager.PermissionService.getPermissions(userContext.user.id, pId);

                if (projectPerms && projectPerms.length > 0) {
                    setHasEditorRole(projectPerms[0].permissions.includes('project-manager'));
                }
            }

        };
        fetchPerm();
    }, [userContext, params]);

    const renderExtCodesList = useCallback(() => (
        <List>
            {extendedCodes.map((extCode: any, index: number) => (
                <ListItem key={extCode._id} sx={{ my: 1 }} >
                    <Box sx={{
                        p: 2,
                        border: '1px solid grey',
                        borderRadius: 2,
                        backgroundColor: 'white',
                        width: '100%',
                        '& .editable-field': {
                            height: 39
                        },
                    }}>
                        <EditableField
                            // defaultState={lastCreatedId.current === extCode._id ? 'editing' : 'viewing'}
                            defaultValue={extCode.name}
                            onValidate={(newName: string) => handleExtendedCodeChange({ type: 'setExtCodeName', value: { index, newName, id: extCode._id } })}
                            onDelete={() => handleExtendedCodeChange({ type: 'removeExtCode', value: { index, id: extCode._id } })}
                            alwaysDisplayIcon displayEditButton displayDeleteButton confirmDeleteText={t('pablo:label.confirmDelete')} />
                    </Box>
                </ListItem>
            )
            )}
        </List>
    ), [extendedCodes, i18n.language]);

    return (
        <div>
            <h4>{t('pablo:pages.structureConfig.title')}</h4>
            <form>
                <Grid>
                    <Grid item xs={12} container sm>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!hasEditorRole}
                                        checked={structureConfiguration.displayLevel}
                                        onChange={(event: any, checked: boolean) => handleConfigurationChange({ type: 'setDisplayLevel', value: checked })}
                                    />
                                }
                                label={t('pablo:pages.assetStr.structure.showNodeLevel').toString()}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!hasEditorRole}
                                        checked={structureConfiguration.displayFullCode}
                                        onChange={(event: any, checked: boolean) => handleConfigurationChange({ type: 'setDisplayFullCode', value: checked })}
                                    />
                                }
                                label={t('pablo:pages.assetStr.structure.showNodeFullCode').toString()}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={!hasEditorRole}
                                        checked={structureConfiguration.displayRootCode}
                                        onChange={(event: any, checked: boolean) => handleConfigurationChange({ type: 'setDisplayRootCode', value: checked })}
                                    />
                                }
                                label={t('pablo:pages.assetStr.structure.showNodeRootCode').toString()}
                            />
                            <FormControlLabel
                                sx={{
                                    '& .MuiFormControlLabel-label': { mr: 2 }, ml: 4
                                }}
                                control={
                                    <TextField
                                        disabled={!hasEditorRole}
                                        value={structureConfiguration.codeSeparator}
                                        onChange={(event: any) => handleConfigurationChange({ type: 'setCodeSeparator', value: event.target.value })}
                                    />
                                }
                                label={t('pablo:pages.assetStr.structure.separator').toString()}
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} container sm>
                        <Grid item xs={12} container sm>
                            <Grid item xs={8}>
                                <h4>{t('pablo:pages.structureConfig.extCodesTitle')}</h4>
                            </Grid>
                            <Grid item xs={4}>
                                <IconButton className='fa fa-plus' onClick={() => handleExtendedCodeChange({ type: 'addExtCode', value: '' })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Box sx={{ width: 0.7, overflowY: 'auto', height: '100%', maxHeight: '50vh' }}>
                                {renderExtCodesList()}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div >
    );
}
