import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogContent, Grid, TextField } from "@material-ui/core"
import { CloseDialogTitle } from "src/components"

export default function CreateSnapshotModal(props: {
    open: boolean,
    onSubmit: (name: string) => void,
    onClose: () => void,
    title: string,
}) {

    const [name, setName] = useState('')

    const { t } = useTranslation(['pablo'])
    const handleSubmit = (event: any) => {
        props.onSubmit(name)
    }
    const handleChange = (event: any) => {
        setName(event.target.value)
    }

    return (
        <Dialog open={props.open}
            onClose={props.onClose}
            onClick={(e: any) => e.stopPropagation()}
            fullWidth={true} maxWidth='sm' >
            <CloseDialogTitle onClose={props.onClose} title={props.title} />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label={t('pablo:table.headers.name')}
                            variant="outlined"
                            value={name}
                            onClick={(e: any) => e.preventDefault()}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid style={{ marginBottom: 8 }} container justify="flex-end">
                        <Button onClick={handleSubmit}>
                            {t('pablo:button.submit')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    )
}