export const prepareUri = (url: string, queryParams: any) => {
    let paramStrings = [];
    for (let p in queryParams) {
        if (queryParams.hasOwnProperty(p)) {
            if (queryParams[p]) {
                paramStrings.push(`${encodeURIComponent(p)}=${encodeURIComponent(queryParams[p])}`);
            }
        }
    }
    const paramsString = paramStrings.join('&');
    return `${url}${paramsString ? '?' + paramsString : ''}`;
}
