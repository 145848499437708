import { Button, Dialog, DialogContent, Grid, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditableTree, { TreeData } from 'editable-tree';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { FrontServiceManager } from 'src/services';


type NewAssetModalProps = {
    show: boolean;
    onSubmit: (event: any, data: any) => void;
    onHide: () => void;
}

const useStyles = makeStyles((theme: any) => ({
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));

export default function NewAssetModal(props: NewAssetModalProps) {
    const classes = useStyles();
    const { t } = useTranslation(['pablo']);
    const projectContext = useContext(ProjectContext);

    const [showSubModal, setShowSubModal] = useState(false);

    const dataReducer = (state: any, action: { type: string, value: any }) => {
        switch (action.type) {
            case 'setStructureId':
                return {
                    ...state,
                    structureId: action.value,
                };
            case 'setCode':
                return {
                    ...state,
                    code: action.value,
                };
            case 'setName':
                return {
                    ...state,
                    name: action.value,
                };
            case 'setCodeName':
                return {
                    ...state,
                    codeName: action.value,
                };
            case 'setState':
                return action.value;
            default:
                throw new Error();
        }
    };

    const [expanded, setExpanded] = useState<Array<string>>([]);

    useEffect(() => {
        if (projectContext && projectContext.currentProject && projectContext.currentProject._id) {
            const exp = localStorage.getItem(`aimspainter_structure_tree_page_expanded_nodes_${projectContext.currentProject._id}`);
            if (exp) {
                const list = JSON.parse(exp);
                if (Array.isArray(list)) {
                    setExpanded(list);
                }
            }
        }
    }, [projectContext]);

    const [data, dispatch] = useReducer(dataReducer, { structureId: '', code: '', name: '', codeName: '' });
    const [assetStructure, setAssetStructure] = useState<any>({ id: '0', code: '0', name: '0', children: [], parentId: null });
    const treeNodeClick = async (event: any, id: string, node: TreeData) => {
        dispatch({ type: 'setState', value: { structureId: node.id, codeName: node.extendedData.name, code: node.extendedData.code, name: data.name } });
        setShowSubModal(false);
    };

    useEffect(() => {
        if (props.show) {
            const fetchAssetStructure = async (level: number) => {
                const strData = await FrontServiceManager.StructureQueryService.getStructureTree(false, level);
                if (strData) {
                    setAssetStructure(strData);
                }
            };
            fetchAssetStructure(2);
            fetchAssetStructure(3);
            fetchAssetStructure(-1);
        }
    }, [props.show]);

    return (
        <Dialog open={props.show} onClose={props.onHide} fullWidth={true} maxWidth='md' style={{ minHeight: 500 }}>
            <CloseDialogTitle onClose={props.onHide} title={t('pablo:dialog.title.add')} />
            <DialogContent>
                <form className={classes.form} onSubmit={(event: any) => {
                    props.onSubmit(event, data);
                }}>
                    {/* <FormGroup > */}
                    <Grid container>
                        <Grid item xs={11}>
                            <TextField
                                required
                                fullWidth
                                label="Type Code"
                                margin="normal"
                                value={data.code != '' ? `${data.code} - ${data.codeName}` : ''}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={1} style={{ alignSelf: 'center', textAlign: 'center' }}>
                            <IconButton onClick={() => setShowSubModal(true)} className='fas fa-ellipsis-h' />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField
                                required
                                fullWidth
                                id="outlined-required"
                                label="Name"
                                variant="outlined"
                                value={data.name}
                                onChange={(e: any) => {
                                    dispatch({ type: 'setName', value: e.target.value });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit">
                        {t('pablo:button.submit')}
                    </Button>
                </form>
                <Dialog maxWidth='md' fullWidth open={showSubModal} onClose={() => setShowSubModal(false)}>
                    <CloseDialogTitle onClose={() => setShowSubModal(false)} title={t('pablo:dialog.title.select')} />
                    <DialogContent dividers={true}>

                        <div style={{ height: '70vh', overflow: 'hidden' }}>
                            <EditableTree
                                defaultExpanded={expanded}
                                onNodeSelect={treeNodeClick}
                                data={assetStructure}
                                showNodeLevel={false}
                            />
                        </div>

                    </DialogContent>
                </Dialog>
            </DialogContent>
        </Dialog>
    );
}
