import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { IProject } from 'src/core';
import { applyProjectConfigurationToCookies } from 'src/core/business/project.business';
import { API, APIServiceManager, PermissionService } from '../services';
import { UserContext } from './user.context';

export interface ProjectContextInterface {
    currentProject: IProject;
    toggle: boolean;
    projects: IProject[];
    setProject: (project: IProject) => void;
    refresh: () => void;
}

const ProjectContext = React.createContext<ProjectContextInterface>({
    currentProject: {
        code: '',
        name: '',
        bucket: '',
        permissions: [],
        source: 'forge'
    },
    toggle: false,
    projects: [],
    setProject: (project: IProject) => { return null; },
    refresh: () => { return null; },
});

function ProjectProvider(props: any) {
    const _api: API = APIServiceManager.API;
    const _permissionService: PermissionService = APIServiceManager.PermissionService;
    const userContext = useContext(UserContext);

    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_level',
        'aimspainter_asset_structure_display_fullCode',
        'aimspainter_asset_structure_display_rootCode',
        'aimspainter_asset_structure_display_codeSeparator',
    ]);

    const initialState = {
        currentProject: {
            code: '',
            name: '',
            bucket: '',
            permissions: [],
        },
        toggle: false,
        projects: [],
        setProject: (project: IProject) => {
            setState((prevState: ProjectContextInterface) => {
                return { ...prevState, currentProject: project };
            });
            _api._project = project;

            if (project.structureConfiguration) {
                applyProjectConfigurationToCookies(cookies, setCookies, project.structureConfiguration, false);
            }
        },
        refresh: () => {
            setState((prevState: ProjectContextInterface) => {
                return { ...prevState, toggle: !prevState.toggle };
            });
        },
    };
    const [state, setState] = useState<ProjectContextInterface>(initialState);

    const loadProjects = useCallback(async () => {
        if (userContext.user && userContext.user.id && userContext.user.id !== '') {
            const permissions = await _permissionService.getPermissions(userContext.user.id, undefined, true);
            const projects = permissions.map((p: any) => {
                let project = p.project;
                project.permissions = p.permissions;
                return project;
            });
            setState((prevState: ProjectContextInterface) => {
                return { ...prevState, projects };
            });

            const recentProjectIdOnLocalStorage: string | null = localStorage.getItem('forge_aimspainter_project');
            if (recentProjectIdOnLocalStorage) {
                try {
                    const recentProject = projects.find((p: any) => p._id.toString() === recentProjectIdOnLocalStorage);
                    // state.setProject(recentProject);
                    setState((prevState: ProjectContextInterface) => {
                        return { ...prevState, currentProject: recentProject };
                    });
                    _api._project = recentProject;

                } catch (error) {
                    console.log(error);
                }
            }
        }
    }, [_api, _permissionService, userContext]);

    useEffect(() => {
        loadProjects();
    }, [loadProjects, userContext, state.toggle]);

    return (
        <ProjectContext.Provider value={state} >
            {props.children}
        </ProjectContext.Provider>
    );
}

export { ProjectContext, ProjectProvider };
