import React from 'react';
import ReactDOM from 'react-dom';
import { MinAssetTable } from 'src/components';
import { ForgeViewerExtensionOptions } from '../forge-viewer.interface';
import ForgeViewerEmitter from '../forge.event';

export default class AssetListPanelExtension extends Autodesk.Viewing.UI.DockingPanel {

    DOMContent: HTMLDivElement;
    viewer: any;
    reactNode: any;
    options: ForgeViewerExtensionOptions;
    _visible: boolean = false;

    constructor(
        viewer: any,
        id: string,
        options: any | undefined
    ) {
        super(viewer.container as HTMLElement, id, 'Asset List', options);
        this.options = options;
        this.viewer = viewer;
        this.container.classList.add('react-docking-panel');
        this.container.classList.add('asset-list-panel');
        this.DOMContent = document.createElement('div');
        this.DOMContent.className = 'content docking-panel docking-panel-scroll docking-panel-container-solid-color-a';
        this.container.appendChild(this.DOMContent);
    }

    initialize() {
        super.initialize();
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }

    onAssetClick = async (asset?: any) => {
        if (asset && asset._id) {
            ForgeViewerEmitter.emit('onAssetSelected', asset);
        } else {
            ForgeViewerEmitter.emit('onAssetSelected', null);
        }
    }

    setVisible(show: boolean) {
        super.setVisible(show);
        this._visible = show;
        if (show) {
            this.reactNode = <MinAssetTable
                colorIndexes={this.options.colorIndexes}
                // getAsset={this.options.getAsset}
                onClick={this.onAssetClick} />;
            ReactDOM.render(
                this.reactNode,
                this.DOMContent
            );
        } else if (this.reactNode) {
            ReactDOM.unmountComponentAtNode(this.DOMContent);
            this.reactNode = null;
        }
    }

    toogleVisibility() {
        this.setVisible(!this._visible);
    }
}