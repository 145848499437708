import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { APIServiceManager, AssetService } from 'src/services';

import DataGrid, { Column, SelectColumn, SortColumn } from 'react-data-grid';

import { ProjectContext } from 'src/contexts/project.context';
//import './react-data-grid.css';
import { Icon, Tooltip } from '@material-ui/core';
import SpinnerText from 'src/components/ui/spinner/spinner.component';
import './asset-datagrid.component.css';

function getComparator(sortColumn: string) {
    return (a: any, b: any) => {

        const aVal = a[sortColumn] === undefined ? '' : a[sortColumn]
        const bVal = b[sortColumn] === undefined ? '' : b[sortColumn]

        return aVal.localeCompare(bVal);
    }
}

function rowKeyGetter(row: any) {
    return row._id;
}

function isAtBottom(event: React.UIEvent<HTMLDivElement>): boolean {
    const target = event.target as HTMLDivElement;

    return Math.abs(target.scrollHeight - Math.round(target.clientHeight + target.scrollTop)) < 200;
}

function autoFocusAndSelect(input: HTMLInputElement | null) {
    input?.focus();
    input?.select();
}


function TextEditorWithEvents({
    row,
    column,
    onRowChange,
    onClose
}: any) {
    const _assetListService: AssetService = APIServiceManager.AssetService;

    return (
        <input
            className="rdg-text-editor"
            ref={autoFocusAndSelect}
            value={row[column.key]}
            onChange={event => onRowChange({ ...row, [column.key]: event.target.value })}
            onBlur={() => {
                const prop = row.properties.find((p: any) => p.key === column.key);
                if (row && row._id && prop && prop.propertyId && column && column.key) {
                    _assetListService.updateAssetProperty(row._id, prop.propertyId, row[column.key])
                }
                onClose(true)
            }}
        />
    );
}

export default function AssetDataGrid(props: any) {
    const _assetListService = APIServiceManager.AssetService;

    const projectContext = useContext(ProjectContext);

    const [rows, setRows] = useState<any>([]);
    const [total, setTotal] = useState('');
    const [properties, setProperties] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState(() => new Set<any>());
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('loading');

    const [hasEditorRole, setHasEditorRole] = useState(false);

    const [sortColumns, setSortColumns] = useState<readonly Readonly<SortColumn>[]>([]);
    const sortColumnsValue = useRef<Array<SortColumn>>([]);

    const [filters, setFilters] = useState<any>({});
    const filtersValue = useRef<any>({});

    useEffect(() => {
        setIsLoading(true);
        refreshData();
        setIsLoading(false);
    }, [props.refresh])

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    useEffect(() => {
        sortColumnsValue.current = Array.from(sortColumns);
    }, [sortColumns])

    useEffect(() => {
        setLoadingText(props.loadingText);
    }, [props.loadingText])


    useEffect(() => {
        setHasEditorRole(projectContext.currentProject.permissions.includes('part-editor'));
    }, [projectContext])

    useEffect(() => {
        setIsLoading(true);
        const deleteAssets = async () => {
            if (selectedRows.size > 0) {
                const ids = Array.from(selectedRows)
                const CHUNK_SIZE = 500
                for (let index = 0; index < ids.length; index += CHUNK_SIZE) {
                    const idsSubset = ids.slice(index, index + CHUNK_SIZE);

                    const _assetListService = APIServiceManager.AssetService;
                    await _assetListService.removeBulkAssets(idsSubset)
                }

                await refreshData();
            }
        }
        deleteAssets()
        setIsLoading(false);
    }, [props.deleteAsset])

    const page = useRef(0);
    const pageSize = 100;

    const getData = async (queryFilters: any) => {

        const queryResult = await _assetListService.getAsset(page.current, pageSize, undefined, 'part', queryFilters);
        const queryData = queryResult.data.map((asset: any) => {
            asset.properties.forEach((prop: any) => {
                asset[prop.key] = prop.value;
            }, {});
            asset.code = asset.structure.code
            return asset;
        });

        setTotal(queryResult.total.toString())
        return { queryData, queryProperties: queryResult.properties };
    };

    const refreshData = async () => {
        let allData: any = [];
        for (let i = 0; i < page.current + 1; i++) {
            const queryResult = await _assetListService.getAsset(i, pageSize, undefined, 'part');
            const data = queryResult.data.map((asset: any) => {
                asset.properties.forEach((prop: any) => {
                    asset[prop.key] = prop.value;
                }, {});
                asset.code = asset.structure.code
                return asset;
            });
            allData = allData.concat(data)
        }

        setRows(allData)
    };

    useEffect(() => {
        setIsLoading(true);
        page.current = 0;
        getData(props.queryFilters).then(({ queryData, queryProperties }: any) => {
            if (queryProperties !== undefined && queryProperties.length > 0) {
                const uniqueProp: Array<any> = [];
                queryProperties.forEach((p: any) => {
                    if (uniqueProp.findIndex((up: any) => up.key === p.key) < 0) {
                        uniqueProp.push(p)
                    }
                });
                setProperties(uniqueProp);
            }
            filtersValue.current = {};
            setFilters({})
            setRows(queryData);
            setIsLoading(false);

        }).catch(err => {
            setIsLoading(false);
        })
    }, [projectContext, props.queryFilters]);

    const buildHeaderWithFilter = (item: any, propertyDescription: string) => {
        return (
            <>
                <div onClick={() => {
                    if (sortColumnsValue.current && sortColumnsValue.current.length > 0) {
                        if (sortColumnsValue.current[0].columnKey === item.column.key) {
                            setSortColumns([
                                {
                                    columnKey: item.column.key,
                                    direction: sortColumnsValue.current[0].direction === 'ASC' ? 'DESC' : 'ASC'
                                }
                            ])
                            return;
                        }
                    }
                    setSortColumns([
                        {
                            columnKey: item.column.key,
                            direction: 'ASC'
                        }
                    ])
                }}>
                    <span>{item.column.key}</span>
                    <Tooltip title={propertyDescription}>
                        <Icon className='fas fa-question-circle' fontSize="small" style={{ float: 'right', margin: 5 }} />
                    </Tooltip>
                </div>
                <div>
                    <input
                        style={{ width: '100%' }}
                        value={filters[item.column.key]}
                        onChange={(e) => {
                            const newFilters = { ...filtersValue.current };
                            newFilters[item.column.key] = e.target.value;
                            filtersValue.current = newFilters;
                            setFilters(newFilters);
                        }}
                    />
                </div>

            </>

        )
    }

    const columnBuilder = (property: any) => {
        return {
            key: property.key,
            name: property.key,
            width: 200,
            frozen: property.key === 'UAID',
            headerCellClass: 'filter-cell',
            headerRenderer: (item: any) => buildHeaderWithFilter(item, property.description),
            editor: TextEditorWithEvents,
            formatter: ({ row }: any) => row[property.key] !== undefined ?
                <span>
                    {row[property.key].startsWith('pw:\\\\') ?
                        <a href={row[property.key]}>{row[property.key]}</a> :
                        row[property.key]
                    }
                </span> :
                <div style={{ textAlign: 'center', color: 'grey', fontStyle: 'italic', fontSize: 10 }}>NA</div>,
            editable: (row: any) => {
                return hasEditorRole && row[property.key] !== undefined && !row[property.key].startsWith('pw:\\\\');
            },
        }
    };

    const columnsInput = [
        SelectColumn,
        {
            key: 'name',
            name: 'Name',
            width: 180,
            frozen: true,
            headerCellClass: 'filter-cell',
            headerRenderer: (item: any) => buildHeaderWithFilter(item, 'Name of the asset'),
        },
        {
            key: 'code',
            name: 'Code',
            width: 80,
            frozen: true,
            headerCellClass: 'filter-cell',
            headerRenderer: (item: any) => buildHeaderWithFilter(item, 'Code of the asset'),
        },
    ];

    const columns = useMemo((): readonly Column<any>[] => {
        return columnsInput.concat(properties.map((c: any) => columnBuilder(c)))
    }, [rows, properties])

    const filteredRows = useMemo(() => {
        const filterRowMethod = (r: any) => {
            return Object.entries(filtersValue.current).map(([key, value]) => {
                if (typeof value === 'string') {
                    if (value.length > 0) {
                        return r[key] !== undefined && r[key].toLowerCase().includes(value.toLowerCase());
                    } else {
                        return true;
                    }
                }
            }).every(Boolean);
        };

        return rows.filter(filterRowMethod);
    }, [rows]);

    function handleFill({ columnKey, sourceRow, targetRows }: any): any[] {
        return targetRows.map((row: any) => {
            if (row[columnKey] !== undefined) {
                const prop = row.properties.find((p: any) => p.key === columnKey)
                _assetListService.updateAssetProperty(row._id, prop._id, sourceRow[columnKey])
                return ({ ...row, [columnKey as keyof any]: sourceRow[columnKey as keyof any] })
            } else {
                return row
            }
        });
    }

    function handlePaste({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }: any): any {
        const prop = targetRow.properties.find((p: any) => p.key === targetColumnKey)
        _assetListService.updateAssetProperty(targetRow._id, prop._id, sourceRow[sourceColumnKey])
        return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey as keyof any] };
    }

    const scrollX = useRef(0);
    const scrollY = useRef(0);


    async function handleScroll(event: React.UIEvent<HTMLDivElement>) {

        const target = event.target as HTMLDivElement;

        if (target.scrollLeft !== scrollX.current) {
            scrollX.current = target.scrollLeft;
            return;
        }

        if (target.scrollTop !== scrollY.current) {
            scrollY.current = target.scrollTop;
            if (isLoading || !isAtBottom(event)) return;
            if (rows.length.toString() === total) return;

            setIsLoading(true);

            page.current++;
            const { queryData, queryProperties } = await getData(props.queryFilters);
            if (queryProperties !== undefined && queryProperties.length > 0) {
                const uniqueProp: Array<any> = properties;
                queryProperties.forEach((p: any) => {
                    if (uniqueProp.findIndex((up: any) => up.key === p.key) < 0) {
                        uniqueProp.push(p)
                    }
                });
                setProperties(uniqueProp);
            }

            setRows([...rows, ...queryData]);
            setIsLoading(false);
        }
    }

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return filteredRows;
        const sortedRows: Array<any> = [...filteredRows];

        sortedRows.sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
        return sortedRows;
    }, [filteredRows, sortColumns]);

    return (
        <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
            <div style={{
                backgroundColor: '#ffffffCC',
                width: 'calc(100% - 90px)',
                height: 'calc(100% - 100px)',
                position: 'absolute',
                zIndex: 10,
                marginTop: -50,
                visibility: isLoading ? 'visible' : 'collapse'
            }}>
                <SpinnerText text={loadingText} />
            </div>
            <DataGrid
                columns={columns}
                rows={sortedRows}
                rowKeyGetter={rowKeyGetter}
                onRowsChange={setRows}
                onFill={handleFill}
                onPaste={handlePaste}
                rowHeight={30}
                headerRowHeight={70}
                selectedRows={selectedRows}
                defaultColumnOptions={{
                    sortable: true,
                    resizable: true
                }}
                sortColumns={sortColumns}
                onSortColumnsChange={setSortColumns}
                onScroll={handleScroll}
                onSelectedRowsChange={setSelectedRows}
                style={{ width: '100%', height: 'calc(100% - 20px)', resize: 'both' }}
            />
            <label>Loaded {rows.length} out of {total}</label>

        </div>

    );
}