import React, { useEffect, useMemo, useState } from 'react';

import { Chip, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {
    DataGridPro, enUS, frFR, GridColumns, GridFilterModel, GridLinkOperator, GridRenderCellParams, GridRowParams, GridSelectionModel, GridSortDirection, GridSortModel, GridToolbarFilterButton, GridValueGetterParams, useGridApiRef
} from '@mui/x-data-grid-pro';
import { RenderCellExpand } from 'src/components/ui/grid-cell-expand';
import { APIServiceManager } from 'src/services';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        minWidth: 200,
        marginLeft: 10,
    },
    title: {
        //paddingLeft: 60,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    row: {

    },
    disableRow: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[500],
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    linkCell: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            overflowY: 'hidden',
            overflowX: 'hidden',
            '&:hover': {
                overflowX: 'scroll',
            }

        },
    },
    toolbar: {
        minWidth: 200,
        margin: '5px 20px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    searchToolbar: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
    cell: {
        outline: 'unset !important',
    },
    header: {
        outline: 'unset !important',
    }
}));

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
    librarySelector: any;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    const classes = useStyles();

    return (
        <div className={classes.searchToolbar}>
            <div>
                {props.librarySelector}
            </div>
            <div>
                <GridToolbarFilterButton />
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function PropertyByLibrary(props: any) {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['pablo']);
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(true);

    const [currLibrary, setCurrLibrary] = useState<any>({ name: 'All', _id: undefined });
    const [data, setData] = useState<Array<any>>([]);
    const [filteredData, setFilteredData] = useState<Array<any>>([]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            // //{ columnField: 'key', operatorValue: 'contains', value: 'Name' },
            // { columnField: 'key', operatorValue: 'equals', value: "Name", id: new ObjectID().toString() },
            // { columnField: 'key', operatorValue: 'equals', value: "test", id: new ObjectID().toString() },
        ],
        linkOperator: GridLinkOperator.Or
    });

    const [pageSize, setPageSize] = React.useState<number>(25);

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'key',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [localeText, setLocaleText] = useState<any>(enUS);

    const [searchText, setSearchText] = React.useState('');

    useEffect(() => {
        if (props.libraries && props.libraries.length > 0 && props.libraries[0]) {
            setCurrLibrary(props.libraries[0]);
        }
    }, [props.libraries]);

    useEffect(() => {
        setSelectionModel([]);
        const fetchProps = async () => {
            setLoading(true);
            const properties = await APIServiceManager.PropertyService.getProperties({
                libraryId: currLibrary._id ? currLibrary._id : null,
                includepset: true
            }, 0, -1);

            const propWithId = properties.data.map((r: any) => { r.id = r._id; return r; });
            setData(propWithId);
            setFilteredData(propWithId);
            setLoading(false);
        };
        fetchProps();
    }, [currLibrary]);


    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field] ? row[field].toString() : undefined);
            });
        });
        // setFilteredData(filteredRows);
        if (filteredRows.length === 0) {
            setFilterModel({
                items: [{ columnField: 'id', operatorValue: 'equals', value: '0', id: 0 }],
            });
        }
        else if (filteredRows.length === data.length) {
            setFilterModel({
                items: [],
            });
        }
        else {
            const filters = filteredRows.map((f: any, index: number) => {
                return { columnField: 'id', operatorValue: 'equals', value: f.id, id: index };
            });
            setFilterModel({
                items: filters,
                linkOperator: GridLinkOperator.Or
            });

        }
    };

    const columns: GridColumns = useMemo(() => {
        return [
            { field: 'id', editable: false, flex: 0, hide: true },
            { field: 'key', headerName: t('pablo:table.headers.code'), editable: false, flex: 0.5 },
            { field: 'name', headerName: t('pablo:table.headers.name'), editable: false, flex: 0.5 },
            { field: 'description', headerName: t('pablo:table.headers.description'), editable: false, flex: 1.0, renderCell: RenderCellExpand },
            {
                field: 'propertySets', headerName: t('pablo:table.headers.propertyset'), editable: false, flex: 0.8,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return (
                        <div className={classes.linkCell}>
                            <div className="cellValue" >
                                {
                                    cellValues.row.propertySets.map((propSet: any) => (
                                        <Chip key={propSet.name} style={{ marginLeft: 5 }}
                                            label={propSet.name} />
                                    ))}
                            </div>
                        </div>
                    );
                },
                valueGetter: (params: GridValueGetterParams) => {
                    const cellValue = params.row[params.field];
                    if (Array.isArray(cellValue)) {
                        return cellValue.map(pset => pset.name).join(' ');
                    }
                    else {
                        return undefined;
                    }
                }
            },
            { field: 'isTechProperty', headerName: t('pablo:table.headers.technicalProp'), type: 'boolean', editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'category', headerName: t('pablo:table.headers.category'), editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'unit', headerName: t('pablo:table.headers.unit'), editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'format', headerName: t('pablo:table.headers.format'), editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'type', headerName: t('pablo:table.headers.type'), editable: false, minWidth: 150, flex: 0.3, hide: true },
        ];
    }, [i18n.language, localeText]);

    const librarySelector = useMemo(() => {
        return Array.isArray(props.libraries) ?
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl className={classes.form}>
                        <InputLabel>{t('pablo:table.headers.library')}</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={currLibrary}
                            onChange={(e: any) => setCurrLibrary(e.target.value)}
                        >
                            {
                                props.libraries.map((l: any, index: number) => <MenuItem key={index} id={l.name} value={l}>{l.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid> : null;
    }, [props.libraries, currLibrary]);

    useEffect(() => {
        switch (i18n.language) {
            case 'fr':
                setLocaleText(frFR.components.MuiDataGrid.defaultProps.localeText);
                break;
            case 'en':
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
            default:
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
        }
    }, [i18n.language]);

    return (
        <DataGridPro
            apiRef={apiRef}
            localeText={localeText}
            classes={{
                cell: classes.cell,
                columnHeader: classes.header
            }}
            style={{ flexGrow: 1, background: 'white' }}

            rows={data}

            columns={columns}
            pagination

            pageSize={pageSize}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 100]}

            loading={loading}

            components={{
                Toolbar: QuickSearchToolbar,
            }}

            componentsProps={{
                toolbar: {
                    librarySelector: librarySelector,
                    value: searchText,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                        requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                },
            }}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setTimeout(() => {
                    if (props.onSelectionChange) {
                        props.onSelectionChange(Array.from(newSelectionModel));
                    }
                });
            }}
            isRowSelectable={(params: GridRowParams) => {
                if (Array.isArray(props.disabledIds)) {
                    return !props.disabledIds.includes(params.id);
                }
                return true;
            }}
            getRowClassName={(params: GridRowParams) => {
                if (Array.isArray(props.disabledIds)) {
                    return props.disabledIds.includes(params.id) ? classes.disableRow : classes.row;
                }
                return classes.row;
            }}

            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
        />);

}

export default PropertyByLibrary;
