import { prepareUri } from 'src/core/helpers/requests';
import { APIServiceManager, API } from '../..';

class ExportService {
    api: API = APIServiceManager.API;

    name() {
        return 'ExportService';
    }

    async exportPropertyLibraryWithStructure(libraryId: string, options: any): Promise<any> {

        const req = await this.api.axios.get(
            prepareUri(`/project/export/library/${libraryId}`, options), {
            responseType: 'blob'
        });
        return req.data;
    }

}

export { ExportService };
