/* eslint-disable */
import { APIServiceManager, API } from '../..';
import { IPabloAlignment } from 'src/pablo-shared';

class AlignmentService {
    api: API = APIServiceManager.API;

    name() {
        return 'AlignmentService';
    }

    async addAlignment(alignmentData: any): Promise<{ data: IPabloAlignment }> {
        const req = await this.api.axios.post('/project/alignment', alignmentData);
        return req.data;
    }

    async getAlignments(page: number, limit: number): Promise<{ data: IPabloAlignment[], pages: number; nextPage: string, total: number }> {
        const req = await this.api.axios.get('/project/alignment', {
            params: {
                page,
                limit,
            }
        });
        return req.data;
    }

    async parseAlignment(fileContent: string, srs: string): Promise<any> {
        const req = await this.api.axios.post('/project/alignment/parse', { content: fileContent, srs });
        return req.data;
    }
}

export { AlignmentService };
