import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from '@material-ui/core';
import EditableTree, { TreeData } from 'editable-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import { FrontServiceManager } from 'src/services';

export default function ModalStructureTree(props: any) {

    const { t } = useTranslation(['pablo']);

    const [selectedStructures, setSelectedStructures] = useState<Set<string>>(new Set());

    const [isWithInheritance, setIsWithInheritance] = useState<boolean>(false);
    const [assetStructure, setAssetStructure] = useState<TreeData>({ id: '', label: '' });

    const backgroundLoadTreeByLevel = () => {
        loadTreeData(3);
        loadTreeData(5);
        loadTreeData(-1);
    }

    const loadTreeData = async (level: number) => {
        const strData = await FrontServiceManager.StructureQueryService.getStructureTree(false, level);
        if (strData) {
            setAssetStructure(strData);
        }
    }

    useEffect(() => {
        setSelectedStructures(new Set())
        // selectedStructures.current.clear();
        if (props.open) {
            backgroundLoadTreeByLevel();
        }
    }, [props.open])

    return (
        <Dialog open={props.open}
            onClose={() => {
                props.onHide();
            }}
            onClick={(e: any) => e.stopPropagation()}
            fullWidth={true} maxWidth='sm' >
            <CloseDialogTitle onClose={() => {
                props.onHide();
            }} title={t('pablo:dialog.title.editStructure')} />
            <DialogContent>
                <div style={{ height: '70vh', overflow: 'hidden' }}>
                    <EditableTree
                        components={{
                            label: (node: TreeData, level: number) => (
                                <span>
                                    <Checkbox
                                        checked={selectedStructures.has(node.id)}
                                        style={{ padding: '0px 5px' }}
                                        onChange={(e: any, checked: boolean) => {
                                            if (!checked) {
                                                if (selectedStructures.has(node.id)) {
                                                    const newSet = new Set(selectedStructures)
                                                    newSet.delete(node.id);
                                                    setSelectedStructures(newSet)
                                                }
                                            } else {
                                                const newSet = new Set(selectedStructures)
                                                newSet.add(node.id);
                                                setSelectedStructures(newSet)
                                            }

                                        }} />
                                    <span>{node.label}</span>
                                </span>
                            )
                        }}
                        data={assetStructure}
                        showNodeLevel={false}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <div style={{ width: '100%', justifyContent: 'flex-start', marginLeft: 20 }}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isWithInheritance}
                                onChange={(e: any, checked: boolean) => {
                                    setIsWithInheritance(checked)
                                }}
                            />
                        }
                        label={t('pablo:components.modalAssetTree.withInheritance')}
                    />
                </div>
                <Button onClick={() => {
                    props.onSubmit(Array.from(selectedStructures), isWithInheritance)
                }}>
                    {t('pablo:button.submit')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}