import { Dialog } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMaterialTable } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { assetIsFrozen } from 'src/core/business/asset.business';
import { FrontServiceManager } from 'src/services';

type AssetTableProps = {
    colorIndexes: any[];
    style?: string;
    onClick: (asset?: { _id: string, colorIndex: number, data?: any, status?: string }) => void;
}


const blackStyle = {
    row: {
        backgrounColor: 'transparent',
        color: '#f3f7fb'
    },
    selectedRow: {
        backgrounColor: '#EEE',
        color: 'black'
    },
    searchFieldAndCellStyle: {
        color: '#f3f7fb'
    }
};

const whiteStyle = {
    row: {
        backgrounColor: 'unset',
        color: 'grey'
    },
    selectedRow: {
        backgrounColor: '#EEE',
        color: 'black'
    },
    searchFieldAndCellStyle: {
        color: 'grey'
    }
};

function MinAssetPropModal(props: any) {
    return (
        <Dialog fullWidth open={props.show} onClose={props.onHide}>
            <StyledMaterialTable
                title="Asset Properties"
                options={{
                    pageSize: 10
                }}
                columns={[
                    { title: 'Key', field: 'key' },
                    { title: 'Value', field: 'value' },
                    { title: 'Category', field: 'category', defaultSort: 'asc' },
                ]}
                data={props.data}
            />
        </Dialog>
    )
}

export function MinAssetTable(props: AssetTableProps) {
    const tableRef = useRef<any>(null);

    const [selectedRow, setSelectedRow] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedAssetProp, setSelectedAssetProp] = useState(null);

    const projectContext = useContext(ProjectContext);
    const { t } = useTranslation(['pablo']);

    const style = props.style === 'white' ? whiteStyle : blackStyle;

    useEffect(() => {
        tableRef.current!.onQueryChange();
    }, [projectContext]);

    const color = (rowData: any) => {
        if (assetIsFrozen(rowData)) {
            return '#A9A9A9';
        } else {
            return (selectedRow === rowData.tableData.id) ? style.selectedRow.color : style.row.color;
        }
    }

    return (
        <>
            <StyledMaterialTable
                height={95}
                transparent
                tableRef={tableRef}
                title="Asset List"
                columns={[
                    { title: 'Name', field: 'name', cellStyle: { padding: '0.3rem', maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' } },
                    { title: 'Code', field: 'structure.code', cellStyle: { padding: '0.3rem' } },
                    {
                        title: 'color', field: 'colorIndex', cellStyle: { padding: '0.3rem' },

                        render: rowData =>
                            <svg width="60px" height="15">
                                <rect style={{ height: '100%', width: '100%', fill: props.colorIndexes[rowData.colorIndex] }} />
                            </svg>
                    }
                ]}
                onRowClick={((evt: any, currentSelectedRow: any) => {
                    if (selectedRow === currentSelectedRow.tableData.id) {
                        setSelectedRow(null)
                        props.onClick();
                    } else {
                        setSelectedRow(currentSelectedRow.tableData.id);
                        props.onClick({
                            _id: currentSelectedRow._id,
                            status: currentSelectedRow.status,
                            colorIndex: currentSelectedRow.colorIndex,
                            data: currentSelectedRow
                        })
                    }
                })}
                actions={[
                    {
                        icon: 'storage',
                        tooltip: t('pablo:tooltip.show'),
                        onClick: (event, rowData) => {
                            setSelectedAssetProp(rowData.properties);
                            setShowModal(true);
                        }
                    }
                ]}
                onChangeRowsPerPage={(size: number) => {
                    setPageSize(size);
                }}
                options={{
                    toolbar: true,
                    showTitle: false,
                    paging: true,
                    grouping: false,
                    pageSize: pageSize,
                    tableLayout: 'auto',
                    exportButton: false,
                    selection: false,
                    pageSizeOptions: [10, 20, 50, 100],
                    searchFieldStyle: style.searchFieldAndCellStyle,
                    actionsCellStyle: style.searchFieldAndCellStyle,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.tableData.id) ? style.selectedRow.backgrounColor : style.row.backgrounColor,
                        fontWeight: (selectedRow === rowData.tableData.id) ? 'bold' : 'unset',
                        color: color(rowData),
                        overflowWrap: (selectedRow === rowData.tableData.id) ? 'unset' : 'break-word',
                        overflow: (selectedRow === rowData.tableData.id) ? 'auto' : 'hidden',
                        whiteSpace: (selectedRow === rowData.tableData.id) ? 'normal' : 'nowrap',
                        textOverflow: (selectedRow === rowData.tableData.id) ? 'unset' : 'ellipsis',
                        maxHeight: (selectedRow === rowData.tableData.id) ? '250px' : 'unset',

                    })
                }}
                data={FrontServiceManager.AssetTableQueryService.getAsset}
            />
            <MinAssetPropModal show={showModal} onHide={() => setShowModal(false)} data={selectedAssetProp} />
        </ >
    )
}
