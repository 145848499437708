import {
    Box,
    Button,
    Card,
    CardContent, CardMedia,
    CircularProgress,
    Grid, Icon, IconButton,
    List,
    ListItem, Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {
    DataGridPro,
    gridPageSelector,
    gridPageSizeSelector,
    GridRenderCellParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiRef,
    useGridSelector
} from '@mui/x-data-grid-pro';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DropFileDialog } from '../../../components';
import { ProjectContext } from '../../../contexts/project.context';
import { APIServiceManager } from '../../../services';
import './models.page.css';

function ModelCard(props: any) {
    const { t } = useTranslation(['pablo']);
    const [manifest, setManifest] = useState<any>({
        models: [],
        derivatives: []
    });
    const [thumbnail, setThumbnail] = useState<any>({});

    useEffect(() => {
        const loadManifest = async () => {
            const manifest = await APIServiceManager.ForgeService.getManifest(props.urn);
            if (manifest && manifest.derivatives && manifest.derivatives.length > 0) {
                const svf = manifest.derivatives.find((d: any) => d.outputType === 'svf' || d.outputType === 'svf2')
                manifest.models = [];
                if (svf) {
                    manifest.models = svf.children.filter((c: any) => c.type === 'geometry')
                }
                setManifest(manifest);
            }
        }

        const loadThumbnail = async () => {
            const thumbnail = await APIServiceManager.ForgeService.getThumbnail(props.urn);
            setThumbnail(thumbnail);
        }

        loadManifest();
        loadThumbnail();
    }, [props.urn]);

    return (
        <Stack sx={{ py: 2, height: 1, backgroundColor: '#F5F5F5' }} direction="column">
            <Box sx={{ m: 1, backgroundColor: 'grey' }}>
                <Grid sx={{ flexGrow: 1, height: 1 }} container spacing={1}>
                    <Grid item xs={6}>
                        <Card sx={{ m: 1, height: 1 }}>
                            <CardMedia sx={{
                                m: 2,
                                paddingTop: '60%',
                                objectFit: 'cover',
                            }}
                                image={thumbnail && thumbnail.length > 0 ? `data:image/jpeg;base64,${thumbnail}` : '//:0'} />
                            <CardContent>
                                <Typography style={{ wordWrap: "break-word" }}>
                                    urn: {props.urn}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card sx={{ p: 1, height: 1 }}>
                            <CardContent>
                                <h3>Information</h3>
                            </CardContent>
                            <CardContent>
                                <List>
                                    <ListItem>Status: {manifest.status}</ListItem>
                                    <ListItem>Progress: {manifest.progress}</ListItem>
                                    <ListItem>Derivatives:  {manifest.derivatives.length}</ListItem>
                                    <ListItem>Models:  {Array.isArray(manifest.models) ? manifest.models.length : 0}</ListItem>
                                </List>
                            </CardContent>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {t('pablo:pages.model.modelList')}
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>Type</TableCell> */}
                                            <TableCell>Role</TableCell>
                                            <TableCell>{t('pablo:table.headers.name')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {manifest.models.map((m: any) => (
                                            <TableRow key={m.guid}
                                            >
                                                {/* <TableCell>{m.type}</TableCell> */}
                                                <TableCell>{m.role}</TableCell>
                                                <TableCell>{m.name}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </Box>

        </Stack>
    )
}

function UpdateIconButton(props: any) {
    const [updating, setUpdating] = useState(false);
    return updating ?
        <CircularProgress size="0.8rem" color='inherit' style={{ marginLeft: 8 }} />
        : <IconButton
            size='small'
            style={{ fontSize: '0.8rem', marginLeft: 2 }}
            className='fa fa-sync'
            onClick={async () => {
                setUpdating(true);
                await props.onClick();
                setUpdating(false)
            }}
        />
}

function ForgeModelsView() {
    const projectContext = useContext(ProjectContext);
    const { t } = useTranslation(['pablo']);

    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
    const [uploadFile, setUploadFile] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [hasEditorRole, setHasEditorRole] = useState(false);

    const params = useParams<{ id: string }>();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<Array<any>>([]);

    const loadModels = async () => {
        try {
            setLoading(true);
            const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
            const page = useGridSelector(apiRef, gridPageSelector);
            const result = await APIServiceManager.ForgeService.getModels(false, page, pageSize, [], undefined, (params as any).id);
            setRows(result.data);
        } catch (error) {
            setRows([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setRows([]);
        loadModels();
    }, [params.id])

    useEffect(() => {
        if (params.id) {
            localStorage.setItem('forge_aimspainter_project', params.id);
        }
        const project = projectContext.projects.find(p => p._id?.toString() === params.id);
        if (project && Array.isArray(project.permissions)) {
            setHasEditorRole(project.permissions.includes('model-editor'));
        }
    }, [projectContext, params.id])

    const getDetailPanelHeight = useCallback(() => 650, []);
    const getDetailPanelContent = useCallback(({ row }) => <ModelCard urn={row.urn} />, [],);

    const customToolbar = useCallback(() => {
        return (
            <GridToolbarContainer style={{ justifyContent: 'right' }}>
                <GridToolbarFilterButton />
                <Button
                    size="small"
                    disabled={!hasEditorRole}
                    onClick={() => setShowUploadFileDialog(true)}
                >
                    <Icon className='fas fa-plus' fontSize="small" />
                    {t('pablo:button.add')}
                </Button>
            </GridToolbarContainer>
        );
    }, [hasEditorRole]);

    const handleSubmitFile = useCallback(async () => {
        if (params.id && uploadFile && uploadFile.name && uploadFile.size) {
            setLoading(true);
            const newModelInDb = await APIServiceManager.ForgeService.addModel(params.id, uploadFile.name);
            if (newModelInDb) {
                //If file size > 100Mb split in resumable
                if (uploadFile.size > 100 * 1024 * 1024) {
                    APIServiceManager.ForgeService.uploadChunkedModel(newModelInDb._id, uploadFile).then(() => loadModels());
                } else {
                    APIServiceManager.ForgeService.uploadSingleModel(newModelInDb._id, uploadFile).catch((error: any) => {
                        console.log(error);
                    }).then(() => {
                        loadModels();
                        setShowUploadFileDialog(false);

                    });
                }
            }
            setLoading(false);
        }
    }, [params.id, uploadFile])

    return (
        <>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                columns={[
                    {
                        field: 'name',
                        headerName: t('pablo:table.headers.name'),
                        flex: 0.7
                    },
                    {
                        headerName: 'Status',
                        field: 'status',
                        flex: 0.3,
                        renderCell: (data: GridRenderCellParams) => <span>
                            {data.row.status}
                            {data.row.status !== 'complete' ? <UpdateIconButton onClick={async () => {
                                await APIServiceManager.ForgeService.updateFromManifest(data.row.id);
                                loadModels();
                            }} /> : null}
                        </span>
                    }
                ]}
                rows={rows}
                rowThreshold={0}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                components={{
                    Toolbar: customToolbar,
                }}
            />
            <DropFileDialog
                fullWidth={true}
                disabled={false}
                title=''
                show={showUploadFileDialog}
                onFileSelected={(file: any) => setUploadFile(file)}
                onImport={handleSubmitFile}
                onClose={() => setShowUploadFileDialog(false)} />
        </>

    );
}


export default ForgeModelsView;
