import { Box } from '@mui/material';
import { Clusters, GeoJSONSource, Line, Map, Popup } from 'dev4bim-react-mapbox-gl';
import GeoJSON from 'geojson';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MapTools } from 'src/components/map/MapTools';
import { MapPageContextProvider, useMapPageContext } from 'src/contexts/MapPageContext';

function MapPage() {
    return (
        <MapPageContextProvider>
            <MapPage_ />
        </MapPageContextProvider>
    );
}

function MapPage_() {
    const { t, i18n } = useTranslation(['pablo']);
    const {
        assets,
        setSelectedAsset,
        assetsFeatures,
    } = useMapPageContext();

    return (
        <Box sx={styles.container} >
            <Map
                id='map'
                accessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN as string}
                displayStats
                translation={useMemo(() => ({
                    button: {
                        cancel: t('pablo:button.cancel'),
                        confirm: t('pablo:button.confirm'),
                        clear: t('pablo:button.clear'),
                        draw: t('pablo:button.draw'),
                        close: t('pablo:button.close'),
                    },
                    start: t('pablo:components.map.start'),
                    end: t('pablo:components.map.end'),
                    newLine: t('pablo:components.map.newLine'),
                    measurement: t('pablo:components.map.measurement'),
                    totalDistance: t('pablo:components.map.totalDistance'),
                    newMarker: t('pablo:components.map.newMarker'),
                }), [i18n.language])}
            >

                <Clusters
                    id='assets-cluster'
                    data={assetsFeatures}
                    autoFocus
                    renderPopup={ClusterPopup}
                    onFeatureClicked={useCallback(function selectAsset(feature) {
                        if (!feature) return;
                        const idx = assets.findIndex((ast) => ast.name == feature.properties?.name);
                        if (idx == -1) return;
                        setSelectedAsset(assets[idx]);
                    }, [assets.length])}
                />

                <Alignments />

                <MapTools />

            </Map >
        </Box>
    );
}

function Alignments() {
    const { alignmentsFeatures } = useMapPageContext();

    return (
        <GeoJSONSource
            name='alignments-data'
            data={alignmentsFeatures}
            autoFocus
        >
            <Line
                id='alignments'
                aboveLayerId='assets-cluster'
                width={2}
                filter={{
                    operation: 'have',
                    propertyKey: 'alignment',
                }}
                onMouseEnter='pointer'
                onMouseLeave=''
                onClick='zoom'
                color='#222'
            />
            <Popup
                parentLayerId='alignments'
                children={ClusterPopup}
                openMethod='hover'
                initialOptions={{
                    closeButton: false,
                    closeOnClick: false,
                }}
                offset={{
                    x: 0,
                    y: -10,
                }}
            />
        </GeoJSONSource>
    );
}

function ClusterPopup(properties: GeoJSON.GeoJsonProperties) {
    return (
        <div>
            <h3>{properties?.name}</h3>
        </div>
    );
}

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
};

export default MapPage;