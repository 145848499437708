class ForgeViewerEvent {
    _events: any = {};


    on(name: string, listener: object) {
        if (!this._events[name]) {
            this._events[name] = [];
        }
        this._events[name].push(listener);
    }

    removeListener(name: string, listenerToRemove: object) {
        if (!this._events[name]) {
            throw new Error(`Can't remove a listener. Event "${name}" doesn't exits.`);
        }

        const filterListeners = (listener: object) => listener !== listenerToRemove;

        this._events[name] = this._events[name].filter(filterListeners);
    }

    emit(name: string, data: any) {
        if (this._events[name]) {
            const fireCallbacks = (callback: any) => {
                callback(data);
            };

            this._events[name].forEach(fireCallbacks);
        }
    }
}

const ForgeViewerEmitter = new ForgeViewerEvent();
export default ForgeViewerEmitter;