import React, { useState, useEffect } from "react"

import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, Button, Checkbox, Grid, DialogActions } from "@material-ui/core"
import { IPermission } from "src/core/interface/permission"
import { UserList, StyledMaterialTable, CloseDialogTitle } from "src/components"
import { useTranslation } from 'react-i18next';

function UserListModal(props: any) {
    const { t } = useTranslation(['pablo']);
    const [selectedUsers, setSelectedUsers] = useState([]);

    return (
        <Dialog open={props.show} onClose={props.onHide} fullWidth maxWidth='md' scroll='paper'>
            <CloseDialogTitle onClose={props.onHide} title={t('pablo:dialog.title.userPermission')} />
            <DialogContent dividers style={{ height: '80vh' }}>
                <UserList
                    users={props.users}
                    editable
                    onSelectionChange={(rows) => {
                        setSelectedUsers(rows)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onAddUsers(selectedUsers)
                }}>
                    {t('pablo:button.submit')}
                </Button>
            </DialogActions>
        </Dialog>

    )
}

function ModalPermissionsComp(props: any) {
    const { t } = useTranslation(['pablo']);
    const [editedPermission, setEditedPermission] = useState<Array<string>>([]);

    const allPermissions = [
        "project-manager",
        "user-manager",
        "structure-editor",
        "asset-editor",
        "asset-validator",
        "part-editor",
        "model-editor",
        "paint-editor"
    ]

    useEffect(() => {
        setEditedPermission([...props.permission])
    }, [props.permission])

    const submit = (e: any) => {
        e.preventDefault();
        const permOrdered = allPermissions.map(p =>
            editedPermission.includes(p) ? p : '').filter(p => p != '');
        props.onSubmit(props.ids, permOrdered);

    };
    return (
        <Dialog open={props.show} onClose={props.onHide} onSubmit={submit} fullWidth maxWidth='sm'>
            <CloseDialogTitle onClose={props.onHide} title={t('pablo:dialog.title.userPermission')} />
            <DialogContent>
                <form>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 50 }}>
                                    <Checkbox onChange={(e: any) => {
                                        if (e.target.checked) {
                                            setEditedPermission(allPermissions);
                                        } else {
                                            setEditedPermission([]);
                                        }
                                    }} />
                                </TableCell>
                                <TableCell>{t('pablo:components.project.rolesAvailable')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allPermissions.map((value: string, index: number) => {
                                return <TableRow key={index}>
                                    <TableCell>
                                        <Checkbox onChange={() => {
                                            const test = [...editedPermission]
                                            const index = test.indexOf(value);
                                            if (index > -1) {
                                                test.splice(index, 1);
                                            } else {
                                                test.push(value);
                                            }
                                            setEditedPermission(test);
                                        }} checked={editedPermission.includes(value)} />
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', textTransform: "capitalize" }}>
                                        {value.replace('-', ' ')}
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                    <Grid style={{ marginBottom: 8 }} container justify="flex-end">
                        <Button type="submit">
                            {t('pablo:button.submit')}
                        </Button>
                    </Grid>
                    {/* <Button type="submit">
                        Submit
							</Button> */}
                </form>
            </DialogContent>
        </Dialog>
    )
}

export function PermissionList(props: any) {
    const { t } = useTranslation(['pablo']);
    const defaultPermission = [{
        userId: '',
        projectId: '',
        permissions: []
    }];

    const [showModal, setShowModal] = useState(false);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [currentPermissions, setCurrentPermissions] = useState<Array<IPermission>>(defaultPermission);

    const defaultColumns: any = [
        {
            field: 'user.firstname',
            title: 'Firstname',
            editable: "never"
        },
        {
            field: 'user.lastname',
            title: 'Lastname',
            editable: "never"
        },
        {
            field: 'user.email',
            title: 'Email',
            editable: "never"
        },
        {
            field: 'permissions',
            title: 'Permissions',
            editable: "never",
            render: (rowData: any) =>
                <p style={{ textTransform: "capitalize", whiteSpace: "pre-wrap" }}>
                    {rowData.permissions.map((s: any) => s.replace('-', ' ')).join('\r\n')}
                </p>
        }
    ];

    return (
        <>
            <StyledMaterialTable
                height={105}
                options={{
                    showTitle: false,
                    emptyRowsWhenPaging: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    selection: true,
                }}
                data={props.userPermissions}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: t('pablo:tooltip.updatePermission'),
                        disabled: !props.editable,
                        onClick: (event: any, rowData: any) => {
                            setCurrentPermissions(rowData);
                            setShowModal(true);
                        }
                    },
                    {
                        tooltip: t('pablo:tooltip.removePermissions'),
                        icon: 'delete',
                        onClick: (evt, data) => props.onDeletePermissions(data)
                    },
                    {
                        icon: 'add',
                        tooltip: t('pablo:tooltip.addUser'),
                        isFreeAction: true,
                        disabled: !props.editable,
                        onClick: (event: any, rowData: any) => {
                            setCurrentPermissions(defaultPermission);
                            setShowUsersModal(true);
                        }
                    }
                ]}
                // editable={{
                //     isDeletable: rowData => props.editable,
                //     onRowDelete: props.onDeletePermission
                // }}
                columns={defaultColumns} />
            <ModalPermissionsComp show={showModal} onHide={() => setShowModal(false)}
                ids={currentPermissions.map(p => p._id)}
                permission={currentPermissions[0].permissions}
                onSubmit={(ids: Array<string>, newPerms: string[]) => {
                    setShowModal(false);
                    for (const id of ids) {
                        props.onUpdatePermission(id, newPerms);
                    }
                }} />
            <UserListModal show={showUsersModal} onHide={() => setShowUsersModal(false)}
                users={props.users}
                onAddUsers={(users: any) => {
                    setShowUsersModal(false);
                    for (const user of users) {
                        props.onAddPermission(user);
                    }

                }} />
        </>
    )
}
