import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { EditPropertyDialog, PropertyModalForm } from 'src/components';
import PropertiesWithinSet from 'src/components/properties/property-within-set.component';
import { APIServiceManager } from 'src/services';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
    },
    form: {
        minWidth: 300,
        marginLeft: 10,
    },
    title: {
        paddingLeft: 60,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fab: {
        position: 'absolute',
        top: 70,
        left: 60,
        zIndex: 99
    },
});

function PropertyWithinSetPage() {
    const { t } = useTranslation(['pablo']);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedProperty, setSelectedProperty] = useState<any>({});
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const classes = useStyles();
    const params = useParams<{ id: string }>();
    const location = useLocation();
    const state = location.state as any;

    const addMultiplePropInSet = async (propertyIds: Array<string>) => {
        if (params.id) {
            await APIServiceManager.PropSetService.addMultiplePropInSet(params.id, propertyIds);
            setRefresh(true);
        }
    };


    const removeMultiplePropsFromSet = async (rows: Array<string>) => {
        if (params.id) {
            await APIServiceManager.PropSetService.removePropertiesFromSet(params.id, rows);
            setRefresh(true);
        }
    };

    const updateProp = async (newData: any, oldProperties: Array<any>) => {
        for (const oldData of oldProperties) {

            const proms = [
                APIServiceManager.PropertyService.updateProperty(oldData._id, {
                    key: newData.key,
                    name: newData.name,
                    category: newData.category,
                    categoryId: newData.categoryId,
                    description: newData.description,
                    isTechProperty: newData.isTechProperty,
                    unit: newData.unit,
                    unitId: newData.unitId,
                    possibleValues: newData.possibleValues,
                    type: newData.type,
                    format: newData.format,
                }, state ? state.projectId : undefined)
            ];

            if (oldData && Array.isArray(oldData.propertySets)) {
                for (const oldSet of oldData.propertySets) {
                    if (newData && Array.isArray(newData.propertySets) &&
                        newData.propertySets.findIndex((n: any) => n._id === oldSet._id) < 0) {
                        proms.push(APIServiceManager.PropSetService.removePropFromSet(oldSet._id, oldData._id));
                    }
                }
            }

            if (newData && Array.isArray(newData.propertySets)) {
                for (const newSet of newData.propertySets) {
                    if (oldData && Array.isArray(oldData.propertySets) &&
                        oldData.propertySets.findIndex((n: any) => n._id === newSet._id) < 0) {
                        proms.push(APIServiceManager.PropSetService.addPropInSet(newSet._id, oldData._id));
                    }
                }
            }

            await Promise.all(proms);
        }
        setRefresh(true);
    };

    const handleRowClick = (rowParam: any, event: any) => {
        const property = rowParam.row;
        property.propertySets = [
            {
                _id: params.id,
                name: location.state && (location.state as any).propSetName ? (location.state as any).propSetName : ''
            }
        ];

        setSelectedProperty(property);
        setOpenDialog(true);
    };

    useEffect(() => {
        if (refresh) {
            setRefresh(!refresh);
        }
    }, [refresh]);
    return (

        <div className={classes.root}>
            <PropertiesWithinSet
                propertySetId={params.id}
                refresh={refresh}
                title={
                    t('pablo:pages.propSet.propertyWithinSetTitle', {
                        name: location.state && (location.state as any).propSetName ? (location.state as any).propSetName : ''
                    })
                }
                onAdd={() => {
                    setShowModal(true);
                }}
                onRowClick={handleRowClick}
                deleteSelection={removeMultiplePropsFromSet}
            />
            <EditPropertyDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                selectedProperties={[selectedProperty]}
                onSubmit={updateProp}
            />

            <PropertyModalForm
                show={showModal}
                onHide={() => setShowModal(false)}
                onSubmit={(propertyIds: Array<any>) => {
                    setShowModal(false);
                    addMultiplePropInSet(propertyIds);
                }}
                existingPropertyIds={[]} />
        </div>
    );
}

export default PropertyWithinSetPage;
