import { ClickAwayListener, Grow, Icon, MenuItem, MenuItemProps, MenuList, Paper, Popper } from '@mui/material';
import React, { useState } from 'react';
import { CTA, CTAProps } from '../CTA/call-to-action.component';

export type CTAGroupProps = {
    label: string,
    defaultMainBtn?: number,
    replaceBtn?: boolean,
    variant?: CTAProps['variant'],
    options: Array<Omit<MenuItemProps, 'onClick'> & {
        name: string,
        icon?: CTAProps['icon'],
        iconProps?: CTAProps['iconProps'],
        onClick?: (event: Parameters<NonNullable<MenuItemProps['onClick']>>[0] | Parameters<NonNullable<CTAProps['onClick']>>[0], index: number) => void,
    }>,
};
export function CTAGroup({ label, variant = 'filled', options, replaceBtn = true, defaultMainBtn }: CTAGroupProps) {
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIdx, setSelectedIdx] = useState<number | null>(defaultMainBtn ?? null);

    const hasSelected = selectedIdx != null;
    return (
        <React.Fragment>
            <div ref={anchorRef} style={{ display: 'inline-flex' }} >
                <CTA
                    variant={variant}
                    onClick={(e) => hasSelected ? options[selectedIdx].onClick?.(e, selectedIdx) : setOpen(!open)}
                    style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRightColor: 'rgba(0, 0, 0, 0.50)',
                    }}
                    icon={hasSelected ? options[selectedIdx].icon : undefined}
                    iconProps={hasSelected ? options[selectedIdx].iconProps : undefined}
                    disabled={hasSelected ? options[selectedIdx].disabled : undefined}
                >
                    {
                        hasSelected
                            ? options[selectedIdx].name
                            : label
                    }
                </CTA>
                <CTA
                    variant={variant}
                    onClick={() => setOpen(!open)}
                    sx={{
                        borderLeft: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        minWidth: '40px',
                    }}
                >
                    <Icon className='fas fa-angle-down' fontSize='small' />
                </CTA>
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                style={{ zIndex: 1300 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList autoFocusItem style={{ minWidth: anchorRef.current?.offsetWidth }} >
                                    {options.map(({ name, icon, iconProps = {}, key, onClick, ...rest }, index) => {
                                        const { sx: iconSx, ...iconRest } = iconProps;
                                        return (
                                            <MenuItem
                                                key={key ?? index}
                                                onClick={(event) => {
                                                    if (replaceBtn) setSelectedIdx(index);
                                                    setOpen(false);
                                                    onClick?.(event, index);
                                                }}
                                                {...rest}
                                            >
                                                {
                                                    !icon
                                                        ? null
                                                        : (
                                                            <Icon
                                                                fontSize='small'
                                                                className={`${typeof icon == 'object' ? icon.type : 'fas'} fa-${typeof icon == 'object' ? icon.name : icon}`}
                                                                sx={{ ...styles['icon'], ...iconSx }}
                                                                {...iconRest}
                                                            />
                                                        )
                                                }
                                                {name}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

const styles = {
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px',
        width: 'auto',
    },
};
