import { Button, Icon } from '@mui/material';
import React from 'react';
import { Asset, useMapPageContext } from 'src/contexts/MapPageContext';
import { InputType } from './EditLocDialog';
import { LngLatInput } from './LngLatInput';
import { nullLocPt } from './utils/locTools';
import { undyingTabLocation } from './utils/undyingTabLocation';

type LineLocationProps = ReturnType<typeof undyingTabLocation> & {
    asset: Asset | null,
    inputType: InputType,
};
export function LineLocation({ asset, inputType, data, setData }: LineLocationProps) {
    const { selectedAlignmentFeature } = useMapPageContext();

    return (
        <>
            {
                data.map(function deletableInput(loc, idx) {
                    return (
                        <LngLatInput
                            inputType={inputType}
                            disabled={!asset || (inputType.modifier == 'pk' && !selectedAlignmentFeature)}
                            key={loc.id}
                            loc={loc}
                            setLoc={function LinePointSetLoc(l) {
                                data[idx] = l;
                                setData([...data]);
                            }}
                        >
                            <Button
                                sx={styles.removeBtn}
                                disabled={data.length <= 2}
                                onClick={function removePointFromLine() {
                                    data.splice(idx, 1);
                                    setData([...data]);
                                }}
                            >
                                <Icon
                                    fontSize='small'
                                    className='fas fa-times'
                                    sx={styles.removeIcon}
                                    style={{
                                        color: '#484848',
                                    }}
                                />
                            </Button>
                        </LngLatInput>
                    );
                })
            }

            <Button
                sx={styles.addBtn}
                onClick={function addPointToLine() {
                    setData([...data, nullLocPt()]);
                }}
            >
                <Icon
                    fontSize='small'
                    className='fas fa-plus'
                    style={{
                        color: '#484848',
                    }}
                />
            </Button>
        </>
    );
}

const styles = {
    removeBtn: {
        ml: 2,
        minWidth: 0,
        borderRadius: '10px',
    },
    removeIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addBtn: {
        width: '100%',
        height: 42,
        mt: 3,
        borderRadius: '7px',
        boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.25)',
        ':hover': {
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.25)',
            background: 'white',
        },
        ':disabled': {
            boxShadow: '0px 0px 10px -4px rgba(0, 0, 0, 0.25)',
            background: 'rgba(0, 0, 0, 0.07)'
        }
    },
};
