import { Chip, Dialog, DialogContent, IconButton, Link as MuiLink, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ObjectId } from 'bson';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CloseDialogTitle, StructureTreeSettings } from 'src/components';
import { APIServiceManager, FrontServiceManager } from 'src/services';



export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    propId: string;
    projectId: string;
}

interface Column {
    id: 'name' | 'code' | 'Link';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Structure Name', minWidth: 170 },
    { id: 'code', label: 'Structure Code', minWidth: 100 },
    {
        id: 'Link', label: 'Structure Path',
        minWidth: 170,
    },
];

export default function PropertyHolder(props: SimpleDialogProps): JSX.Element {
    const { t } = useTranslation(['pablo']);
    const [structures, setStructures] = useState<Array<any>>([]);

    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_codeSeparator',
        'aimspainter_asset_structure_display_rootCode',
    ]);

    const [showTreeSettings, setShowTreeSettings] = useState(false);

    useEffect(() => {
        if (props.open) {
            const fetchPropsStructures = async () => {
                if (ObjectId.isValid(props.propId) && ObjectId.isValid(props.projectId)) {
                    const structuresOfProperties = await APIServiceManager.PropertyService.getWherePropertyIsUsed(props.propId, props.projectId);
                    setStructures(structuresOfProperties);
                }
            }
            fetchPropsStructures();
        }
    }, [props.open]);

    return (
        <>

            <Dialog onClose={props.onClose} open={props.open} fullWidth={true} maxWidth='md' scroll='paper'>
                <CloseDialogTitle onClose={props.onClose} title={t('pablo:dialog.title.propertyholder')}>
                    <IconButton className='fa fa-cog' onClick={() => setShowTreeSettings(true)} />
                </CloseDialogTitle >
                <DialogContent>
                    <TableContainer style={{ minHeight: 200, maxHeight: 516 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell >
                                        {t('pablo:table.headers.code')}
                                    </TableCell>
                                    <TableCell >
                                        {t('pablo:table.headers.name')}
                                    </TableCell>
                                    <TableCell >
                                        {t('pablo:table.headers.fullCode')}
                                    </TableCell>
                                    <TableCell >
                                        {t('pablo:table.headers.link')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {structures.map((row) => {
                                    return (
                                        <TableRow key={row._id}>
                                            <TableCell >
                                                {row.code}
                                            </TableCell>
                                            <TableCell >
                                                {row.name}
                                            </TableCell>
                                            <TableCell >
                                                {
                                                    FrontServiceManager.StructureQueryService.getFullCode(row.fullCode,
                                                        cookies.aimspainter_asset_structure_display_codeSeparator,
                                                        cookies.aimspainter_asset_structure_display_rootCode == 'true'
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell >
                                                <Chip style={{ marginLeft: 5 }}
                                                    label={<MuiLink style={{ color: '#1976d2' }} component='div'><Link state={{ selectedStructure: row._id }}
                                                        to={{
                                                            pathname: `/structure`
                                                        }}>{t('pablo:dialog.propertyHolder.gotoStructure')}</Link></MuiLink>} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

            </Dialog>
            <StructureTreeSettings show={showTreeSettings} onClose={() => setShowTreeSettings(false)} hideOptions={[0, 1]} />
        </>
    );
}
