import { FormControlLabel, Grid, Link, Switch, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReportModal } from 'src/components';
import { UserContext } from 'src/contexts/user.context';
import { APIServiceManager } from 'src/services';

function ProjectFunctionalityView() {
    const userContext = useContext(UserContext);
    const { t } = useTranslation(['pablo']);
    const params = useParams();
    useEffect(() => {
        const loadData = async () => {
            const pId = (params as any).id;
            const projects = await APIServiceManager.ProjectService.getProject(pId);
            const proj = projects[0];

            if (proj) {
                if (proj.functionality) {
                    setIsModelEnable(proj.functionality.modelEnable);
                    setIsMapEnable(proj.functionality.mapEnable);
                    setIsStructureEnable(proj.functionality.structureEnable);
                    setIsPartDatabaseEnable(proj.functionality.partDatabaseEnable);
                    setIsAssetDatabaseEnable(proj.functionality.assetDatabaseEnable);
                    setIsObjectDatabaseEnable(proj.functionality.objectDatabaseEnable);
                } else {
                    setIsModelEnable(false);
                    setIsMapEnable(false);
                    setIsStructureEnable(false);
                    setIsPartDatabaseEnable(false);
                    setIsAssetDatabaseEnable(false);
                    setIsObjectDatabaseEnable(false);
                }

                if (proj.options) {
                    setAssetDbURL(proj.options.assetDbURL);
                    setIsAssetReplaced(proj.options.isAssetReplaced);
                } else {
                    setAssetDbURL('');
                    setIsAssetReplaced(false);
                }
            } else {
                setIsModelEnable(false);
                setIsMapEnable(false);
                setIsStructureEnable(false);
                setIsPartDatabaseEnable(false);
                setIsAssetDatabaseEnable(false);
                setIsObjectDatabaseEnable(false);

                setAssetDbURL('');
                setIsAssetReplaced(false);
            }

        };
        loadData();
    }, [params]);

    const [isModelEnable, setIsModelEnable] = useState<boolean>(true);
    const [isMapEnable, setIsMapEnable] = useState<boolean>(true);
    const [isStructureEnable, setIsStructureEnable] = useState<boolean>(true);
    const [isPartDatabaseEnable, setIsPartDatabaseEnable] = useState<boolean>(true);
    const [isAssetDatabaseEnable, setIsAssetDatabaseEnable] = useState<boolean>(true);
    const [isObjectDatabaseEnable, setIsObjectDatabaseEnable] = useState<boolean>(true);

    const [assetDbURL, setAssetDbURL] = useState<string>('');
    const [isAssetReplaced, setIsAssetReplaced] = useState<boolean>(true);

    const [hasEditorRole, setHasEditorRole] = useState(false);

    const [showReportModal, setShowReportModal] = useState(false);
    const [reportModalData, setReportModalData] = useState();

    useEffect(() => {
        const fetchPerm = async () => {
            if (userContext && userContext.user && userContext.user.id) {
                const pId = (params as any).id;
                const projectPerms = await APIServiceManager.PermissionService.getPermissions(userContext.user.id, pId);

                if (projectPerms && projectPerms.length > 0) {
                    setHasEditorRole(projectPerms[0].permissions.includes('project-manager'));
                }
            }

        };
        fetchPerm();
    }, [userContext, params]);

    useEffect(() => {
        if (showReportModal) {
            const fetchReport = async () => {
                const pId = (params as any).id;
                const data = await APIServiceManager.ReportService.getReports(pId, true);
                setReportModalData(data[0]);
            };
            fetchReport();
        }
    }, [showReportModal]);

    const handleChange = async (name: string, value: any) => {
        const newValue: any = {
            functionality: {
                modelEnable: isModelEnable,
                mapEnable: isMapEnable,
                structureEnable: isStructureEnable,
                partDatabaseEnable: isPartDatabaseEnable,
                assetDatabaseEnable: isAssetDatabaseEnable,
                objectDatabaseEnable: isObjectDatabaseEnable,
            },
            options: {
                assetDbURL: assetDbURL,
                isAssetReplaced: isAssetReplaced
            }
        };
        switch (name) {
            case 'modelEnable':
                setIsModelEnable(value);
                newValue.functionality.modelEnable = value;
                break;
            case 'mapEnable':
                setIsMapEnable(value);
                newValue.functionality.mapEnable = value;
                break;
            case 'structureEnable':
                setIsStructureEnable(value);
                newValue.functionality.structureEnable = value;
                break;
            case 'partDatabaseEnable':
                setIsPartDatabaseEnable(value);
                newValue.functionality.partDatabaseEnable = value;
                break;
            case 'assetDatabaseEnable':
                setIsAssetDatabaseEnable(value);
                newValue.functionality.assetDatabaseEnable = value;
                break;
            case 'objectDatabaseEnable':
                setIsObjectDatabaseEnable(value);
                newValue.functionality.objectDatabaseEnable = value;
                break;
            case 'assetDbURL':
                setAssetDbURL(value);
                newValue.options.assetDbURL = value;
                break;
            case 'isAssetReplaced':
                setIsAssetReplaced(value);
                newValue.options.isAssetReplaced = value;
                break;
            default:
                break;
        }
        const pId = (params as any).id;
        await APIServiceManager.ProjectService.updateProject(pId, newValue);
    };

    return (
        <div>
            <form noValidate autoComplete="off">
                <h4>{t('pablo:pages.functionality.selection')}</h4>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={!hasEditorRole}
                            value={isModelEnable}
                            control={<Switch value="checkedBox"
                                checked={isModelEnable}
                                onChange={(e: any) => handleChange('modelEnable', e.target.checked)}
                                name="model" />}
                            label="Model"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={!hasEditorRole}
                            value={isMapEnable}
                            control={<Switch value="checkedBox"
                                checked={isMapEnable}
                                onChange={(e: any) => handleChange('mapEnable', e.target.checked)}
                                name="map" />}
                            label="Map"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={!hasEditorRole}
                            value={isStructureEnable}
                            control={<Switch value="checkedBox"
                                checked={isStructureEnable}
                                onChange={(e: any) => handleChange('structureEnable', e.target.checked)} name="tree" />}
                            label="Tree"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={!hasEditorRole}
                            value={isPartDatabaseEnable}
                            control={<Switch value="checkedBox"
                                checked={isPartDatabaseEnable}
                                onChange={(e: any) => handleChange('partDatabaseEnable', e.target.checked)} name="partDatabase" />}
                            label="Part Database"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={!hasEditorRole}
                            value={isAssetDatabaseEnable}
                            control={<Switch value="checkedBox"
                                checked={isAssetDatabaseEnable}
                                onChange={(e: any) => handleChange('assetDatabaseEnable', e.target.checked)} name="assetDatabase" />}
                            label="Asset Database"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={!hasEditorRole}
                            value={isObjectDatabaseEnable}
                            control={<Switch value="checkedBox"
                                checked={isObjectDatabaseEnable}
                                onChange={(e: any) => handleChange('objectDatabaseEnable', e.target.checked)} name="assetDatabase" />}
                            label="Object Database"
                        />
                    </Grid>
                </Grid>
            </form>
            <form noValidate autoComplete="off">
                <h4>{t('pablo:pages.functionality.configuration')}</h4>
                <Grid item xs={12}>
                    <TextField
                        disabled={!hasEditorRole}
                        fullWidth
                        label={t('pablo:pages.functionality.lien')}
                        margin="normal"
                        value={assetDbURL}
                        variant="outlined"
                        onChange={(e: any) => { handleChange('assetDbURL', e.target.value); }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        disabled={!hasEditorRole}
                        value={isAssetReplaced}
                        control={<Switch value="checkedBox"
                            checked={isAssetReplaced}
                            onChange={(e: any) => handleChange('isAssetReplaced', e.target.checked)} name="assetReplaced" />}
                        label={t('pablo:pages.functionality.replace')}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'end' }} >
                    <Link onClick={() => setShowReportModal(true)}>{t('pablo:pages.functionality.showReport')}</Link>
                </Grid>

            </form>

            <ReportModal show={showReportModal} onHide={() => setShowReportModal(false)} data={reportModalData} />
        </div >

    );
}

export default ProjectFunctionalityView;
