import React, { useEffect, useRef, useState } from 'react';

import StructureNodeView from './structure-node.page';
import StructurePropertyView from './structure-property.page';
import { SplitterDiv } from 'src/components';
import { TreeData } from 'editable-tree';
import { APIServiceManager } from 'src/services';
import { useLocation } from 'react-router-dom';

function StructurePage() {
    const [selectedNode, setSelectedNode] = useState<TreeData>({ id: '', label: '' });

    const canClick = useRef(false)
    const [hasChangeInProp, setHasChangeInProp] = useState(false);

    const location = useLocation();
    const state = location.state as any
    useEffect(() => {
        if (state && state.selectedStructure) {

            const fetchStructure = async () => {
                const treeElement = await APIServiceManager.StructureService.getStructures({ _id: state.selectedStructure, fullCode: true });
                if (Array.isArray(treeElement) && treeElement.length > 0) {
                    const treeElem = treeElement[0];
                    setSelectedNode({
                        id: treeElem._id,
                        label: `${treeElem.code} - ${treeElem.name}`,
                        extendedData: {
                            code: treeElem.code,
                            name: treeElem.name,
                            fullCode: treeElem.fullCode
                        }
                    })
                }

            }
            fetchStructure();
        }
    }, [state])

    const handleNodeClick = async (node: TreeData) => {
        if (!(canClick.current)) {
            setSelectedNode(node)
        }
    }

    return (
        <SplitterDiv
            firstPanelMinSize={385}
            secondPanelMinSize={240}
            defaultSecondPanelSize={500}
        >
            <StructureNodeView
                defaultSelectedNodeId={selectedNode.id}
                selectionCanChanged={!hasChangeInProp}
                onNodeClick={handleNodeClick} />
            <StructurePropertyView onChange={(change: boolean) => {
                canClick.current = change;
                setHasChangeInProp(change);
            }}
                selectedTreeNode={selectedNode} />
        </SplitterDiv>
    );
}

export default StructurePage;
