import AIMBuilderExtension from './aim-builder/aim-builder.extension';
import AssetListPanelExtension from './asset-list/asset-list.extension';
import AssetStructurePanelExtension from './asset-structure/asset-structure.extension';
import PainterEditorPanelExtension from './painter-editor/painter-editor.extension';
import PainterViewerPanelExtension from './painter-viewer/painter-viewer.extension';

import { ForgeViewerExtensionOptions } from './forge-viewer.interface';
import './new-forge-viewer.css';

const ExtensionId = 'AIMSPainter.Painter.v2';
export class PainterExtension extends Autodesk.Viewing.Extension {
    _group: Autodesk.Viewing.UI.ControlGroup | undefined;
    _secondGroup: Autodesk.Viewing.UI.ControlGroup | undefined;
    _toolbar: Autodesk.Viewing.UI.ToolBar | undefined;
    _options: ForgeViewerExtensionOptions;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeViewerExtensionOptions) {
        super(viewer, options);
        this._group = undefined;
        this._secondGroup = undefined;
        this._options = options;
    }

    load() {
        console.log(`${ExtensionId} has been loaded`);
        return true;
    }

    unload() {
        if (this._group) {
            this.viewer.toolbar.removeControl(this._group);
        }
        if (this._secondGroup) {
            this.viewer.toolbar.removeControl(this._secondGroup);
        }


        console.log(`${ExtensionId} has been unloaded`);
        return true;
    }

    onToolbarCreated() {
        this._toolbar = new Autodesk.Viewing.UI.ToolBar('AIMSPainter.Toolbar');
        this._group = new Autodesk.Viewing.UI.ControlGroup('AIMSPainter.ControlGroup');
        this._secondGroup = new Autodesk.Viewing.UI.ControlGroup('AIMSPainter.ControlGroup2');

        const assetListPanel = new AssetListPanelExtension(this.viewer, `${ExtensionId}.AssetListPanel`, this._options);
        this._options.AssetListPanel = assetListPanel;

        const assetStructurePanel = new AssetStructurePanelExtension(this.viewer, `${ExtensionId}.AssetStructurePanel`, this._options);
        this._options.AssetStructurePanel = assetStructurePanel;

        const painterViewerPanel = new PainterViewerPanelExtension(this.viewer, `${ExtensionId}.PainterViewerPanel`, this._options);
        const painterEditorPanel = new PainterEditorPanelExtension(this.viewer, `${ExtensionId}.PainterEditorPanel`, this._options);

        const aimBuilderPanel = new AIMBuilderExtension(this.viewer, `${ExtensionId}.AIMBuilderPanel`, this._options);

        this.addButton(this._group, 'assetListPanelButton', 'asset-list-icon-btn', 'Show list of Asset', assetListPanel, true);
        this.addButton(this._group, 'painterViewerPanelButton', 'asset-view-icon-btn', 'AIMSPainter Viewer Tools', painterViewerPanel, true);
        this.addButton(this._group, 'painterEditorPanelButton', 'asset-paint-icon-btn', 'AIMSPainter Editor Tools', painterEditorPanel, this.options.canEdit);

        // this.addButton(this._secondGroup, 'aimBuilderButton', 'adsk-debug', 'AIMSPainter Auto Build Tools', aimBuilderPanel, true);

        this._group.addEventListener(Autodesk.Viewing.UI.SIZE_CHANGED, this.onWindowResize);
        this._secondGroup.addEventListener(Autodesk.Viewing.UI.SIZE_CHANGED, this.onWindowResize);
        this._toolbar.addControl(this._group);
        this._toolbar.addControl(this._secondGroup);
        this.viewer.container.appendChild(this._toolbar.container);
        setTimeout(() => {
            this._toolbar!.addClass('aimspainter-toolbar');
            this.onWindowResize();
        }, 100);
    }

    onWindowResize = () => {
        if (this._toolbar) {
            const dim = this._toolbar.getDimensions() as { width: number; height: number };
            if (dim && !isNaN(dim.height)) {
                this._toolbar.container.style.setProperty('top', (this.viewer.getDimensions().height / 2 + dim.height / 2).toString());
            }
        }
    };

    addButton(group: Autodesk.Viewing.UI.ControlGroup, name: string, icon: string, tooltip: string, panel: any, canClick: boolean) {
        const panelButton = new Autodesk.Viewing.UI.Button(name);

        panelButton.setToolTip(tooltip);
        panelButton.setIcon(icon);
        if (!canClick) {
            panelButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
            panelButton.setToolTip(tooltip + '(No sufficient access rigths)');
        }

        group.addControl(panelButton);
        this.viewer.addPanel(panel);

        panel.addVisibilityListener((state: any) => {
            panelButton.setState(
                state ? Autodesk.Viewing.UI.Button.State.ACTIVE : Autodesk.Viewing.UI.Button.State.INACTIVE
            );
        });

        panelButton.onClick = (e: Event) => {
            panel.toogleVisibility();
        };
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionId, PainterExtension);
