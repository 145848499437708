import { gzipSync } from 'react-zlib-js';
import { API, APIServiceManager } from '../..';
import { unzipJson } from '../../../core/helpers/zip';
class StructureService {
    api: API = APIServiceManager.API;
    name() {
        return 'StructureService';
    }

    async getStructureTree(includeFullCode: boolean, maxLevel: number = -1): Promise<any> {
        try {
            const req = await this.api.axios.get('/project/structure', {
                params: {
                    tree: true,
                    maxLevel,
                    includeFullCode,
                }
            });

            if (req.data.data) {
                return unzipJson(req.data.data.data)
            }
            return {};
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getStructures(query: any): Promise<any> {
        try {
            const req = await this.api.axios.get('/project/structure', {
                params: query
            });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async importFile(file: any): Promise<any> {
        try {
            const formData = new FormData();
            formData.append('file', file)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const req = await this.api.axios.post(`/project/structure/import/excel`, formData, config);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addStructure(newStructure: any): Promise<any> {
        try {
            const req = await this.api.axios.post('/project/structure', newStructure);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addManyStructure(newStructures: Array<any>): Promise<any> {
        try {
            const encoder = new TextEncoder();
            const buf = encoder.encode(JSON.stringify(newStructures));
            const buffer = Buffer.from(buf);
            const zip = gzipSync(buffer);
            const req = await this.api.axios.post('/project/structure/many', { zip });

            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateStructure(id: string, newInfo: any): Promise<any> {
        try {
            const req = await this.api.axios.put(`/project/structure/${id}`, newInfo);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateStructureParent(id: string, parentId: string, reorder: boolean = false, neworder?: number): Promise<any> {
        try {
            const req = await this.api.axios.put(`/project/structure/${id}/parent/${parentId}`, {},
                { params: { reorder, neworder } }
            );
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateStructureOrder(data: Array<any>): Promise<any> {
        try {
            const req = await this.api.axios.post('/project/structure/reorder', data);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removeStructure(id: string, withChildren: boolean = false): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/project/structure/${id}`, { params: { withChildren } });
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async saveAssetStructure(newData: any): Promise<any> {
        try {
            const encoder = new TextEncoder();
            const buf = encoder.encode(JSON.stringify(newData));
            const buffer = Buffer.from(buf);
            const zip = gzipSync(buffer);

            const req = await this.api.axios.post('/project/structure/save', { zip });
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async saveStructureElements(newData: any): Promise<any> {
        try {
            const encoder = new TextEncoder();
            const buf = encoder.encode(JSON.stringify(newData));
            const buffer = Buffer.from(buf);
            const zip = gzipSync(buffer);

            const req = await this.api.axios.post('/project/structure/save/element', { zip });
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

export { StructureService };
