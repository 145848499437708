import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { AuthService } from '../auth';
import { APIServiceManager } from '../manager.service';
import { getI18n } from 'react-i18next';

class API {
    _authService = APIServiceManager.AuthService;

    name() {
        return 'API';
    }

    private options: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
    }

    constructor() {
        this._axios = axios.create(this.options);
        this._axios.interceptors.request.use(async (config: any) => {
            let tokenInfo: { token: string, payload: any } = { token: '', payload: null }
            try {
                tokenInfo = this._authService.verifyIdToken(false)
            } catch (err) {
                throw new axios.Cancel('user not logged in, operation cancelled')
            }
            if (this._authService.isTokenExpired(tokenInfo)) {
                tokenInfo = await this._authService.refreshIdToken()
            }
            config.headers.Authorization = `Bearer ${tokenInfo.token}`;
            config.headers['Accept-Language'] = getI18n().language;
            if (config.url.startsWith('/project/') && !config.headers.project) {
                if (this._project == undefined || this._project._id === null || this._project._id === '') {
                    try {
                        const pIdFromLocalStorage = localStorage.getItem('forge_aimspainter_project');
                        if (pIdFromLocalStorage != null && pIdFromLocalStorage != undefined) {
                            this._project = { _id: pIdFromLocalStorage };
                            config.headers.project = pIdFromLocalStorage;
                        }
                    } catch (error) {
                        console.log(error);
                        throw new axios.Cancel('project not set, operation cancelled')
                    }
                } else {
                    config.headers.project = this._project._id;
                }
            }
            return config;
        }, (error: any) => {
            return Promise.reject(error);
        });
    }

    _project: any = {
        _id: ''
    };

    private _axios: AxiosInstance;
    public get axios(): AxiosInstance {
        return this._axios;
    }

    async getError() {
        try {
            const req = await this._axios.get('/error');
            return req.data;
        } catch (error) {
            console.log(error)
        }

    }
}

export { API };
