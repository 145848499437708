import React, { useState, useEffect } from 'react';
import { APIServiceManager, AuthService } from '../../services';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function LoginPage() {

    const _authService: AuthService = APIServiceManager.getService('AuthService') as AuthService
    const { t } = useTranslation(['pablo']);

    // TODO: no need for useEffect(), just check isAuthenticated(), which is now synchronous
    const [isAuthenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const init = async () => {
            setAuthenticated(_authService.isAuthenticated())
        };
        init();

    }, [_authService]);

    if (isAuthenticated) {
        return (
            <Navigate to="home" />
        );
    } else {
        return (
            <div>
                <h3>{t('pablo:page.login.welcome')}</h3>
                <h6>{t('pablo:page.login.continue')}<a href="/home"> login</a>
                </h6>
            </div>
        )
    }

}

export default LoginPage;
