import { API, APIServiceManager } from '../..';
import { IPermission } from 'src/core/interface/permission';

class PermissionService {
	api: API = APIServiceManager.API;

	name() {
	    return 'PermissionService';
	}

	async getPermissions(userId?: string, projectId?: string, projectImage?: boolean, levels?: string[], group?: boolean): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/perm', {
	            params: {
	                group, userId, projectId, projectImage, levels
	            }
	        });
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	// async getMyPermissionsForLevels(levels: string[]): Promise<any> {
	// 	try {
	// 		const req = await this.api.axios.get(`${process.env.REACT_APP_API_URL}/perm/get/my?levels=${levels.join('/')}`);
	// 		return req.data.data;
	// 	} catch (error) {
	// 		console.log(error);
	// 		throw error;
	// 	}
	// }

	// async getMyPermissions(group: boolean): Promise<any> {
	// 	try {
	// 		const req = await this.api.axios.get(`${process.env.REACT_APP_API_URL}/perm/get/all/my?group=true`);
	// 		return req.data.data;
	// 	} catch (error) {
	// 		console.log(error);
	// 		throw error;
	// 	}
	// }

	// async getProjectPermissions(projectId: string): Promise<any> {
	// 	try {
	// 		const req = await this.api.axios.get(`${process.env.REACT_APP_API_URL}/perm/admin/project/${projectId}/get`);
	// 		return req.data.data;
	// 	} catch (error) {
	// 		console.log(error);
	// 		throw error;
	// 	}
	// }

	// async getProjectsPermissions(ids: string[]): Promise<any> {
	// 	try {
	// 		const req = await this.api.axios.post(`${process.env.REACT_APP_API_URL}/perm/admin/projects/get`, ids);
	// 		return req.data.data;
	// 	} catch (error) {
	// 		console.log(error);
	// 		throw error;
	// 	}
	// }

	// async getMyProjectsPermissions(): Promise<any> {
	// 	try {
	// 		const req = await this.api.axios.get(`${process.env.REACT_APP_API_URL}/perm/projects/my/get`);
	// 		return req.data.data;
	// 	} catch (error) {
	// 		console.log(error);
	// 		throw error;
	// 	}
	// // }

	async updatePermission(id: string, perms: string[]): Promise<any> {
	    try {
	        const req = await this.api.axios.put(`/perm/${id}`, { permissions: perms });
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async addPermission(perm: IPermission): Promise<any> {
	    try {
	        const req = await this.api.axios.post('/perm', perm);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async removePermission(id: String): Promise<any> {
	    try {
	        const req = await this.api.axios.delete(`/perm/${id}`);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}


}

export { PermissionService };
