import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGridPro,
    enUS,
    frFR,
    GridApi,
    GridEditRowsModel,
    GridPagination,
    GridRowId,
    GridSelectedRowCount,
    GridSelectionModel,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiContext,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { APIServiceManager, FrontServiceManager } from 'src/services';


const useStyles = makeStyles((theme: any) => ({
    root: {
        '& .MuiDataGrid-cell--editing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: 'rgb(126,10,15, 0.1)',
            color: '#750f0f',
        },
    },
    searchToolbar: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
}));


function CustomToolbar(props: {
    apiRef: React.MutableRefObject<GridApi>;
    onSearchChanged: (search: string) => void;
}) {
    const classes = useStyles();

    const { apiRef, onSearchChanged } = props;
    const { t } = useTranslation(['pablo']);
    const [search, setSearch] = useState('');
    const inputRef = useRef<any>();

    const handleOnChange = useCallback((event: any) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

        setSearch(event.target.value);
        if (onSearchChanged) {
            onSearchChanged(event.target.value);
        }

    }, [onSearchChanged]);

    const handleClick = () => {
        const id: GridRowId = 'new';
        apiRef.current.updateRows([{ id, isNew: true }]);

        const state = apiRef.current.state;
        const newRowIndex = (state.pagination.page + 1) * state.pagination.pageSize - 1;
        // const newRows = [...(state.rows.allRows)];
        // newRows.splice(newRowIndex, 0, id);
        // const newState = { ...state };
        // newState.rows..rows.allRows = newRows
        // apiRef.current.setState(newState);
        apiRef.current.setRowMode(id, 'edit');

        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: newRowIndex,
            });

            const rowParams = apiRef.current.getRowParams(id);
            const rowEditableColumns = rowParams.columns.filter((col: any) => col.editable);

            if (Array.isArray(rowEditableColumns) && rowEditableColumns.length > 0) {
                apiRef.current.setCellFocus(id, rowEditableColumns[0].field);
            }
        });
    };
    const args: any = {};

    return (
        <GridToolbarContainer className={classes.searchToolbar}>
            <Button
                size="small"
                onClick={handleClick}
            >
                <AddIcon fontSize="small" />
                {t('pablo:button.add')}
            </Button>
            <GridToolbarFilterButton {...args} />
            <TextField
                variant="standard"
                value={search}
                onChange={handleOnChange}
                placeholder="Search…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: search != '' ? 'visible' : 'hidden' }}
                            onClick={() => {
                                setSearch('');
                                if (props.onSearchChanged) {
                                    props.onSearchChanged('');
                                }
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </GridToolbarContainer>
    );
}


function CustomFooter(props: {
    onDeleteSelection?: (selection: GridSelectionModel) => void;
}) {
    const apiRef = useGridApiContext();
    const { t } = useTranslation(['pablo']);

    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        setConfirm(false);
    }, [apiRef.current.state.selection.length]);

    return (
        <Grid container style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
            {
                confirm ?
                    <Grid container item xs={4} spacing={2} direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Grid item xs={5} >
                            <Typography>{t('pablo:label.confirm')} ?</Typography>
                        </Grid>
                        <Grid item container xs={7}>
                            <Grid item xs={2}>
                                <IconButton className="fa fa-check" onClick={(event: any) => {
                                    props.onDeleteSelection!(apiRef.current.state.selection);
                                }} />
                            </Grid>
                            <Grid item xs>
                                <IconButton className="fa fa-times" onClick={(event: any) => {
                                    setConfirm(false);
                                }} />
                            </Grid>
                        </Grid>
                    </Grid> :
                    apiRef.current.state.selection.length > 0 ?
                        <Grid container item xs={4} spacing={2}>
                            {
                                props.onDeleteSelection ?
                                    <Grid item xs={1}>
                                        <IconButton size="small" className="fa fa-trash" onClick={(event: any) => {
                                            setConfirm(true);
                                        }} />
                                    </Grid> : null
                            }

                            <Grid item xs={10}>
                                <GridSelectedRowCount selectedRowCount={apiRef.current.state.selection.length} />
                            </Grid>
                        </Grid>
                        : null
            }
            <Grid item xs={8} style={{ justifyContent: 'flex-end' }}>
                <GridPagination />
            </Grid>
        </Grid>
    );
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}


function UnitsView() {
    const { t, i18n } = useTranslation(['pablo']);
    const apiRef = useGridApiRef();
    const classes = useStyles();
    const params = useParams<{ id: string }>();

    const [units, setUnits] = useState<Array<any>>([]);
    const [filteredData, setFilteredData] = useState<Array<any>>([]);

    const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({});

    const [searchText, setSearchText] = React.useState('');
    const [localeText, setLocaleText] = useState<any>(enUS);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = units.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field] ? row[field].toString() : undefined);
            });
        });
        setFilteredData(filteredRows);
    };


    useEffect(() => {
        switch (i18n.language) {
            case 'fr':
                setLocaleText(frFR.components.MuiDataGrid.defaultProps.localeText);
                break;
            case 'en':
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
            default:
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
        }
    }, [i18n.language]);

    const handleEditRowsModelChange = useCallback((model: GridEditRowsModel) => {
        const updatedModel = { ...model };

        const id = Object.keys(updatedModel)[0];
        const otherKeys = units.map(u => {
            if (u.id === id) {
                return undefined;
            }
            return u.name;
        });
        Object.keys(updatedModel).forEach((id) => {
            if (updatedModel[id].name) {
                const hasError = otherKeys.includes(updatedModel[id].name.value);
                updatedModel[id].name = { ...updatedModel[id].name, error: hasError };
            }
        });
        setEditRowsModel(updatedModel);
    }, [units]);

    const fetchUnits = async (projectId: string) => {
        const units: Array<any> = await APIServiceManager.UnitsService.getUnits(projectId);
        const unitsWithId = units.map(u => {
            u.id = u._id;
            return u;
        });
        setUnits(unitsWithId);
        setFilteredData(unitsWithId);
    };

    useEffect(() => {
        if (params.id) {
            fetchUnits(params.id);
        }

    }, [params]);

    const handleRowEditCommit = async (id: GridRowId) => {
        const unitRow = editRowsModel[id];
        const newUnit: any = {
            name: unitRow.name.value,
            frName: unitRow.frName.value,
            symbol: unitRow.symbol.value,
            unitType: unitRow.unitType.value,
        };

        if (id === 'new') {
            try {
                newUnit.isProject = true;
                newUnit.projectId = params.id;
                await APIServiceManager.UnitsService.addUnit(newUnit);
                FrontServiceManager.AlertEmitter.emitAlert(t('pablo:message.units.addOneSuccess',
                    { name: newUnit.name }));
            } catch (error) {
                FrontServiceManager.AlertEmitter.emitAlert(t('pablo:message.units.addOneError',
                    { name: newUnit.name }), 'error');
                throw error;
            }
            finally {
                if (params.id) {
                    fetchUnits(params.id);
                }
            }
        } else {
            await APIServiceManager.UnitsService.updateUnit(id.toString(), newUnit);
        }
    };

    const onAdd = async () => {

    };

    return (
        <Paper className={classes.root} style={{ height: '100%' }}>
            <DataGridPro
                localeText={localeText}
                apiRef={apiRef}
                rows={filteredData}
                editMode="row"
                pagination
                checkboxSelection
                disableSelectionOnClick
                columns={[
                    {
                        field: 'name',
                        headerName: t('pablo:table.headers.name'),
                        editable: true,
                        flex: 0.30,
                    },
                    {
                        field: 'frName',
                        headerName: t('pablo:table.headers.frName'),
                        editable: true,
                        flex: 0.30,
                    },
                    {
                        field: 'symbol',
                        headerName: t('pablo:table.headers.symbol'),
                        editable: true,
                        flex: 0.10,
                    },
                    {
                        field: 'unitType',
                        headerName: t('pablo:table.headers.unitType'),
                        editable: true,
                        flex: 0.20,
                    },
                ]}
                componentsProps={{
                    toolbar: {
                        apiRef,
                        value: searchText,
                        onSearchChanged: (value: string) => requestSearch(value),
                        clearSearch: () => requestSearch(''),
                        onAdd: onAdd,
                    },
                    footer: {
                        onDeleteSelection: async (selection: GridSelectionModel) => {
                            for (const id of selection) {
                                await APIServiceManager.UnitsService.deleteUnit(id.toString());
                            }
                            if (params.id) {
                                fetchUnits(params.id);
                            }
                        }
                    }
                }}
                components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooter
                }}
                editRowsModel={editRowsModel}
                onEditRowsModelChange={handleEditRowsModelChange}
                onRowEditCommit={handleRowEditCommit}
            />
        </Paper>

    );
}

export default UnitsView;
