import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import { SnapshotSelectItem } from 'src/types';


export default function ExportTreeModal(props: {
    open: boolean;
    onClose: () => void;
    onExport: (newSnapshot: string, oldSnapshot: string, options: any) => void;
    snapshotSummaries: Array<SnapshotSelectItem>;
}) {

    const { t, i18n } = useTranslation(['pablo']);
    const CURRENT_STATE_ID = 'CURRENT';
    const [doDiff, setDoDiff] = useState(false);
    const [selectedForExport, setSelectedForExport] = useState<SnapshotSelectItem>({ id: '', label: '', createdAt: new Date() });
    const [versionsForExport, setVersionsForExport] = useState<any>([]);
    const [selectedForDiff, setSelectedForDiff] = useState<SnapshotSelectItem>({ id: '', label: '', createdAt: new Date() });
    const [versionsForDiff, setVersionsForDiff] = useState<any>([]);
    const [canDoDiff, setCanDoDiff] = useState(true);

    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_rootCode',
        'aimspainter_asset_structure_display_codeSeparator',
    ]);

    const [separator, setSeparator] = useState<string>()
    const [repeatRoot, setRepeatRoot] = useState<boolean>(false)

    useEffect(() => {
        setSeparator(cookies.aimspainter_asset_structure_display_codeSeparator);
    }, [cookies.aimspainter_asset_structure_display_codeSeparator]);

    useEffect(() => {
        setRepeatRoot(cookies.aimspainter_asset_structure_display_rootCode === 'true');
    }, [cookies.aimspainter_asset_structure_display_rootCode]);

    useEffect(() => {
        if (versionsForExport.length > 0) {
            versionsForExport[0].label = t('pablo:dialog.title.exportCurrentDataModel');
        }
    }, [i18n.language])

    const onExport = () => {
        let newSnapshotId = '';
        let oldSnapshotId = '';
        if (selectedForExport.id !== CURRENT_STATE_ID) {
            newSnapshotId = selectedForExport.id;
        }
        if (doDiff) {
            oldSnapshotId = selectedForDiff.id;
        }
        props.onExport(newSnapshotId, oldSnapshotId, { separator, repeatRoot });
    };

    useEffect(() => {
        const resetOnClose = () => {
            if (!props.open) {
                setDoDiff(false);
                if (versionsForExport.length) {
                    setSelectedForExport(versionsForExport[0]);
                }
            }
        }
        resetOnClose();
    }, [props.open]);

    useEffect(() => {
        setVersionsForExport([
            { id: CURRENT_STATE_ID, label: t('pablo:dialog.title.exportCurrentDataModel'), createdAt: Date.now() },
            ...props.snapshotSummaries,
        ]);
    }, [props.snapshotSummaries]);

    useEffect(() => {
        if (versionsForExport.length) {
            setSelectedForExport(versionsForExport[0]);
        }
    }, [versionsForExport]);


    useEffect(() => {
        const setSnapshotListForDiff = () => {
            if (props.snapshotSummaries.length && selectedForExport.id) {
                setSelectedForDiff({ id: '', label: '', createdAt: new Date() })
                if (selectedForExport.id === CURRENT_STATE_ID) {
                    setVersionsForDiff([...props.snapshotSummaries]);
                } else {
                    setVersionsForDiff(props.snapshotSummaries.filter(snapshot =>
                        snapshot.createdAt < selectedForExport.createdAt
                    ));
                }
            }
        };
        setSnapshotListForDiff();
    }, [props.snapshotSummaries, selectedForExport]);

    useEffect(() => {
        if (versionsForDiff.length) {
            setSelectedForDiff(versionsForDiff[0]);
        }
    }, [versionsForDiff]);

    useEffect(() => {
        const noDiffIfOldestSnapshotSelectedForExport = () => {
            let result = true;
            if (props.snapshotSummaries.length === 0) {
                result = false;
            } else if (selectedForExport.id && selectedForExport.id !== CURRENT_STATE_ID) {
                const earliestDate = props.snapshotSummaries.reduce(
                    (prev: any, snapshot) => prev < snapshot.createdAt ? prev : snapshot.createdAt,
                    Date.now());
                if (selectedForExport.createdAt.toString() === earliestDate.toString()) {
                    result = false;
                    setDoDiff(false);
                }
            }
            setCanDoDiff(result);
        };
        noDiffIfOldestSnapshotSelectedForExport();
    }, [selectedForExport]);

    const menuList = (input: Array<SnapshotSelectItem>) => {
        return (input.map((d: any) => <MenuItem value={d.id} key={d.id} > {d.label}</ MenuItem>))
    };

    return (
        <Dialog
            fullWidth={true} maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <CloseDialogTitle onClose={props.onClose} title={t('pablo:dialog.title.exportCurrentDataModel')} />
            <DialogContent>
                <Grid container spacing={2} justifyContent="space-evenly">
                    <Grid item xs={6} fontSize="1.2em">
                        {t('pablo:dialog.exportTree.selectVersion')}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={selectedForExport.id}
                                onChange={(event) => {
                                    const version = versionsForExport.find((v: any) => v.id === event.target.value);
                                    if (version) {
                                        setSelectedForExport(version);
                                    }
                                }}
                            >
                                {menuList(versionsForExport)}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            style={{ visibility: canDoDiff ? 'visible' : 'hidden' }}
                            control={
                                <Checkbox
                                    checked={doDiff}
                                    onChange={(event: any, checked: boolean) => setDoDiff(checked)}
                                />
                            }
                            label={t('pablo:dialog.exportTree.exportWithTrackedChanges').toString()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                style={{ visibility: doDiff ? 'visible' : 'hidden' }}
                                value={selectedForDiff.id}
                                onChange={(event) => {
                                    const snapshot = versionsForDiff.find((s: any) => s.id === event.target.value);
                                    if (snapshot) {
                                        setSelectedForDiff(snapshot);
                                    }
                                }}
                            >
                                {menuList(versionsForDiff)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={repeatRoot}
                                    onChange={(event: any, checked: boolean) => setRepeatRoot(checked)}
                                />
                            }
                            label={t('pablo:pages.assetStr.structure.showNodeRootCode').toString()}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <TextField
                                    value={separator}
                                    onChange={(event: any) => setSeparator(event.target.value)}
                                />
                            }
                            label={t('pablo:pages.assetStr.structure.separator').toString()}
                            labelPlacement="start"
                            sx={{ "& .MuiFormControlLabel-label": { mr: 2 } }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose} color="inherit">
                    {t('pablo:button.cancel')}
                </Button>
                <Button autoFocus onClick={onExport} color="inherit">
                    {t('pablo:button.export')}
                </Button>
            </DialogActions>
        </Dialog >
    );
}