import { Drawer, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const defaultDrawerWidth = 240;
const minDrawerWidth = 5;
const maxDrawerWidth = 1000;


const useStyles = makeStyles((theme: any) => ({
    appBar: {
        width: '250px',
        right: 'unset',
        marginLeft: (props: any) => props.right ? 'unset' : props.drawerWidth,
        marginRight: (props: any) => props.right ? props.drawerWidth : 'unset',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginLeft: (props: any) => props.right ? 'unset' : theme.spacing(2),
        marginRight: (props: any) => props.right ? theme.spacing(2) : 'unset',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: (props: any) => props.drawerWidth,
        flexShrink: 0,
        top: 'unset',
    },
    drawerPaper: {
        width: (props: any) => props.drawerWidth,
        top: 'unset',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    toolbar: theme.mixins.toolbar,
    dragger: {
        width: '5px',
        cursor: 'ew-resize',
        padding: '4px 0 0',
        borderTop: '1px solid #ddd',
        position: 'absolute',
        top: 0,
        left: (props: any) => props.right ? 0 : 'unset',
        right: (props: any) => props.right ? 'unset' : 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: '#f4f7f9'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: (props: any) => props.right ? 'unset' : props.drawerWidth,
        marginRight: (props: any) => props.right ? props.drawerWidth : 'unset',
    },
}));

export default function SizableDrawer(props: any) {

    const [drawerWidth, setDrawerWidth] = React.useState(defaultDrawerWidth);

    const handleMouseDown = (e: any) => {
        document.addEventListener('mouseup', handleMouseUp, true);
        document.addEventListener('mousemove', handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp, true);
        document.removeEventListener('mousemove', handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newWidth = props.right ?
            document.body.clientWidth - e.clientX :
            e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    const handleDrawerClose = () => {
        setDrawerWidth(5);
    };

    const classes = useStyles({ ...props, drawerWidth });

    return (
        <div>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children[1]}
            </main>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={props.right ? 'right' : 'left'}
                PaperProps={{ style: { width: drawerWidth } }}
            >

                <div className={classes.drawerHeader}>
                    {props.title}
                    <IconButton onClick={handleDrawerClose}>
                        {
                            props.right ?
                                <ChevronRightIcon /> :
                                <ChevronLeftIcon />
                        }
                    </IconButton>
                </div>
                <div className={classes.toolbar} />
                <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />

                {props.children[0]}
            </Drawer>
        </div>

    );
}
