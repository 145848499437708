import React, { createContext, ReactNode, useContext, useState } from 'react';

export type TabsContext = {
    tabsOptions: Array<{
        label: string,
    }>,
    setTabsOptions: (_: TabsContext['tabsOptions']) => void,
    activeTab: number,
    setActiveTab: (_: number) => void,
};

const TabsContext_ = createContext<TabsContext | null>(null);

export function useTabsContext() {
    return useContext(TabsContext_ as React.Context<TabsContext>);
}

export type TabsContextProviderProps = {
    defaultTab: number,
    children: ReactNode,
}
export function TabsContextProvider({ defaultTab, children }: TabsContextProviderProps) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [tabsOptions, setTabsOptions] = useState<TabsContext['tabsOptions']>([]);

    const context: TabsContext = {
        tabsOptions,
        setTabsOptions,
        activeTab,
        setActiveTab,
    };

    return (
        <TabsContext_.Provider value={context} >
            {children}
        </TabsContext_.Provider>
    )
}
