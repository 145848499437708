import { Grid, TableBody, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { StyledTable, StyledTableCell, StyledTableContainer } from './single-asset-styles';

export function AssetSnapshots(props: {
    snapshotSummaries: Array<any>,
}) {
    const { t, i18n } = useTranslation(['pablo']);
    const [headerLabels, setHeaderLabels] = useState<any>({ name: '', data: '' });
    const [title, setTitle] = useState<any>('');
    const [data, setData] = useState<any>([])

    useEffect(() => {
        setHeaderLabels(
            {
                date: t('pablo:components.asset.single.createdAt'),
                name: t('pablo:components.asset.single.versionName'),
            });
        setTitle(t('pablo:components.asset.single.savedSnapshots'));
    }, [i18n.language]);


    useEffect(() => {
        const theData = props.snapshotSummaries.map((summary) => {
            return {
                id: summary.id,
                name: summary.name,
                createdAt: `${summary.createdAt.toLocaleDateString(i18n.language)} ${summary.createdAt.toLocaleTimeString(i18n.language)}`,
            }
        });
        setData(theData);
    }, [props.snapshotSummaries, i18n.language]);

    const getRows = () => {
        return data.map((snapshotSummary: any) =>
            <TableRow key={snapshotSummary.name}>
                <StyledTableCell
                    style={{ width: 200 }}
                >
                    {snapshotSummary.createdAt}
                </StyledTableCell>
                <StyledTableCell>
                    {snapshotSummary.name}
                </StyledTableCell>
            </TableRow>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>{title}</h3>
            </Grid>
            <Grid item xs={12}>
                <StyledTableContainer>
                    <StyledTable stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    style={{ width: 200 }}
                                >
                                    {headerLabels.date}
                                </StyledTableCell>
                                <StyledTableCell>{headerLabels.name}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getRows()}
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>
            </Grid>
        </Grid>
    )
}