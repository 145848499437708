import {
    Button, Checkbox, Dialog, DialogContent, FormControl, Grid, Icon, Input,
    InputLabel, ListItemText, MenuItem, Select, TextField, Toolbar
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetDataGrid from 'src/components/asset/datagrid/asset-datagrid.component';
import { ProjectContext } from 'src/contexts/project.context';
import { APIServiceManager, ProjectService } from 'src/services';
import { saveFileLocally } from 'src/utils/file';


function FilterDialog(props: any) {
    const [str, setStr] = useState([]);
    const [name, setName] = useState('');
    const [codes, setCodes] = useState<any>([]);
    // const [searchText, setSearchText] = useState('');
    const [propKey, setPropKey] = useState('');
    const [propValue, setPropValue] = useState('');

    const [propKey2, setPropKey2] = useState('');
    const [propValue2, setPropValue2] = useState('');

    const [propKey3, setPropKey3] = useState('');
    const [propValue3, setPropValue3] = useState('');

    const { t } = useTranslation(['pablo']);

    useEffect(() => {
        const getStr = async () => {
            if (props.open) {
                const strQuery = await APIServiceManager.StructureService.getStructures({ editable: true });
                setStr(strQuery);
            }
        };
        getStr();
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={props.onHide} fullWidth maxWidth="lg">
            <DialogContent>
                <form onSubmit={(e: any) => {
                    e.preventDefault();
                    const filter: any = {};
                    if (name !== '' && name !== undefined) filter.name = name;
                    if (codes.length > 0) filter['structure.codes'] = codes.join(',');
                    // if (searchText !== '' && searchText !== undefined) filter.search = searchText;
                    if (propKey !== '' && propKey !== undefined) {
                        filter.propKey = propKey;
                    } else if (propValue !== '' && propValue !== undefined) {
                        filter.search = propValue;
                    }
                    if (propValue !== '' && propValue !== undefined) filter.propValue = propValue;

                    if (propKey2 !== '' && propKey2 !== undefined) {
                        filter.propKey2 = propKey2;
                    }
                    if (propValue2 !== '' && propValue2 !== undefined) filter.propValue2 = propValue2;

                    if (propKey3 !== '' && propKey3 !== undefined) {
                        filter.propKey3 = propKey3;
                    }
                    if (propValue3 !== '' && propValue3 !== undefined) filter.propValue3 = propValue3;

                    props.onSubmit(filter);
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Asset Name"
                                margin="normal"
                                value={name}
                                onChange={(e: any) => setName(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{t('pablo:label.assetCode')}</InputLabel>
                                <Select
                                    fullWidth
                                    id="outlined-required"
                                    label="Asset Code"
                                    value={codes}
                                    multiple
                                    input={<Input />}
                                    onChange={(e: any) => setCodes(e.target.value)}
                                    renderValue={(selected: any) => (selected as string[]).join(', ')}
                                    variant="outlined">
                                    {str.map((s: any) => (
                                        <MenuItem key={s.code} value={s.code}>
                                            <Checkbox checked={codes.indexOf(s.code) > -1} />
                                            <ListItemText primary={s.code} />
                                        </MenuItem>

                                        // <MenuItem value={s.code}>{s.code} - {s.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container style={{ padding: -2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('pablo:label.searchPropertyName')}
                                        margin="normal"
                                        value={propKey}
                                        onChange={(e: any) => setPropKey(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('pablo:label.searchPropertyValue')}
                                        margin="normal"
                                        value={propValue}
                                        onChange={(e: any) => setPropValue(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('pablo:label.searchPropertyName')}
                                        margin="normal"
                                        value={propKey2}
                                        onChange={(e: any) => setPropKey2(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('pablo:label.searchPropertyValue')}
                                        margin="normal"
                                        value={propValue2}
                                        onChange={(e: any) => setPropValue2(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label={t('pablo:label.searchPropertyName')}
                                        margin="normal"
                                        value={propKey3}
                                        onChange={(e: any) => setPropKey3(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        label={t('pablo:label.searchPropertyValue')}
                                        margin="normal"
                                        value={propValue3}
                                        onChange={(e: any) => setPropValue3(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button type="submit">
                        {t('pablo:button.submit')}
                    </Button>
                    <Button type="button" onClick={() => {
                        setName('');
                        setCodes([]);
                        // setSearchText('');
                        setPropKey('');
                        setPropValue('');
                        setPropKey2('');
                        setPropValue2('');
                        setPropKey3('');
                        setPropValue3('');
                    }}>
                        {t('pablo:button.clear')}
                    </Button>
                </form>
            </DialogContent>
        </Dialog >
    );
}


export default function DataGridView() {

    const projectContext = useContext(ProjectContext);

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('loading');
    const [filters, setFilters] = useState({});
    const [refresh, doRefresh] = useState(0);
    const [deleteAsset, doDelete] = useState(0);
    const [hasEditorRole, setHasEditorRole] = useState(false);
    const [hasAdminRole, setHasAdminRole] = useState(false);

    useEffect(() => {
        setHasEditorRole(projectContext.currentProject.permissions.includes('part-editor'));
        setHasAdminRole(projectContext.currentProject.permissions.includes('project-manager'));
    }, [projectContext]);

    const importAsset = async (e: any) => {
        setIsLoading(true);
        var reader = new FileReader();
        reader.onload = async (fe: ProgressEvent<FileReader>) => {
            if (fe.target) {
                let objects = [];
                try {
                    objects = JSON.parse(fe.target.result as string);
                } catch (error) {
                    alert('Json file is empty or not formatted');
                    setIsLoading(false);
                    setLoadingText('loading');
                    return;
                }

                const newObjects = [];

                for (let k = 0; k < objects.length; k++) {
                    const tempObj: any = objects[k];

                    newObjects.push({
                        ...tempObj,
                        code: tempObj.Classification_Code,
                        type: 'part',
                        name: tempObj.Name,
                    });
                }

                const CHUNK_SIZE = 50;
                let numImportedAsset = 0;
                for (let index = 0; index < newObjects.length; index += CHUNK_SIZE) {

                    const element = newObjects.slice(index, index + CHUNK_SIZE);
                    const importRes = await APIServiceManager.AssetService.importAssets(element);
                    numImportedAsset += importRes.count;
                    setLoadingText(`loaded ${index += CHUNK_SIZE} out of ${objects.length}`);
                }
                alert(`Number of assets imported: ${numImportedAsset}`);
            }
            doRefresh(prev => prev + 1);
            setIsLoading(false);
            setLoadingText('loading');
        };

        reader.onerror = (fe: ProgressEvent<FileReader>) => {
            setIsLoading(false);
            setLoadingText('loading');
        };
        reader.readAsBinaryString(e.target.files![0]);
    };

    const handleDelete = async () => {
        doDelete(prev => prev + 1);
        // await _assetListService.removeAssets(Array.from(selectedRows));
        doRefresh(prev => prev + 1);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const downloadExport = async () => {
        const blob = await APIServiceManager.AssetService.exportToExcel({ type: 'part' });

        if (blob) {
            saveFileLocally(blob, 'output.xlsx');
        }
    };

    const syncAsset = async () => {
        const _projectService: ProjectService = APIServiceManager.getService('ProjectService') as ProjectService;
        const pId = projectContext.currentProject._id;

        if (pId) {
            const projects = await _projectService.getProject(pId);
            const projectOptions = projects[0].options;

            const message = projectOptions.isAssetReplaced ?
                'You are about to sync the asset list with an external database (property values will be overwritter).\n Do you want to continue ?' :
                'You are about to sync the asset list with an external database (property values will not be overwritter).\n Do you want to continue ?';
            const dosync = window.confirm(message);
            if (dosync) {
                setIsLoading(true);
                const result = await APIServiceManager.AssetService.syncAsset(projectOptions.assetDbURL, projectOptions.isAssetReplaced);
                doRefresh(refresh + 1);
                setIsLoading(false);

            }
        }
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Toolbar className="header-filters-toolbar">

                <Button size="small" onClick={() => setOpen(true)}>
                    <Icon className="fa fa-filter" />
                </Button>


                <Button disabled={!hasAdminRole} size="small" onClick={handleDelete}>
                    <Icon className="fa fa-trash-alt" />
                </Button>

                <div className="mr-2">
                    <label>
                        <input
                            disabled={!hasEditorRole}
                            onChange={importAsset}
                            style={{ display: 'none' }}
                            id="upload-excel"
                            name="upload-excel"
                            type="file"
                        />
                        <Button disabled={!hasEditorRole} size="small" component="span" >
                            <Icon className="fa fa-file-import" />
                        </Button>
                    </label>
                </div>

                <Button disabled={!hasAdminRole} size="small" onClick={syncAsset} >
                    <Icon className="fa fa-sync" style={{ width: '1.2em' }} />
                </Button>

                <Button disabled={!hasAdminRole} size="small" onClick={downloadExport} >
                    <Icon className="fa fa-file-export" style={{ width: '1.2em' }} />
                </Button>


            </Toolbar>
            <AssetDataGrid loadingText={loadingText} isLoading={isLoading} refresh={refresh} deleteAsset={deleteAsset} queryFilters={filters} />
            <FilterDialog open={open} onHide={handleDialogClose} onSubmit={(filter: any) => {
                setFilters(filter);
                setOpen(false);
            }} />
        </div>
    );
}