import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Grid, IconButton, Paper, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGridPro,
    enUS,
    frFR,
    GridColumns,
    GridPagination,
    GridSelectedRowCount,
    GridSelectionModel,
    GridSortDirection,
    GridSortModel,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiContext
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APIServiceManager } from 'src/services';
import { CTA } from '../ui/CTA/call-to-action.component';
import { RenderCellExpand } from '../ui/grid-cell-expand';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        minWidth: 200,
        marginLeft: 10,
    },
    title: {
        paddingLeft: 60,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    row: {

    },
    disableRow: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[500],
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    toolbar: {
        minWidth: 200,
        margin: '5px 20px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    linkCell: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            overflowY: 'hidden',
            overflowX: 'hidden',
            '&:hover': {
                overflowX: 'scroll',
            }

        },
    },
    cell: {
        outline: 'unset !important',
    },
    header: {
        outline: 'unset !important',
    },
    searchToolbar: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: '8px 16px 0px 0px !important',
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
    ctaRight: {
        marginRight: '16px !important',
        marginTop: '8px !important',
    },

}));


function FooterWithAction(props: {
    deleteSelection?: (selection: GridSelectionModel) => void;
    editSelection?: (selection: GridSelectionModel) => void;
}) {
    const apiRef = useGridApiContext();
    return (
        <Grid container style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
            {apiRef.current.state.selection.length > 0 ?
                <Grid container item xs={6} spacing={2}>
                    {
                        props.editSelection ?
                            <Grid item xs={1}>
                                <IconButton size="small" className="fa fa-edit" onClick={(event: any) => {
                                    props.editSelection!(apiRef.current.state.selection);
                                }} />
                            </Grid> : null
                    }

                    {
                        props.deleteSelection ?
                            <Grid item xs={1}>
                                <IconButton size="small" className="fa fa-trash" onClick={(event: any) => {
                                    props.deleteSelection!(apiRef.current.state.selection);
                                }} />
                            </Grid> : null
                    }

                    <Grid item xs={10}>
                        <GridSelectedRowCount selectedRowCount={apiRef.current.state.selection.length} />
                    </Grid>
                </Grid>
                : null
            }
            <Grid item xs={6} style={{ justifyContent: 'flex-end' }}>
                <GridPagination />
            </Grid>
        </Grid>
    );
}

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface LibraryToolbarProps {
    clearSearch: () => void;
    onSearchChange: () => void;
    onAdd: () => void;

    value: string;
    title: string;
}

function LibraryToolbar(props: LibraryToolbarProps) {
    const classes = useStyles();
    const { t } = useTranslation(['pablo']);

    const history = useNavigate();
    const args: any = {};
    return (
        <div className={classes.searchToolbar}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    size="large"
                    onClick={() => {
                        history(-1);
                    }}
                >
                    <ChevronLeftIcon fontSize="large" />
                </Button>
                <Typography variant="h6">
                    {props.title}
                </Typography>
            </div>

            <GridToolbarContainer>
                <TextField
                    variant="standard"
                    value={props.value}
                    onChange={props.onSearchChange}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={props.clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />

                <CTA>
                    <GridToolbarFilterButton
                        componentsProps={{
                            button: {
                                disableRipple: true,
                                disableElevation: true,
                                disableFocusRipple: true,
                                disableTouchRipple: true,
                            },
                        }}
                        sx={{
                            color: 'inherit',
                            padding: 0,
                        }}
                        {...args}
                    />
                </CTA>
            </GridToolbarContainer>

            <CTA
                icon={{
                    name: 'plus',
                    type: 'fa',
                }}
                size="small"
                variant='filled'
                onClick={props.onAdd}
                className={classes.ctaRight}
            >
                {t('pablo:button.add')}
            </CTA>
        </div>
    );
}


function PropertiesWithinSet(props: any) {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['pablo']);

    const [data, setData] = useState<Array<any>>([]);
    const [filteredData, setFilteredData] = useState<Array<any>>([]);

    const [loading, setLoading] = useState(true);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [pageSize, setPageSize] = useState<number>(25);

    const [localeText, setLocaleText] = useState<any>(enUS);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'key',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const [searchText, setSearchText] = useState('');

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field] ? row[field].toString() : undefined);
            });
        });
        setFilteredData(filteredRows);
    };

    useEffect(() => {
        const fetchProps = async () => {
            setLoading(true);
            const set = await APIServiceManager.PropSetService.getSets({ _id: props.propertySetId }, true);
            const propWithId = set[0].properties.map((r: any) => { r.id = r._id; return r; });
            setData(propWithId);
            setFilteredData(propWithId);
            setLoading(false);
        };
        fetchProps();
    }, [props.propertySetId, props.refresh]);

    const columns: GridColumns = useMemo(() => {
        return [
            { field: 'key', headerName: t('pablo:table.headers.code'), editable: false, flex: 0.5 },
            { field: 'name', headerName: t('pablo:table.headers.name'), editable: false, flex: 0.5 },
            { field: 'description', headerName: t('pablo:table.headers.description'), editable: false, flex: 1.0, renderCell: RenderCellExpand },
            { field: 'isTechProperty', headerName: t('pablo:table.headers.technicalProp'), type: 'boolean', editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'category', headerName: t('pablo:table.headers.category'), editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'unit', headerName: t('pablo:table.headers.unit'), editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'format', headerName: t('pablo:table.headers.format'), editable: false, minWidth: 150, flex: 0.3, hide: true },
            { field: 'type', headerName: t('pablo:table.headers.type'), editable: false, minWidth: 150, flex: 0.3, hide: true },
        ];
    }, [i18n.language, localeText]);

    useEffect(() => {
        switch (i18n.language) {
            case 'fr':
                setLocaleText(frFR.components.MuiDataGrid.defaultProps.localeText);
                break;
            case 'en':
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
            default:
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
        }
    }, [i18n.language]);

    const getFooter = useCallback(() => {
        return (
            <FooterWithAction editSelection={props.editSelection} deleteSelection={props.deleteSelection} />
        );
    }, [props.editSelection, props.deleteSelection]);

    return (
        <Paper style={{ height: '100%' }}>
            <DataGridPro
                localeText={localeText}
                classes={{
                    cell: classes.cell,
                    columnHeader: classes.header
                }}
                rows={filteredData}
                columns={columns}
                pagination
                loading={loading}

                pageSize={pageSize}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 50, 100]}

                sortModel={sortModel}
                onSortModelChange={(model: GridSortModel) => setSortModel(model)}

                components={{
                    Toolbar: LibraryToolbar,
                    Footer: getFooter
                }}

                componentsProps={{
                    toolbar: {
                        title: props.title,
                        value: searchText,
                        onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                        onAdd: props.onAdd,
                    },
                }}

                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                checkboxSelection
                disableSelectionOnClick
                onRowClick={props.onRowClick}
            />
        </Paper>
    );

}

export default PropertiesWithinSet;
