import { Paper, Popper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef, useState } from 'react';
interface GridCellExpandProps {
    value: any;
    width: number;
}

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
}));

function isOverflown(element: Element): boolean {
    return (
        element !== null ? element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth : false
    );
}

export function GridCellExpand(props: GridCellExpandProps) {
    const { width, value } = props;
    const wrapper = useRef<HTMLDivElement | null>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = useState(false);
    const showPopper = useRef(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current!);

        setTimeout(() => {
            showPopper.current = isCurrentlyOverflown;
        });

        setAnchorEl(cellDiv.current);

        setTimeout(() => {
            const isCurrentlyOverflown = isOverflown(cellValue.current!);
            if (showPopper.current && isCurrentlyOverflown) {
                setShowFullCell(true);
            }
        }, 500);
    };

    const handleMouseLeave = () => {
        showPopper.current = false;
        setTimeout(() => {
            setShowFullCell(false);
        });

    };


    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        function handleScroll(event: any) {
            showPopper.current = false;
            setShowFullCell(false);
        }

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('scroll', handleScroll, true);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('scroll', handleScroll, true);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className="cellValue">
                {value}
            </div>
            {showPopper.current && showFullCell && wrapper.current && (
                <Popper
                    onMouseLeave={handleMouseLeave}
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        onMouseLeave={handleMouseLeave}
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
}

export function RenderCellExpand(params: GridRenderCellParams) {
    return (
        <GridCellExpand
            value={params.value ? params.value.toString() : ''}
            width={params.colDef.computedWidth}
        />
    );
}


export function withRenderCellExpands(props: any) {
    return (
        <GridCellExpand
            value={props.children}
            width={props.colDef.computedWidth}
        />
    );
}