import collision from './collision';
const THREE: any = window.THREE;

//referece:
// https://forge.autodesk.com/blog/custom-window-selection-forge-viewer-part-iii
export class MySelectionWindow extends Autodesk.Viewing.Extension {

    _options: any;
    _viewer: Autodesk.Viewing.GuiViewer3D;
    _group: Autodesk.Viewing.UI.ControlGroup | undefined;
    _button: Autodesk.Viewing.UI.Button | undefined;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
        super(viewer, options);
        this._viewer = viewer;

        this._options = options;
        this._container = viewer.canvas.parentElement;

    }

    _boundingBoxInfo: any;
    //bounding sphere of this model
    _boundingSphere: any = null;
    //container DIV of the viewer
    _container: HTMLElement | null;
    //start point of select window
    _mouseStart = new THREE.Vector3(0, 0, -10);
    //end point of select window
    _mouseEnd = new THREE.Vector3(0, 0, -10);
    //is selecting window running
    _running: boolean = false;
    //rectangle lines of select window
    _lineGeom: any = new THREE.Geometry();
    // _line_mesh: THREE.Line = new THREE.Line();

    _rectGroup: any = new THREE.Group();;
    //material for rectangle lines of select window
    _materialLineInclude: any = new THREE.LineBasicMaterial({
        color: new THREE.Color(0xFF00FF),
        linewidth: 1,
        opacity: .6
    });

    _materialLineIntersect: any = new THREE.LineDashedMaterial({ color: 0xFF0000, dashSize: 3, gapSize: 3 });

    _intersect: boolean | undefined = undefined;

    //when extension is loaded
    load = () => {
        console.log('MySelectionWindow is loaded!');
        //bind keyup event
        this._viewer.impl.invalidate(true);
        return true;
    };

    //when extension is unloaded 
    unload = () => {
        console.log('MySelectionWindow is now unloaded!');
        //unbind keyup event
        // document.addEventListener('keyup', this.onKeyUp);

        if (this._group) {
            this.viewer.toolbar.removeControl(this._group);
        }

        return true;
    };

    //build boundingbox info of each fragments
    init = (viewerContainer: any) => {

        var model: any = this._viewer.model;
        // this._container = viewerContainer;

        //get bounding sphere of  whole model
        this._boundingSphere = model.getBoundingBox().getBoundingSphere();

        //fragments list array
        var fragList = model.getFragmentList();
        //boxes array 
        var boxes = fragList.fragments.boxes;
        //map from frag to dbid
        var fragid2dbid = fragList.fragments.fragId2dbId;

        //build _boundingBoxInfo by the data of Viewer directly
        //might probably be a bit slow with large model..
        this._boundingBoxInfo = [];
        var index = 0;
        for (var step = 0; step < fragid2dbid.length; step++) {
            index = step * 6;
            var thisBox = new THREE.Box3(new THREE.Vector3(boxes[index], boxes[index + 1], boxes[index + 2]),
                new THREE.Vector3(boxes[index + 3], boxes[index + 4], boxes[index + 5]));


            this._boundingBoxInfo.push({ bbox: thisBox, dbId: fragid2dbid[step] });
        }
    }

    onToolbarCreated = () => {
        // Add a new button to the toolbar group
        this._group = this.viewer.toolbar.getControl('allMyExtensionToolbar') as Autodesk.Viewing.UI.ControlGroup;
        if (!this._group) {
            this._group = new Autodesk.Viewing.UI.ControlGroup('allMyExtensionToolbar');
            this.viewer.toolbar.addControl(this._group);
        }
        this._button = new Autodesk.Viewing.UI.Button('testButton');
        this._button.onClick = this.onButtonClick;

        this._button.setToolTip('Select by box');
        this._button.setIcon('select-box-icon-btn');
        this._group.addControl(this._button);

    }


    onButtonClick = (e: Event) => {
        this._button!.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);

        this._viewer.clearSelection();
        this._viewer.setNavigationLock(true);

        this._container!.addEventListener('mousedown', this.onMouseDown);

        var camera = new THREE.OrthographicCamera(0, this._viewer.canvas.clientWidth, 0, this._viewer.canvas.clientHeight, 1, 1000)
        this._viewer.impl.createOverlayScene("selectionWindowOverlay", undefined, undefined, camera);

        (this._viewer.impl as any).overlayScenes.selectionWindowOverlay.skipDepthTarget = true;
        (this._viewer.impl as any).overlayScenes.selectionWindowOverlay.skipIdTarget = true;
    }

    createLine = (mat: THREE.Material) => {
        this._lineGeom = new THREE.Geometry();

        this._lineGeom.vertices.push(
            this._mouseStart.clone(),
            this._mouseStart.clone(),
            this._mouseStart.clone(),
            this._mouseStart.clone(),
            this._mouseStart.clone());

        // add geom to group
        const line_mesh = new THREE.Line(this._lineGeom, mat, THREE.LineStrip);

        this._rectGroup = new THREE.Group();
        this._rectGroup.add(line_mesh);
    }

    removeLineFromScene = () => {
        this._viewer.impl.removeOverlay("selectionWindowOverlay", this._rectGroup);
        this._viewer.impl.invalidate(false, false, true);
    }

    addLineToScene = () => {
        this._viewer.impl.addOverlay("selectionWindowOverlay", this._rectGroup);
        // this._viewer.impl.sceneAfter.add(this._rectGroup);

        this._viewer.impl.invalidate(false, false, true);
    }

    updateLineVertice = (clientX: any, clientY: any) => {
        //calculate the offset with viewer container position, for Three.js geometry
        const viewer_pos = this._viewer.container.getBoundingClientRect();
        //get mouse points
        this._mouseEnd.x = clientX - viewer_pos.x;
        this._mouseEnd.y = clientY - viewer_pos.y;

        //update rectange lines
        this._lineGeom.vertices[1].x = this._mouseStart.x;
        this._lineGeom.vertices[1].y = this._mouseEnd.y;
        this._lineGeom.vertices[2] = this._mouseEnd.clone();
        this._lineGeom.vertices[3].x = this._mouseEnd.x;
        this._lineGeom.vertices[3].y = this._mouseStart.y;
        this._lineGeom.vertices[4] = this._lineGeom.vertices[0];

        if (this._intersect) {
            try {
                this._lineGeom.computeLineDistances();
                this._lineGeom.lineDistancesNeedUpdate = true;
            } catch {

            }
        }

        this._lineGeom.verticesNeedUpdate = true;

        this._viewer.impl.invalidate(false, false, true);
    }

    onMouseMove = (evt: any) => {
        if (this._running) {
            if (this._mouseEnd.x < this._mouseStart.x) {
                if (!this._intersect) {
                    this.removeLineFromScene();
                    this.createLine(this._materialLineIntersect);
                    this.addLineToScene();
                }
                this._intersect = true;
            } else {
                if (this._intersect == undefined || this._intersect) {

                    this.removeLineFromScene();
                    this.createLine(this._materialLineInclude);
                    this.addLineToScene();
                }
                this._intersect = false;
            }
            this.updateLineVertice(evt.clientX, evt.clientY);
        }
    }

    onMouseUp = (evt: any) => {
        if (this._running) {
            //calculate the offset with viewer container position, for Three.js geometry
            const viewer_pos = this._viewer.container.getBoundingClientRect();

            //get mouse points 
            this._mouseEnd.x = evt.clientX - viewer_pos.x;
            this._mouseEnd.y = evt.clientY - viewer_pos.y;


            //get box within the area of select window, or partially intersected. 
            //now we need to offset the screenpoint back without viewer container position.
            var ids = this.compute({
                clientX: this._mouseStart.x + viewer_pos.x,
                clientY: this._mouseStart.y + viewer_pos.y
            },
                {
                    clientX: this._mouseEnd.x + viewer_pos.x,
                    clientY: this._mouseEnd.y + viewer_pos.y
                },
                this._intersect); // true:  partially intersected.  false: inside the area only

            this._viewer.select(ids);
            this._button!.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);

            this._viewer.setNavigationLock(false);
            this._running = false;
            this._intersect = undefined;

            this._container!.removeEventListener('mouseup', this.onMouseUp);
            this._container!.removeEventListener('mousemove', this.onMouseMove);
            this._container!.removeEventListener('mousedown', this.onMouseDown);

            // //remove the Overlay Scene
            this.removeLineFromScene();
            this._viewer.impl.removeOverlayScene("selectionWindowOverlay");
        }
    }

    onMouseDown = (evt: any) => {
        //calculate the offset with viewer container position, for Three.js geometry
        const viewer_pos = this._viewer.container.getBoundingClientRect();
        //get mouse points  
        this._mouseStart.x = evt.clientX - viewer_pos.x;
        this._mouseStart.y = evt.clientY - viewer_pos.y;
        this._running = true;

        // this.createLine(this._materialLineInclude);
        // this.addLineToScene();

        this._container!.addEventListener('mouseup', this.onMouseUp);
        this._container!.addEventListener('mousemove', this.onMouseMove);
    }

    //prepare the range of select window and filter out those objects
    compute = (pointer1: any, pointer2: any, partialSelect: any) => {

        // build 4 rays to project the 4 corners
        // of the selection window

        var xMin = Math.min(pointer1.clientX, pointer2.clientX)
        var xMax = Math.max(pointer1.clientX, pointer2.clientX)

        var yMin = Math.min(pointer1.clientY, pointer2.clientY)
        var yMax = Math.max(pointer1.clientY, pointer2.clientY)

        var ray1 = this.pointerToRay({
            clientX: xMin,
            clientY: yMin
        })

        var ray2 = this.pointerToRay({
            clientX: xMax,
            clientY: yMin
        })

        var ray3 = this.pointerToRay({
            clientX: xMax,
            clientY: yMax
        })

        var ray4 = this.pointerToRay({
            clientX: xMin,
            clientY: yMax
        })

        // first we compute the top of the pyramid
        var top = new THREE.Vector3(0, 0, 0)

        top.add(ray1.origin)
        top.add(ray2.origin)
        top.add(ray3.origin)
        top.add(ray4.origin)

        top.multiplyScalar(0.25)

        // we use the bounding sphere to determine
        // the height of the pyramid
        var { center, radius } = this._boundingSphere

        // compute distance from pyramid top to center
        // of bounding sphere

        var dist = new THREE.Vector3(
            top.x - center.x,
            top.y - center.y,
            top.z - center.z)

        // compute height of the pyramid:
        // to make sure we go far enough,
        // we add the radius of the bounding sphere

        var height = radius + dist.length()

        // compute the length of the side edges

        var angle = ray1.direction.angleTo(
            ray2.direction)

        var length = height / Math.cos(angle * 0.5)

        // compute bottom vertices

        var v1 = new THREE.Vector3(
            ray1.origin.x + ray1.direction.x * length,
            ray1.origin.y + ray1.direction.y * length,
            ray1.origin.z + ray1.direction.z * length)

        var v2 = new THREE.Vector3(
            ray2.origin.x + ray2.direction.x * length,
            ray2.origin.y + ray2.direction.y * length,
            ray2.origin.z + ray2.direction.z * length)

        var v3 = new THREE.Vector3(
            ray3.origin.x + ray3.direction.x * length,
            ray3.origin.y + ray3.direction.y * length,
            ray3.origin.z + ray3.direction.z * length)

        var v4 = new THREE.Vector3(
            ray4.origin.x + ray4.direction.x * length,
            ray4.origin.y + ray4.direction.y * length,
            ray4.origin.z + ray4.direction.z * length)

        // create planes

        var plane1 = new THREE.Plane()
        var plane2 = new THREE.Plane()
        var plane3 = new THREE.Plane()
        var plane4 = new THREE.Plane()
        var plane5 = new THREE.Plane()

        plane1.setFromCoplanarPoints(top, v1, v2)
        plane2.setFromCoplanarPoints(top, v2, v3)
        plane3.setFromCoplanarPoints(top, v3, v4)
        plane4.setFromCoplanarPoints(top, v4, v1)
        plane5.setFromCoplanarPoints(v3, v2, v1)

        var planes = [
            plane1,
            plane2,
            plane3,
            plane4,
            plane5
        ]

        var vertices = [
            v1, v2, v3, v4, top
        ]

        // filter all bounding boxes to determine
        // if inside, outside or intersect

        var result = this.filterBoundingBoxes(
            planes, vertices, partialSelect)

        // all inside bboxes need to be part of the selection

        var dbIdsInside = result.inside.map((bboxInfo: any) => {

            return bboxInfo.dbId
        })

        // if partialSelect = true
        // we need to return the intersect bboxes

        if (partialSelect) {

            var dbIdsIntersect = result.intersect.map((bboxInfo) => {

                return bboxInfo.dbId
            })


            return [...dbIdsInside, ...dbIdsIntersect]
        }

        return dbIdsInside
    }

    //rays of the corners of select window
    pointerToRay = (pointer: any) => {

        var camera = this._viewer.navigation.getCamera();
        var pointerVector = new THREE.Vector3()
        var rayCaster = new THREE.Raycaster()
        var pointerDir = new THREE.Vector3()
        var domElement = this._viewer.canvas

        var rect = domElement.getBoundingClientRect()

        var x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1
        var y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1

        if (camera.isPerspective) {

            pointerVector.set(x, y, 0.5)

            pointerVector.unproject(camera)

            rayCaster.set(camera.position,
                pointerVector.sub(
                    camera.position).normalize())

        } else {

            pointerVector.set(x, y, -15)

            pointerVector.unproject(camera)

            pointerDir.set(0, 0, -1)

            rayCaster.set(pointerVector,
                pointerDir.transformDirection(
                    camera.matrixWorld))
        }

        return rayCaster.ray
    }

    //filter out those objects in the range of select window
    filterBoundingBoxes = (planes: any, vertices: any, partialSelect: any) => {

        var intersect = []
        var outside = []
        var inside = []

        var triangles = [
            { a: vertices[0], b: vertices[1], c: vertices[2] },
            { a: vertices[0], b: vertices[2], c: vertices[3] },
            { a: vertices[1], b: vertices[0], c: vertices[4] },
            { a: vertices[2], b: vertices[1], c: vertices[4] },
            { a: vertices[3], b: vertices[2], c: vertices[4] },
            { a: vertices[0], b: vertices[3], c: vertices[4] }
        ]

        for (let bboxInfo of this._boundingBoxInfo) {

            // if bounding box inside, then we can be sure
            // the mesh is inside too

            if (this.containsBox(planes, bboxInfo.bbox)) {
                inside.push(bboxInfo)
            } else if (partialSelect) {

                //reconstructed by using AABBCollision lib.
                if (this.boxIntersectVertex(bboxInfo.bbox, triangles))
                    intersect.push(bboxInfo)
                else
                    outside.push(bboxInfo)

            } else {
                outside.push(bboxInfo)
            }
        }

        return {
            intersect,
            outside,
            inside
        }
    }


    //get those boxes which are included in the
    //range of select window
    containsBox = (planes: any, box: any) => {

        var { min, max } = box

        var vertices = [
            new THREE.Vector3(min.x, min.y, min.z),
            new THREE.Vector3(min.x, min.y, max.z),
            new THREE.Vector3(min.x, max.y, max.z),
            new THREE.Vector3(max.x, max.y, max.z),
            new THREE.Vector3(max.x, max.y, min.z),
            new THREE.Vector3(max.x, min.y, min.z),
            new THREE.Vector3(min.x, max.y, min.z),
            new THREE.Vector3(max.x, min.y, max.z)
        ]

        for (let vertex of vertices) {

            for (let plane of planes) {

                if (plane.distanceToPoint(vertex) < 0) {

                    return false
                }
            }
        }

        return true
    }

    //get those boxes which are initersected with the
    //range of select window (triangles)
    boxIntersectVertex = (box: any, triangles: any) => {
        for (let index in triangles) {
            var t = triangles[index];
            if (collision.isIntersectionTriangleAABB(t.a, t.b, t.c, box))
                return true;
        }
        return false;
    }

}

// MySelectionWindow.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
// MySelectionWindow.prototype.varructor = MySelectionWindow;

Autodesk.Viewing.theExtensionManager.registerExtension('MySelectionWindow', MySelectionWindow);