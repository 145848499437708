import { Card, CardActions, CardContent, CardHeader, CardMedia, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from 'src/contexts/project.context';
import { IProject } from 'src/core';

const lastProjectId = { curr: localStorage.getItem('forge_aimspainter_project') };

export type ProjectSelectorCardProps = {
    project: IProject,
    onActivate?: () => void,
    isActive?: boolean,
};
export function ProjectSelectorCard({ project: p, onActivate, isActive }: ProjectSelectorCardProps) {
    const projectContext = useContext(ProjectContext);

    useEffect(() => {
        if (p._id as string === lastProjectId.curr) {
            onActivate?.();
        }
    }, [projectContext]);

    const activate = () => {
        if (p && p._id) {
            localStorage.setItem('forge_aimspainter_project', p._id!);
            lastProjectId.curr = p._id;
            projectContext.setProject(p);
        }
        onActivate?.();
    }

    return (
        <ProjectCard project={p} onActivate={activate} isActive={isActive} />
    )
}

export type ProjectCardProps = {
    project: IProject,
    onActivate?: () => void,
    isActive?: boolean,
};
export function ProjectCard({ project: p, onActivate, isActive }: ProjectCardProps) {
    const { t } = useTranslation(['pablo']);
    const classes = useStyles();

    const image = p.image && p.image.mimetype && p.image.data ?
    `data:${p.image.mimetype};base64,${p.image.data}` :
    `../projectType/${p.type}.jpg`;

    return (
        <Card
            variant="outlined"
            onClick={onActivate}
            className={`${classes.card} ${isActive ? classes.active : ''}`}>
            <CardHeader title={p.name} onClick={onActivate} />
            <CardMedia
                onClick={onActivate}
                className={classes.media}
                image={image} />
            <CardContent onClick={onActivate}>
                {p.description}
            </CardContent>
            <CardActions >
                <Chip
                    style={{
                        cursor: 'pointer',
                        ...(isActive ? {} : {
                            opacity: 0,
                        }),
                    }}
                    label={t('pablo:pages.home.isActive')}
                    color='info'
                    variant='outlined'
                />
            </CardActions>
        </Card>
    )
}

const useStyles = makeStyles((theme: any) => ({
    card: {
        cursor: 'pointer',
        minWidth: 350,
        borderWidth: 1,
        borderColor: theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white,
        '&$active': {
            borderColor: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
            boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.25)',
        },
        '&:hover': {
            boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.25)',
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    active: {}
}));
