import React from 'react';
import { Route } from 'react-router-dom';
import {
    AllProjectsPage,
    AssetRegisterView,
    DatabaseView,
    DataGridView,
    GalleryView, HomePage, MapView, ProfilePage,
    ProjectPropLibraryPage,
    ProjectPropSetPage,
    PropertiesByLibraryPage,
    PropertyPage,
    PropertyWithinSetPage,
    PropLibraryPage,
    PropSetPage,
    SingleAssetView,
    StructurePage
} from '../pages';
import AdminRoutes from './admin.routes';
import ManagerRoutes from './manager.routes';

const ForgeViewer = React.lazy(() => import('../pages/new-viewer/new-viewer.page'));

function AuthenticatedRoutes() {

    return (
        <>
            <Route index element={<HomePage />} />
            <Route path="forge/gallery" element={<GalleryView />} />
            <Route path="map" element={<MapView />} />
            <Route path="forge/viewer" element={<ForgeViewer />} />
            <Route path="projects" element={<AllProjectsPage />} />

            <Route path="register">
                <Route path="asset">
                    <Route index element={<AssetRegisterView />} />
                    <Route path=":id" element={<SingleAssetView />} />
                </Route>
            </Route>

            <Route path="structure" element={<StructurePage />} />
            <Route path="structure/:id/proplib" element={<PropertiesByLibraryPage />} />
            <Route path="database" element={<DatabaseView />} />
            <Route path="data" element={<DataGridView />} />
            <Route path="profile" element={<ProfilePage />} />

            <Route path="prop">
                <Route path="lib">
                    <Route path="standard" element={<PropLibraryPage />} />
                    <Route path="project" element={<ProjectPropLibraryPage />} />
                    <Route path=":id/properties" element={<PropertyPage />} />
                </Route>
                <Route path="set">
                    <Route path="standard" element={<PropSetPage />} />
                    <Route path="project" element={<ProjectPropSetPage />} />
                    <Route path=":id/properties" element={<PropertyWithinSetPage />} />
                </Route>
            </Route>
            {AdminRoutes()}
            {ManagerRoutes()}
        </>
    );
}


export default AuthenticatedRoutes;
