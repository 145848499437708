import { API, APIServiceManager } from '../..';

class ExtendedCodeService {
    api: API = APIServiceManager.API;
    name() {
        return 'ExtendedCodeService';
    }

    async getExtendedCodes(): Promise<any> {
        try {
            const req = await this.api.axios.get('/project/extendedcode');
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getExtendedCodesForProject(projectId: string): Promise<any> {
        try {
            const req = await this.api.axios.get('/project/extendedcode', { headers: { project: projectId } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addExtendedCode(newCode: any): Promise<any> {
        try {
            const req = await this.api.axios.post('/project/extendedcode', newCode);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateExtendedCode(id: string, newInfo: any): Promise<any> {
        try {
            const req = await this.api.axios.put(`/project/extendedcode/${id}`, newInfo);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removeExtendedCode(id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/project/extendedcode/${id}`);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removeExtendedCodeForProject(projectId: string, id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/project/extendedcode/${id}`, { headers: { project: projectId } });
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

export { ExtendedCodeService };
