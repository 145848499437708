import React, { useState } from 'react';
import ForgeModelsView from '../models/models.page';
import './myprojects.page.css';

import { makeStyles } from '@mui/styles';
import CategoriesView from '../categories/categories.page';
import PermissionView from '../permissions/permissions.page';
import ProjectFunctionalityView from '../project-functionality/project-functionality.page';
import UnitsView from '../units/units.page';

import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReportsView from '../reports/reports.page';
import StructureConfigView from '../structureconfig/structureconfig.page';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        paddingLeft: 20
    },
    tabPanel: {
        marginBottom: 16
    },
    tabLabel: {
        outline: '0px !important'
    },

});

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ height: 'calc(100% - 60px)' }}

            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index &&

                children

            }
        </div>
    );
}

function ProjectManagerPage() {
    const { t, i18n } = useTranslation(['pablo']);
    const classes = useStyles();
    const [value, setValue] = useState(0);

    return (
        <div className={classes.root}>

            <div className={classes.tabPanel}>
                <Tabs value={value} onChange={(e: any, i: number) => setValue(i)}
                    variant="scrollable"
                    scrollButtons="auto">
                    <Tab className={classes.tabLabel} label={t('pablo:label.forgemodels')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.itwinmodels')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.users')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.functionality')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.structure')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.reports')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.units')} />
                    <Tab className={classes.tabLabel} label={t('pablo:label.categories')} />
                </Tabs>
            </div>

            <TabPanel value={value} index={0}>
                <ForgeModelsView />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div>NA</div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PermissionView />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ProjectFunctionalityView />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <StructureConfigView />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <ReportsView />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <UnitsView />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <CategoriesView />
            </TabPanel>
        </div>
    );
}

export default ProjectManagerPage;
