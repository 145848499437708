import React from 'react';
import ReactDOM from 'react-dom';
import { ForgeViewerExtensionOptions } from '../forge-viewer.interface';
import ForgeViewerEmitter from '../forge.event';
import { GetNumberOfObject, isolatePainted, isolateUnPainted, showNotPaintedInfo, showPaintedInfo } from '../viewer-helper';
import PainterViewerPanel from './painter-viewer-panel.component';


export default class PainterViewerPanelExtension extends Autodesk.Viewing.UI.DockingPanel {

    DOMContent: HTMLDivElement;
    viewer: any;
    reactNode: any;
    _options: ForgeViewerExtensionOptions;
    _visible: boolean = false;

    _selectedAssetId: string = '';
    _auto: boolean = false;
    _isolate: boolean = false;

    constructor(
        viewer: Autodesk.Viewing.GuiViewer3D,
        id: string,
        options: any | undefined
    ) {
        super(viewer.container as HTMLElement, id, 'AIMSPainter Viewer', options);
        this._options = options;
        this.viewer = viewer;
        this.container.classList.add('react-docking-panel');
        this.container.classList.add('viewer-panel');
        this.DOMContent = document.createElement('div');
        this.DOMContent.className = 'content docking-panel docking-panel-scroll docking-panel-container-solid-color-a right';
        this.container.appendChild(this.DOMContent);
    }

    assetSelected = (data: any) => {
        this._selectedAssetId = data && data._id ? data._id : null;
        if (this._auto) {
            if (this._isolate) {
                this.isolateAsset();
            } else {
                this.showAsset();
            }
        }
    }

    initialize() {
        super.initialize();
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }

    changeDisplay = (type: string) => {
        if (!this._isolate) {
            if (type === 'painted') {
                this.showAllAsset();
            } else {
                this.showNotAsset();
            }
        } else {
            if (type === 'painted') {
                this.isolateAllAsset();
            } else {
                this.isolateNotAsset();
            }
        }
    }

    showAsset = () => {
        this._options.AssetListPanel.setVisible(true);
        setTimeout(async () => {
            const paintedInfo = await this._options.getPaintedInfo(this._options.urn, this._selectedAssetId);
            showPaintedInfo(this.viewer, paintedInfo, this._options.colorIndexes);
        }, 200);
    }

    showAllAsset = () => {
        this._options.AssetListPanel.setVisible(true);
        setTimeout(async () => {
            const paintedInfo = await this._options.getPaintedInfo(this._options.urn, undefined);
            showPaintedInfo(this.viewer, paintedInfo, this._options.colorIndexes);
        }, 200);
    }

    showNotAsset = () => {
        setTimeout(async () => {
            const paintedInfo = await this._options.getPaintedInfo(this._options.urn);
            showNotPaintedInfo(this.viewer, paintedInfo);
        }, 200);
    }

    isolateAllAsset = async () => {
        this._options.AssetListPanel.setVisible(true);
        setTimeout(async () => {
            const paintedInfo = await this._options.getPaintedInfo(this._options.urn);

            isolatePainted(this.viewer, paintedInfo);
            showPaintedInfo(this.viewer, paintedInfo, this._options.colorIndexes);
        }, 200);
    }

    isolateAsset = async () => {
        const paintedInfo = await this._options.getPaintedInfo(this._options.urn, this._selectedAssetId);
        isolatePainted(this.viewer, paintedInfo);
    }

    isolateNotAsset = async () => {
        const paintedInfo = await this._options.getPaintedInfo(this._options.urn);
        isolateUnPainted(this.viewer, paintedInfo, this._options.colorIndexes);
    }

    getPaintedStats = async () => {
        const totalObj = GetNumberOfObject(this.viewer);
        const paintedObj = await this._options.getPaintedCount(this._options.urn);
        return [
            { type: 'painted', label: 'Painted objects', value: paintedObj },
            { type: 'notpainted', label: 'Not painted objects', value: totalObj - paintedObj },
        ];

    }

    setVisible = (show: boolean) => {
        super.setVisible(show);
        this._visible = show;
        if (show) {

            this.reactNode = <PainterViewerPanel
                onChangeDisplay={this.changeDisplay}
                onStateChange={(isAuto: boolean, isIsolate: boolean) => { this._auto = isAuto; this._isolate = isIsolate; }}
                onGetPaintedStats={this.getPaintedStats}
                viewer={this.viewer} />;
            ForgeViewerEmitter.on('onAssetSelected', this.assetSelected);
            ReactDOM.render(
                this.reactNode,
                this.DOMContent
            );
        } else if (this.reactNode) {
            ForgeViewerEmitter.removeListener('onAssetSelected', this.assetSelected);
            ReactDOM.unmountComponentAtNode(this.DOMContent);
            this.reactNode = null;
        }
    }

    toogleVisibility = () => {
        this.setVisible(!this._visible);

    }
}