import { DialogActions } from '@material-ui/core';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControlLabel,
    FormGroup, TextField
} from '@mui/material';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { applyProjectConfigurationToCookies } from 'src/core/business/project.business';


type StructureTreeSettingProps = {
    show: boolean;
    onClose: () => void;
    hideOptions?: Array<number>;
}
export default function StructureTreeSettings(props: StructureTreeSettingProps) {

    const projectContext = useContext(ProjectContext);

    const { t } = useTranslation(['pablo']);
    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_level',
        'aimspainter_asset_structure_display_fullCode',
        'aimspainter_asset_structure_display_rootCode',
        'aimspainter_asset_structure_display_codeSeparator',
    ]);

    return (
        <Dialog open={props.show} onClose={props.onClose}>
            <CloseDialogTitle onClose={props.onClose} title={t('pablo:pages.assetStr.settings')} />
            <DialogContent>
                <FormGroup>
                    <FormControlLabel
                        sx={{
                            contentVisibility: props.hideOptions?.includes(0) ? 'hidden' : 'unset'
                        }}
                        control={
                            <Checkbox
                                checked={cookies.aimspainter_asset_structure_display_level === 'true'}
                                onChange={(event: any, checked: boolean) => setCookies('aimspainter_asset_structure_display_level', checked, { path: '/' })}
                            />
                        }
                        label={t('pablo:pages.assetStr.structure.showNodeLevel').toString()}
                    />
                    <FormControlLabel
                        sx={{
                            contentVisibility: props.hideOptions?.includes(1) ? 'hidden' : 'unset'
                        }}
                        control={
                            <Checkbox
                                checked={cookies.aimspainter_asset_structure_display_fullCode === 'true'}
                                onChange={(event: any, checked: boolean) => setCookies('aimspainter_asset_structure_display_fullCode', checked, { path: '/' })}
                            />
                        }
                        label={t('pablo:pages.assetStr.structure.showNodeFullCode').toString()}
                    />
                    <FormControlLabel
                        sx={{
                            contentVisibility: props.hideOptions?.includes(2) ? 'hidden' : 'unset'
                        }}
                        control={
                            <Checkbox
                                checked={cookies.aimspainter_asset_structure_display_rootCode === 'true'}
                                onChange={(event: any, checked: boolean) => setCookies('aimspainter_asset_structure_display_rootCode', checked, { path: '/' })}
                            />
                        }
                        label={t('pablo:pages.assetStr.structure.showNodeRootCode').toString()}
                    />
                    <FormControlLabel
                        sx={{
                            contentVisibility: props.hideOptions?.includes(3) ? 'hidden' : 'unset',
                            '& .MuiFormControlLabel-label': { mr: 2 }, ml: 4
                        }}
                        control={
                            <TextField
                                value={cookies.aimspainter_asset_structure_display_codeSeparator}
                                onChange={(event: any) => setCookies('aimspainter_asset_structure_display_codeSeparator', event.target.value, { path: '/' })}
                            />
                        }
                        label={t('pablo:pages.assetStr.structure.separator').toString()}
                        labelPlacement="start"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    applyProjectConfigurationToCookies(cookies, setCookies, projectContext.currentProject.structureConfiguration);
                }}>
                    {t('pablo:label.resetDefault')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}