import { Grid, TableBody, TableRow } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { StyledTable, StyledTableCell, StyledTableContainer } from './single-asset-styles';
import { APIServiceManager, UserService } from 'src/services';
import ReactTimeAgo from 'react-time-ago';

enum AssetAction {
    Create = 'create',
    RequestReview = 'requestReview',
    Reject = 'reject',
    Validate = 'validate',
}

export function AssetHistory(props: {
    history: Array<any>,
}) {

    const _userService: UserService = APIServiceManager.getService('UserService') as UserService;
    const { t, i18n } = useTranslation(['pablo']);
    const [users, setUsers] = useState<any>([]);

    const getTitle = useCallback(
        () => t('pablo:components.asset.single.history'),
        [i18n.language],
    );

    useEffect(() => {
        const loadUsers = () => {
            _userService.getUsers()
                .then((result: any) => {
                    setUsers([...result]);
                });
        }
        loadUsers();
    }, []);

    const getTimeAgo = useCallback((historyItem: any) =>
        (<ReactTimeAgo date={new Date(historyItem.createdAt)} locale={i18n.language} />),
        [i18n.language],
    );

    const userLabel = useCallback((userId: string) => {
        const user = users.find((u: any) => u._id.toString() === userId.toString());
        if (!user) {
            return t('pablo:components.asset.single.unknownUser');
        } else if (user.firstname && user.lastname) {
            return `${user.firstname} ${user.lastname}`;
        } else
            return user.username || user.email;
    }, [users, i18n.language]);

    const actionFragment = useCallback((action: AssetAction) => {
        switch (action) {
            case AssetAction.RequestReview:
                return t('pablo:components.asset.single.requestedValidation');
            case AssetAction.Reject:
                return t('pablo:components.asset.single.rejectedAsset');
            case AssetAction.Validate:
                return t('pablo:components.asset.single.validatedAsset');
            case AssetAction.Create:
                return t('pablo:components.asset.single.createdAsset');
        }
    }, [i18n.language]);

    const getRows = useCallback(() =>
        props.history.map((historyItem: any) =>
        (<TableRow key={historyItem.createdAt}>
            <StyledTableCell>
                {userLabel(historyItem.by)} <strong>{actionFragment(historyItem.actionType)}</strong>   {getTimeAgo(historyItem)}
            </StyledTableCell>
        </TableRow>)
        ),
        [props.history, userLabel, getTimeAgo, actionFragment],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>{getTitle()}</h3>
            </Grid>
            <Grid item xs={12}>
                <StyledTableContainer>
                    <StyledTable >
                        <TableBody>
                            {getRows()}
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>
            </Grid>
        </Grid >
    )
}