import {
    Button,
    Dialog, DialogActions, DialogContent, Icon
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIServiceManager, PropSetService } from 'src/services';
import { CloseDialogTitle } from '..';
import StyledMaterialTable from '../ui/styled-material-table.component';

function PropertySetsDialog(props: any) {
    const { t } = useTranslation(['pablo']);

    const [propSets, setPropSets] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);

    useEffect(() => {
        const fetchPropSets = async () => {
            const propSetSvc = APIServiceManager.getService('PropSetService') as PropSetService;
            const sets = await propSetSvc.getSets(null, false);
            setPropSets(sets);
        };
        fetchPropSets();
    }, []);

    const onSubmit = (e: any) => {
        props.onSubmit(selectedRows);
        props.onClose();
    }

    const onCancel = (e: any) => {
        props.onClose();
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth='sm'>
            <CloseDialogTitle onClose={props.onClose} title={t('pablo:pages.propSet.libTitle')} />
            <DialogContent dividers style={{ height: '60vh' }}>
                <StyledMaterialTable
                    height={95}
                    options={{
                        selection: true,
                        showTitle: false,
                    }}
                    columns={[
                        // { title: "Id", field: "_id" },
                        {
                            field: 'name',
                            title: t('pablo:table.headers.name')
                        },
                        {
                            field: 'propertyIds.length',
                            title: t('pablo:table.headers.properties')
                        },
                        {
                            field: 'isProject',
                            title: t('pablo:table.headers.limitToProject'),
                            render: (data: any) => data.isProject ?
                                <Icon className='fa fa-check' /> :
                                <Icon className='fa fa-times' />

                        }
                    ]}
                    onSelectionChange={(rows: any) => setSelectedRows(rows)}
                    data={propSets} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>{t('pablo:button.cancel')}</Button>
                <Button autoFocus onClick={onSubmit}>{t('pablo:button.submit')}</Button>
            </DialogActions>

        </Dialog>
    )
}

export default PropertySetsDialog