import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import { APIServiceManager } from 'src/services';
import PropertyByLibrary from './porperty-by-library.component';


interface PropertyModalFormProps {
	show: boolean;
	onSubmit: (propertiesByPset: Array<any>) => void;
	onHide: () => void;
	existingPropertyIds: Array<string>;
}

export default function PropertyModalForm(props: PropertyModalFormProps) {


    const allLibs = [{ name: 'All', _id: '' }];
    const [libraries, setLibraries] = useState<Array<any>>([]);
    const [libLookup, setLibLookup] = useState<any>({});

    const selectedProps = useRef<Array<any>>([]);
    const [canSubmit, setCanSubmit] = useState(false);

    const { t } = useTranslation(['pablo']);


    useEffect(() => {
        const fetchPropLibs = async () => {
            const propLibSvc = APIServiceManager.PropLibraryService;
            const libs = await propLibSvc.getLibraries(true);
            const libObj = libs.reduce((acc: any, curr: any) => {
                acc[curr._id] = curr.name
                return acc;
            }, {});

            setLibLookup(libObj);
            setLibraries(allLibs.concat(libs));
        };
        if (props.show) {
            fetchPropLibs();
        }
    }, [props.show]);


    const handleSelectionChange = useCallback((rows: Array<any>) => {
        selectedProps.current = rows
        setCanSubmit(selectedProps.current.length > 0)
    }, []);

    return (
        <Dialog
            fullScreen
            open={props.show}
            onClose={props.onHide}
            maxWidth='lg'
            fullWidth
            // scroll='paper'
        >
            <CloseDialogTitle onClose={props.onHide} title={t('pablo:dialog.title.selectProperties')}/>
            <DialogContent dividers style={{ height: '80vh' }}>
                <PropertyByLibrary
                    libraries={libraries}
                    onSelectionChange={handleSelectionChange}
                    disabledIds={props.existingPropertyIds}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={!canSubmit} onClick={() => {
                    props.onSubmit(Array.from(selectedProps.current))
                    selectedProps.current = [];
                }}>
                    {t('pablo:button.submit')}
                </Button>
            </DialogActions>
        </Dialog >
    );
}
