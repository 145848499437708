import { List } from '@mui/icons-material';
import { Button, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSwitch } from 'src/components';
import { assetIsFrozen } from 'src/core/business/asset.business';

const useStyles = makeStyles({
    gridRoot: {
        margin: '10px 5px 0px 5px',
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        // backgroundColor: 'rgba(38,38,38,0.8)'
    },
    gridRow: {
        marginTop: 16,
    },
    assetInputLabel: {
        color: 'white',
        padding: '7px 5px'
    },
    relationTypeFormControl: {
        minWidth: 250,
        width: '100%'
    },
    button: {
        color: (props: any) => `${props.style}`,
        '&:focus': {
            outline: 'unset'
        },
    },
    underline: {
        '&&&:before': {
            borderBottom: (props: any) => `1px solid ${props.style}`, //"1px solid white"
        },
        '&&:after': {
            borderBottom: (props: any) => `2px solid ${props.style}`, //"2px solid white"
        }
    },
    inputRoot: {
        '& label': {
            color: (props: any) => `${props.style} !important`, //'white !important',
            transition: 'unset',
        },
    },
    whiteColor: {
        color: (props: any) => `${props.style} !important`,
        // backgroundColor: 'red',
    },

});

interface PainterEditorPanelProps {
    eventEmitter: any
    onListAsset: () => void;
    onListAssetStructure: () => void;
    onPaintSelection: (asset: any, relationType: string, createAsset: boolean, structure: any) => void;
    onPaintVisible: (asset: any, relationType: string, createAsset: boolean, structure: any) => void
    onAutoPaintChanged: (b: boolean) => void;
    onUnPaintSelection: () => void;
    style?: string
}

interface PainterEditorPanelState {
    autoPaint: boolean;
    autoAsset: boolean;
    relationType: string;
    asset: any;
    assetStructure: any;
}

const defaultState: PainterEditorPanelState = {
    autoPaint: false, autoAsset: false, relationType: 'isPartOf', asset: null, assetStructure: null
};

export default function PainterEditor(props: PainterEditorPanelProps) {
    const classes = useStyles({ style: props.style == 'white' ? 'black' : 'white' });
    const { t } = useTranslation(['pablo']);
    const [selectedAssetIsFrozen, setSelectedAssetIsFrozen] = useState<boolean>(false);
    const checkValidation = () => {
        if (state && state.asset) {
            if (assetIsFrozen(state.asset)) {
                setSelectedAssetIsFrozen(true);
                setValidated(false);
            } else {
                setSelectedAssetIsFrozen(false);
                if (state.autoAsset) {
                    if (state.assetStructure) {
                        setValidated(true);
                    } else {
                        setValidated(false);
                    }
                } else {
                    setValidated(true);
                }
            }
        } else {
            setValidated(false);
        }
    };


    const dataReducer = (state: PainterEditorPanelState, action: { type: string, value: any }) => {
        setTimeout(() => {
            checkValidation();
        }, 200);
        switch (action.type) {
            case 'setAutoPaint':

                return {
                    ...state,
                    autoPaint: action.value,
                };
            case 'setAutoAsset':
                return {
                    ...state,
                    autoAsset: action.value,
                };
            case 'setRelationType':
                return {
                    ...state,
                    relationType: action.value,
                };
            case 'setAsset':
                return {
                    ...state,
                    asset: action.value,
                };

            case 'setAssetStructure':
                return {
                    ...state,
                    assetStructure: action.value,
                };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(dataReducer, defaultState);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        props.onAutoPaintChanged(state.autoPaint);
    }, [state.autoPaint]);

    useEffect(() => {
        const assetSelected = (asset: any) => {
            dispatch({ type: 'setAsset', value: asset });
        };
        const assetStructureSelected = (structure: any) => {
            dispatch({ type: 'setAssetStructure', value: structure });
        };

        if (props.eventEmitter) {
            props.eventEmitter.on('onAssetSelected', assetSelected);
            props.eventEmitter.on('onAssetStructureSelected', assetStructureSelected);
            return () => {
                props.eventEmitter.removeListener('onAssetSelected', assetSelected);
                props.eventEmitter.removeListener('onAssetStructureSelected', assetStructureSelected);
            };
        }

    }, []);

    const helperText = () => {
        if (selectedAssetIsFrozen && state.asset && state.asset.status) {
            return t('pablo:components.forge.assetFrozenBecauseOfStatus', { status: t(`pablo:label.status.${state.asset.status}`) });
        }
        return '';
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        if (event.target.checkValidity()) { // use can also use e.target.reportValidity

            if (event.nativeEvent.submitter.name === 'paintSel') {
                props.onPaintSelection(state.asset, state.relationType, state.autoAsset, state.assetStructure);
            } else if (event.nativeEvent.submitter.name === 'paintVis') {

            }
        }
    };

    return (
        // <div>
        //     <h3 className={classes.button}>Test</h3>
        // </div>
        <form className={classes.gridRoot}>
            <Grid spacing={1}>
                <Grid container className={classes.gridRow}>
                    <Grid item xs={8}>
                        <TextField
                            error={selectedAssetIsFrozen}
                            helperText={helperText()}
                            required
                            classes={{
                                root: classes.inputRoot
                            }} style={{ width: '100%' }} id="standard-basic" label={t('pablo:components.forge.selectAsset')}
                            InputLabelProps={{
                                focused: true
                            }}
                            InputProps={{
                                readOnly: true,
                                classes: { input: classes.inputRoot, underline: classes.underline, formControl: classes.whiteColor }
                            }}
                            value={
                                state.asset && state.asset.data ?
                                    `${state.asset.data.structure.code} - ${state.asset.data.name}`
                                    : ' '
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton className={classes.button} onClick={props.onListAsset}>
                            <List />
                        </IconButton>
                    </Grid>
                    {/* <Grid item xs={1}>
                    <IconButton className={classes.button} onClick={props.onAddAsset}>
                        <Add />
                    </IconButton>
                </Grid> */}
                </Grid>
                <Grid container className={classes.gridRow}>
                    <Grid item xs={8}>
                        <FormControl className={classes.relationTypeFormControl}>
                            <InputLabel classes={{
                                root: classes.inputRoot,
                                formControl: classes.whiteColor,
                                focused: classes.whiteColor,
                                animated: classes.whiteColor
                            }}>Relation Type:</InputLabel>
                            <Select
                                classes={{
                                    //root: classes.inputRoot,
                                    select: classes.whiteColor,
                                    iconOpen: classes.whiteColor,
                                    icon: classes.whiteColor,
                                }}
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                value={state.relationType}

                                input={<Input classes={{
                                    formControl: classes.whiteColor,
                                    underline: classes.underline,
                                }} />}
                                onChange={(e: any) => dispatch({ type: 'setRelationType', value: e.target.value as string })}
                            >
                                <MenuItem value={'isPartOf'}>{t('pablo:components.forge.isPartOf')}</MenuItem>
                                <MenuItem value={'isRelatedTo'}>{t('pablo:components.forge.isRelatedTo')}</MenuItem>
                                <MenuItem value={'iTypeOf'}>{t('pablo:components.forge.isTypeOf')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
                <Grid container className={classes.gridRow}>
                    <Grid item>
                        <Button className={classes.button} variant="outlined" disabled={!validated} onClick={() => {
                            props.onPaintSelection(state.asset, state.relationType, state.autoAsset, state.assetStructure);
                        }}>{t('pablo:button.paintSelection')}</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.button} variant="outlined" disabled={!validated} onClick={() => {
                            props.onPaintVisible(state.asset, state.relationType, state.autoAsset, state.assetStructure);
                        }}>{t('pablo:button.paintVisible')}</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.button} variant="outlined" onClick={() => {
                            props.onUnPaintSelection();
                        }}>{t('pablo:button.unPaintSelection')}</Button>
                    </Grid>
                </Grid>
                <Grid container className={classes.gridRow}>
                    <Grid item xs={12}>
                        <StyledSwitch checked={state.autoPaint}
                            onChange={(e: any) => dispatch({ type: 'setAutoPaint', value: e.target.checked })}
                            label={t('pablo:components.forge.paintSelection')} />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledSwitch checked={state.autoAsset}
                            onChange={(e: any) => dispatch({ type: 'setAutoAsset', value: e.target.checked })}
                            label={t('pablo:components.forge.paintNewAsset')} />
                    </Grid>
                </Grid>
                <Grid style={{ display: state.autoAsset ? '' : 'none' }} container className={classes.gridRow}>
                    <Grid item xs={8}>
                        <TextField classes={{
                            root: classes.inputRoot
                        }} style={{ width: '100%' }} id="standard-basic" label={t('pablo:components.forge.selectAsset')}
                            InputLabelProps={{
                                focused: true
                            }}
                            InputProps={{
                                readOnly: true,
                                classes: { input: classes.inputRoot, underline: classes.underline, formControl: classes.whiteColor }
                            }}
                            value={
                                state.assetStructure && state.assetStructure.code ?
                                    state.assetStructure.code : ' '}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton className={classes.button} onClick={props.onListAssetStructure}>
                            <List />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </form >
    );
}