import { tableCellClasses, tableClasses, tableContainerClasses, Table, TableCell, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTable = styled(Table)(({ theme }) => ({
    [`&.${tableClasses.root}`]: {
        backgroundColor: theme.palette.common.white,
        heigth: '100%',
        width: '100%',
    },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    [`&.${tableContainerClasses.root}`]: {
        maxHeight: 420,
    },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
