import { AssetObjectsLink, ObjectLinkWithAsset, TypeLink } from '../../../core';
import { API, APIServiceManager } from '../..';

class LinkService {
	api: API = APIServiceManager.API;
	name() {
	    return 'LinkService';
	}

	async getLinks(query: any): Promise<any> {
	    try {
	        var searchParams = new URLSearchParams([
	            ['page', query.page.toString()],
	            ['limit', query.pageSize],
	            ['search', query.search],
	            ...query.filters.map((f: any) => [`regexfilter.${f.column.field}`, f.value])
	        ]);

	        const req = await this.api.axios.get(`/project/links?${searchParams}`);
	        return req.data
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getLinksForQuery(query: any): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/project/links', {
	            params: { ...query }
	        });
	        return req.data
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getBulkLinks(): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/project/links');
	        return req.data
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getLinkCountForQuery(query: any): Promise<any> {
	    try {
	        console.log(query);

	        const req = await this.api.axios.get('/project/links/count', {
	            params: { ...query }
	        });
	        return req.data.data
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getLinkCount(urn?: string): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/project/links/count', {
	            params: { urn }
	        });
	        return req.data.data
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getLink(urn: string, objectId: string): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/project/links', {
	            params: { urn, objectId }
	        });
	        return req.data.data
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getAllLinks(urn?: string, assetId?: string): Promise<AssetObjectsLink[]> {
	    try {
	        let nextPage = true;
	        let i = 0;
	        let data: any[] = [];
	        while (nextPage) {
	            const req = await this.api.axios.get('/project/links', {
	                params: {
	                    page: i,
	                    limit: 10,
	                    urn: urn,
	                    assetId: assetId
	                }
	            });
	            data = data.concat(req.data.data);
	            nextPage = req.data.nextPage;
	            i++;
	        }
	        return data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getAllLinksWithAsset(): Promise<ObjectLinkWithAsset[]> {
	    try {
	        let nextPage = true;
	        let i = 0;
	        let data: any[] = [];
	        while (nextPage) {
	            const req = await this.api.axios.get(`/project/links?limit=10&page=${i}`);
	            data = data.concat(req.data.data);
	            nextPage = req.data.nextPage;
	            i++;
	        }

	        return data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}


	async createLink(links: any[]): Promise<boolean> {
	    try {
	        const req = await this.api.axios.post('/project/links/bulk', links);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	// async updateLinkProperty(id: string, propertyName: string, propertyValue: string) {
	// 	try {
	// 		const req = await this.api.axios.put(`/project/links/${id}/property`, {
	// 			name: propertyName,
	// 			value: propertyValue
	// 		});
	// 		return req.data;
	// 	} catch (error) {
	// 		console.log(error);
	// 		throw error;
	// 	}


	// }

	async removeLink(links: any[]): Promise<boolean> {
	    try {
	        const req = await this.api.axios.delete('/project/links/bulk', {
	            data: links
	        });
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getTypeLinks(page?: number, limit?: number, search?: string): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/project/typelink', {
	            params: {
	                page, limit, search
	            }
	        });
	        return req.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async createTypeLink(newData: TypeLink): Promise<any> {
	    try {
	        const req = await this.api.axios.post('/project/typelink', newData);
	        return req.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async updateTypeLink(id: string, newData: TypeLink): Promise<any> {
	    try {
	        const req = await this.api.axios.put(`/project/typelink/${id}`, newData);
	        return req.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}


	async removeTypeLink(id: string): Promise<any> {
	    try {
	        const req = await this.api.axios.delete(`/project/typelink/${id}`);
	        return req.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async saveTypeLinks(types: TypeLink[]): Promise<boolean> {
	    try {
	        const req = await this.api.axios.post('/project/typelink/save', types);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}
}

export { LinkService };
