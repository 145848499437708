import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
    formControl: {
        margin: theme.spacing(0),
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    selectSnapshotToView: {
        color: 'rgb(0,0,0,0.54)'
    }
}));

export default function SnapshotSelect(props: {
    data: Array<any>,
    onChange: (id: string) => void,
}) {
    const { t } = useTranslation(['pablo']);
    const classes = useStyles();

    const handleChange = (event: SelectChangeEvent<any>) => {
        props.onChange(event.target.value.id);
    };
    const menuList = () => {
        return (
            props.data.map((d: any) => <MenuItem value={d} key={d.id}>{d.label}</MenuItem>)
        );
    };
    return (
        <FormControl variant='standard' className={classes.formControl}>
            <Select
                value={''}
                onChange={handleChange}
                displayEmpty={true}
                className={classes.selectSnapshotToView}
            >
                <MenuItem value={''}><em>{t('pablo:pages.assetStr.structure.viewSnapshot')}</em></MenuItem>
                {menuList()}
            </Select>
        </FormControl>
    );
}