import { Alert } from '@mui/lab';
import {
    Backdrop, Button, Checkbox,
    CircularProgress, Dialog,
    DialogActions, DialogContent,
    FormControlLabel, FormGroup,
    Snackbar, TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { CloseDialogTitle, DropFileDialog, EditPropertyDialog, PropertiesLibraries } from 'src/components';
import { APIServiceManager, ExportService, FrontServiceManager, PropertyService, PropSetService } from 'src/services';
import { saveFileLocally } from 'src/utils/file';

const useStyles = makeStyles((theme: any) => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
    },
    header: {
        //marginTop: -45,
        //marginLeft: 10
    },
    form: {
        minWidth: 300,
        marginLeft: 10,
    },
    fab: {
        position: 'absolute',
        top: 70,
        left: 60,
        zIndex: 99
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function PropertyPage() {
    const { t } = useTranslation(['pablo']);

    const _propSvc = APIServiceManager.getService('PropertyService') as PropertyService;
    const _propSetSvc = APIServiceManager.getService('PropSetService') as PropSetService;
    const _exportSvc = APIServiceManager.getService('ExportService') as ExportService;

    const [refresh, setRefresh] = useState<boolean>(true);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    //const [selectedProperty, setSelectedProperty] = useState<any>({});
    const [selectedProperties, setSelectedProperties] = useState<Array<any>>([]);
    const [showFileImport, setShowFileImport] = useState(false);
    const [importedFile, setImportedFile] = useState<any>(null);

    const classes = useStyles();
    const params = useParams<{ id: string }>();
    const location = useLocation();
    const state = location.state as any;

    const [importMessageOpen, setImportMessageOpen] = useState(false);
    const [importMessage, setImportMessage] = useState<any>({});
    const selectedRowIds = useRef<Array<GridRowId>>();

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [showExportConfig, setShowExportConfig] = useState(false);

    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_rootCode',
        'aimspainter_asset_structure_display_codeSeparator',
    ]);

    const [separator, setSeparator] = useState<string>();
    const [repeatRoot, setRepeatRoot] = useState<boolean>(false);


    useEffect(() => {
        setSeparator(cookies.aimspainter_asset_structure_display_codeSeparator);
    }, [cookies.aimspainter_asset_structure_display_codeSeparator]);

    useEffect(() => {
        setRepeatRoot(cookies.aimspainter_asset_structure_display_rootCode === 'true');
    }, [cookies.aimspainter_asset_structure_display_rootCode]);

    useEffect(() => {
        if (refresh) {
            setTimeout(() => {
                setRefresh(false);
                //setRefreshContent([]);
            }, 500);
        }
    }, [refresh]);

    const updateProp = async (newData: any, oldProperties: Array<any>) => {
        if (oldProperties.length > 0) {
            for (const oldData of oldProperties) {

                const proms = [
                    _propSvc.updateProperty(oldData._id, {
                        key: newData.key,
                        name: newData.name,
                        category: newData.category,
                        categoryId: newData.categoryId,
                        description: newData.description,
                        isTechProperty: newData.isTechProperty,
                        unit: newData.unit,
                        unitId: newData.unitId,
                        possibleValues: newData.possibleValues,
                        type: newData.type,
                        format: newData.format,
                    }, state ? state.projectId : undefined)
                ];

                if (oldData && Array.isArray(oldData.propertySets)) {
                    for (const oldSet of oldData.propertySets) {
                        if (newData && Array.isArray(newData.propertySets) &&
                            newData.propertySets.findIndex((n: any) => n._id === oldSet._id) < 0) {
                            proms.push(_propSetSvc.removePropFromSet(oldSet._id, oldData._id));
                        }
                    }
                }

                if (newData && Array.isArray(newData.propertySets)) {
                    for (const newSet of newData.propertySets) {
                        if (oldData && Array.isArray(oldData.propertySets) &&
                            oldData.propertySets.findIndex((n: any) => n._id === newSet._id) < 0) {
                            proms.push(_propSetSvc.addPropInSet(newSet._id, oldData._id));
                        }
                    }
                }

                await Promise.all(proms);
            }
        } else {
            const creationResult = await _propSvc.addProperty({
                key: newData.key,
                name: newData.name,
                category: newData.category,
                categoryId: newData.categoryId,
                description: newData.description,
                libraryId: params.id,
                unit: newData.unit,
                unitId: newData.unitId,
                possibleValues: newData.possibleValues,
                type: newData.type,
                format: newData.format,
            }, state.projectId);

            if (newData && Array.isArray(newData.propertySets)) {
                for (const newSet of newData.propertySets) {
                    await _propSetSvc.addPropInSet(newSet._id, creationResult.data.upserted[0]._id);
                }
            }

            setImportMessage({
                content: creationResult.status === 200 ? t('pablo:pages.propertylib.creationResultSuccess', { key: newData.key }) : t('pablo:pages.propertylib.creationResultError'),
                severity: creationResult.status === 200 ? 'success' : 'error'
            });
            setImportMessageOpen(true);

        }
        setRefresh(true);
        selectedRowIds.current = [];
    };

    const deleteMultipleProps = async (rows: Array<string>) => {
        const proms = [];

        const deletionResult = await _propSvc.removeProperties(rows, state.projectId);
        setImportMessage({
            content: t('pablo:pages.propertylib.deletionResultMessage', { count: deletionResult.deletedCount }),
            severity: 'success'
        });
        setImportMessageOpen(true);

        setRefresh(true);
    };


    const editMultipleProps = async (rowIds: Array<GridRowId>) => {
        selectedRowIds.current = rowIds;

        const propertiesForIds: any = await APIServiceManager.PropertyService.getProperties({ ids: rowIds, includepset: true }, 0, -1);
        setSelectedProperties(propertiesForIds.data);
        setOpenDialog(true);
    };

    const handleRowClick = async (rowParam: any, event: any) => {
        setSelectedProperties([rowParam.row]);
        setOpenDialog(true);
    };

    const handleOnImport = async () => {
        if (params.id) {
            const result = await FrontServiceManager.PropertyQueryService.importPropertyFile(importedFile, params.id);
            setShowFileImport(false);
            setRefresh(true);

            setImportMessage({
                content: t('pablo:pages.propertylib.importResultMessage', { count: result.filter(r => r.status === 200).length }),
                severity: 'success'
            });
            setImportMessageOpen(true);
        }

    };

    const handleExport = async () => {
        if (params.id) {
            setOpenBackdrop(true);
            const blob = await _exportSvc.exportPropertyLibraryWithStructure(params.id, { separator, repeatRoot });
            setOpenBackdrop(false);
            if (blob) {
                saveFileLocally(blob, 'output.xlsx');
            }
        }
        setShowExportConfig(false);
    };

    const handleCloseSnackbar = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setImportMessageOpen(false);
    };

    return (
        <div className={classes.root}>
            <PropertiesLibraries
                libraryId={params.id}
                refresh={refresh}
                title={t('pablo:pages.propertylib.title',
                    {
                        name: location.state && state.library ? state.library : ''
                    }
                )}
                onAdd={() => {
                    setSelectedProperties([]);
                    setOpenDialog(true);
                }}
                onRowClick={handleRowClick}
                deleteSelection={deleteMultipleProps}
                editSelection={editMultipleProps}
                onImport={() => setShowFileImport(true)}
                onExport={() => setShowExportConfig(true)}
            />
            <Backdrop className={classes.backdrop} open={openBackdrop} onClick={() => setOpenBackdrop(false)}>
                <CircularProgress />
            </Backdrop>
            <EditPropertyDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                i18nTitle={selectedProperties.length ? 'property' : 'newProperty'}
                selectedProperties={selectedProperties}
                onSubmit={updateProp}
            />
            <DropFileDialog
                acceptExtension={['.xlsx', '.xls']}
                onClose={() => setShowFileImport(false)} fullWidth show={showFileImport}
                maxWidth="md"
                onFileSelected={(file: any) => { setImportedFile(file); }}
                templateFileName='importPropertiesTemplate.xlsx'
                getTemplateBlob={(lng: string) => { return APIServiceManager.TemplateService.getPropertyImportTemplate(lng); }}
                disabled={importedFile === null}
                onImport={handleOnImport}
                title={t('pablo:dialog.title.importData')}
            />
            <Snackbar
                style={{ minWidth: 300 }}
                open={importMessageOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert onClose={() => setImportMessageOpen(false)} severity={importMessage.severity} style={{ minWidth: 300 }}>
                    {importMessage.content}
                </Alert>
            </Snackbar>
            <Dialog open={showExportConfig} onClose={() => setShowExportConfig(false)}>
                <CloseDialogTitle onClose={() => setShowExportConfig(false)} title={t('pablo:pages.assetStr.settings')} />
                <DialogContent>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={repeatRoot}
                                    onChange={(event: any, checked: boolean) => setRepeatRoot(checked)}
                                />
                            }
                            label={t('pablo:pages.assetStr.structure.showNodeRootCode').toString()}
                        />
                        <FormControlLabel
                            control={
                                <TextField
                                    value={separator}
                                    onChange={(event: any) => setSeparator(event.target.value)}
                                />
                            }
                            label={t('pablo:pages.assetStr.structure.separator').toString()}
                            labelPlacement="start"
                            sx={{ '& .MuiFormControlLabel-label': { mr: 2 }, ml: 4 }}
                        />
                    </FormGroup>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setShowExportConfig(false)} color="inherit">
                        {t('pablo:button.cancel')}
                    </Button>
                    <Button autoFocus onClick={handleExport} color="inherit">
                        {t('pablo:button.export')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default PropertyPage;
