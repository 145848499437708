import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { APIServiceManager, ReportService } from 'src/services';
import { TextField, } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { StyledMaterialTable } from 'src/components';

function ReportsView() {
    const { t } = useTranslation(['pablo']);
    const _reportService: ReportService = APIServiceManager.getService('ReportService') as ReportService;
    const params = useParams();

    const [reports, setReports] = useState([])

    const loadReports = useCallback(async () => {
        const pId = (params as any).id;
        const reports = await _reportService.getReports(pId, false);
        const reportsDateFormat = reports.map((r: any) => {
            const date = new Date(r.createdAt);
            r.createdAt = date.toLocaleDateString() + ' - ' + date.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                hour12: false,
                minute: '2-digit'
            });
            return r;
        })

        setReports(reportsDateFormat);
    }, [params, _reportService]);

    useEffect(() => {
        loadReports();
    }, [params, loadReports]);

    return (
        <StyledMaterialTable
            height={95}
            options={{
                pageSizeOptions: [10, 20, 50, 100],
                showTitle: false
            }}
            columns={[
                { title: 'Type', field: 'type' },
                { title: 'date', field: 'createdAt' },
            ]}
            data={reports}

            detailPanel={[
                {
                    tooltip: t('pablo:tooltip.showManifest'),
                    render: (rowData: any) => {
                        return (
                            <TextField
                                style={{ width: '100%' }}
                                required
                                fullWidth
                                multiline
                                id="outlined-required"
                                variant="outlined"
                                value={rowData.message}
                            />
                        )


                    },
                }
            ]}
        />
    );
}


export default ReportsView;
