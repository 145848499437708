import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from 'src/contexts/project.context';
import { IProject } from 'src/core';
import { ProjectSelectorCard } from '../card/project-card.component';

const useStyles = makeStyles((theme: any) => ({
    root: {
        margin: '30px !important',
        width: '100%'
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));


function ProjectDeck() {
    const { t } = useTranslation(['pablo']);

    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(-1);

    const projectContext = useContext(ProjectContext);

    const projectCards = projectContext.projects.map((p: IProject, index: number) => {
        const image = p.image && p.image.mimetype && p.image.data ?
            `data:${p.image.mimetype};base64,${p.image.data}` :
            `../projectType/${p.type}.jpg`;
        return (
            <Grid item key={`${p._id}`} >
                <ProjectSelectorCard
                    project={p}
                    onActivate={() => setActiveIndex(index)}
                    isActive={activeIndex === index}
                />
            </Grid >


        );
    });

    return (
        <Grid container spacing={3} className={classes.root}>
            {
                projectContext.projects.length > 0 ?
                    projectCards :
                    <p>{t('pablo:components.project.projectAccess')}</p>
            }
        </Grid >
    );
}

export default ProjectDeck;