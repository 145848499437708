import React, { useEffect, useContext, useRef } from 'react';
import { Query } from 'material-table';
import { ProjectContext } from 'src/contexts/project.context';
import { useTranslation } from 'react-i18next';

import '../material-table.css';

import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { StyledMaterialTable } from 'src/components';

interface ILinkDataListProps {
    getData: (query: Query<any>) => Promise<any>;
}

function LinkDataList(props: ILinkDataListProps) {
    const tableRef = useRef<any>(null);
    const projectContext = useContext(ProjectContext);
    const { t } = useTranslation(['pablo']);

    const [cookies, setCookies] = useCookies(['aimspainter_gallery_table_page_size']);

    useEffect(() => {
        tableRef.current!.onQueryChange();
    }, [projectContext]);

    return (
        <StyledMaterialTable
            height={95}
            tableRef={tableRef}
            title={t('pablo:table.headers.objectsList')}
            localization={{
                body: {
                    emptyDataSourceMessage: t('pablo:components.asset.emptyDataSourceMessage'),
                }
            }}
            options={{
                pageSize: cookies.aimspainter_gallery_table_page_size ? parseInt(cookies.aimspainter_gallery_table_page_size) : 20,
                pageSizeOptions: [10, 20, 50, 100],
                grouping: false,
                exportButton: true,
                toolbar: true,
                paging: true,
                emptyRowsWhenPaging: false,
                loadingType: 'overlay',
                filtering: true
            }}
            onChangeRowsPerPage={(pageSize: number) => {
                setCookies('aimspainter_gallery_table_page_size', pageSize, { path: '/' })
            }}
            columns={
                [
                    {
                        field: 'asset.name',
                        title: 'Asset Id'
                    },
                    {
                        field: 'asset.structure.code',
                        title: 'Asset Code'
                    },
                    {
                        field: 'objectId',
                        title: 'Object Id'
                    },
                    {
                        field: 'extId',
                        title: 'Software Id',
                        cellStyle: { fontSize: 'x-small' }
                    },
                    {
                        field: 'ifcId',
                        title: 'IFC Id',
                        cellStyle: { fontSize: 'x-small' }
                    },
                    {
                        field: 'type',
                        title: 'Part type'
                    },
                    {
                        field: '_id',
                        title: 'path',
                        grouping: false,
                        filtering: false,
                        render: (data: any, type: ('row' | 'group')) => {
                            if (data && data.model && data.model.source === 'itwin') {
                                return (
                                    <Link to={{
                                        pathname: `/itwin/viewer`,
                                    }} state={{
                                        modelId: data.model.identifier,
                                    }}>
                                        {t('pablo:pages.database.linkToItwin')}
                                    </Link>
                                )
                            } else if (data && data.urn) {
                                const decodedUrn = atob(data.urn);
                                const uriParts = decodeURI(decodedUrn).split('/');
                                if (uriParts.length > 1) {
                                    return (
                                        <Link to={{
                                            pathname: `/forge/viewer`,
                                        }} state={{
                                            urn: data.urn,
                                            objectId: data.objectId
                                        }}>
                                            {uriParts[1]}
                                        </Link>
                                    )
                                }

                            }
                            return <span>N/A</span>
                        }
                    }
                ]
            }
            data={props.getData}
        />
    )
}

export default LinkDataList