export function applyProjectConfigurationToCookies(cookies: any, setCookies: any, projectStructureConfiguration: any, force: boolean = true) {
    if (force) {
        if (projectStructureConfiguration) {
            setCookies('aimspainter_asset_structure_display_codeSeparator', projectStructureConfiguration.codeSeparator.toString());
            setCookies('aimspainter_asset_structure_display_fullCode', projectStructureConfiguration.displayFullCode.toString());
            setCookies('aimspainter_asset_structure_display_level', projectStructureConfiguration.displayLevel.toString());
            setCookies('aimspainter_asset_structure_display_rootCode', projectStructureConfiguration.displayRootCode.toString());
        }
    }
    else {
        if (!cookies.aimspainter_asset_structure_display_codeSeparator && projectStructureConfiguration.codeSeparator) {
            setCookies('aimspainter_asset_structure_display_codeSeparator', projectStructureConfiguration.codeSeparator.toString());
        }
        if (!cookies.aimspainter_asset_structure_display_fullCode && projectStructureConfiguration.displayFullCode) {
            setCookies('aimspainter_asset_structure_display_fullCode', projectStructureConfiguration.displayFullCode.toString());
        }
        if (!cookies.aimspainter_asset_structure_display_level && projectStructureConfiguration.displayLevel) {
            setCookies('aimspainter_asset_structure_display_level', projectStructureConfiguration.displayLevel.toString());
        }
        if (!cookies.aimspainter_asset_structure_display_rootCode && projectStructureConfiguration.displayRootCode) {
            setCookies('aimspainter_asset_structure_display_rootCode', projectStructureConfiguration.displayRootCode.toString());
        }
    }

}