import React from 'react';
import { Asset, useMapPageContext } from 'src/contexts/MapPageContext';
import { InputType } from './EditLocDialog';
import { LngLatInput } from './LngLatInput';
import { undyingTabLocation } from './utils/undyingTabLocation';

type LineSegmentProps = ReturnType<typeof undyingTabLocation> & {
    asset: Asset | null,
    inputType: InputType,
};
export function LineSegment({ asset, inputType, data, setData }: LineSegmentProps) {
    const { selectedAlignmentFeature } = useMapPageContext();

    return (
        <>
            <LngLatInput
                half
                label='Start'
                loc={data[0]}
                setLoc={(loc) => setData([loc, data[1]])}
                disabled={!asset || (inputType.modifier == 'pk' && !selectedAlignmentFeature)}
                inputType={inputType}
            />
            <LngLatInput
                half
                label='End'
                loc={data[1]}
                setLoc={(loc) => setData([data[0], loc])}
                disabled={!asset || (inputType.modifier == 'pk' && !selectedAlignmentFeature)}
                inputType={inputType}
            />
        </>
    );
}
