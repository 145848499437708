import { Box, Icon, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ToolButtonProps = {
    type: keyof typeof icons,
    onClick?: () => void,
    disabled?: boolean,
};
export function ToolButton({ type, onClick, disabled }: ToolButtonProps) {
    const { t } = useTranslation(['pablo']);

    return (
        <Tooltip title={t(`pablo:tooltip.mapToolButton.${type}`)} >
            <Box sx={styles.container} >
                <IconButton
                    size='small'
                    onClick={onClick}
                    disabled={disabled}
                    sx={styles.button}
                >
                    <Icon
                        fontSize='small'
                        className={`fas fa-${icons[type]}`}
                        style={{
                            width: 'inherit',
                            height: 'inherit',
                            color: '#484848',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                </IconButton>
            </Box>
        </Tooltip>
    );
}

const icons = {
    'focus': 'location',
    'visible': 'eye',
    'invisible': 'eye-slash',
    'halfvisible': 'low-vision',
    'edit': 'map-marker-edit',
    'add': 'map-marker-plus',
    'draw': 'pencil-ruler',
    'measure': 'ruler',
    'upload': 'upload',
};

const styles = {
    container: {
        width: 42,
        height: 42,
        mt: 2,
        mb: 2,
        ml: 2,
    },
    button: {
        width: 'inherit',
        height: 'inherit',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.25)',
        ':hover': {
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.25)',
            background: 'white',
        },
        ':disabled': {
            boxShadow: '0px 0px 10px -4px rgba(0, 0, 0, 0.25)',
            background: 'rgba(0, 0, 0, 0.07)'
        }
    },
};
