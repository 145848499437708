import { API, APIServiceManager } from "..";

class TemplateService {
    api: API = APIServiceManager.API;
    name() {
        return 'TemplateService';
    }

    async getStructureImportTemplate(lng: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/templates/structure/import?lng=${lng}`, {
                responseType: 'blob'
            });

            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getPropertyValuesImportTemplate(lng: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/templates/property/import/values?lng=${lng}`, {
                responseType: 'blob'
            });

            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getPropertyImportTemplate(lng: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/templates/property/import?lng=${lng}`, {
                responseType: 'blob'
            });

            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

}

export { TemplateService };