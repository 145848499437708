import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ErrorPage(props: any) {
	const { t } = useTranslation(['pablo']);
	return (
		<div className="page-not-found">
			<img className="forge-hero" alt="" />
			<div className="container">
				<label>
					{t('pablo:label.error')}
					<br />
					<br />
					{t('pablo:label.suggestion')}
					<br />
					<br />
					<Link to="/">{t('pablo:label.home')}</Link>
					&nbsp;
				</label>
			</div>
		</div>
	);
}

export default ErrorPage;

