import axios from 'axios';
import jwt_decode from 'jwt-decode';

interface TokenInfo {
    token: string,
    payload: any
}

const TOKEN_NAME = 'id_token';
const getToken = () => localStorage.getItem(TOKEN_NAME);
const storeToken = (idToken: string) => { localStorage.setItem(TOKEN_NAME, idToken); };
const deleteToken = () => { localStorage.removeItem(TOKEN_NAME); };
const tokenInQuery = (query: URLSearchParams): boolean => !!query.get(TOKEN_NAME);
const storeTokenFromQuery = (query: URLSearchParams) => { storeToken(query.get(TOKEN_NAME) as string); };

const reloadPageWithoutQuery = () => {
    const next: string = `${window.location.origin}${window.location.pathname}`;
    console.log(`Removing query string and reloading ${next}`);
    window.location.href = next;
};

const verifyClaims = (payload: any) => {
    const expectedClaims = ['exp', 'email', 'name'];
    for (var claim of expectedClaims) {
        if (!payload[claim]) {
            throw Error(`Missing required claim from token ${claim}`);
        }
    }
};

const verifyExp = (exp: number, marginInSeconds = 10) => {
    const now = new Date();
    const nowMillis = now.getTime();
    const expDate = new Date(exp * 1000);
    const nowPlusExpMargin = new Date(marginInSeconds * 1000 + nowMillis);

    if (nowPlusExpMargin > expDate) {
        throw Error(`Token expired exp: ${expDate.toLocaleString()}, now: ${nowPlusExpMargin.toLocaleString()} margin: ${marginInSeconds} seconds`);
    }
};

const verifyJwt = (token: string, checkExp = true): TokenInfo => {
    let payload: any;
    try {
        payload = jwt_decode(token as string);
    } catch (err) {
        throw new Error(`Unable to decode token. Error: ${err}\nToken contents: ${token}`);
    }
    verifyClaims(payload);
    if (checkExp) {
        verifyExp(payload['exp']);
    }

    return { token, payload };
};

class AuthService {
    name() {
        return 'AuthService';
    }

    authorizeUri = (): string => {
        const url: string = `${process.env.REACT_APP_AUTHORIZE_URL}`;
        const query: string = `next=${window.location.href}`;
        return encodeURI(`${url}?${query}`);
    }

    logoutUri = (): string => {
        const url: string = `${process.env.REACT_APP_LOGOUT_URL}`;
        const query: string = `next=${window.location.href}&id_token=${this.verifyIdToken().token}`;
        return encodeURI(`${url}?${query}`);
    }

    update(query: URLSearchParams) {
        if (tokenInQuery(query)) {
            storeTokenFromQuery(query);
            reloadPageWithoutQuery();
        }
    }

    verifyIdToken(checkExp = true): TokenInfo {
        const token: string | null = getToken();
        if (!token) {
            throw new Error('Not authenticated');
        }
        return verifyJwt(token, checkExp);
    }

    isAuthenticated = () => {
        try {
            this.verifyIdToken();
            return true;
        } catch (err) {
            return false;
        }
    }

    isTokenExpired = (tokenInfo: TokenInfo) => {
        try {
            verifyExp(tokenInfo.payload['exp']);
            return false;
        } catch (err) {
            return true;
        }
    }

    tokenExpiresInLessThan = (seconds: number): boolean => {
        const tokenInfo = this.verifyIdToken(false);
        try {
            verifyExp(tokenInfo.payload['exp'], seconds);
            return false;
        } catch (err) { return true; }
    }

    async refreshIdToken() {
        try {
            const response: any = await axios.get(`${process.env.REACT_APP_REFRESH_URL}`);
            const { id_token } = response.data;
            storeToken(id_token);
            console.log(`Refreshed ID token. exp: ${new Date(this.verifyIdToken().payload.exp * 1000).toLocaleString()}`);
            return this.verifyIdToken();
        } catch (err) {
            console.log('refresh token error:');
            console.log(err);
            throw err;
        }
    }

    logout = () => {
        if (this.isAuthenticated()) {
            const uri: string = this.logoutUri();
            deleteToken();
            console.log(`About to log out to ${uri}`);
            window.location.href = uri;
        } else {
            deleteToken();
        }
    }
}

export { AuthService };
