import { API, APIServiceManager } from '../..';

class CategoriesService {
    api: API = APIServiceManager.API;

    name() {
        return 'CategoriesService';
    }

    async getCategories(limitToProject: string, query?: any): Promise<[]> {
        try {
            const req = await this.api.axios.get('/categories', { params: { limitToProject, ...query } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateCategory(id: string, unit: any): Promise<[]> {
        try {
            const req = await this.api.axios.put(`/categories/${id}`, unit);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async deleteCategory(id: string): Promise<[]> {
        try {
            const req = await this.api.axios.delete(`/categories/${id}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    async addCategory(unit: any): Promise<[]> {
        try {
            const req = await this.api.axios.post('/categories', unit);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

}
export { CategoriesService };