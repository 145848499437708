import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { PieChart, StyledSwitch } from 'src/components';
import ForgeViewerEmitter from '../forge.event';
import { clearThemingColors, setAllGrey } from '../viewer-helper';


const useStyles = makeStyles({
    root: {
        margin: '10px 5px 0px 5px',
        height: '350px',
    },
    button: {
        color: 'white',
        '&:focus': {
            outline: 'unset'
        },
    },
});

export default function PainterViewerPanel(props: any) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [isIsolate, setIsIsolate] = useState(false);
    const [isAuto, setIsAuto] = useState(false);


    const handleIsolateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsIsolate(event.target.checked);
        props.onStateChange(isAuto, event.target.checked);
    };

    const handleAutoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAuto(event.target.checked);
        props.onStateChange(event.target.checked, isIsolate);
    };

    useEffect(() => {
        const fetchData = async () => {
            const stats = await props.onGetPaintedStats();
            setData(stats);
        };

        ForgeViewerEmitter.on('onPaintChanged', fetchData);
        fetchData();

        return () => {
            ForgeViewerEmitter.removeListener('onPaintChanged', fetchData);
        };
    }, [props.onGetPaintedStats]);

    const pieChartClick = (d: any) => {
        props.onChangeDisplay(d.type);
    };

    return (
        <div>
            <StyledSwitch checked={isAuto} onChange={handleAutoChange} name="checked" label="Auto" />
            <StyledSwitch checked={isIsolate} onChange={handleIsolateChange} name="checked" label="Isolate" />
            <PieChart
                data={data}
                width={150}
                height={150}
                innerRadius={40}
                outerRadius={75}
                onClick={pieChartClick}
                margin="0px 20px"
            />
            <Button className={classes.button} onClick={() => setAllGrey(props.viewer)}>Remove Color</Button>
            <Button className={classes.button} onClick={() => clearThemingColors(props.viewer)}>Reset model Color</Button>
        </div>
    );
}