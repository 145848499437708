import { Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, Route } from 'react-router-dom';
import { UserContext } from 'src/contexts/user.context';
import { ProjectManagerPage } from 'src/pages/admin';
import { APIServiceManager } from 'src/services';
import RequireManage from './protected/PermissionRoutes';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: '1px solid',
        height: '100%',
        width: 250
    },
    tabPanel: {
        width: 'calc(100% - 250px)'
    }
});

export default function ManagerRoutes() {
    const classes = useStyles();
    const userContext = useContext(UserContext);

    const [permissions, setPermissions] = useState<Array<any>>([]);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (userContext.user.id) {
                const perms = await APIServiceManager.PermissionService.getPermissions(userContext.user.id, undefined, false, undefined, true);
                setPermissions(perms);
            }
        };
        fetchPermissions();
    }, [userContext.user.id]);

    return (
        <Route path="manage" element={
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={(e: any, i: number) => setValue(i)}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    {permissions.map((p: any) =>
                        <Tab key={p.project._id} label={`${p.project.code} - ${p.project.name}`} component={Link} to={`project/${p.project._id}`} />
                    )}

                </Tabs>
                <div className={classes.tabPanel}>
                    <RequireManage>
                        <Outlet />
                    </RequireManage>
                </div>
            </div>
        }>
            <Route path="project/:id" element={<ProjectManagerPage />} />
        </Route>
    );
}
