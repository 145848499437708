import { Box, Icon, IconButton } from '@mui/material';
import React, { forwardRef } from 'react';
import { Asset } from 'src/contexts/MapPageContext';

export type AssetListingProps = {
    asset: Asset,
    isSelected: boolean,
    setSelectedAsset: (_: Asset | null) => void,
    refreshAssets: () => void,
};
export const AssetItem = forwardRef(AssetItem_);
function AssetItem_({ asset, isSelected, setSelectedAsset, refreshAssets }: AssetListingProps, ref: React.Ref<unknown>) {

    return (
        <Box
            ref={ref}
            sx={!isSelected ? styles.container : { ...styles.container, ...styles.selectedContainer }}
            onClick={() => setSelectedAsset(isSelected ? null : asset)}
        >
            {asset.name}
            <Box sx={styles.iconContainer} >
                <IconButton
                    size='small'
                    disabled={!asset.geojson}
                    onClick={function hideAsset(e) {
                        e.stopPropagation();
                        asset.visible = !asset.visible;
                        refreshAssets();
                    }}
                >
                    <Icon
                        fontSize='small'
                        className={`fas fa-${!asset.geojson || !asset.visible ? 'eye-slash' : 'eye'}`}
                        sx={styles.icon}
                        style={{
                            color: asset.geojson ? '#484848' : '#48484899',
                        }}
                    />
                </IconButton>
            </Box>
        </Box>
    );
}

const styles = {
    container: {
        cursor: 'pointer',
        width: 'auto',
        minWidth: 'unset',
        height: '42px',
        mt: 2.5,
        mr: 2.5,
        ml: 2.5,
        p: 2,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.15)',
        ':hover': {
            boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.15)',
            background: 'white',
        },
    },
    selectedContainer: {
        boxShadow: 'inset 2px 4px 5px 0px rgba(0, 0, 0, 0.15)',
        ':hover': {
            boxShadow: 'inset 0px 3px 5px 3px rgba(0, 0, 0, 0.15)',
            background: 'white',
        },
    },
    iconContainer: {
        height: 'inherit',
        width: '42px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: 'inherit',
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};
