import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useRef } from 'react';
import { APIServiceManager } from 'src/services';
import ForgeViewerProperties from './forge-viewer.interface';

import { ProjectContext } from 'src/contexts/project.context';
import './aimspainter.extension';
import './custom-properties.extension';
import './selection/window-selection.extension';

import './new-forge-viewer.css';

const useStyles = makeStyles({
    viewer: {
        overflow: 'hidden',
        width: 'calc(100% - 85px)',
        height: 'calc(100% - 85px)',
        position: 'absolute',
    }
});

export default function NewForgeViewer(props: ForgeViewerProperties) {

    const classes = useStyles();
    const projectContext = useContext(ProjectContext);
    const viewerContainer = useRef<HTMLDivElement>(null);
    const viewerRef = useRef<Autodesk.Viewing.GuiViewer3D>();

    useEffect(() => {

        if (props.urn.length > 10) {
            const options = {
                env: 'AutodeskProduction',
                getAccessToken: async (onSuccess: any) => {
                    var tokenInfos = await APIServiceManager.ForgeService.getToken();
                    if (tokenInfos && tokenInfos.access_token) {
                        var accessToken = tokenInfos.access_token;
                        var expire = tokenInfos.expires_in;
                        onSuccess(accessToken, expire);
                    } else {
                        props.onRedirect();
                    }

                },
            };

            Autodesk.Viewing.Initializer(options, () => {
                viewerRef.current = new Autodesk.Viewing.GuiViewer3D(viewerContainer.current!);
                const viewer = viewerRef.current;
                viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, geometobjectTreeCreated);
                var startedCode = viewer.start();
                if (startedCode > 0) {
                    console.error('Failed to create a Viewer: WebGL not supported.');
                    return;
                }

                Autodesk.Viewing.Document.load(
                    props.urn,
                    onLoadModels,
                    onDocumentLoadFailure
                );

                Promise.all([
                    import('./aimspainter.extension'),
                    import('./custom-properties.extension'),
                    import('./selection/window-selection.extension')
                ]).then(() => {
                    loadExtensions(viewer);
                });
            });
        }
    }, [props.urn]);


    const onLoadModels = async (viewerDocument: Autodesk.Viewing.Document) => {
        var viewables = viewerDocument.getRoot().search({ type: 'geometry', role: '3d' });
        const viewer = viewerRef.current!;
        var loadOptions = {
            isAEC: true,
            applyScaling: 'm', //always in meter
            applyRefPoint: true,
        };

        await viewer.loadDocumentNode(viewerDocument, viewables[0], loadOptions);

    };


    const geometobjectTreeCreated = async (evt: any) => {
        //load the extension MySelectionWindow and call initialization
        const ext: any = await viewerRef.current!.loadExtension('MySelectionWindow');
        ext.init(viewerContainer.current);

    };


    const loadExtensions = (forgeViewer: any) => {
        const extProps = { ...props, urn: props.urn.split(':')[1], canEdit: projectContext.currentProject.permissions.includes('paint-editor') };
        forgeViewer.loadExtension('AIMSPainter.Painter.v2', extProps);
        forgeViewer.loadExtension('AIMSPainter.CustomPropertyPanelExtension.v2', extProps);
        forgeViewer.loadExtension('AIMSPainter.AIMBuilder.v1', extProps);

        // forgeViewer.loadExtension('AIMSPainter.CustomPropertyPanelExtension');
        // forgeViewer.loadExtension('Autodesk.DocumentBrowser');
        // forgeViewer.loadExtension("Autodesk.VisualClusters");
        // forgeViewer.loadExtension("Autodesk.Snapping");
    };

    return (
        <div
            id="viewer"
            className={classes.viewer}
            ref={viewerContainer}
        />
    );
}

function onDocumentLoadFailure() {
    console.error('Failed fetching Forge manifest');
}