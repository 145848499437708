import { APIServiceManager } from 'src/services'
import { SnapshotSelectItem } from 'src/types';

interface SnapshotInput {
    _id: string;
    createdAt: string;
    name: string;
}

class StructureSnapshotService {

    nextId: number = 1

    name() {
        return 'StructureSnapshotService'
    }

    async createSnapshot(name: string): Promise<any> {
        const newSnapshot: any = await APIServiceManager.StructureSnapshotApiService.postSnapshot(name)
        return {
            name: newSnapshot.name,
            _id: newSnapshot._id,
            createdAt: newSnapshot.createdAt
        }
    }

    formatSummariesForSelect(
        rawSummaries: Array<SnapshotInput>
    ): Array<SnapshotSelectItem> {
        return rawSummaries.map((raw) => ({
            label: this.makeSummaryLabel(raw),
            id: raw._id,
            createdAt: new Date(raw.createdAt),
        }))
    }

    makeSummaryLabel(inObj: SnapshotInput): string {
        const createdAt: Date = new Date(inObj.createdAt)
        const result: string = `${inObj.name} ${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`
        return result
    }

    formatSnapshotForSelect(snapshot: SnapshotInput): SnapshotSelectItem {
        return {
            label: this.makeSummaryLabel(snapshot),
            id: snapshot._id,
            createdAt: new Date(snapshot.createdAt),
        }
    }

    async formatSnapshotForTree(id: string): Promise<any> {
        const formatNode = (node: any, depth: number, rank: number, id?: string) => {
            const currNodeId = id ? `${id}-${rank}` : rank.toString();
            const children: Array<any> = []
            let childRank = 1
            for (const child of node.children) {
                const formattedChild = formatNode(child, depth + 1, childRank, currNodeId)
                childRank++;
                children.push(formattedChild)
            }

            return {
                id: currNodeId,
                extendedData: {
                    name: node.name,
                    code: node.code,
                    extendedCodes: node.extendedCodes,
                },
                children: children,
            }
        }
        const result: { id: string, label: string, tree: any } = { id: id, label: '', tree: {} }
        const snapshot = await APIServiceManager.StructureSnapshotApiService.getSnapshot(id)
        result.label = this.makeSummaryLabel(snapshot)
        result.tree = formatNode(snapshot.root, 1, 1)
        return result
    }
}

export { StructureSnapshotService }
