import { useState, useEffect } from 'react';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FrontServiceManager } from './services';

interface IAlert {
    open: boolean
    message: string;
    severity: 'success' | 'warning' | 'error';
}

const initialState: IAlert = {
    open: false,
    message: '',
    severity: 'success'
}

export default function AlertHandler(props: any) {

    const [state, setState] = useState<IAlert>(initialState);

    const handleClose = () => {
        setState(initialState)
    }

    const handleAlert = (alert: IAlert) => {
        setState(alert)
    }

    useEffect(() => {
        FrontServiceManager.AlertEmitter.on('alert', handleAlert)
        return () => {
            FrontServiceManager.AlertEmitter.removeListener('alert', handleAlert)
        }
    }, []);

    return (
        <Snackbar
            style={{ minWidth: 300 }}
            open={state.open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
            <Alert onClose={handleClose} severity={state.severity} style={{ minWidth: 300 }}>
                {state.message}
            </Alert>
        </Snackbar>

    );


}