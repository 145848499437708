import { Dialog } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CloseDialogTitle } from 'src/components';
import { UserContext } from 'src/contexts/user.context';


function RequireAdmin({ children }: { children: JSX.Element }) {

    const { t } = useTranslation(['pablo']);

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const userContext = useContext(UserContext);
    useEffect(() => {
        let user = userContext.user;

        if (user.access_right === 'admin') {
            setIsAuthorized(true);

        } else {
            setShowAlert(true);
            setIsAuthorized(false);
        }
    }, [userContext])

    if (isAuthorized) {
        return children
    } else {
        if (isRedirect) {
            return (
                <Navigate to='/' />
            )
        } else {
            setTimeout(() => {
                setIsRedirect(true)
            }, 3000);
            return (
                <Dialog open={showAlert} onClose={() => setIsRedirect(true)}>
                    <CloseDialogTitle onClose={() => setIsRedirect(true)} title={'Restricted Access'} />
                    {t('pablo:router.message.adminError')}
                </Dialog >

            )
        }
    }


}

export default RequireAdmin
