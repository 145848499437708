import { Box, TextField } from '@mui/material';
import { Select } from 'dev4bim-react-utils';
import React, { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { InputType } from './EditLocDialog';
import { InputLocPt } from './utils/locTools';

export type LngLatInputProps = {
    loc: InputLocPt,
    setLoc: (_: InputLocPt) => void,
    inputType: InputType,
    disabled?: boolean,
    children?: ReactNode,
} & Select<{
    half: true,
    label?: string,
}, {
    half?: false,
    label?: [string, string],
}>;
export function LngLatInput({ children, inputType, loc, setLoc, disabled, half, label }: LngLatInputProps) {
    const [xStr, setXStr] = useState(nToStr(loc.x, inputType));
    const prevLoc = useRef(loc);

    useEffect(() => {
        if (loc == prevLoc.current) return;
        setXStr(nToStr(loc.x, inputType));
    }, [loc]);

    return (
        <Box sx={styles.container} >
            <TextField
                disabled={disabled}
                margin='dense'
                id='lng'
                label={label
                    ? (Array.isArray(label) ? label[0] : label)
                    : inputType.modifier == 'gps' ? 'Longitude' : 'Pk'
                }
                placeholder='ex: 2.2945...'
                variant='standard'
                sx={{ mr: 3 }}
                value={xStr}
                onChange={updateLocFromInput(loc, setLoc, inputType, 'lng-pk', prevLoc, setXStr)}
                required
            />
            {
                half
                    ? null
                    : (
                        <TextField
                            disabled={disabled}
                            margin='dense'
                            id='lat'
                            label={label ? label[1] : inputType.modifier == 'gps' ? 'Latitude' : 'Distance'}
                            placeholder='ex: 48.8582...'
                            type='number'
                            variant='standard'
                            value={loc.y?.toString() ?? ''}
                            onChange={updateLocFromInput(loc, setLoc, inputType, 'lat-dist', prevLoc)}
                            required
                        />
                    )
            }
            {children}
        </Box>
    );
}

function updateLocFromInput(
    loc: LngLatInputProps['loc'],
    setLoc: LngLatInputProps['setLoc'],
    inputType: InputType,
    type: 'lng-pk' | 'lat-dist',
    prevLoc: MutableRefObject<InputLocPt>,
    setXStr?: (_: string) => void,
) {
    return useCallback(function onInputUpdate(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const str = type == 'lng-pk' ? formatStr(e.target.value, inputType) : e.target.value;
        if (type == 'lng-pk') {
            setXStr?.(str);
        }
        let value;
        if (type == 'lng-pk' && inputType.modifier == 'pk') {
            value = parsePk(str, inputType);
        } else {
            value = !str ? null : parseFloat(str);
        }

        const newLoc = { ...loc };
        newLoc[type == 'lng-pk' ? 'x' : 'y'] = value;
        setLoc(newLoc);
        prevLoc.current = newLoc;
    }, [loc, setLoc, inputType]);
}

function nToStr(pk: number | null, inputType: InputType): string {
    if (pk == null) return '';
    if (inputType.modifier != 'pk') return pk.toString();
    return `${pk | 0}+${((pk - (pk | 0)) * 1000).toString().substring(0, 6)}`;
}

function formatStr(str: string, inputType: InputType): string {
    if (!str) return str;
    if (inputType.modifier != 'pk') return str;
    let parts = str.split('+');

    if (parts.length == 1) {
        const i = parts[0].indexOf('.');
        if (i != -1) {
            parts = [parts[0].slice(0, i), parts[0].slice(i + 1)];
        }
    }
    parts[0] = parts[0].replace(/\D/, '');
    if (parts.length == 1) return parts[0];

    parts[1] = parts.slice(1).join('');
    let sub = parts[1].split('.');
    console.log(sub);
    if (sub.length > 2) {
        sub = [sub[0], sub.slice(1).join('')];
    }
    sub[0] = sub[0].replace(/\D/, '');
    if (sub.length == 2) sub[1] = sub[1].replace(/\D/, '');

    if (sub[0].length > 3) {
        sub[1] = sub[0].slice(3) + (sub[1] ?? '');
        sub[0] = sub[0].slice(0, 3);
    }

    parts[1] = sub.length == 1 ? sub[0] : `${sub[0]}.${sub[1]}`;
    return `${parts[0]}+${parts[1]}`;
}

function parsePk(str: string, inputType: InputType): number | null {
    if (!str) return null;
    if (inputType.modifier != 'pk') return parseFloat(str);
    const parts = str.split('+');
    parts[0] = !parts[0] ? '0' : parts[0];
    parts[1] = !parts[1] ? '0' : parts[1];
    console.log(parts, parseInt(parts[0]) * 1000, parseFloat(parts[1]), ((parseInt(parts[0]) * 1000) + parseFloat(parts[1])) / 1000);
    return ((parseInt(parts[0]) * 1000) + parseFloat(parts[1])) / 1000;
}

const styles = {
    container: {
        mt: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
};
