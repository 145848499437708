import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { useStillMounted } from 'dev4bim-react-utils';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIServiceManager } from 'src/services';
import { useSnackBars } from 'src/utils/hooks/useSnackBars';
import CloseDialogTitle from '../ui/modal-title-with-close-button.component';

export type ConvertDxfDialogProps = {
    alignmentFeaturesDxfContent: undefined | string,
    onClose: () => void,
    onSaved: (_: GeoJSON.FeatureCollection<GeoJSON.LineString>) => void,
};
export function ConvertDxfDialog({ alignmentFeaturesDxfContent, onClose, onSaved }: ConvertDxfDialogProps) {
    const { t } = useTranslation(['pablo']);
    const { load, Loader } = useSnackBars();
    const { stillMountedCb } = useStillMounted();
    const [srs, setSrs] = useState('');
    const srsRef = useRef(srs);
    srsRef.current = srs;
    const contentRef = useRef(alignmentFeaturesDxfContent);
    contentRef.current = alignmentFeaturesDxfContent;

    const onSubmit = useCallback(function submit() {
        if (!contentRef.current || !srsRef.current) return;

        load(APIServiceManager.AlignmentService.parseAlignment(contentRef.current, srsRef.current)
            .then(stillMountedCb((res) => {
                onSaved(res.data);
            })));
    }, [onSaved]);

    return (
        <Dialog
            open={!!alignmentFeaturesDxfContent}
            sx={styles.container}
            onClose={onClose}
        >
            <CloseDialogTitle title={t('pablo:dialog.convertDxf.title')} onClose={onClose} />
            <DialogContent
                dividers
                onKeyDown={function submit(ev) {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        onSubmit();
                    }
                }}
            >
                <TextField
                    id='SRS'
                    label={t('pablo:dialog.convertDxf.srs')}
                    placeholder='ex: EPSG:3943'
                    margin="dense"
                    type="text"
                    variant="standard"
                    sx={styles.textField}
                    value={srs}
                    onChange={function updateInput(e) {
                        setSrs(e.target.value);
                    }}
                    required
                />

            </DialogContent>
            <DialogActions>
                <Loader />
                <Button onClick={onClose}>{t('pablo:button.cancel')}</Button>
                <Button
                    disabled={!srs}
                    onClick={onSubmit}
                >
                    {t('pablo:button.next')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const styles = {
    container: {
        '& .MuiDialog-paper': {
            overflow: 'visible',
        },
    },
    contentContainer: {
        p: 2,
        textAlign: 'center',
    },
    textField: {
        mr: 3,
    },
};
