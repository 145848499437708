import { PabloGeolocation } from 'src/pablo-shared';
import { Alignment, AlignmentFeature, Asset } from 'src/contexts/MapPageContext';
import { v4 as uuid } from 'uuid';
import { InputType } from '../EditLocDialog';
import { findPkIdx, pkToPoint } from './convertPointPk';
import { growData, InputLoc, InputLocPt, isValidLoc, simplifyLoc } from './locTools';

export function getDataFromAsset(asset: Asset | null): { data: InputLoc, type: InputType } | null {
    const geoloc = asset?.geolocation;
    if (!geoloc) return null;
    let data: InputLocPt[];
    let type: InputType;

    if (geoloc.type == 'point-loc') {
        type = { destination: 'point', modifier: 'gps' };
        data = [{ x: geoloc.lng, y: geoloc.lat, id: uuid() }];
    } else if (geoloc.type == 'point-pk') {
        type = { destination: 'point', modifier: 'pk' };
        data = [{ x: geoloc.pk, y: geoloc.dist, id: uuid() }];
    } else if (geoloc.type == 'segment') {
        type = { destination: 'segment', modifier: 'pk' };
        data = [
            { x: geoloc.pkStart, y: 0, id: uuid() },
            { x: geoloc.pkEnd, y: 0, id: uuid() },
        ];
    } else if (geoloc.type == 'range-loc') {
        type = { destination: 'line', modifier: 'gps' };
        data = geoloc.coords.map(({ lng, lat }) => ({ x: lng, y: lat, id: uuid() }));
    } else {
        type = { destination: 'line', modifier: 'pk' };
        data = geoloc.coords.map(({ pk, dist }) => ({ x: pk, y: dist, id: uuid() }));
    }

    return {
        data: growData(data),
        type,
    };
}

type LocToAssetReturn = {
    geojson: GeoJSON.Feature<GeoJSON.Point | GeoJSON.LineString>,
    geolocation: PabloGeolocation,
};
export function locToAsset(loc: InputLoc, inputType: InputType, alignment: AlignmentFeature, alignments: Alignment[]): LocToAssetReturn | null {
    if (!isValidLoc(loc, inputType)) {
        return null;
    }
    const locs = simplifyLoc(loc, inputType);
    if (!locs.length) return null;
    const geojson: LocToAssetReturn['geojson'] = {
        'type': 'Feature',
        'properties': {},
        'geometry': {
            'type': inputType.destination == 'point' ? 'Point' : 'LineString',
            'coordinates': [],
        },
    };
    let geolocation: PabloGeolocation;
    if (inputType.modifier == 'gps') {
        const coords = locs.map((l) => [l.x, l.y] as [number, number]);
        geojson.geometry.coordinates = inputType.destination == 'point' ? coords[0] : coords;
        const geoCoords = locs.map((l) => ({ lng: l.x, lat: l.y }));
        if (inputType.destination == 'point') {
            geolocation = {
                type: 'point-loc',
                ...geoCoords[0],
            };
        } else {
            geolocation = {
                type: 'range-loc',
                coords: geoCoords,
            };
        }
    } else {
        if (!alignment || !alignments) return null;
        const fullAlignment = findFullAlignment(alignment.properties.parentName, alignment.properties.name, alignments);
        if (!fullAlignment) return null;
        const pkCoords = locs.map((l) => ({ pk: l.x, dist: l.y }));
        if (inputType.destination == 'segment' && pkCoords[0].pk > pkCoords[1].pk) {
            const tmp = pkCoords[0];
            pkCoords[0] = pkCoords[1];
            pkCoords[1] = tmp;
        }
        geolocation = {
            type: inputType.destination == 'point' ? 'point-pk' : inputType.destination == 'line' ? 'range-pk' : 'segment',
            alignment: {
                name: fullAlignment.name,
                lineName: alignment.properties.name,
            },
            ...(inputType.destination == 'point'
                ? pkCoords[0]
                : inputType.destination == 'segment'
                    ? { pkStart: pkCoords[0].pk, pkEnd: pkCoords[1].pk }
                    : { coords: pkCoords }
            )
        } as any;

        if (inputType.destination == 'segment') {
            const is = findPkIdx(pkCoords[0].pk, alignment.geometry.coordinates as [number, number][]);
            let ie = findPkIdx(pkCoords[1].pk, alignment.geometry.coordinates as [number, number][]);
            ie = ie > 0 ? ie : ie;
            const { lng: lngS, lat: latS } = pkToPoint(pkCoords[0], alignment);
            const { lng: lngE, lat: latE } = pkToPoint(pkCoords[1], alignment);
            geojson.geometry.coordinates = [
                [lngS, latS],
                ...(alignment.geometry.coordinates.slice(is, ie)),
                [lngE, latE],
            ];
        } else {
            const geoCoords = pkCoords.map((pk) => {
                const { lng, lat } = pkToPoint(pk, alignment)
                return [lng, lat];
            });
            geojson.geometry.coordinates = inputType.destination == 'point' ? geoCoords[0] : geoCoords;
        }
    }
    return {
        geojson,
        geolocation,
    }
}

function findFullAlignment(name: string, lineName: string, alignments: Alignment[]) {
    return alignments.find((al) => al.name == name && !!al.geojson.features.find((f) => f.properties.name == lineName));
}
