import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CTA } from 'src/components';
import { ProjectSelectorCard } from 'src/components/project/card/project-card.component';
import { ProjectContext } from 'src/contexts/project.context';

export default function HomePage() {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <LeftPanel />
            <RightPanel />
        </div>
    );
}

const useStyles = makeStyles((theme: any) => ({
    root: {
        height: '100%',
        margin: '0 8px',
        display: 'flex',
        flexDirection: 'row',
    },
}));

function LeftPanel() {
    const { t } = useTranslation(['pablo']);
    const classes = useLeftPanelStyles();

    return (
        <Card className={classes.container} variant='elevation' >
            <h1 className={classes.title} >{t('pablo:pages.home.welcome')}</h1>
            <img src='/logos/pablo.png' className={classes.logoImg} />
            <p className={classes.presentation} >{t('pablo:pages.home.presentation-1')}</p>
            <p className={classes.presentation} >{t('pablo:pages.home.presentation-2')}</p>
            <p className={classes.presentation} >{t('pablo:pages.home.presentation-3')}</p>
            <div className={classes.blocksImgContainer} >
                <img src='/blocks.svg' className={classes.blocksImg} />
            </div>
        </Card>
    );
}

const useLeftPanelStyles = makeStyles((theme: any) => ({
    container: {
        height: '100%',
        flex: 1,
        marginRight: '16px',
        padding: '24px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: '3em',
        margin: '0 0 16px 0',
    },
    logoImg: {
        width: '80%',
    },
    presentation: {
        fontSize: '1.2em',
    },
    blocksImgContainer: {
        flexGrow: 1,
        marginTop: '16px',
        position: 'relative',
    },
    blocksImg: {
        height: '130%',
        right: '-5%',
        bottom: '-12%',
        position: 'absolute',
        transform: 'translate(24px, 24px)',
    },
}));

function RightPanel() {
    const { t } = useTranslation(['pablo']);
    const classes = useRightPanelStyles();
    const projectContext = useContext(ProjectContext);
    const [activeIndex, setActiveIndex] = useState(-1);

    return (
        <Card className={classes.container} variant='elevation' >
            <div className={classes.top} >
                <div className={classes.headerContainer} >
                    <h1 className={classes.headerTitle} >{t('pablo:pages.home.header')}</h1>
                    <CTA href='/projects' variant='filled' icon='arrow-right' >
                        {t('pablo:button.seeAllProjects')}
                    </CTA>
                </div>
                <Grid
                    container
                    spacing={3}
                    className={classes.gridContainer}
                // flexWrap='nowrap'
                >
                    {projectContext.projects.map((p, idx) => (
                        <Grid item key={`${p._id}`} >
                            <ProjectSelectorCard
                                project={p}
                                onActivate={() => setActiveIndex(idx)}
                                isActive={activeIndex === idx}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
            {/* <div className={classes.bottom} >
                <div className={classes.headerContainer} >
                    <h1 className={classes.headerTitle} >{t('pablo:pages.home.headerTasks')}</h1>
                    <CTA href='/projects' variant='filled' icon='arrow-right' >
                        {t('pablo:button.checkTasks')}
                    </CTA>
                </div>
                <div className={classes.tasksContainer} >
                    <TasksTable />
                </div>
            </div> */}
        </Card>
    );
}

const useRightPanelStyles = makeStyles((theme: any) => ({
    container: {
        height: '100%',
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    top: {

    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: '16px',
    },
    headerTitle: {
        margin: 0,
    },
    gridContainer: {
        width: '100% !important',
        paddingRight: '24px !important',
        margin: '0 !important',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    bottom: {
        marginTop: '16px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    tasksContainer: {
        flexGrow: 1,
    },
}));
