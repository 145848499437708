import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props: {
    open: boolean,
    onContinue: any,
    onCancel: any,
    continueButtonLabel: string,
    cancelButtonLabel: string,
    title?: string,
    content: string,
}) {

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {props.title ? (<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>) : ''
            }
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="primary">
                    {props.cancelButtonLabel}
                </Button>
                <Button onClick={props.onContinue} color="primary" autoFocus>
                    {props.continueButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}