import { APIServiceManager, API } from '../..';
import axios from 'axios'
class ITwinService {
    api: API = APIServiceManager.API;;

    name() {
        return 'ITwinService';
    }

    async createNewModelVersion(contextId: string, modelId: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/project/itwin/context/${contextId}/model/${modelId}`);

            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async refreshModelList(): Promise<any> {
        try {
            const req = await this.api.axios.get(`/project/itwin/imodels/refresh`);
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getITwinModel(modelId: string) {
        try {
            const req = await this.api.axios.get(`/project/itwin?modelId=${modelId}`);
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async addModelToProject(modelId: string) {
        try {
            const req = await this.api.axios.post(`/project/itwin/imodels/${modelId}`);
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }


}

export { ITwinService };
