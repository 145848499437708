import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Icon,
    Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGridPro,
    enUS,
    frFR, GridCellParams,
    GridColumns,
    GridFilterModel, GridRowsProp,
    GridSelectionModel,
    gridSelectionStateSelector,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueGetterParams,
    useGridApiContext,
    useGridApiRef,
    useGridSelector
} from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CTA } from 'src/components';
import { CTAGroup } from 'src/components/ui/CTAGroup/call-to-action-group.component';
import { APIServiceManager, FrontServiceManager } from 'src/services';
import StructureTreeSettings from '../../structure/tree/structure-tree-settings-modal.component';
import NewAssetModal from '../new/newasset-modal.component';

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        outline: 'unset !important',
    },
    header: {
        outline: 'unset !important',
    },
}));

function AssetListToolbar(props: any) {
    const { t } = useTranslation(['pablo']);
    const args: any = {
        componentsProps: {
            button: {
                disableRipple: true,
                disableElevation: true,
                disableFocusRipple: true,
                disableTouchRipple: true,
            },
        },
        sx: {
            color: 'inherit',
            padding: 0,
        },
    };
    const apiRef = useGridApiContext();
    const selectionState = useGridSelector(apiRef, gridSelectionStateSelector);

    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [showTreeSettings, setShowTreeSettings] = useState(false);
    return (
        <Box>
            <Grid container sx={{ py: 0.5, px: 2, alignItems: 'center' }}>
                <Grid item xs={2}>
                    <h2>
                        {t('pablo:components.asset.list.title')}
                    </h2>
                </Grid>
                <Grid item xs={8}>
                    <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <CTA>
                            <GridToolbarFilterButton {...args} />
                        </CTA>
                        <CTA sx={{ ml: 2 }} >
                            <GridToolbarColumnsButton {...args} />
                        </CTA>
                        <CTA
                            sx={{ ml: 2 }}
                            size='small'
                            icon={{
                                name: 'cog',
                                type: 'fa',
                            }}
                            onClick={() => setShowTreeSettings(true)}
                        >
                            {t('pablo:button.settings')}
                        </CTA>
                    </GridToolbarContainer>
                </Grid >
                <Grid item xs={4} ><div /></Grid>
            </Grid >

            <Box sx={{ py: 0.5, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <CTAGroup
                    label={t('pablo:button.groupedActions')}
                    options={[
                        {
                            name: t('pablo:button.edit'),
                            onClick: props.onEdit,
                            icon: {
                                name: 'edit',
                                type: 'fa',
                            },
                            disabled: selectionState.length < 1,
                        },
                        {
                            name: t('pablo:button.remove'),
                            onClick: () => setShowRemoveConfirmation(true),
                            icon: {
                                name: 'trash',
                                type: 'fa',
                            },
                            disabled: selectionState.length < 1,
                            iconProps: {
                                sx: {
                                    height: '1.5rem',
                                },
                            },
                        },
                    ]}
                />
                <Box>
                    <CTA
                        size='small'
                        icon={{
                            name: 'plus',
                            type: 'fa',
                        }}
                        variant='filled'
                        onClick={props.onAdd}
                    >
                        {t('pablo:button.add')}
                    </CTA>
                </Box>
            </Box>

            <Dialog open={showRemoveConfirmation} onClose={() => setShowRemoveConfirmation(false)}>
                <DialogContent>
                    <DialogContentText>
                        {t('pablo:components.asset.list.confirmDeletion')}
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        props.onDelete();
                        setShowRemoveConfirmation(false);
                    }} autoFocus>
                        <Icon className='fas fa-check' />
                    </Button>
                    <Button onClick={() => setShowRemoveConfirmation(false)}><Icon className='fa fa-times' /></Button>
                </DialogActions>
            </Dialog>
            <StructureTreeSettings show={showTreeSettings} onClose={() => setShowTreeSettings(false)} hideOptions={[0, 1]} />
        </Box>
    );
}

export default function AssetList() {

    const { t, i18n } = useTranslation(['pablo']);
    const classes = useStyles();

    const history = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<GridRowsProp>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterValue, setFilterValue] = useState<GridFilterModel>();

    const [localeText, setLocaleText] = useState<any>(enUS);

    const [cookies] = useCookies([
        'aimspainter_asset_structure_display_codeSeparator',
        'aimspainter_asset_structure_display_rootCode'
    ]);

    const apiRef = useGridApiRef();
    const prevSelectionModel = useRef<GridSelectionModel>(selectionModel);

    useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);
            const newRows = await FrontServiceManager.AssetTableQueryService.getAssetData(page, pageSize, filterValue);
            if (!active) {
                return;
            }

            setData(newRows.data);
            setTotalRows(newRows.total);

            setLoading(false);
            setTimeout(() => {
                setSelectionModel(prevSelectionModel.current);
            });
        })();

        return () => {
            active = false;
        };
    }, [page, pageSize, filterValue, refresh]);

    const columns: GridColumns = useMemo(() => {
        return [
            { field: 'name', hideable: false, headerName: t('pablo:table.headers.name'), flex: 0.25 },
            { field: 'structure.name', headerName: t('pablo:table.headers.type'), valueGetter: (params: GridValueGetterParams) => params.row.structure.name, flex: 0.15 },
            { field: 'structure.code', headerName: t('pablo:table.headers.code'), valueGetter: (params: GridValueGetterParams) => params.row.structure.code, flex: 0.1 },
            {
                field: 'structure.fullCode', headerName: t('pablo:table.headers.fullCode'),
                valueGetter: (params: GridValueGetterParams) => FrontServiceManager.StructureQueryService.getFullCode(params.row.structure.fullCode,
                    cookies.aimspainter_asset_structure_display_codeSeparator, cookies.aimspainter_asset_structure_display_rootCode == 'true'),
                flex: 0.15
            },
        ];
    }, [i18n.language, localeText, cookies.aimspainter_asset_structure_display_codeSeparator, cookies.aimspainter_asset_structure_display_rootCode]);

    const handleSubmitNewAssetModel = async (e: any, data: any) => {
        e.preventDefault();
        setShowModal(false);
        await APIServiceManager.AssetService.addAsset({ structureId: data.structureId, name: data.name });
        setRefresh(!refresh);
    };

    const deleteSelection = async () => {
        await APIServiceManager.AssetService.removeBulkAssets(selectionModel.map(s => s.toString()));
        setRefresh(!refresh);
    };

    const editSelection = () => {
        if (!selectionModel.length) return;
        history(`${selectionModel[0]}`);
    };

    useEffect(() => {
        switch (i18n.language) {
            case 'fr':
                setLocaleText(frFR.components.MuiDataGrid.defaultProps.localeText);
                break;
            case 'en':
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
            default:
                setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
                break;
        }
    }, [i18n.language]);

    return (
        <>
            <DataGridPro
                apiRef={apiRef}
                classes={{
                    cell: classes.cell,
                    columnHeader: classes.header,
                }}
                sx={{
                    backgroundColor: 'white',
                }}
                columns={columns}
                rows={data}
                rowCount={totalRows}
                pageSize={pageSize}
                loading={loading}
                pagination
                localeText={localeText}
                paginationMode="server"
                rowsPerPageOptions={[20, 50, 100]}
                onPageChange={(newPage) => {
                    prevSelectionModel.current = selectionModel;
                    setPage(newPage);
                }}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                onCellDoubleClick={(params: GridCellParams) => {
                    history(`${params.id}`);
                }}
                components={{
                    Toolbar: AssetListToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        onAdd: () => setShowModal(true),
                        onDelete: () => deleteSelection(),
                        onEdit: editSelection,
                    },
                }}
                filterMode="server"
                onFilterModelChange={(filterModel: GridFilterModel) => {
                    setFilterValue(filterModel);
                }}
            />
            < NewAssetModal
                show={showModal}
                onSubmit={handleSubmitNewAssetModel}
                onHide={() => setShowModal(false)}
            />
        </>
    );
}
