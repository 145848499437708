import React, { useState, useEffect, useCallback } from 'react';
import { APIServiceManager, PropLibraryService } from '../../../services';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next'

function PropertyLibrariesPage() {
    const { t } = useTranslation(['pablo']);
    const _propertyLibraryService: PropLibraryService = APIServiceManager.getService('PropLibraryService') as PropLibraryService;
    const [libraries, setLibraries] = useState([]);


    useEffect(() => {
        loadData();
    }, []);

    const columns: any = useCallback(() => [
        {
            field: 'name',
            title: 'Name',
            editable: "always"
        },
        {
            field: 'count',
            title: 'Number of properties',
            editable: "never"
        },
    ], []);

    const loadData = async () => {
        const libs = await _propertyLibraryService.getLibraries(true);
        setLibraries(libs);
    }

    const addLibrary = async (newData: any) => {
        await _propertyLibraryService.addLibrary(newData.name, newData.language, false);
        await loadData();
    }

    const updateLibrary = async (newData: any, oldData: any) => {
        await _propertyLibraryService.updateLibrary(newData._id, newData);
        await loadData();
    }

    const deleteLibrary = async (oldData: any) => {
        await _propertyLibraryService.removeLibrary(oldData._id);
        await loadData();
    }

    return (
        <div className="custom-editable-material-table-100 ">
            <MaterialTable
                title={t('pablo:pages.propertyLib.propertyLibraryList')}
                columns={columns()}
                localization={{
                    body: {
                        editRow: {
                            deleteText: t('pablo:pages.propertyLib.deleteText')
                        }
                    }
                }}
                data={libraries}
                editable={{
                    onRowUpdate: updateLibrary,
                    onRowDelete: deleteLibrary,
                    onRowAdd: addLibrary,
                    isDeletable: (rowData: any) => !rowData.count,
                }}
            />
        </div>


    );
}

export default PropertyLibrariesPage;
