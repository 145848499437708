import { Box } from '@mui/material';
import { simplifyGeometryToPoint, useMeasure, usePointFocus } from 'dev4bim-react-mapbox-gl';
import { useStillMounted } from 'dev4bim-react-utils';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMapPageContext } from 'src/contexts/MapPageContext';
import { useSnackBars } from 'src/utils/hooks/useSnackBars';
import DropFileDialog from '../ui/drop-file/drop-file-dialog.component';
import { ToolButton } from './ToolButton';

export type ToolsListProps = {
    locDialogOpen: boolean,
    openLocDialog: () => void,
    onImportedAlignment: (_: GeoJSON.FeatureCollection<GeoJSON.LineString> | string) => void,
};
export function ToolsList({ locDialogOpen, openLocDialog, onImportedAlignment }: ToolsListProps) {
    const { t } = useTranslation(['pablo']);
    const {
        assets,
        selectedAsset,
        refreshAssets,
    } = useMapPageContext();
    const focus = usePointFocus();
    const measure = useMeasure();
    const [dropFile, setDropFile] = useState(false);
    const selectedFile = useRef<any>();

    const allVisible = useMemo(() => assets.reduce((acc, ast) => ({
        visible: acc.visible + (ast.visible && ast.geojson ? 1 : 0),
        total: acc.total + (ast.geojson ? 1 : 0),
    }), { visible: 0, total: 0 }), [assets]);

    const makeAllVisibility = useCallback((makeVisible: boolean) => (() => {
        assets.forEach((ast) => ast.visible = makeVisible);
        refreshAssets();
    }), [assets]);

    return (
        <Box sx={styles.container} >
            <Box sx={styles.toolsTitle} >
                {t('pablo:components.toolsList.tools')}
            </Box>
            <Box sx={{ ...styles.toolsContainer, ...styles.subContainer }} >

                <ToolButton
                    type='focus'
                    disabled={!selectedAsset || !selectedAsset.geojson}
                    onClick={function focusPoint() {
                        if (selectedAsset && selectedAsset.geojson) {
                            focus(simplifyGeometryToPoint(selectedAsset.geojson.geometry));
                            if (!selectedAsset.visible) {
                                selectedAsset.visible = true;
                                refreshAssets();
                            }
                        }
                    }}
                />

                <ToolButton
                    type={selectedAsset && !selectedAsset.geojson ? 'add' : 'edit'}
                    disabled={locDialogOpen}
                    onClick={openLocDialog}
                />

                <ToolButton
                    type={allVisible.visible == allVisible.total ? 'visible' : !allVisible.visible ? 'invisible' : 'halfvisible'}
                    onClick={makeAllVisibility(!allVisible.visible)}
                />

                <ToolButton
                    type='measure'
                    disabled={locDialogOpen}
                    onClick={function launchMeasureMode() {
                        measure();
                    }}
                />

                <ToolButton
                    type='upload'
                    disabled={locDialogOpen}
                    onClick={function askToDropFile() {
                        setDropFile(true);
                    }}
                />

                <DropFileDialog
                    show={dropFile}
                    onClose={() => setDropFile(false)}
                    onFileSelected={(file) => selectedFile.current = file}
                    title={t('pablo:dialog.uploadFile')}
                    acceptExtension={['.geojson', '.dxf']}
                    onImport={handleFileImport(selectedFile, setDropFile, onImportedAlignment)}
                />
            </Box>
        </Box>
    );
}

function isValidGeoJSON(object: { [key: string]: any }) {
    const checkFeature = function checkFeature(feature: any) {
        if (feature.type !== 'Feature') {
            return 'is not a feature';
        }
        feature.properties = feature.properties ?? {};
        if (feature.geometry?.type !== 'LineString') {
            return feature.geometry ? 'is not a line' : 'is missing a geometry';
        } else if (!feature.geometry.coordinates?.length) {
            return 'have no coordinates';
        }
        for (const i in feature.geometry.coordinates) {
            if (feature.geometry.coordinates[i]?.length !== 2) {
                return `invalid coordinates (index: ${i})`;
            }
        }
        return null;
    };

    if (object.type !== 'FeatureCollection') {
        return 'is not a feature collection';
    } else if (!object.features?.length) {
        return 'have no features';
    }
    for (const i in object.features) {
        const err = checkFeature(object.features[i]);
        if (err) {
            return `have an invalid feature (index: ${i}): ${err}`;
        }
    }
    return null;
}

function handleFileImport(
    fileName: React.MutableRefObject<any>,
    setDropFile: (_: boolean) => void,
    onImportedAlignment: ToolsListProps['onImportedAlignment'],
) {
    const { success, error } = useSnackBars();
    const { stillMounted } = useStillMounted();

    return useCallback(function handlingFileImport() {
        if (!fileName.current) return;
        const reader = new FileReader();

        reader.onload = async function parseFile(f: ProgressEvent<FileReader>) {
            if (f.target && f.target.result && typeof f.target.result == 'string') {
                const typeStr: string = fileName.current.type;
                const type = typeStr.length >= 4 && typeStr.slice(typeStr.length - 4) == '.dxf' ? 'dxf' : 'geojson';
                const object = type == 'geojson' ? JSON.parse(f.target.result) : f.target.result;
                const err = type == 'geojson' ? isValidGeoJSON(object) : null;
                if (err) {
                    error('importError', { error: err });
                } else {
                    stillMounted(() => {
                        onImportedAlignment(object as any);
                        setDropFile(false);
                    });
                    success('import', {}, { autoHideDuration: 1500 });
                }
            }
        };
        reader.readAsBinaryString(fileName.current);
    }, [onImportedAlignment]);
}

const styles = {
    subContainer: {
        borderRadius: '10px',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px 0px 10px 0px rgb(0, 0, 0, 0.10)',
    },
    container: {
        ml: 2,
        display: 'flex',
        flexDirection: 'column',
    },
    toolsTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 'auto',
    },
    toolsContainer: {
        pr: 2,
        display: 'flex',
        flexDirection: 'row',
    },
};
