import { useCancelableEffect } from 'dev4bim-react-utils';
import { IPabloAlignment, IPabloAssetsGeolocation } from 'src/pablo-shared';
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { APIServiceManager } from 'src/services';
import { useSnackBars } from 'src/utils/hooks/useSnackBars';

export type FeatureProps = {
    name: string,
    visible?: boolean,
    [key: string]: any,
};

export type Asset = IPabloAssetsGeolocation & {
    visible: boolean,
};

export type AlignmentProps = FeatureProps & {
    parentName: string,
};
export type Alignment = Omit<IPabloAlignment, 'geojson'> & {
    geojson: GeoJSON.FeatureCollection<GeoJSON.LineString, AlignmentProps>,
};
export type AlignmentFeature = GeoJSON.Feature<GeoJSON.LineString, AlignmentProps> | null;
export type AlignmentsFeatures = GeoJSON.FeatureCollection<GeoJSON.LineString, AlignmentProps>;

export type MapPageContext = {
    assets: Asset[],
    refreshAssets: () => void,
    selectedAsset: Asset | null,
    setSelectedAsset: (_: Asset | null) => void,
    alignments: Alignment[],
    addAlignment: (_: IPabloAlignment | Alignment) => void,
    selectedAlignmentFeature: AlignmentFeature,
    setSelectedAlignmentFeature: (_: AlignmentFeature) => void,
    assetsFeatures: GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, FeatureProps>,
    alignmentsFeatures: AlignmentsFeatures,
};

const MapPageContext_ = createContext<MapPageContext | null>(null);

export function useMapPageContext() {
    return useContext(MapPageContext_ as React.Context<MapPageContext>);
}

export function MapPageContextProvider({ children }: { children: ReactNode }) {
    const [assets, setAssets] = useState<Asset[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [alignments, setAlignments] = useState<Alignment[]>([]);
    const [selectedAlignmentFeature, setSelectedAlignmentFeature] = useState<MapPageContext['selectedAlignmentFeature']>(null);

    const context: MapPageContext = {
        assets,
        refreshAssets: () => setAssets([...assets]),
        selectedAsset,
        setSelectedAsset,
        alignments,
        addAlignment: (al) => setAlignments([...alignments, adaptAlignment(al)]),
        selectedAlignmentFeature,
        setSelectedAlignmentFeature,
        ...calculateFeatures(assets, alignments),
    };

    initialDataFetch(setAssets, setAlignments);

    return (
        <MapPageContext_.Provider value={context} >
            {children}
        </MapPageContext_.Provider>
    )
}

function calculateFeatures(assets: Asset[], alignments: Alignment[]) {

    const assetsFeatures = useMemo<GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, FeatureProps>>(function createGeoJSONCollection() {
        const points = assets.filter((ast) => !!ast.geojson) as
            (Omit<Asset, 'geojson'> & Required<Pick<Asset, 'geojson'>>)[];

        return {
            'type': 'FeatureCollection',
            'features': points.map((ast) => ({
                ...ast.geojson,
                properties: {
                    ...ast.geojson.properties,
                    name: ast.name,
                    visible: ast.visible,
                }
            })),
        };
    }, [assets, alignments]);

    const alignmentsFeatures = useMemo<GeoJSON.FeatureCollection<GeoJSON.LineString, AlignmentProps>>(() => ({
        'type': 'FeatureCollection',
        'features': alignments
            .map((al) => al.geojson.features)
            .reduce((acc, r) => [...acc, ...r], [])
            .map((feature: any) => {
                feature.properties.visible = true;
                feature.properties.alignment = true;
                return feature;
            }),
    }), [alignments]);

    return {
        assetsFeatures,
        alignmentsFeatures,
    };
}

function initialDataFetch(
    setAssets: (_: Asset[]) => void,
    setAlignments: (_: Alignment[]) => void,
) {
    const { error } = useSnackBars();

    useCancelableEffect(function fetchAssets(isCanceled) {
        APIServiceManager.AssetService.getAssetGeolocation()
            .then((res) => {
                if (isCanceled()) return;
                setAssets(res.data.map((ast) => ({
                    ...ast,
                    visible: !!ast.geojson,
                })));
            })
            .catch(() => error('fetchError', { type: 'assets' }, { persist: true }));

        APIServiceManager.AlignmentService.getAlignments(0, 50)
            .then((res) => {
                if (isCanceled()) return;
                setAlignments(res.data.map(adaptAlignment));
            })
            .catch(() => error('fetchError', { type: 'alignments' }, { persist: true }));
    }, []);

}

function adaptAlignment(alignment: IPabloAlignment | Alignment): Alignment {
    alignment.geojson.features
        .forEach((f) => {
            (f as Alignment['geojson']['features'][number]).properties = {
                ...f.properties,
                parentName: alignment.name,
            };
        });
    return alignment as Alignment;
}
