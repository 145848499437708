import { getExtId, getIFCGuid } from './id-guid-helper';

const Three = window.THREE;

export function getSelectionIds(viewer: Autodesk.Viewing.GuiViewer3D): Promise<any[]> {
    return Promise.all(viewer.getSelection().map(async (id: number) => {
        const extId = await getExtId(viewer, id);
        const ifcGuid = getIFCGuid(extId);
        return [id, extId, ifcGuid];
    }));
}

export function getVisibleObjectIds(viewer: Autodesk.Viewing.GuiViewer3D): Promise<any[]> {
    return Promise.all(getVisibleItemsDbIds(viewer).map(async (id: number) => {
        const extId = await getExtId(viewer, id);
        const ifcGuid = getIFCGuid(extId);
        return [id, extId, ifcGuid];
    }));
}

export function paintObjects(viewer: Autodesk.Viewing.GuiViewer3D, ids: number | number[], colorIndexes: Array<any>, index: number) {
    const vectorColor = indexToColor(colorIndexes, index);
    if (Array.isArray(ids)) {
        for (const id of ids) {
            viewer.setThemingColor(id, vectorColor);
        }
    } else {
        viewer.setThemingColor(ids, vectorColor);
    }

    //viewer.hide(ids);
}

export function unPaintObjects(viewer: Autodesk.Viewing.GuiViewer3D, ids: number | number[]) {
    const vectorColor = new Three.Vector4(0, 0, 0, 0);
    if (Array.isArray(ids)) {
        for (const id of ids) {
            viewer.setThemingColor(id, vectorColor);
        }
    } else {
        viewer.setThemingColor(ids, vectorColor);
    }
}


export function showPaintedInfo(viewer: Autodesk.Viewing.GuiViewer3D, paintedInfo: any, colorIndexes: any) {
    viewer.clearThemingColors(viewer.model);
    for (let i = 0; i < paintedInfo.length; i++) {
        const paintedObject = paintedInfo[i];
        if (paintedObject.colorIndex > -1) {
            const vectorColor = indexToColor(colorIndexes, paintedObject.colorIndex);
            viewer.setThemingColor(paintedObject.objectId, vectorColor);
        } else {
            viewer.setThemingColor(paintedObject.objectId, new Three.Vector4(1, 0, 0, 1));
        }
    }
}

export function showNotPaintedInfo(viewer: Autodesk.Viewing.GuiViewer3D, paintedInfo: any) {
    clearThemingColors(viewer);
    const paintedIds = paintedInfo.map((p: any) => { return parseInt(p.objectId); });
    const allObj = GetObjectDbIds(viewer);

    allObj.filter(id => !paintedIds.includes(id)).forEach(id => {
        viewer.setThemingColor(id, new Three.Vector4(1, 0, 0, 1));
    });
}

export function isolatePainted(viewer: Autodesk.Viewing.GuiViewer3D, paintedInfo: any) {
    clearThemingColors(viewer);
    viewer.showAll();
    const paintedIds = paintedInfo.map((o: any) => { return o.objectId; });
    viewer.isolate(paintedIds, viewer.model);
}

export function isolateAllPainted(viewer: Autodesk.Viewing.GuiViewer3D, paintedInfo: any) {
    clearThemingColors(viewer);
    viewer.showAll();
    const paintedIds = paintedInfo.map((o: any) => { return o.objectId; });
    viewer.isolate(paintedIds);
}

export function isolateUnPainted(viewer: Autodesk.Viewing.GuiViewer3D, paintedInfo: any, colorIndexes: Array<any>) {
    clearThemingColors(viewer);
    viewer.showAll();
    paintedInfo.forEach((e: any) => {
        const vectorColor = indexToColor(colorIndexes, e.colorIndex);
        viewer.setThemingColor(e.objectId, vectorColor);
        viewer.hide(e.objectId);
    });
}

export function colorIds(viewer: any, ids: Array<any>, color: any) {
    for (const id of ids) {
        viewer.setThemingColor(id, color);
    }
}

export function clearThemingColors(viewer: Autodesk.Viewing.GuiViewer3D) {
    viewer.clearThemingColors(viewer.model);
}


export function setAllGrey(viewer: Autodesk.Viewing.GuiViewer3D) {
    clearThemingColors(viewer);
    const objectIds = GetAllDbIds(viewer);
    for (const id of objectIds) {
        viewer.setThemingColor(id, new Three.Vector4(0.5, 0.5, 0.5, 1));
    }
}

function getGeomAndFrag(viewer: Autodesk.Viewing.GuiViewer3D) {
    const fragList = viewer.model.getFragmentList();
    const obj3D = [];
    if (fragList.geoms && fragList.geoms.geoms) {
        for (const geom of fragList.geoms.geoms) {
            if (geom && geom.polyCount > 2) {
                obj3D.push(geom.id);
            }
        }
    }
    const fragIndex = [];
    for (let i = 0; i < fragList.geomids.length; i++) {
        fragIndex.push(fragList.geomids[i]);
    }
    return { fragIndex, obj3D };
}

export function GetObjectDbIds(viewer: Autodesk.Viewing.GuiViewer3D) {
    const fragList = viewer.model.getFragmentList();
    const { fragIndex, obj3D } = getGeomAndFrag(viewer);
    for (let i = 0; i < fragList.geomids.length; i++) {
        if (obj3D.includes(fragList.geomids[i])) {
            fragIndex.push(i);
        }
    }
    return fragIndex.map((index: number) => fragList.fragments.fragId2dbId[index]);
}

export function GetAllDbIds(viewer: Autodesk.Viewing.GuiViewer3D) {
    const fragList = viewer.model.getFragmentList();
    return fragList.fragments.fragId2dbId;
}

export function GetNumberOfObject(viewer: Autodesk.Viewing.GuiViewer3D) {
    const { fragIndex } = getGeomAndFrag(viewer);
    return fragIndex.length;
}

function getVisibleItemsDbIds(viewer: Autodesk.Viewing.GuiViewer3D): number[] {
    const parentId = viewer.getIsolatedNodes();
    const subIds = parentId.flatMap((id: number) => {
        return getLeafNode(viewer, id);
    });
    return subIds;
}

function getLeafNode(viewer: any, parent: number): number[] {
    const instanceTree = viewer.model.getData().instanceTree;
    const arr = getLeaf(instanceTree, parent);
    return arr;
}

function getLeaf(tree: any, parent: number) {
    let arr: number[] = [];
    if (tree.getChildCount(parent) !== 0) {
        tree.enumNodeChildren(
            parent,
            (children: number) => {
                const subArr = getLeaf(tree, children);
                arr = subArr.concat(arr);
            },
            false
        );
    } else {
        arr.push(parent);
    }
    return arr;
}


function indexToColor(colorIndexes: Array<any>, index: number) {
    const color = colorIndexes[index];
    return convertHex(color, 100);
}

function convertHex(hex: string, opacity: number) {
    if (/^#[0-9A-F]{6}$/i.test(hex)) {
        hex = hex.replace('#', '');
        const r: number = parseInt(hex.substring(0, 2), 16);
        const g: number = parseInt(hex.substring(2, 4), 16);
        const b: number = parseInt(hex.substring(4, 6), 16);

        const result = new Three.Vector4(r / 255, g / 255, b / 255, opacity / 100);
        return result;
    }
    return new Three.Vector4(1, 1, 1, 1);
}
