import { Button, ButtonProps, Icon, IconProps } from '@mui/material';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type CTAProps = Omit<ButtonProps, 'variant'> & {
    variant?: 'outlined' | 'filled',
    disableIcon?: boolean,
    icon?: string | {
        name: string,
        type: 'fa' | 'fas',
    },
    iconProps?: IconProps,
};
export function CTA({
    variant = 'outlined',
    disableIcon = false,
    iconProps = {},
    icon,
    sx,
    children,
    href,
    ...props
}: CTAProps) {
    const Wrapper = href ? Link : React.Fragment;
    const wrapperProps: LinkProps = {
        to: href as string,
    };

    const { sx: iconSx, ...iconRest } = iconProps;
    return (
        <Wrapper {...(href ? wrapperProps : {}) as any} >
            <Button {...props} sx={{ ...btnStyles['common'], ...btnStyles[variant], ...sx }} >
                {
                    !icon || disableIcon
                        ? null
                        : (
                            <Icon
                                fontSize='small'
                                className={`${typeof icon == 'object' ? icon.type : 'fas'} fa-${typeof icon == 'object' ? icon.name : icon}`}
                                sx={{ ...iconStyles['common'], ...iconStyles[variant], ...iconSx }}
                                {...iconRest}
                            />
                        )
                }
                {children}
            </Button>
        </Wrapper>
    );
}

const btnStyles = {
    common: {
        boxShadow: '0px 3px 8px -2px rgba(0, 0, 0, 0.25)',
        border: '2px solid #643c5a',
        padding: '4px 8px',
    },
    outlined: {
        color: '#643c5a',
    },
    filled: {
        color: 'white',
        backgroundColor: '#643c5a',
        '&:hover': {
            backgroundColor: '#643c5add',
        },
        '&:disabled': {
            color: '#FFFFFFAA',
            backgroundColor: '#643c5aAA',
            border: '2px solid #643c5a00',
        },
    },
};

const iconStyles = {
    common: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px',
        width: 'auto',
    },
    outlined: {
        color: '#643c5a',
    },
    filled: {
        color: 'white',
    },
};
