import React, { useState } from "react";
import i18n from 'i18next';

export interface LanguageContextInterface {
    lng: string;
    setLng: (lng: string) => void;
}

const LanguageContext = React.createContext<LanguageContextInterface>({
    lng: '',
    setLng: (newLng: string) => null,
});

function LanguageProvider(props: any) {
    const initialState = {
        lng: '',
        setLng: async (newLng: string) => {
            i18n.changeLanguage(newLng);
            setState((prevState: LanguageContextInterface) => {
                return { ...prevState, lng: newLng }
            })
        },

    }
    const [state, setState] = useState<LanguageContextInterface>(initialState);

    return (
        <LanguageContext.Provider value={state} >
            {props.children}
        </LanguageContext.Provider>
    )
}

export { LanguageContext, LanguageProvider }
