import { createGenerateClassName, makeStyles, StylesProvider } from '@material-ui/core';
import MaterialTable, { MaterialTableProps } from 'material-table';
import React from 'react';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        "& .MuiPaper-root": {
            height: '100%',
            width: '100%',
            backgroundColor: (props: any) => props.transparent ? 'transparent' : null,

            '&> div:nth-of-type(2)': {
                height: (props: any) => props.options?.grouping ? 'unset' : `calc(100% - ${props.height}px)`,
                overflow: 'hidden',
                '& div': {
                    height: '100%',
                    overflowX: 'hidden',
                }
            },

            '&> div:nth-of-type(3)': {
                height: (props: any) => props.options?.grouping ? `calc(100% - ${props.height}px)` : 'unset',
                overflow: 'hidden',
                '& div': {
                    height: '100%',
                    overflowX: 'hidden',
                },
            },
            '& td': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
        },
        "& .mt-MuiPaper-root": {
            height: '100%',
            width: '100%',
            backgroundColor: (props: any) => props.transparent ? 'transparent' : null,
            '&> div:nth-of-type(2)': {
                height: (props: any) => props.options?.grouping ? 'unset' : `calc(100% - ${props.height}px)`,
                overflow: 'hidden',
                '& div': {
                    height: '100%',
                    overflowX: 'hidden',
                }
            },
            '&> div:nth-of-type(3)': {
                height: (props: any) => props.options?.grouping ? `calc(100% - ${props.height}px)` : 'unset',
                overflow: 'hidden',
                '& div': {
                    height: '100%',
                    overflowX: 'hidden',
                },
            },
        },
        "& div[class*='MTableToolbar-title']": {
            width: (props: any) => `calc(100% - ${67 + 50 * props.buttonCount}px)`
        },
        "& div[class*='mt-MTableToolbar-title']": {
            width: (props: any) => `calc(100% - ${67 + 50 * props.buttonCount}px)`
        },
        "& tfoot": {
            "& td": {
                borderBottom: 'unset'
            },
        },
    },
});

interface StyledMaterialTableProps<RowData extends object> extends MaterialTableProps<RowData> {
    height?: number
    transparent?: boolean,
    buttonCount?: number
}

const generateClassName = createGenerateClassName({
    productionPrefix: 'mt',
    seed: 'mt'
});

export default function StyledMaterialTable<RowData extends object>(args: StyledMaterialTableProps<RowData>) {
    const classes = useStyles(args);
    return (
        <div className={classes.root}>
            <StylesProvider generateClassName={generateClassName}>
                <MaterialTable {...args} />
            </StylesProvider>
        </div>
    )
}