import { FormControlLabel, Switch, withStyles } from "@material-ui/core"
import React from "react"

const switchStyle = {
    root: {
        padding: 7,
        width: 50,
    },
    thumb: {
        width: 16,
        height: 16,
    },
    switchBase: {
        color: " #d9e1e9 !important",
        padding: 7,
        '&$checked': {
            color: "#d9e1e9",
        },
        '&$checked + $track': {
            backgroundColor: "#d9e1e9 !important",
        },
    },
    colorSecondary: {
        color: "#fff !important", //Color of slider
        "&$checked": {
            color: "#1dc6f2 !important", //Color of slider when checked
            "&:hover": {
                backgroundColor: "rgba(30,30,30, 0.04)",
            }
        }
    },
    track: {
        height: 3,
        transform: 'translateY(6px)',
        backgroundColor: "#d9e1e9 !important", //Color of the base line
        opacity: "1 !important",
    },
    checked: {
        "& + $track": {
            opacity: "1 !important",
        }
    },
}


const StyledWithSwitch = withStyles(switchStyle)(Switch);
const StyledFormControlLabel = withStyles({
    root: {
        marginLeft: 'unset',
    },
    label: {
        marginLeft: 0,
        marginTop: 2
    }
})(FormControlLabel);

export const StyledSwitch = (props: any) => (
    <StyledFormControlLabel
        control={
            <StyledWithSwitch checked={props.checked} onChange={props.onChange} name={props.name} />
        }
        label={props.label} />
)