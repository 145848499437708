import React, { useState, useEffect } from "react";

export default function EditableProp(props: { guid: string, value: string, onCommitValue: (newValue: string) => void }) {
    const [editable, setEditable] = useState(false);
    const [editedValue, setEditedValue] = useState("");

    useEffect(() => {
        setEditedValue(props.value)
    }, [props.value]);

    const onValueChange = (e: any) => {
        setEditedValue(e.target.value)
    };

    if (editable) {
        return (
            <input style={{ width: '80%', height: '80%', backgroundColor: 'transparent', color: 'white' }} autoFocus value={editedValue} onChange={onValueChange} onBlur={() => {
                props.onCommitValue(editedValue)
                setEditable(false)
            }}></input>
        )
    }
    else {
        return (
            <div style={{ width: '80%', height: '80%' }} id={props.guid} onClick={() => {
                setEditable(true);
            }}>
                {editedValue}
            </div >
        )
    }
}
