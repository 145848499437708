import { Button, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import PropertyByLibrary from 'src/components/asset/property/porperty-by-library.component';
import { APIServiceManager } from 'src/services';

// interface PropertyModalFormProps {
// 	show: boolean;
// 	onSubmit: (propertiesByPset: Array<any>) => void;
// 	onHide: () => void;
// 	existingPropertyIds: Array<string>;
// }

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
    }
});

export default function PropertyByLibraryPage() {

    const classes = useStyles();
    const params = useParams<{ id: string }>();
    const allLibs = [{ name: 'All', _id: '' }];
    const [disabledIds, setDisabledIds] = useState<Array<any>>([]);
    const [libraries, setLibraries] = useState<Array<any>>([]);
    const selectedProps = useRef<Array<any>>([]);
    const [canSubmit, setCanSubmit] = useState(false);

    const history = useNavigate();
    const location = useLocation();
    const state = location.state as any;
    const { t } = useTranslation(['pablo']);

    useEffect(() => {

        if (state && state.disabledIds) {
            setDisabledIds(state.disabledIds);
        }

    }, [state]);

    useEffect(() => {
        const fetchPropLibs = async () => {
            const propLibSvc = APIServiceManager.PropLibraryService;
            const libs = await propLibSvc.getLibraries(true);
            const libObj = libs.reduce((acc: any, curr: any) => {
                acc[curr._id] = curr.name;
                return acc;
            }, {});

            setLibraries(allLibs.concat(libs));
        };

        fetchPropLibs();

    }, []);


    const onNewPropSubmit = useCallback(async (properties: Array<any>) => {
        if (params.id) {
            const proms = properties.map((propId: any) => {

                return APIServiceManager.StructurePropertyService.addPropertyToStructure(params.id!, propId);
            });
            await Promise.all(proms);
        }
    }, [params]);

    const handleSelectionChange = useCallback((rows: Array<any>) => {
        selectedProps.current = rows;
        setCanSubmit(selectedProps.current.length > 0);
    }, []);

    const goToStructurePage = () => {
        history('/structure', {
            state: {
                selectedStructure: params.id
            }
        });
    };
    return (
        <div className={classes.root}>
            <PropertyByLibrary
                libraries={libraries}
                onSelectionChange={handleSelectionChange}
                disabledIds={disabledIds}
            />

            <DialogActions>
                <Button onClick={() => {
                    goToStructurePage();
                }}>
                    {t('pablo:button.cancel')}
                </Button>
                <Button disabled={!canSubmit} onClick={async () => {
                    //props.onSubmit(Array.from(selectedProps.current))
                    await onNewPropSubmit(Array.from(selectedProps.current));
                    selectedProps.current = [];
                    goToStructurePage();
                }}>
                    {t('pablo:button.submit')}
                </Button>

            </DialogActions>
        </div >
    );
}
