import { API, APIServiceManager } from '../..';

class PropertyService {
    api: API = APIServiceManager.API;
    name() {
        return 'PropertyService';
    }

    async getAllProperties(): Promise<[]> {
        try {
            const req = await this.api.axios.get('/properties');
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getProperties(query: any,
        page?: number,
        limit?: number,
        search?: string,
        orderBy?: string,
        orderDirection?: string,
        filters?: string,
        exclude?: string
    ): Promise<{ count: number, data: Array<any>, status: number }> {
        try {
            const req = await this.api.axios.get('/properties', {
                params: { page, limit, search, orderBy, orderDirection, filters, exclude, ...query }
            });
            return req.data;
        } catch (error) {
            console.log(error);
            return {
                count: 0,
                data: [],
                status: 500,
            }
        }
    }
    async getWherePropertyIsUsed(id: string, projectId?: string): Promise<[any]> {
        try {
            const req = await this.api.axios.get(`/properties/${id}/propstructures/${projectId}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateProperty(id: string, property: any, projectId?: string): Promise<any> {
        try {
            const req = await this.api.axios.put(`/properties/${id}`, property, {
                params: { projectId }
            });
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    async addProperty(property: any, projectId?: string): Promise<any> {
        try {
            const req = await this.api.axios.post('/properties', [property], {
                params: { projectId }
            });
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async addProperties(properties: Array<any>): Promise<any> {
        try {
            const req = await this.api.axios.post('/properties', properties);
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    async removeProperty(id: any, projectId?: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/properties/${id}`, {
                params: { projectId }
            });
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async removeProperties(ids: Array<string>, projectId?: string): Promise<any> {
        try {
            const req = await this.api.axios.delete('/properties', {
                params: { projectId },
                data: ids
            });
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export { PropertyService };
