import { Chip, ChipProps } from '@mui/material';
import { DataGridPro, GridColumns, GridValueGetterParams } from '@mui/x-data-grid-pro';
import React from 'react';

export type TasksTableProps = {
  
};
export function TasksTable(props: TasksTableProps) {

    return (
        <DataGridPro
            columns={columns}
            rows={dummyData}
        />
    )
}

type Task = {
    name: string,
    description: string,
    from: string,
    to: string,
    status: 'status-1' | 'status-2' | 'status-3',
    dueDateUTC: number,
    id: number,
};

const statusConvert: Record<Task['status'], { label: string, color: ChipProps['color'] }> = {
    'status-1': {
        label: 'Status 1',
        color: 'success',
    },
    'status-2': {
        label: 'Status 2',
        color: 'warning',
    },
    'status-3': {
        label: 'Status 3',
        color: 'error',
    },
};

const columns: GridColumns = [
    { headerName: 'Name', field: 'name' },
    { headerName: 'Task', field: 'description', flex: 1 },
    { headerName: 'Task from', field: 'from' },
    { headerName: 'Assigned to', field: 'to' },
    {
        headerName: 'Status',
        field: 'status',
        renderCell: (params) => (
            <Chip
                label={statusConvert[params.row.status as Task['status']].label}
                color={statusConvert[params.row.status as Task['status']].color}
            />
        ),
    },
    {
        headerName: 'Due date',
        field: 'dueDateUTC',
        valueGetter: (params: GridValueGetterParams) => new Date(params.row.dueDateUTC).toISOString().slice(0, 10),
    },
];

const dummyData: Task[] = [
    {
        name: 'Project 1',
        description: 'Task description 0123456789',
        from: 'T.FERRARA',
        to: 'F.TAI',
        status: 'status-2',
        dueDateUTC: Date.UTC(2021, 6, 21),
        id: 0,
    },
    {
        name: 'Project 1',
        description: 'Task description 0123456789',
        from: 'T.FERRARA',
        to: 'F.TAI',
        status: 'status-3',
        dueDateUTC: Date.UTC(2021, 6, 21),
        id: 1,
    },
    {
        name: 'Project 1',
        description: 'Task description 0123456789',
        from: 'T.FERRARA',
        to: 'F.TAI',
        status: 'status-1',
        dueDateUTC: Date.UTC(2021, 6, 21),
        id: 2,
    },
    {
        name: 'Project 1',
        description: 'Task description 0123456789',
        from: 'T.FERRARA',
        to: 'F.TAI',
        status: 'status-2',
        dueDateUTC: Date.UTC(2021, 6, 21),
        id: 3,
    },
    {
        name: 'Project 1',
        description: 'Task description 0123456789',
        from: 'T.FERRARA',
        to: 'F.TAI',
        status: 'status-2',
        dueDateUTC: Date.UTC(2021, 6, 21),
        id: 4,
    },
    {
        name: 'Project 1',
        description: 'Task description 0123456789',
        from: 'T.FERRARA',
        to: 'F.TAI',
        status: 'status-1',
        dueDateUTC: Date.UTC(2021, 6, 21),
        id: 5,
    },
];
