import { APIServiceManager } from 'src/services';

type SelectionIds = {
    id: string;
    extId?: string;
    ifcId?: string;
}

export enum ModelSource {
    Forge = 'forge',
    ITwin = 'itwin'
}

type ModelInfo = {
    source: ModelSource
    //Identifier could be a urn for Forge or a ModelId for Itwin
    identifier?: string;
}

class PainterService {
    name() {
        return 'PainterService';
    }

    async paintSelection(
        selectionIds: Array<SelectionIds>,
        assetId: string,
        model: ModelInfo,
        relationType: string
    ) {
        const links = selectionIds.map((selectionid: SelectionIds) => {
            return {
                assetId: assetId,
                objectId: selectionid.id,
                ifcId: selectionid.ifcId,
                extId: selectionid.extId,
                model: model,
                type: relationType
            };
        });
        return await APIServiceManager.LinkService.createLink(links)
    }

    async unPaintSelection(
        selectionIds: Array<SelectionIds>,
        model: ModelInfo,
    ) {
        const links = selectionIds.map((selectionid: SelectionIds) => {
            return {
                objectId: selectionid.id,
                extId: selectionid.extId,
                model: model,
            };
        });
        return await APIServiceManager.LinkService.removeLink(links)
    }

    async getLinkCountOnModel(modelInfo: ModelInfo): Promise<number> {
        const count = await APIServiceManager.LinkService.getLinkCountForQuery({
            'model.source': modelInfo.source,
            'model.identifier': modelInfo.identifier
        });
        return count;
    }

    async getLinksOnModel(modelInfo: ModelInfo): Promise<any> {
        const links = await APIServiceManager.LinkService.getLinksForQuery({
            limit: 0,
            'model.source': modelInfo.source,
            'model.identifier': modelInfo.identifier,
            exclude: 'projectId;asset;project;createdAt;updatedAt'
        });
        return links.data;
    }

    async getLinksOnModelForId(modelInfo: ModelInfo, objectId: string): Promise<any> {
        const links = await APIServiceManager.LinkService.getLinksForQuery({
            limit: 0,
            objectId,
            'model.source': modelInfo.source,
            'model.identifier': modelInfo.identifier,
            exclude: 'projectId;project;createdAt;updatedAt'
        });
        return links.data;
    }

    async getLinksOnModelForIds(modelInfo: ModelInfo, ids: Array<string>, includeProp: boolean): Promise<any> {
        const links = await APIServiceManager.LinkService.getLinksForQuery({
            limit: 0,
            includeProp,
            'model.source': modelInfo.source,
            'model.identifier': modelInfo.identifier,
            exclude: 'projectId;project;createdAt;updatedAt',
            idList: ids
        });
        return links.data;
    }

    async getAssetsOnModelForLinkIds(modelInfo: ModelInfo, ids: Array<string>, includeProp: boolean): Promise<any> {
        const links = await APIServiceManager.LinkService.getLinksForQuery({
            limit: -1,
            includeProp,
            group: true,
            'model.source': modelInfo.source,
            'model.identifier': modelInfo.identifier,
            exclude: 'projectId;project;createdAt;updatedAt',
            idList: ids
        });
        return links.data;
    }


    async getLinksOnModelByAsset(modelInfo: ModelInfo): Promise<any> {
        const links = await APIServiceManager.LinkService.getLinksForQuery({
            limit: 0,
            group: true,
            'model.source': modelInfo.source,
            'model.identifier': modelInfo.identifier,
            exclude: 'projectId;project;createdAt;updatedAt'
        });
        return links.data;
    }
}

export { PainterService };