import { TreeData, cloneNode } from 'editable-tree';
import { APIServiceManager } from 'src/services';

function dbTreeToTreeView(tree: any): TreeData {
    const parseTree = (node: any): TreeData => {
        return node.loading ? {
            id: node.id.toString(),
            label: 'loading',
            loading: true
        } : {
            id: node._id.toString(),
            label: `${node.code} - ${node.name}`,
            order: node.order,
            extendedData: {
                code: node.code,
                name: node.name,
                inherit: node.inherit,
                extendedCodes: node.extendedCodes,
                fullCode: node.fullCode,
                deletable: node.deletable,
            },
            loading: node.loading,
            children: node.children && node.children.length > 0 ? node.children.map((c: any) => parseTree(c)) : [],
        }
    }
    const newTree = parseTree(tree);
    return newTree;
}

class StructureQueryService {
    name() {
        return 'StructureQueryService';
    }

    getFullCode(fullCodeUnformated: string, separator: string = '-', showNodeRootCode: boolean = false): string {
        const indexOfSpace = fullCodeUnformated.indexOf(' ');
        const fullCodesToUse = showNodeRootCode && indexOfSpace > 0 ? fullCodeUnformated : fullCodeUnformated.substring(indexOfSpace + 1);
        return fullCodesToUse.replaceAll(' ', separator)
    }

    getLabel(
        node: TreeData,
        level: number = 0,
        separator: string = '-',
        showNodeFullCode: boolean = false,
        showNodeLevel: boolean = false,
        showNodeRootCode: boolean = false,
    ): string {

        const code: string = node.extendedData && node.extendedData.code ? node.extendedData.code : '';
        const name: string = node.extendedData && node.extendedData.name ? node.extendedData.name : '';
        const fullCodes: string = node.extendedData && node.extendedData.fullCode ? node.extendedData.fullCode : '';
        const fullCodesToUse = showNodeRootCode && level > 0 ? fullCodes : fullCodes.substring(fullCodes.indexOf(' ') + 1);

        const codeWithSeparator = fullCodesToUse.replaceAll(' ', separator);
        const extendedCodes = node.extendedData && node.extendedData.extendedCodes && node.extendedData.extendedCodes.length > 0 ? node.extendedData.extendedCodes : [];

        const extendedCodesDisplay = extendedCodes.map((e: any) => e.value).join(' / ')
        const nodeLabelFromData =
            node.extendedData ?
                extendedCodes.length > 0 ?
                    showNodeFullCode ?
                        `${codeWithSeparator} : ${name} / ${extendedCodesDisplay}` :
                        `${code} : ${name} / ${extendedCodesDisplay}`
                    :
                    showNodeFullCode ?
                        `${codeWithSeparator} : ${name}` :
                        `${code} : ${name}`
                :
                `${node.label}`
            ;
        return showNodeLevel ?
            `N${level} - ${nodeLabelFromData}` :
            `${nodeLabelFromData}`;
    }

    printTree(node: TreeData, prefix: string) {
        if (node && node.children) {
            for (const child of node.children) {
                this.printTree(child, `${prefix}    `)
            }
        }
    }

    childCodeIsUnique(parentNode: TreeData, child: TreeData) {
        if (parentNode && parentNode.children) {
            for (const someChild of parentNode.children) {
                if (child.id.toString() !== someChild.id.toString() &&
                    child.extendedData.code === someChild.extendedData.code) {
                    return false
                }
            }
        }
        return true
    }

    async getStructureTree(fullCode: boolean, maxLevel?: number): Promise<TreeData> {
        try {
            const str = await APIServiceManager.StructureService.getStructureTree(fullCode, maxLevel);
            const strData = dbTreeToTreeView(cloneNode(str, true));
            return strData;
        } catch (error) {
            return {
                id: '',
                label: ''
            }
        }
    }
}

export { StructureQueryService };