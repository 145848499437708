import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import ExpandIconOnlyTreeItem from './tree-item.component';

interface RenderTree {
    _id: string;
    name: string;
    code: string;
    editable: boolean;
    inherit: boolean;
    root: boolean;
    parentId: string;
    children?: RenderTree[];
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

function ModalNode(props: any) {
    const { t } = useTranslation(['pablo']);

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [inherit, setInherit] = useState(true);

    useEffect(() => {
        setCode(props.info.code);
        setName(props.info.name);
        setInherit(props.info.inherit);
    }, [props.info]);

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth='sm' >
            <CloseDialogTitle onClose={props.onClose} title={t('pablo.dialog.title.editStructure')} />
            <DialogContent>
                <form
                    onSubmit={(event: any) => {
                        event.preventDefault();
                        props.onClose();
                        props.onSubmit(event, props.info.id, code, name, props.info.type, inherit);
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Code"
                                variant="outlined"
                                value={code}
                                onChange={(e: any) => setCode(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={(e: any) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={inherit}
                                        onChange={(event: any, checked: boolean) => {
                                            setInherit(checked);
                                        }}
                                    />
                                }
                                label="Inherit properties"
                            />
                        </Grid>
                        <Grid style={{ marginBottom: 8 }} container justifyContent="flex-end">
                            <Button type="submit">
                                {t('pablo:button.submit')}
                            </Button>
                        </Grid>
                    </Grid>

                </form>

            </DialogContent>
        </Dialog >
    );
}
export default function Tree(props: any) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({ id: '', code: '', name: '', type: 'new', inherit: 'true' });
    const openModal = (modalInfo: any) => {
        setModalInfo(modalInfo);
        setOpen(true);
    };

    const renderTree = (nodes: RenderTree) => (
        <ExpandIconOnlyTreeItem
            selectionCanChanged={props.selectionCanChanged}
            key={nodes._id}
            nodeId={nodes._id}
            code={nodes.code}
            name={nodes.name}
            inherit={nodes.inherit}
            parentId={nodes.parentId}
            onClick={props.onClick}
            onAdd={(id: string, name: string, code: string) => {
                openModal({ id, code, name, type: 'new', inherit: true });
            }}
            onEdit={(id: string, name: string, code: string, inherit: boolean) => {
                openModal({ id, code, name, type: 'edit', inherit });
            }}
            onMoveUp={props.onMoveUp}
            onRemove={props.onRemove}
            canAdd={props.editable}
            canEdit={props.editable && nodes.editable}
            canRemove={props.editable && nodes.editable}
            root={nodes.root}
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </ExpandIconOnlyTreeItem>
    );


    return (
        <div>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={[props.data._id]}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {renderTree(props.data)}
            </TreeView>
            <ModalNode
                open={open}
                onClose={() => setOpen(false)}
                info={modalInfo}
                onSubmit={(event: any, id: string, code: string, name: string, type: string, inherit: boolean) => {
                    switch (type) {
                        case 'new':
                            props.onAdd(id, code, name, inherit);
                            break;
                        case 'edit':
                            props.onEdit(id, code, name, inherit);
                            break;
                        default:
                            break;
                    }
                }} />
        </div>

    );
}