import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

interface DialogTitleProps {
    title: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const CloseDialogTitle = (props: DialogTitleProps) => {
    const { title, children, onClose, ...other } = props;
    return (
        <Box sx={{
            margin: 0,
            padding: 2,
        }} {...other}>
            <Typography variant="h6">{title}</Typography>
            {children}
            {onClose ? (
                <IconButton aria-label="close" sx={{
                    position: 'absolute',
                    right: 1,
                    top: 1,
                }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </Box>
    );
};

export default CloseDialogTitle;