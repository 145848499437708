import React, { useContext, useState, useEffect } from 'react';
import './forge-models.component.css';

import { GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star'
import { ProjectContext } from 'src/contexts/project.context';
import { LoadingComponent } from 'src/components';
import { useTranslation } from 'react-i18next';
interface ForgeModelsProps {
	getData: (query: any, withThrumbnail: boolean) => Promise<{ data: any, totalCount: number, page: number }>;
	onClick: (name: string, urn: string) => void;
}

interface IForgeModelsState {
	data: any;
	page: number;
	pageSize: number;
	totalPages: number;
}

function FavIconButton(props: any) {
	const [isFav, setIsFav] = useState(false);

	useEffect(() => {
		const test = localStorage.getItem(`model-is-fav-${props.urn}`);
		if (test === 'true') {
			setIsFav(true);
		}
	}, [props.urn]);


	useEffect(() => {
		localStorage.setItem(`model-is-fav-${props.urn}`, `${isFav}`)
	}, [isFav]);

	return (
		<IconButton
			onClick={(e: any) => {
				e.preventDefault();
				e.stopPropagation();
				setIsFav(!isFav)
			}} >
			{
				isFav ? <StarIcon className='fav-icon' /> : <StarBorderIcon className='non-fav-icon' />
			}

		</IconButton>
	)
}

function ForgeModels(props: ForgeModelsProps) {
	const initialState = {
		data: [],
		page: 0,
		pageSize: 5,
		totalPages: 1,
	};

	const { t, i18n } = useTranslation(['pablo']);
	const projectContext = useContext(ProjectContext);
	const fetchState = async (): Promise<IForgeModelsState> => {
		const queryResult = await props.getData({ page: 0, pageSize: 10 }, true);

		return {
			totalPages: queryResult.totalCount / 10,
			data: queryResult.data,
			page: 0,
			pageSize: 10,
		}
	}

	return (
		<LoadingComponent
			initialState={initialState} updateContext={projectContext} fetchState={fetchState}>
			{(value: IForgeModelsState) => (
				<GridList spacing={5} cols={4} cellHeight={200} className="model-grid">
					{
						value && value.data && value.data.length > 0 ?
							value.data.map((tile: any, index: number) => {
								return (
									<GridListTile key={index} cols={1} className="grid-tile"
										onClick={() => {
											props.onClick(tile.name, tile.urn);
										}}>
										<img src={`data:image/jpeg;base64,${tile.thumbnail}`} alt={tile.name} />
										<GridListTileBar
											title={tile.name}
											actionIcon={
												<FavIconButton urn={tile.urn} />
											}
										/>
									</GridListTile>
								)
							}) :
							<p>{t('pablo:components.forge.noModuleFounds')}</p>
					}
				</GridList>
			)}
		</LoadingComponent>
	)
}
export default ForgeModels;
