import React, { useState, useEffect, useCallback, useContext } from 'react';
import './permissions.page.css';
import { useParams } from 'react-router-dom';

import { APIServiceManager, PermissionService, UserService } from '../../../services';
import { PermissionList } from 'src/components/project/permission-list/permission-list.component';
import { UserContext } from 'src/contexts/user.context';

function PermissionView() {
    const _userService: UserService = APIServiceManager.getService('UserService') as UserService;
    const _permissionService: PermissionService = APIServiceManager.getService('PermissionService') as PermissionService;

    const userContext = useContext(UserContext)
    const [users, setUsers] = useState<Array<any>>([]);

    const params = useParams();

    const [projectPermission, setProjectPermission] = useState<any>({
        projectId: '',
        permissions: [],
        project: {
            code: '',
            name: ''
        }
    });

    const [hasEditorRole, setHasEditorRole] = useState(false);
    const [hasAdminRole, setHasAdminRole] = useState(false);

    const loadUsers = async () => {
        const tempUsers = await _userService.getUsers();
        setUsers(tempUsers)
    };

    const loadData = useCallback(async () => {
        const pId = (params as any).id;
        const perms = await _permissionService.getPermissions(undefined, pId, false, undefined, true);
        const myPerms = (await _permissionService.getPermissions(userContext.user.id, pId))[0];

        setHasEditorRole(myPerms.permissions.includes('user-manager'))
        setProjectPermission(perms[0]);
    }, [params, _permissionService, userContext.user.id]);

    useEffect(() => {
        loadData();
        loadUsers();
    }, [params, loadData]);

    useEffect(() => {
        setHasAdminRole(userContext.user.access_right === 'admin')
    }, [userContext]);

    const onAddPermission = async (user: any) => {
        const pId = (params as any).id;
        await _permissionService.addPermission({
            userId: user._id,
            projectId: pId,
            permissions: []
        });

        await loadData();
    };

    const onUpdatePermission = async (id: string, newPermissions: string[]) => {
        await _permissionService.updatePermission(id, newPermissions);
        await loadData();
    };

    const deletePermissions = async (oldData: Array<any>) => {
        for (const p of oldData) {
            await _permissionService.removePermission(p._id);
        }

        await loadData();
    }
    return (
        <PermissionList
            editable={hasAdminRole || hasEditorRole}
            users={users}
            userPermissions={projectPermission.permissions}
            onAddPermission={onAddPermission}
            onUpdatePermission={onUpdatePermission}

            onDeletePermissions={deletePermissions}>
        </PermissionList>
    );
}

export default PermissionView;
