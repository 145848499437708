import React, { useEffect, useRef } from "react"

export function useIsMountedRef() {
    const isMountedRef = useRef<boolean>(false);
    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
    });
    return isMountedRef;
}

export function usePromise<T>(callback: () => Promise<T>, defaultValue: T, update: any) {
    const isMountedRef = useIsMountedRef();

    type PromiseT = {
        value: T;
        error: string | null;
        isPending: boolean;
    }

    const [state, setState] = React.useState<PromiseT>({ value: defaultValue, error: null, isPending: true })

    React.useEffect(() => {

        callback.call(null).then(
            (value: T) => {
                if (isMountedRef.current) {
                    setState({ value, error: null, isPending: false })
                }

            }
        ).catch((error: any) => {
            if (isMountedRef.current) {
                setState({ value: defaultValue, error: error, isPending: false })
            }


        });
    }, [update])

    const { value, error, isPending } = state
    return [value, error, isPending]
}
