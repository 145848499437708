import { CircularProgress, Icon, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";

export function useSnackBars() {
    const { t } = useTranslation(['pablo']);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const success = useCallback(function successSnackBar(i18nId?: string, i18nOpt?: { [key: string]: string },
        options?: Parameters<typeof enqueueSnackbar>[1]) {
        enqueueSnackbar(t('pablo:snackbars.' + (i18nId ?? 'success'), i18nOpt), {
            variant: 'success',
            autoHideDuration: 2500,
            action: CloseBtn(closeSnackbar),
            ...options,
        });
        setLoading(false);
    }, [enqueueSnackbar]);

    const error = useCallback(function errorSnackBar(i18nId?: string, i18nOpt?: { [key: string]: string },
        options?: Parameters<typeof enqueueSnackbar>[1]) {
        enqueueSnackbar(t('pablo:snackbars.' + (i18nId ?? 'error'), i18nOpt), {
            variant: 'error',
            autoHideDuration: 3500,
            action: CloseBtn(closeSnackbar),
            ...options,
        });
        setLoading(false);
    }, [enqueueSnackbar]);

    const load = useCallback(function launchLoad(promise?: Promise<unknown> | null,
        successArgs?: Parameters<typeof success>,
        errorArgs?: Parameters<typeof error>) {
        setLoading(true);
        if (!promise) return;
        promise
            .then(() => successArgs ? success(...successArgs) : success())
            .catch(() => errorArgs ? error(...errorArgs) : error());
    }, []);

    return {
        enqueueSnackbar,
        closeSnackbar,
        success,
        error,
        load,
        Loader: loading ? Loader : NullLoader,
    };
}

function NullLoader() {
    return (
        null
    )
}

function Loader() {
    return (
        <CircularProgress color="inherit" />
    )
}

function CloseBtn(close: (key: string) => void) {
    return (key: string) => (
        <IconButton onClick={() => close(key)} sx={{ color: 'inherit' }} >
            <Icon
                fontSize='small'
                className='fas fa-times'
            />
        </IconButton>
    )
}
