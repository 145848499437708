import '@fortawesome/fontawesome-free/css/all.min.css';
import { SnackbarProvider } from 'notistack';
import React, { FunctionComponent, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import AlertHandler from './alert';
import { LanguageProvider } from './contexts/lng.context';
import { ProjectProvider } from './contexts/project.context';
import { UserProvider } from './contexts/user.context';
import ErrorHandler from './error';
import './i18n/config';
import PabloRoutes from './routes';
import { API, APIServiceManager } from './services';

const SnackbarProviderUntyped = SnackbarProvider as any;

const App: FunctionComponent = () => {
    const api: API = APIServiceManager.API;
    return (
        <LanguageProvider>
            <CookiesProvider>
                <ErrorHandler axios={api.axios}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <UserProvider>
                            <ProjectProvider>
                                <SnackbarProviderUntyped maxSnack={3} >
                                    <BrowserRouter >
                                        <div className='app'>
                                            <PabloRoutes />
                                        </div>
                                    </BrowserRouter>
                                </SnackbarProviderUntyped>
                            </ProjectProvider>
                        </UserProvider>
                    </Suspense>
                </ErrorHandler>
                <AlertHandler />
            </CookiesProvider>
        </LanguageProvider>
    );
};

export default App;
