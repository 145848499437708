import React, { useState } from "react";

export interface UserContextInterface {
    user: {
        id: string,
        payload: any,
        token: string,
        access_right: string,
        [key: string]: any,
    };
    setUser: (user: any) => void;
}

interface UserInfo {
    id: string,
    payload: any,
    token: string,
    access_right: string,
    name?: string,
    email?: string
}

const UserContext = React.createContext<UserContextInterface>({
    user: {
        id: '',
        payload: {},
        token: '',
        access_right: 'user'
    },
    setUser: (user: UserInfo) => null,
});

function UserProvider(props: any) {

    const initialState = {
        user: {
            id: '',
            payload: {},
            token: '',
            access_right: 'user',
        },
        setUser: (newUser: UserInfo) => {

            if (newUser && newUser.payload) {
                const tempUser = newUser.payload as { email: string; name: string };
                newUser.name = tempUser.name;
                newUser.email = tempUser.email;
            }

            setState((prevState: UserContextInterface) => {
                return { ...prevState, user: newUser }
            })
        },

    }
    const [state, setState] = useState<UserContextInterface>(initialState);

    return (
        <UserContext.Provider value={state} >
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }
