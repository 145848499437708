import { API, APIServiceManager } from '../..';

class ReportService {
	api: API = APIServiceManager.API;

	name() {
	    return 'ReportService';
	}


	async getReports(projectId: string, last: boolean): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/reports', {
	            params: {
	                projectId, last
	            }
	        });
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

}

export { ReportService };
