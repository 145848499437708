type severity = 'success' | 'warning' | 'error';

class AlertEvent {

    _events: any = {};
    on(name: string, listener: object) {
        if (!this._events[name]) {
            this._events[name] = [];
        }
        this._events[name].push(listener);
    }

    removeListener(name: string, listenerToRemove: object) {
        if (!this._events[name]) {
            throw new Error(`Can't remove a listener. Event "${name}" doesn't exits.`);
        }

        const filterListeners = (listener: object) => listener !== listenerToRemove;

        this._events[name] = this._events[name].filter(filterListeners);
    }

    emitAlert(message: string, severity: severity = 'success') {
        if (this._events['alert']) {
            const fireCallbacks = (callback: any) => {
                callback({
                    message, severity, open: true
                });
            };

            this._events['alert'].forEach(fireCallbacks);
        }
    }
}

export const AlertEmitter = new AlertEvent();