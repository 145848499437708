import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, TextField } from '@mui/material';
import { useOnNextFrame, useStillMounted } from 'dev4bim-react-utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlignmentFeature, Asset, useMapPageContext } from 'src/contexts/MapPageContext';
import { APIServiceManager } from 'src/services';
import { useSnackBars } from 'src/utils/hooks/useSnackBars';
import { Tab } from '../../Tabs/Tab';
import { Tabs } from '../../Tabs/Tabs';
import { LineLocation } from './LineLocation';
import { LineSegment } from './LineSegment';
import { PointLocation } from './PointLocation';
import { undyingTabLocation } from './utils/undyingTabLocation';

export type InputType = {
    modifier: 'gps' | 'pk',
    destination: 'point' | 'line' | 'segment',
};

export type TabFunc = {
    close: () => void,
    onSubmit: () => boolean,
    isValid: () => boolean,
    startMapInteraction: (success: () => void, cancel: () => void) => void,
};

export type EditLocDialogProps = {
    open: boolean,
    onClose: () => void,
};
export function EditLocDialog({ open, onClose }: EditLocDialogProps) {
    const { t } = useTranslation(['pablo']);
    const { load, Loader } = useSnackBars();
    const [mapInteraction, setMapInteraction] = useState(false);
    const {
        assets,
        selectedAsset,
        setSelectedAsset,
        refreshAssets,
        alignmentsFeatures,
        selectedAlignmentFeature,
        setSelectedAlignmentFeature,
    } = useMapPageContext();
    const [inputType, setInputType] = useState<InputType>({ modifier: 'gps', destination: 'point' });
    const [func, setFunc] = useState<TabFunc>({
        close: () => { }, Styles: () => { },
    });
    const [refreshContent, setRefreshContent] = useState(false);
    const shouldAutocomplete = useRef(false);
    shouldAutocomplete.current = open && !selectedAsset ? true : shouldAutocomplete.current;
    const { stillMountedCb } = useStillMounted();

    useEffect(function updateTabReopenOnlyWhenNotMapInteracting() {
        if (!open || !selectedAsset || mapInteraction) return;
        setRefreshContent(!refreshContent);
        setInputType({
            modifier: inputType.modifier,
            destination: selectedAsset.geojson?.geometry.type == 'LineString' ? 'line' : 'point',
        });
        const geoloc = selectedAsset.geolocation;
        if (!geoloc) return;
        setInputType({
            modifier: geoloc.type == 'point-loc' || geoloc.type == 'range-loc' ? 'gps' : 'pk',
            destination: geoloc.type == 'segment' ? 'segment'
                : geoloc.type == 'range-loc' || geoloc.type == 'range-pk' ? 'line' : 'point',
        });
        if (geoloc.type == 'point-pk' || geoloc.type == 'range-pk' || geoloc.type == 'segment') {
            const feature = alignmentsFeatures.features.find((f) =>
                f.properties.parentName == geoloc.alignment.name
                && f.properties.name == geoloc.alignment.lineName);
            setSelectedAlignmentFeature(feature ?? null);
        }
    }, [selectedAsset, open]);


    const close = useCallback(function globalClose() {
        func.close();
        shouldAutocomplete.current = false;
        setMapInteraction(false);
        setInputType({
            modifier: inputType.modifier,
            destination: 'point',
        });
        onClose();
    }, [onClose, func]);

    const onSubmit = useCallback(function globalSubmit() {
        if (!selectedAsset) return;
        if (!func.onSubmit()) return;
        load(APIServiceManager.AssetService.updateAssetGeolocation(selectedAsset)
            .then(stillMountedCb(() => {
                refreshAssets();
                close();
            })));
    }, [close, refreshAssets, func]);


    const globalTabContext = undyingTabLocation(selectedAsset, inputType, refreshContent, mapInteraction, refreshAssets);
    useEffect(() => setFunc(globalTabContext.func), [globalTabContext.func]);

    const onNextFrame = useOnNextFrame();

    return (
        <Dialog
            open={mapInteraction ? false : open}
            sx={styles.container}
            onClose={close}
        >
            <DialogTitle>{t('pablo:dialog.editLocation.title')}{selectedAsset ? `: ${selectedAsset.name}` : ''}</DialogTitle>

            <DialogContent
                sx={styles.contentContainer}
                style={{ padding: 16 }}
                onKeyDown={function submit(ev) {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        onSubmit();
                    }
                }}
            >

                {
                    !shouldAutocomplete.current
                        ? null
                        : (
                            <Autocomplete
                                disablePortal
                                id="choose-asset"
                                options={assets}
                                getOptionLabel={getAssetName}
                                sx={{ width: 300, overflow: 'visible' }}
                                renderInput={autoCompleteInputAssets}
                                value={selectedAsset}
                                onChange={function setAsset(event: any, newValue: Asset | null) {
                                    setSelectedAsset(newValue);
                                }}
                            />
                        )
                }

                <Tabs
                    value={inputType.destination == 'point' ? 0 : inputType.destination == 'line' ? 1 : 2}
                    onChange={function setAppropriateInputType(idx) {
                        setInputType({
                            modifier: idx == 2 ? 'pk' : inputType.modifier,
                            destination: idx == 0 ? 'point' : idx == 1 ? 'line' : 'segment',
                        });
                    }}
                >
                    {
                        inputType.destination == 'segment'
                            ? null
                            : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                    }}
                                >
                                    GPS
                                    <Switch
                                        checked={inputType.modifier == 'pk'}
                                        onChange={(e) => setInputType({
                                            modifier: e.target.checked ? 'pk' : 'gps',
                                            destination: inputType.destination,
                                        })}
                                    />
                                    PK
                                </Box>
                            )
                    }
                    {
                        inputType.modifier == 'gps'
                            ? null
                            : (
                                <Autocomplete
                                    disablePortal
                                    id="choose-alignment"
                                    options={alignmentsFeatures.features}
                                    getOptionLabel={getAlignmentFeatureName}
                                    sx={{ width: 300, overflow: 'visible', mt: 2 }}
                                    renderInput={autoCompleteInputAlignment}
                                    value={selectedAlignmentFeature}
                                    onChange={function setAsset(event: any, newValue: typeof selectedAlignmentFeature) {
                                        setSelectedAlignmentFeature(newValue);
                                    }}
                                />
                            )
                    }
                    <Tab
                        label={t('pablo:dialog.editLocation.point')}
                        component={(
                            <PointLocation
                                asset={selectedAsset}
                                inputType={inputType}
                                {...globalTabContext}
                            />
                        )}
                        sx={styles.tabContainer}
                    />
                    <Tab
                        label={t('pablo:dialog.editLocation.line')}
                        component={(
                            <LineLocation
                                asset={selectedAsset}
                                inputType={inputType}
                                {...globalTabContext}
                            />
                        )}
                        sx={styles.tabContainer}
                    />
                    <Tab
                        label={t('pablo:dialog.editLocation.lineSegment')}
                        component={(
                            <LineSegment
                                asset={selectedAsset}
                                inputType={inputType}
                                {...globalTabContext}
                            />
                        )}
                        sx={styles.tabContainer}
                    />
                </Tabs>
            </DialogContent>

            <DialogActions>
                <Loader />
                <Button
                    disabled={!selectedAsset}
                    onClick={function startMapInteraction() {
                        setMapInteraction(true);
                        if (selectedAsset && !selectedAsset.visible) {
                            selectedAsset.visible = true;
                            refreshAssets();
                        }
                        func.startMapInteraction(function globalStopMapInteraction() {
                            setSelectedAsset(selectedAsset);
                            onNextFrame(() => setMapInteraction(false));
                        }, close);
                    }}
                >
                    {t('pablo:button.map')}
                </Button>
                <Button onClick={close}>{t('pablo:button.cancel')}</Button>
                <Button
                    disabled={!selectedAsset || !func.isValid()}
                    onClick={onSubmit}
                >
                    {t('pablo:button.save')}
                </Button>
            </DialogActions>

        </Dialog>
    );
}

function getAssetName(asset: Asset) { return asset.name; }
function autoCompleteInputAssets(params: any) {
    return <TextField {...params} label='Assets' />;
}
function autoCompleteInputAlignment(params: any) {
    const { t } = useTranslation(['pablo']);
    return <TextField {...params} label={t('pablo:dialog.editLocation.alignment')} />;
}
function getAlignmentFeatureName(alignment: AlignmentFeature) {
    return !alignment ? '' : `${alignment.properties.name} - ${alignment.properties.parentName}`;
}

const styles = {
    container: {
        '& .MuiDialog-paper': {
            overflow: 'visible',
        },
    },
    contentContainer: {
        p: 2,
    },
    tabContainer: {
        pt: 0,
    },
    inputContainer: {
        mt: 2,
        display: 'flex',
        flexDirection: 'row',
    },
    lineAddBtn: {
        width: '100%',
        height: 42,
        mt: 3,
        borderRadius: '7px',
        boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.25)',
        ':hover': {
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.25)',
            background: 'white',
        },
        ':disabled': {
            boxShadow: '0px 0px 10px -4px rgba(0, 0, 0, 0.25)',
            background: 'rgba(0, 0, 0, 0.07)'
        }
    },
};
