import { ObjectId } from 'bson'
import { IMongoObject } from '../mongo-object'

export interface IPabloProjectOptions {
    modelEnable: boolean,
    structureEnable: boolean,
    partDatabaseEnable: boolean,
    assetDatabaseEnable: boolean,
    objectDatabaseEnable: boolean
}

export enum Source {
    itwin = 'itwin',
    forge = 'forge',
}

export interface IPabloProject extends IMongoObject {
    name: string;
    code: string;
    type: string;
    description: string;
    status?: string;
    imgId?: ObjectId;
    image?: string;
    bucket?: string;
    options?: IPabloProjectOptions;
    source?: Source;
    itwinId?: string;
}

export interface IPabloProjectImage extends IMongoObject {
    projectId?: ObjectId;
    project?: IPabloProject;
    data: string;
    mimeType: string
}

export interface IPabloProjectObject extends IMongoObject {
    projectId?: ObjectId;
    project?: IPabloProject
}

export interface IPabloSharedProjectObject extends IPabloProjectObject {
    isProject: boolean;
}

export interface IRequestParamProjectId {
    projectId: ObjectId
}

export interface IPabloProjectQuery extends IPabloProject {
    includeImage: boolean;
}

export interface IPabloLimitToProject {
    limitToProject: boolean
}