import React from 'react';

import { Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import produce, { Draft } from 'immer';
import { useTranslation } from 'react-i18next';
import { SpinnerText } from 'src/components';
import { IProject } from 'src/core';
import { IPermission } from 'src/core/interface/permission';
import { useImmer } from 'use-immer';

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        '& ul': {
            width: 'unset'
        }
    },
    form: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(2),
        textAlign: 'left',

    },
    text: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        padding: 16
    }
}));

interface NewProjectProps {
    onSubmit: (data: NewProjectState) => Promise<any>;
}

interface NewProjectState {
    project: IProject;
    permissions: IPermission[];
    imageFile: any;
    loading: boolean;
    hasError: boolean;
    message: string | undefined;
    errorMessage: string | undefined;
}

const initialState = {
    project: {
        name: '',
        code: '',
        bucket: '',
        description: '',
        type: '',
        source: 'forge',
        permissions: []
    },
    permissions: [],
    imageFile: {},
    loading: false,
    hasError: false,
    message: undefined,
    errorMessage: undefined
};


function NewProject(props: NewProjectProps) {
    const { t } = useTranslation(['pablo']);
    const classes = useStyles();

    const [state, setState] = useImmer<NewProjectState>(initialState);

    const dispatch = (prop: string, value: any) => {
        setState(
            produce((draft: Draft<NewProjectState>) => {
                switch (prop) {
                    case 'code':
                        draft.project.code = value;
                        break;
                    case 'name':
                        draft.project.name = value;
                        break;
                    case 'type':
                        draft.project.type = value;
                        break;
                    case 'source':
                        draft.project.source = value;
                        break;
                    case 'description':
                        draft.project.description = value;
                        break;
                    case 'file':
                        draft.imageFile = value;
                        break;
                    case 'loading':
                        draft.loading = value;
                        break;
                    case 'hasError':
                        draft.hasError = value;
                        break;
                    case 'message':
                        draft.message = value;
                        break;
                    case 'errorMessage':
                        draft.errorMessage = value;
                        break;
                    default:
                        break;
                }

            })
        );
    };

    const submitForm = async (e: any) => {

        dispatch('loading', true);
        e.preventDefault();
        const res = await props.onSubmit(state);

        dispatch('loading', false);

        if (res === true) {
            dispatch('message', t('pablo:components.project.projectCreated'));

        } else {
            dispatch('hasError', true);
            dispatch('message', t('pablo:components.project.projectNotCreated'));
            if (res.statusCode === 500 && res.message.startsWith('E11000 duplicate key error')) {
                dispatch('errorMessage', t('pablo:components.project.errorMessage'));
            }


        }
    };

    return (
        <div className={classes.root}>
            <form style={{ opacity: state.loading ? '0.5' : 'unset' }}
                className="profileForm"
                onSubmit={submitForm}>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Code</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        < TextField
                            className={classes.text}
                            required
                            variant='standard'
                            fullWidth
                            value={state.project.code}
                            onChange={(e: any) => {
                                dispatch('code', e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Name</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        < TextField
                            className={classes.text}
                            required
                            variant='standard'
                            fullWidth
                            value={state.project.name}
                            onChange={(e: any) => {
                                dispatch('name', e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Type</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl variant='standard' className={classes.formControl} fullWidth>
                            <Select
                                placeholder="Choose project type..."
                                value={state.project.type}
                                onChange={(e: any) => {
                                    dispatch('type', e.target.value);
                                }}
                            >
                                <MenuItem value="Rail">Rail</MenuItem>
                                <MenuItem value="Metro">Metro</MenuItem>
                                <MenuItem value="HighSpeedLine">HighSpeedLine</MenuItem>

                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>


                <Grid container >
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Description</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        < TextField
                            className={classes.text}
                            required
                            variant='standard'
                            fullWidth
                            multiline
                            rows={4}
                            value={state.project.description}
                            onChange={(e: any) => {
                                dispatch('description', e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Source</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl variant='standard' className={classes.formControl} fullWidth>
                            <Select
                                placeholder="Choose model source (Autodesk Forge or Bentley Itwin)..."
                                value={state.project.source}
                                onChange={(e: any) => {
                                    dispatch('source', e.target.value);
                                }}
                            >
                                <MenuItem value="forge">Autodesk® Forge</MenuItem>
                                <MenuItem value="itwin">Bentley® Itwin</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>


                <Grid container >
                    <Grid item xs={3}>
                        <Typography className={classes.text}>Image</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.button}>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                {t('pablo:button.selectFile')}
                                <input
                                    accept="image/*"
                                    type="file"
                                    hidden
                                    onChange={(e: any) => {
                                        dispatch('file', e.target.files[0]);
                                    }}
                                />
                            </Button>
                        </div>

                    </Grid>
                </Grid>
                <Grid style={{ marginBottom: 8 }} container justifyContent="flex-end">
                    <Button type="submit" variant="contained">
                        {t('pablo:button.submit')}
                    </Button>
                </Grid>


            </form>

            {state.message ? <h4>{state.message}</h4> : null}
            {state.errorMessage ? <p>{state.errorMessage}</p> : null}
            {
                state.loading ?
                    <div style={{ marginTop: '50px', left: '50%', marginLeft: '-4em', position: 'absolute' }}>
                        <SpinnerText text="Loading"></SpinnerText>
                    </div> : null
            }
        </div >
    );
}
export default NewProject;
