import { APIServiceManager } from 'src/services';

class PropertyQueryService {
    name() {
        return 'PropertyQueryService';
    }

    importPropertyFile(file: any, libraryId: string): Promise<Array<any>> {

        return new Promise((resolve: any, reject: any) => {
            var reader = new FileReader();

            reader.onload = async (fe: ProgressEvent<FileReader>) => {
                if (fe.target) {
                    const xlsx = await import('xlsx');
                    const workbook = xlsx.read(fe.target.result, {
                        type: 'binary',
                    });


                    if (!workbook.SheetNames.includes('Properties')) {
                        resolve([])
                        return;
                    }

                    const ws: any = workbook.Sheets['Properties'];
                    var range = xlsx.utils.decode_range(ws['!ref']);
                    range.s.r = 3;

                    const rows: Array<any> = xlsx.utils.sheet_to_json(ws, { range });
                    const proms = rows.map((row: any) => {
                        try {
                            const isTechProperty: boolean = Boolean(row.Technical_Property.substring(0, 1));
                            const category: string = row.Category && row.Category.length > 4 ? row.Category.substring(4) : null;
                            const unit: string = row.Unit && row.Unit.length > 4 ? row.Unit.substring(4) : null;
                            const format: string = row.Format.substring(4);
                            const type: string = row.Type.substring(4);
                            const newProp = {
                                libraryId: libraryId,
                                key: row.Code.replaceAll('*', '-'),
                                name: row.Name.replaceAll('*', '-'),
                                description: row.Description.replaceAll('*', '-'),
                                isTechProperty,
                                category,
                                unit,
                                format,
                                type,
                                possibleValues: []
                                // possibleValues: row.Possible_Values ? row.Possible_Values.split(';') : []
                            };

                            if (workbook.SheetNames.includes(row.Code)) {
                                const pValueSheet: any = workbook.Sheets[row.Code];
                                var pSheetRange = xlsx.utils.decode_range(pValueSheet['!ref']);
                                pSheetRange.s.r = 3;

                                const pValueRows: any = xlsx.utils.sheet_to_json(pValueSheet, { range: pSheetRange });
                                newProp.possibleValues = pValueRows.map((r: any) => {
                                    return { value: r.Value, description: r.Description }
                                });
                            }

                            return APIServiceManager.PropertyService.addProperty(newProp);
                        } catch (error) {

                        }

                    });

                    const newProperties = (await Promise.all(proms)).flat(2);
                    const pSetProms = [];
                    const psets = await APIServiceManager.PropSetService.getSets(null);
                    const propertiesByPset = rows.reduce((acc: any, row: any, index: number) => {
                        if (row.Pset) {
                            if (!acc.hasOwnProperty(row.Pset)) {
                                acc[row.Pset] = [index];
                            } else {
                                acc[row.Pset].push(index);
                            }
                        }
                        return acc;
                    }, {})
                    for (const psetName in propertiesByPset) {
                        const psetIndex = psets.findIndex((p: any) => p.name === psetName);
                        if (psetIndex > -1) {
                            const psetId = psets[psetIndex]._id;
                            const propertyIndexes = propertiesByPset[psetName];
                            const propertyIds = propertyIndexes.map((index: number) => newProperties[index].data[0]._id);
                            pSetProms.push(APIServiceManager.PropSetService.addMultiplePropInSet(psetId, propertyIds));
                        }
                    }

                    await Promise.all(pSetProms);

                    resolve(newProperties);
                }
            };
            reader.readAsBinaryString(file);
        })

    }

}

export { PropertyQueryService };