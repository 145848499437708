import { Divider, Icon, ListItem, ListItemIcon, ListItemText, MenuItem, Popover, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useContext } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { ProjectContext } from 'src/contexts/project.context';

const useStyles = makeStyles((theme: any) => ({
    disconnect: {
        marginTop: 'auto'
    },
    menuRoute: {
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`
        }
    },
    activeRoute: {
        backgroundColor: `${theme.palette.primary.dark} !important`
    },
    linkDisabled: {
        opacity: 0.5,
        cursor: 'not-allowed'
    },
    typography: {
        padding: theme.spacing(2),
    },
    popoverMenu: {
        padding: theme.spacing(1),
        minWidth: 200
    }
}));

export default function LinkMenuItem(props: {
    path: string,
    title: string,
    position: string,
    icon: string,
    subRoutes: any[] | undefined,
    onClick: (event: any) => void,
    pathMatches: RegExp[] | undefined,
}) {

    const location = useLocation();
    const isActive = location.pathname === props.path
        || (
            props.subRoutes
            && props.subRoutes.findIndex((sub) => (props.path + sub.path) === location.pathname) != -1
        ) || (
            props.pathMatches
            && props.pathMatches.findIndex((val) => val.test(location.pathname)) != -1
        );
    const classes = useStyles();
    const projectContext = useContext(ProjectContext);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    if (Array.isArray(props.subRoutes)) {

        const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        };

        return (
            <div
                className={clsx({
                    [classes.disconnect]: props.title === 'disconnect' || props.position === 'bottom',
                    [classes.linkDisabled]: props.title !== 'Home' && props.position !== 'bottom' &&
                        projectContext != null &&
                        projectContext.currentProject != undefined &&
                        (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined)
                })}
            >
                <ListItem
                    button
                    onClick={handlePopoverOpen}
                    className={clsx(classes.menuRoute, {
                        [classes.activeRoute]: isActive
                    })}
                >
                    <ListItemIcon>
                        <Tooltip title={
                            props.title !== 'Home' && props.position !== 'bottom' &&
                                projectContext != null &&
                                projectContext.currentProject != undefined &&
                                (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined) ?
                                'Please set an active project' :
                                props.title
                        }>
                            <Icon

                                fontSize="small"
                                className={clsx(props.icon + ' fa-fw', {
                                    white: isActive,
                                    grey: !isActive,
                                })}

                            />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={`${props.title}`} />
                </ListItem>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popoverMenu}
                        onMouseLeave={handlePopoverClose}
                    >
                        {props.subRoutes.map((subRoute: any, index: number) => {
                            return <Link
                                key={`subR-${props.title}-${index}`}
                                replace={false}
                                to={{
                                    pathname: `${props.path}${subRoute.path}`,
                                }}
                                onClick={props.onClick}
                                className={clsx({
                                    [classes.disconnect]: subRoute.title === 'disconnect' || subRoute.position === 'bottom',
                                    [classes.linkDisabled]: subRoute.title !== 'Home' && subRoute.position !== 'bottom' &&
                                        projectContext != null &&
                                        projectContext.currentProject != undefined &&
                                        (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined)
                                })}
                            >
                                <MenuItem onClick={handlePopoverClose}>{subRoute.title}</MenuItem>
                                {subRoute.divider ? <Divider variant="fullWidth" /> : null}

                            </Link>;
                        }
                        )}
                    </div>
                </Popover>
            </div>
        );

    } else {
        return (
            <Link
                to={props.path}
                key={props.title}
                onClick={props.onClick}
                className={clsx({
                    [classes.disconnect]: props.title === 'disconnect' || props.position === 'bottom',
                    [classes.linkDisabled]: props.title !== 'Home' && props.position !== 'bottom' &&
                        projectContext != null &&
                        projectContext.currentProject != undefined &&
                        (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined)
                })}
            >

                <ListItem
                    button
                    className={clsx(classes.menuRoute, {
                        [classes.activeRoute]: isActive
                    })}
                >
                    <ListItemIcon>
                        <Tooltip title={
                            props.title !== 'Home' && props.position !== 'bottom' &&
                                projectContext != null &&
                                projectContext.currentProject != undefined &&
                                (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined) ?
                                'Please set an active project' :
                                props.title
                        }>
                            <Icon
                                fontSize="small"
                                className={clsx(props.icon + ' fa-fw', {
                                    white: isActive,
                                    grey: !isActive,
                                })}
                            />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={`${props.title}`} />


                </ListItem>
            </Link>
        );
    }

}