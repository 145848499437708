import { IProject } from 'src/core';
import { API, APIServiceManager } from '../..';

class ProjectService {
    api: API = APIServiceManager.API;

    name() {
        return 'ProjectService';
    }

    async getProjects(): Promise<IProject[]> {
        try {
            const req = await this.api.axios.get('/projects');
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getProject(_id: string, code?: string, img?: boolean, limit?: number, startAt?: string, beginsWith?: string): Promise<any> {
        try {
            const req = await this.api.axios.get('/projects', {
                params: {
                    _id, code, img, limit, startAt, beginsWith
                }
            });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async add(code: string, name: string, type: string, source: string, description?: string, bucket?: string): Promise<any> {
        try {
            const req = await this.api.axios.post('/projects', {
                code, name, type, bucket, source, description
            });
            return req.data.data
        } catch (error) {
            throw JSON.parse((error as any).message);
        }
    }

    async putImage(projectId: string, file: any): Promise<any> {
        try {
            const formData = new FormData();
            formData.append('img', file)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const req = await this.api.axios.put(`/projects/${projectId}/img`, formData, config);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removeProject(id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/projects/${id}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateProject(id: string, newData: any): Promise<any> {
        try {
            const req = await this.api.axios.put(`/projects/${id}`, newData);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async deleteProject(id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/projects/${id}`);
            return req.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


}

export { ProjectService };
