import { API, APIServiceManager } from '../..';
import { IAssetProp } from 'src/core';

class StructurePropertyService {
    api: API = APIServiceManager.API;
    name() {
        return 'StructurePropertyService';
    }

    async getPropertiesOf(id: string, withAncestor: boolean = true): Promise<any> {
        try {
            const req = await this.api.axios.get(`/project/structure/${id}/properties?ancestor=${withAncestor}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addPropertyToStructure(structureId: string, propertyId: string): Promise<any> {
        try {
            const req = await this.api.axios.put(`/project/structure/${structureId}/properties/${propertyId}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removePropertyToStrcture(structureId: string, propertyId: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/project/structure/${structureId}/properties/${propertyId}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async stopInheritanceOfPropertyForStructure(structureId: string, propertyId: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/project/structure/${structureId}/properties/${propertyId}/stopinheritance`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

}

export { StructurePropertyService };
