import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridCellEditCommitParams, GridCellParams, gridClasses, GridColumns } from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { statusIsFrozen } from "src/core/business/asset.business";
import { APIServiceManager } from "src/services";


const StyleDataGrid = styled(DataGridPro)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    height: '50vh',
    overflow: 'auto',
    [`& .${gridClasses.columnHeadersInner}`]: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
    },
    [`& .${gridClasses.columnHeaders}`]: {
        backgroundColor: theme.palette.grey[400],
    },
}));

export function AssetProperties({ properties, id, status }: { properties: Array<any>, id: string, status: string }) {
    const { t, i18n } = useTranslation(['pablo']);
    const classes = useStyles();
    const [rows, setRows] = useState<Array<any>>([]);

    const handleCellEditCommit = useCallback(async (params: GridCellEditCommitParams, event: any) => {
        try {
            event.persist();
            await APIServiceManager.AssetService.updateAssetProperty(id, params.id, params.value);
            setRows((prev: Array<any>) =>
                prev.map((row: any) => (row.id === params.id ? { ...row, value: params.value } : row)),
            );
        } catch (error) {
            setRows((prev: Array<any>) => [...prev]);
        }

    }, []);

    const isCellEditable = useCallback((params: GridCellParams) => {
        return !statusIsFrozen(status);
    }, [status]);

    useEffect(() => {
        setRows(properties)
    }, [properties]);

    const columns = useMemo<GridColumns>(() => {
        return [
            { field: 'key', headerName: t('pablo:table.headers.code'), editable: false, flex: 0.2 },
            { field: 'name', headerName: t('pablo:table.headers.name'), editable: false, flex: 0.4 },
            { field: 'value', headerName: t('pablo:table.headers.value'), editable: true, flex: 0.4 },
        ]
    }, [i18n.language]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>{t('pablo:components.asset.single.detailedPropertiesTitle')}</h3>
            </Grid>
            <Grid item xs={12}>
                <StyleDataGrid
                    classes={{
                        "cell--editable": classes.cellEdit,
                    }}
                    hideFooter
                    disableColumnResize
                    rows={rows}
                    rowHeight={32}
                    columns={columns}
                    onCellEditCommit={handleCellEditCommit}
                    isCellEditable={isCellEditable}
                />
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles({
    cellEdit: {
        border: '1px solid',
        borderRadius: '4px',
    },
});
