import { AppBar, Drawer, Icon, IconButton, List, ListItem, Toolbar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LanguageContext } from 'src/contexts/lng.context';
import { ProjectContext } from 'src/contexts/project.context';
import LinkMenuItem from './link-menu-item.component';


const drawerWidth = 240;
const useStyles = makeStyles((theme: any) => {
    console.log(theme);

    return ({
        root: {
            display: 'flex'
        },
        main: {
            height: '100%',
            width: '100%'
        },
        appBar: {
            paddingLeft: '65px',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        appBarBtn: {
            width: '40px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#643c5a',
        },
        appBarBtnItem: {
            width: 'fit-content !important',
            padding: '8px !important',
            marginLeft: '8px !important',
            marginRight: '8px !important',
            borderRadius: '5px !important',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 !important',
            minHeight: '50px !important',
        },
        menuButton: {
            marginLeft: '1px'
        },
        hide: {
            display: 'none'
        },
        drawerParent: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawer: {
            backgroundColor: `${theme.palette.tertiary.main} !important`,
            transition: '0.15s !important',
            color: 'white !important',
            '& .MuiListItem-gutters': {
                padding: '0 !important'
            },
            '& .MuiListItem-root': {
                padding: '5px 0 !important',
            },
            '& .MuiIcon-fontSizeSmall': {
                fontSize: '1em !important',
                color: 'white'
            },
            '& .MuiListItemIcon-root': {
                minWidth: '35px !important',
                marginLeft: 15,
            },
            '& .MuiIcon-root': {
                width: 'auto'
            },
        },
        drawerIcon: {
            margin: '0 !important',
            width: '49px',
            height: '50px',
        },
        drawerIconSeparator: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
            height: '50px',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        drawerOpen: {
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: 'hidden',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1
            },
            width: '50px !important'
        },
        drawerContent: {

        },
        drawerFooter: {
            position: 'absolute',
            bottom: 0,
            width: '100%'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        routeName: {
            marginLeft: '20px'
        },
        overlay: {
            position: 'absolute',
            top: '50px',
            left: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            height: 'calc(100% - 50px)',
            width: '100%',
            zIndex: theme.zIndex.drawer,
        },
        navList: {
            height: 'calc(100% - 20px)',
            width: '100%',
            padding: '16px 0 !important',
            display: 'flex',
            flexDirection: 'column'
        },
        flex: {
            display: 'flex',
            alignItems: 'center'
        },
        languagesContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '15px'
        },
        language: {
            padding: '0 10px',
            borderRight: `1px solid ${theme.palette.info.main}`,
            cursor: 'pointer',
            '&:last-child': {
                borderRight: '0'
            }
        },
        disabled: {
            color: theme.palette.info.main
        },
    });
});

export default function Header(props: { routes: Array<any>, handleDisconnect: () => void }) {
    const languageContext = useContext(LanguageContext);
    const projectContext = useContext(ProjectContext);

    const { i18n } = useTranslation();

    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const [lng, setLng] = React.useState<string>('fr');

    useEffect(() => {
        setLng(i18n.language.substr(0, 2));
    }, [i18n.language]);

    function handleDrawerChange() {
        setOpen(!open);
    }

    function handleChangeLanguage(lng: string) {
        setLng(lng);
        languageContext.setLng(lng);
    }

    function handleChangeRoute(route: any, event: any) {

        if (route.title !== 'Home' && route.position !== 'bottom' &&
            (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined)) {
            event.preventDefault();
        }
        setOpen(false);
    }

    return (
        <div>
            <AppBar
                position="fixed"
                color="inherit"
                className={classes.appBar}
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.flex}>
                        <div className={classes.routeName}>Pablo</div>
                    </div>

                    <div className={classes.languagesContainer}>
                        <div
                            className={clsx(classes.language, {
                                [classes.disabled]: lng !== 'fr'
                            })}
                            onClick={() => { handleChangeLanguage('fr'); }}
                        >FR</div>
                        <div
                            style={{ borderRight: 0 }}
                            className={clsx(classes.language, {
                                [classes.disabled]: lng !== 'en'
                            })}
                            onClick={() => { handleChangeLanguage('en'); }}
                        >EN</div>
                        {props.routes.filter(r => r.visible).filter(route => route.position === 'navbar').map((route: any) =>
                            <Link
                                to={route.path}
                                key={route.title}
                                onClick={route.path === '/disconnect' ? props.handleDisconnect : (e: any) => handleChangeRoute(route, e)}
                                className={classes.appBarBtn}
                            >
                                <ListItem
                                    button
                                    className={classes.appBarBtnItem}
                                >
                                    <Tooltip title={
                                        route.title !== 'Home' && route.position !== 'bottom' &&
                                            projectContext != null &&
                                            projectContext.currentProject != undefined &&
                                            (projectContext.currentProject._id === null || projectContext.currentProject._id === undefined) ?
                                            'Please set an active project' :
                                            route.title
                                    }>
                                        <Icon
                                            fontSize="small"
                                            className={route.icon + ' fa-fw'}
                                        />
                                    </Tooltip>
                                </ListItem>
                            </Link>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <div
                className={clsx(classes.overlay, {
                    [classes.hide]: !open
                })}
                onClick={handleDrawerChange}
            />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawerParent, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
            >
                <div className={classes.drawerContent}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerChange}
                        edge="start"
                        className={classes.drawerIcon}
                    >
                        {!open ? <Icon fontSize="small" className="fa fa-bars" /> : <Icon fontSize="small" className="fas fa-chevron-left close-button" />}
                    </IconButton>
                    <div
                        className={classes.drawerIconSeparator}
                    ></div>
                    <List className={classes.navList}>
                        {props.routes.filter(r => r.visible).filter(route => route.position !== 'bottom' && route.position !== 'navbar').map((route: any) =>
                            <LinkMenuItem key={`submenulink-${route.title}`}
                                {...route} onClick={route.path === '/disconnect' ? props.handleDisconnect : (e: any) => handleChangeRoute(route, e)} />
                        )}
                    </List>
                </div>
                <div className={classes.drawerFooter}>
                    <List className={classes.navList}>
                        {props.routes.filter(r => r.visible).filter(route => route.position === 'bottom').map((route: any) =>
                            <LinkMenuItem key={`menulink-${route.title}`}
                                {...route} onClick={route.path === '/disconnect' ? props.handleDisconnect : (e: any) => handleChangeRoute(route, e)} />
                        )}
                    </List>
                </div>

            </Drawer>
        </div>

    );
}