import {
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Tooltip
} from '@material-ui/core';
import EditableTree, { TreeData } from 'editable-tree';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle, WarningDialog } from 'src/components';
import { FrontServiceManager } from 'src/services';

export default function SnapshotTreeModal(props: {
    open: boolean,
    onHide: any,
    treeData: { label: string, id: string, tree: any },
    onDeleteSnapshot: (id: string) => void;
}) {

    const { t } = useTranslation(['pablo']);

    const [warningDialogOpen, setWarningDialogOpen] = useState(false);

    const getAllIds = () => {
        const result = [props.treeData.tree.id];
        return result;
    };

    const deleteSnapshot = async () => {
        props.onHide();
        props.onDeleteSnapshot(props.treeData.id);
    };

    const labelRender = useCallback((node: TreeData): JSX.Element => {
        const nodeLabel = FrontServiceManager.StructureQueryService.getLabel(node);
        return (<span>{nodeLabel}</span>);
    }, []);

    return (
        <div>
            <WarningDialog open={warningDialogOpen}
                onContinue={() => {
                    setWarningDialogOpen(false);
                    deleteSnapshot();
                }}
                onCancel={() => {
                    setWarningDialogOpen(false);
                }}
                continueButtonLabel={t('pablo:dialog.structureSnapshot.deleteSnapshot').toString()}
                cancelButtonLabel={t('pablo:button.cancel').toString()}
                content={t('pablo:dialog.structureSnapshot.deleteSnapshotWarning').toString()}
            />
            <Dialog open={props.open}
                onClose={() => {
                    props.onHide();
                }}
                onClick={(e: any) => e.stopPropagation()}
                fullWidth={true} maxWidth='sm' >
                <CloseDialogTitle onClose={() => {
                    props.onHide();
                }} title={props.treeData.label} />
                <DialogContent>
                    <div style={{ height: '70vh', overflow: 'hidden' }}>
                        <EditableTree
                            data={props.treeData.tree}
                            showNodeLevel={false}
                            defaultExpanded={getAllIds()}
                            components={{
                                label: labelRender
                            }}
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Tooltip title={t('pablo:dialog.structureSnapshot.deleteSnapshot').toString()}>
                        <IconButton onClick={() => {
                            setWarningDialogOpen(true);
                        }}
                            className="fa fa-trash"
                        />
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </div>
    );
}
