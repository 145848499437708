import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SpinnerText } from 'src/components';
import { UserContext } from 'src/contexts/user.context';
import { APIServiceManager, AuthService } from 'src/services';

function RequireAuth({ children }: { children: JSX.Element }) {
    const userContext = useContext(UserContext);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const _authService: AuthService = APIServiceManager.AuthService;
                const isAuth = await _authService.isAuthenticated();

                if (isAuth) {
                    const tokenInfo = _authService.verifyIdToken();
                    const payload: any = tokenInfo['payload'];
                    const token: string = tokenInfo['token'];
                    let userDb = await APIServiceManager.UserService.getCurrentUser();
                    if (userDb) {
                        const userInfo = {
                            id: userDb.userId,
                            access_right: userDb.access_right,
                            token,
                            payload
                        };
                        userContext.setUser(userInfo);
                    } else {
                        userContext.setUser({ id: '', payload, token, access_right: 'visitor' });
                    }
                }
            } catch (error) {
                userContext.setUser({});
            }
        };
        fetchUser();
    }, [])

    let location = useLocation();
    const authService: AuthService = APIServiceManager.AuthService;
    const useQuery = (): URLSearchParams => new URLSearchParams(location.search)
    authService.update(useQuery());
    if (authService.isAuthenticated()) {
        if (authService.tokenExpiresInLessThan(10 * 60)) {
            // Silently and asynchronously refresh token
            // if it expires in less then 10 minutes
            authService.refreshIdToken()
        }
        return children;
    } else {
        window.location.replace(authService.authorizeUri());
        return <SpinnerText text="Connecting to authorization server" />;
    }
}


export default RequireAuth
