import { APIServiceManager } from 'src/services';

class AssetSnapshotService {
    name() {
        return 'AssetSnapshotService';
    }

    async createSnapshot(assetId: any, name: string): Promise<any> {
        const newSnapshot: any = (await APIServiceManager.AssetService.postSnapshot(assetId, name)).data;
        return newSnapshot;
    }

    formatAssetSummary(raw: any): any {
        return {
            name: raw.name,
            id: raw._id,
            createdAt: new Date(raw.createdAt),
        };
    }
}

export { AssetSnapshotService };