import React, { useState, useEffect, useCallback } from 'react';
import './adminpermissions.page.css';
import { useParams } from 'react-router-dom';

import { APIServiceManager, PermissionService, UserService } from '../../../services';
import { PermissionList } from 'src/components/project/permission-list/permission-list.component';

function AdminPermissionView() {
    const _userService: UserService = APIServiceManager.getService('UserService') as UserService;
    const _permissionService: PermissionService = APIServiceManager.getService('PermissionService') as PermissionService;

    const [users, setUsers] = useState<Array<any>>([]);
    const params = useParams();

    const [projectPermission, setProjectPermission] = useState<any>({
        projectId: '',
        permissions: [],
        project: {
            code: '',
            name: ''
        }
    });

    const loadUsers = async () => {
        const tempUsers = await _userService.getUsers();
        setUsers(tempUsers)
    };

    const loadData = useCallback(async () => {
        const pId = (params as any).id;
        const perms = await _permissionService.getPermissions(undefined, pId, false, undefined, true);

        if (Array.isArray(perms) && perms.length > 0) {
            setProjectPermission(perms[0]);
        }

    }, [params, _permissionService]);

    useEffect(() => {
        loadData();
        loadUsers();
    }, [params, loadData]);

    const onAddPermission = async (user: any) => {
        const pId = (params as any).id;
        await _permissionService.addPermission({
            userId: user._id,
            projectId: pId,
            permissions: []
        });

        await loadData();
    };

    const onUpdatePermission = async (id: string, newPermissions: string[]) => {
        await _permissionService.updatePermission(id, newPermissions);
        await loadData();
    };

    const deletePermission = async (oldData: any) => {
        await _permissionService.removePermission(oldData._id);
        await loadData();
    }
    return (
        <PermissionList
            editable={true}
            users={users}
            userPermissions={projectPermission.permissions}
            onAddPermission={onAddPermission}
            onUpdatePermission={onUpdatePermission}
            onDeletePermission={deletePermission}>
        </PermissionList>
    );
}

export default AdminPermissionView;
