import { AuthService } from './auth';
import { API } from './api/api';

import {
    AssetService,
    AlignmentService,
    StructureService,
    ExportService,
    ForgeService,
    LinkService,
    PermissionService,
    ProjectService,
    PropertyService,
    PropLibraryService,
    PropSetService,
    ReportService,
    StructurePropertyService,
    StructureSnapshotApiService,
    UnitsService,
    CategoriesService,
    UserService,
    TemplateService,
    ExtendedCodeService
} from './api';

import {
    AssetSnapshotService,
    AssetTableQueryService,
    StructureQueryService,
    StructureSnapshotService,
    PropertyQueryService,
    AlertEmitter,
    PainterService
} from './locale';
import { ITwinService } from './api/itwin/itwin.service';


class ServiceManagerClass {
    protected _services: any;

    constructor() {
        this._services = {};
    }

    registerService(service: any) {
        this._services[service.name()] = service;
    }

    getService(name: string) {
        if (this._services[name]) {
            return this._services[name];
        }

        return undefined;
    }
}

class APIServiceManagerClass extends ServiceManagerClass {
    registerAllService = () => {
        const authServ = new AuthService();
        this.registerService(authServ);

        const apiServ = new API();
        this.registerService(apiServ);

        const projectSrv = new ProjectService();
        const reportSrv = new ReportService();
        const userSrv = new UserService();
        const permSrv = new PermissionService();
        const forgeSrv = new ForgeService();
        const assetStrSrv = new StructureService();
        const snapshotSrv = new StructureSnapshotApiService();
        const strPropSrv = new StructurePropertyService();
        const assetListSrv = new AssetService();
        const alignmentListSrv = new AlignmentService();
        const linkSrv = new LinkService();
        const propSrv = new PropertyService();
        const propLibrarySrv = new PropLibraryService();
        const propSetSrv = new PropSetService();
        const unitsService = new UnitsService();
        const categoriesService = new CategoriesService();
        const exportService = new ExportService();
        const itwinService = new ITwinService();
        const templateService = new TemplateService();
        const extendedCodeService = new ExtendedCodeService();

        this.registerService(projectSrv);
        this.registerService(reportSrv);
        this.registerService(userSrv);
        this.registerService(permSrv);
        this.registerService(forgeSrv);
        this.registerService(assetStrSrv);
        this.registerService(strPropSrv);
        this.registerService(assetListSrv);
        this.registerService(alignmentListSrv);
        this.registerService(snapshotSrv);
        this.registerService(linkSrv);
        this.registerService(propSrv);
        this.registerService(propLibrarySrv);
        this.registerService(propSetSrv);
        this.registerService(unitsService);
        this.registerService(categoriesService);
        this.registerService(exportService);
        this.registerService(itwinService);
        this.registerService(templateService);
        this.registerService(extendedCodeService);
    }

    checkService = () => {
        return this._services.length === 18;
    }

    public get AuthService(): AuthService { return this.getService('AuthService'); }
    public get API(): API { return this.getService('API'); }

    public get ProjectService(): ProjectService { return this.getService('ProjectService'); }
    public get ReportService(): ReportService { return this.getService('ReportService'); }
    public get UserService(): UserService { return this.getService('UserService'); }
    public get PermissionService(): PermissionService { return this.getService('PermissionService'); }
    public get ForgeService(): ForgeService { return this.getService('ForgeService'); }


    public get AssetService(): AssetService { return this.getService('AssetService'); }
    public get AlignmentService(): AlignmentService { return this.getService('AlignmentService'); }
    public get StructureService(): StructureService { return this.getService('StructureService'); }
    public get StructureSnapshotApiService(): StructureSnapshotApiService { return this.getService('StructureSnapshotApiService'); }
    public get StructurePropertyService(): StructurePropertyService { return this.getService('StructurePropertyService'); }
    public get ExtendedCodeService(): ExtendedCodeService { return this.getService('ExtendedCodeService'); }

    public get LinkService(): LinkService { return this.getService('LinkService'); }
    public get PropertyService(): PropertyService { return this.getService('PropertyService'); }
    public get PropLibraryService(): PropLibraryService { return this.getService('PropLibraryService'); }
    public get PropSetService(): PropSetService { return this.getService('PropSetService'); }
    public get UnitsService(): UnitsService { return this.getService('UnitsService'); }
    public get CategoriesService(): CategoriesService { return this.getService('CategoriesService'); }
    public get ExportService(): ExportService { return this.getService('ExportService'); }
    public get ITwinService(): ITwinService { return this.getService('ITwinService'); }
    public get TemplateService(): TemplateService { return this.getService('TemplateService'); }
}

class FrontServiceManagerClass extends ServiceManagerClass {

    registerAllService = () => {
        const assetSnapshotService = new AssetSnapshotService();
        const assetTableQuerySrv = new AssetTableQueryService();
        const structureQuerySrv = new StructureQueryService();
        const structureSnapshotSrv = new StructureSnapshotService();
        const propertyQuerySrv = new PropertyQueryService();
        const painterSrv = new PainterService();

        this.registerService(assetSnapshotService);
        this.registerService(assetTableQuerySrv);
        this.registerService(structureQuerySrv);
        this.registerService(structureSnapshotSrv);
        this.registerService(propertyQuerySrv);
        this.registerService(painterSrv);
    }

    public get AssetSnapshotService(): AssetSnapshotService { return this.getService('AssetSnapshotService'); }
    public get AssetTableQueryService(): AssetTableQueryService { return this.getService('AssetTableQueryService'); }
    public get StructureQueryService(): StructureQueryService { return this.getService('StructureQueryService'); }
    public get StructureSnapshotService(): StructureSnapshotService { return this.getService('StructureSnapshotService'); }
    public get PropertyQueryService(): PropertyQueryService { return this.getService('PropertyQueryService'); }
    public get PainterService(): PainterService { return this.getService('PainterService'); }
    public get AlertEmitter() { return AlertEmitter }

    checkService = () => {
        return this._services.length === 3;
    }
}

var APIServiceManager = new APIServiceManagerClass();
var FrontServiceManager = new FrontServiceManagerClass();

export { APIServiceManager, FrontServiceManager };
