import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TextFieldProps } from '@mui/material';
import { useStillMounted } from 'dev4bim-react-utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPabloAlignment } from 'src/pablo-shared';
import { APIServiceManager } from 'src/services';
import { useSnackBars } from 'src/utils/hooks/useSnackBars';
import CloseDialogTitle from '../ui/modal-title-with-close-button.component';

export type NewAlignmentProps = {
    alignmentFeatures: undefined | GeoJSON.FeatureCollection<GeoJSON.LineString>,
    onClose: () => void,
    onSaved: (_: IPabloAlignment) => void,
};
export function NameAlignmentDialog({ alignmentFeatures, onClose, onSaved }: NewAlignmentProps) {
    const { t } = useTranslation(['pablo']);
    const { load, Loader } = useSnackBars();
    const [names, setNames] = useState({
        name: (alignmentFeatures as any)?.name ?? '',
        subNames: new Array(alignmentFeatures?.features.length).map((_, i) => (
            alignmentFeatures?.features[i].properties?.name ?? ''
        )),
    });
    const namesRef = useRef(names);
    namesRef.current = names;
    const alignmentRef = useRef(alignmentFeatures);
    alignmentRef.current = alignmentFeatures;
    const { stillMountedCb } = useStillMounted();

    useEffect(function setInitialNames() {
        if (!alignmentFeatures) return;
        setNames({
            name: (alignmentFeatures as any).name ?? '',
            subNames: alignmentFeatures.features.map((feature) => (
                feature.properties?.name ?? ''
            )),
        });
    }, [alignmentFeatures]);

    const onSubmit = useCallback(function submit() {
        if (!alignmentRef.current || !isValid(namesRef.current)) return;
        const alignment: IPabloAlignment = { name: namesRef.current.name, geojson: alignmentRef.current as any };
        alignment.geojson?.features.forEach((feature, i) => {
            feature.properties = feature.properties ?? {};
            feature.properties.name = namesRef.current.subNames[i];
        });
        delete (alignment.geojson as any)?.name;
        load(APIServiceManager.AlignmentService.addAlignment(alignment)
            .then(stillMountedCb((res) => {
                onSaved(res.data);
                onClose();
            })));
    }, [onSaved]);

    return (
        <Dialog
            open={!!alignmentFeatures}
            sx={styles.container}
            onClose={onClose}
            maxWidth='sm'
            fullWidth
        >
            <CloseDialogTitle title={t('pablo:dialog.newAlignment.title')} onClose={onClose} />
            <DialogContent
                dividers
                onKeyDown={function submit(ev) {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        onSubmit();
                    }
                }}
            >
                <Box sx={styles.textContainer} >
                    <Box sx={styles.subNameContainer} >
                        <DialogTitle sx={styles.titles} >{t('pablo:dialog.newAlignment.name')}:</DialogTitle>
                        <Input
                            id='Alignment-Name'
                            label={t('pablo:dialog.newAlignment.name')}
                            value={names.name}
                            setValue={(v) => setNames({ ...names, name: v })}
                        />
                    </Box>
                    <DialogTitle sx={styles.titles} >{t('pablo:dialog.newAlignment.lineNames')}:</DialogTitle>
                    <Box sx={styles.subNamesContainer} >
                        {
                            names.subNames.map(function subNameInput(name, idx) {
                                const from = alignmentFeatures?.features[idx].geometry.coordinates[0];
                                const to = alignmentFeatures?.features[idx].geometry.coordinates[alignmentFeatures?.features[idx].geometry.coordinates.length - 1];

                                if (!from || !to) return null;
                                return (
                                    <Box sx={styles.subNameContainer} key={idx} >
                                        <Input
                                            id='Sub-Alignment-Name'
                                            label={`${t('pablo:dialog.newAlignment.subName')} - ${idx}`}
                                            value={name}
                                            setValue={function subNames(v) {
                                                names.subNames[idx] = v;
                                                setNames({ ...names });
                                            }}
                                        />
                                        <Box sx={styles.locContainer} >
                                            <Box sx={styles.locTypes} >
                                                <Box>
                                                    {t('pablo:dialog.newAlignment.from')}
                                                </Box>
                                                <Box>
                                                    {t('pablo:dialog.newAlignment.to')}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box>
                                                    Lng: {from[0].toFixed(2)} - Lat: {from[1].toFixed(2)}
                                                </Box>
                                                <Box>
                                                    Lng: {to[0].toFixed(2)} - Lat: {to[1].toFixed(2)}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions>
                <Loader />
                <Button onClick={onClose}>{t('pablo:button.cancel')}</Button>
                <Button
                    disabled={!isValid(names)}
                    onClick={onSubmit}
                >
                    {t('pablo:button.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Input({ value, setValue, sx, ...props }: { id: string, label: string, value: string, setValue: (_: string) => void, sx?: TextFieldProps['sx'] }) {

    return (
        <TextField
            margin="dense"
            type="text"
            variant="standard"
            sx={{ mr: 3, ...sx }}
            value={value}
            onChange={function updateInput(e) {
                setValue(e.target.value);
            }}
            required
            {...props}
        />
    );
}

function isValid(names: { name: string, subNames: string[] }): boolean {
    return !!names.name && names.subNames.reduce((acc, name) => !!name && acc, true);
}

const styles = {
    container: {
        '& .MuiDialog-paper': {
            overflow: 'visible',
        },
    },
    contentContainer: {
        alignSelf: 'center',
        p: 2,
    },
    title: {
        textAlign: 'center',
        pb: 1,
    },
    titles: {
        pb: 1,
        pl: 2,
    },
    textContainer: {
        mt: 2,
    },
    subNamesContainer: {
        p: 2,
        mb: 2,
        border: 'solid',
        borderWidth: '1px',
        borderRadius: 3,
        boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 10%)',
    },
    subNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    locContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    locTypes: {
        textAlign: 'end',
        pr: 1,
    },
};
