import { useState, useEffect } from 'react';
import axios from 'axios';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
export default function ErrorHandler(props: any) {
    const [state, setState] = useState<any>({});

    const handleClose = () => {
        setState({ open: false, error: null, message: '' })
    }

    useEffect(() => {
        const requestInterceptor = props.axios.interceptors.request.use((req: any) => {
            setState({ error: null });
            return req;
        });

        const responseInterceptor = props.axios.interceptors.response.use(
            (res: any) => res,
            (error: any) => {
                let message
                console.log({ catchErrorInInterceptor: error });

                if (error) {
                    if (
                        error.request &&
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ) {
                        let reader = new FileReader();
                        reader.onload = () => {
                            message = JSON.parse(reader.result as string).message;
                            setState({ open: true, error, message });
                        };
                        reader.readAsText(error.response.data);

                    } else if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            message = error.response.data.message
                            setState({ open: true, error, message });
                        } else {
                            message = JSON.stringify(error.response.data)
                            setState({ open: true, error, message });
                        }

                    } else if (error.response && error.response) {
                        message = JSON.stringify(error.response)
                        setState({ open: true, error, message });
                    } else {
                        const test = JSON.parse(JSON.stringify(error));

                        if (test && test.message) {
                            message = test.message
                            setState({ open: true, error, message });
                        } else {
                            message = JSON.stringify(error)
                            setState({ open: true, error, message });
                        }

                    }

                }

                throw new axios.Cancel(message);
            }
        );
        return () => {
            props.axios.interceptors.request.eject(requestInterceptor);
            props.axios.interceptors.response.eject(responseInterceptor);
        }

    }, []);

    return (
        <div style={{ height: '100%' }}>
            {props.children}
            <Snackbar
                style={{ minWidth: 300 }}
                open={state.open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={handleClose} severity="error" style={{ minWidth: 300 }}>
                    {state.message}
                </Alert>
            </Snackbar>
        </div>


    );



}