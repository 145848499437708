import { Box, BoxProps } from '@mui/material';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { TabsContext, useTabsContext } from './TabsContext';

export type TabProps = TabsContext['tabsOptions'][number] & {
    sx?: BoxProps['sx'],
} & ({
    children: ReactNode,
} | {
    component: ReactNode,
})
export function Tab({ label, sx, ...other }: TabProps) {
    const context = useTabsContext();
    const idx = useMemo(() => {
        const res = context.tabsOptions.length;
        context.tabsOptions.push({
            label,
        })
        return res;
    }, []);

    useEffect(() => {
        context.setTabsOptions([...context.tabsOptions]);

        return () => {
            const idx = context.tabsOptions.findIndex((opt) => opt.label);
            if (idx == -1) return;
            context.tabsOptions.splice(idx, 1);
            context.setTabsOptions([...context.tabsOptions]);
        };
    }, []);

    return (
        <TabPanel value={context.activeTab} index={idx} sx={sx} >
            {'children' in other ? other.children : other.component}
        </TabPanel>
    )
}

interface TabPanelProps {
    children?: React.ReactNode,
    index: number,
    value: number,
    sx?: BoxProps['sx'],
}
function TabPanel({ children, value, index, sx }: TabPanelProps) {

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            sx={{ height: 'inherit', width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: 3, height: '100%', width: '100%', ...sx }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}
