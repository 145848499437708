import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';


export default function ReportModal(props: any) {
    const { t } = useTranslation(['pablo']);

    const [info, setInfo] = useState({
        createdAt: '',
        message: ''
    });
    useEffect(() => {
        if (props.data) {
            const temp = props.data;
            if (temp.createdAt != '') {
                const date = new Date(temp.createdAt);
                temp.createdAt = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
            }
            setInfo(temp);
        }
    }, [props.data]);

    return (
        <Dialog open={props.show} onClose={props.onHide} fullWidth={true} maxWidth='md'>
            <CloseDialogTitle onClose={props.onHide} title={t('pablo:dialog.title.report')} />
            <DialogContent>

                <Grid container spacing={2}>
                    <Grid item xs={1} style={{ alignSelf: 'center' }}>
                        <Typography>Date</Typography>
                    </Grid>
                    <Grid item xs={11} >
                        <TextField
                            fullWidth
                            margin="normal"
                            value={info.createdAt}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ alignSelf: 'center' }}>
                        <Typography>{t('pablo:dialog.title.report')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            multiline
                            id="outlined-required"
                            variant="outlined"
                            value={info.message}
                        />

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
