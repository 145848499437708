import { CircularProgress } from '@material-ui/core';
import React from 'react';
import './spinner.component.css';

function SpinnerText(props: any) {
    return (
        <div className="spinner">
            <CircularProgress className="animation" role="status" />
            <span className="text" >{props.text}</span>
        </div>
    );
}


// const SpinnerText: React.FunctionComponent = () => {
//     return (
//         <div className="spinnerCenterScreen">
//             <Spinner animation="border" role="status">
//             </Spinner>
//             <span>{this.props.text}</span>
//         </div>
//     );
// };

export default SpinnerText;
