import { Box, BoxProps, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { TabsContextProvider, useTabsContext } from './TabsContext';

export type TabsProps = {
    children: ReactNode,
    value?: number | null,
    onChange?: (idx: number) => void,
    sx?: BoxProps['sx'],
    defaultTab?: number,
};
export function Tabs({ defaultTab = 0, ...other }: TabsProps) {
    return (
        <TabsContextProvider defaultTab={defaultTab} >
            <Tabs_ {...other} />
        </TabsContextProvider>
    )
}

function Tabs_({ children, value, onChange, sx }: Omit<TabsProps, 'defaultTab'>) {
    const { activeTab, setActiveTab, tabsOptions } = useTabsContext();

    useEffect(() => {
        if (typeof value != 'number') return;
        setActiveTab(value);
    }, [value]);

    const handleChange = useCallback((event: React.SyntheticEvent, selectedTab: number) => {
        setActiveTab(selectedTab);
        onChange?.(selectedTab);
    }, [onChange]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', ...sx }}>
                <MuiTabs value={activeTab} onChange={handleChange}>
                    {
                        tabsOptions.map((opt) => (
                            <MuiTab key={opt.label} label={opt.label} />
                        ))
                    }
                </MuiTabs>
            </Box>
            {children}
        </>
    )
}
