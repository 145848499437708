/* eslint-disable */
import { APIServiceManager, API } from '../..';
import { IAsset } from 'src/core';
import { IPabloAssetsGeolocation } from 'src/pablo-shared';


class AssetService {
    api: API = APIServiceManager.API;

    name() {
        return 'AssetService';
    }

    async getAsset(page?: number,
        limit?: number,
        search?: string,
        type?: string,
        query?: {}
    ): Promise<{ data: IAsset[], properties: [], categories: [], pages: number; nextPage: string, total: number }> {
        const req = await this.api.axios.get('/project/asset', {
            params: {
                page, limit, search, type, ...query
            }
        });
        return req.data;
    }


    async getAssetList(page: number, limit: number, filter: any): Promise<{ data: IAsset[], pages: number; nextPage: string, total: number }> {
        const req = await this.api.axios.get('/project/asset/list', {
            params: {
                page, limit, filter
            }
        });
        return req.data;
    }

    async getAssetGeolocation(
        page?: number,
        limit?: number,
    ): Promise<{ data: IPabloAssetsGeolocation[], categories: [], pages: number; nextPage: string, total: number }> {
        const req = await this.api.axios.get('/project/asset/geolocation', {
            params: {
                page,
                limit,
            }
        });
        return req.data;
    }

    async updateAssetGeolocation(
        asset: IPabloAssetsGeolocation
    ) {
        const req = await this.api.axios.put(`/project/asset/${asset._id}/geolocation`, asset.geolocation);
        return req.data;
    }

    getAssetStream(query?: {}) {
        return this.api.axios.get('/project/asset', {
            params: {
                stream: true, ...query
            },
            responseType: 'stream'
        });
    }

    async getAssetById(_id: string, includeAuto: boolean) {
        const req = await this.api.axios.get('/project/asset', {
            params: {
                _id, includeAuto
            }
        });
        return req.data;
    }

    async getRelatedAssetById(id: string) {
        const req = await this.api.axios.get(`/project/asset/${id}/tree`);
        return req.data;
    }

    async updateAsset(assetId: string, assetData: any): Promise<any> {
        const req = await this.api.axios.put(`/project/asset/${assetId}`, assetData);
        return req.data;
    }

    async updateAssetProperty(assetId: string, propertyInstId: any, value: any): Promise<any> {
        const req = await this.api.axios.put(`/project/asset/${assetId}/property`, { _id: propertyInstId, value });
        return req.data;
    }

    async addAsset(assetData: any, addSequenceToName: boolean = false): Promise<any> {
        const queryString = addSequenceToName ? '?addSequenceToName=true' : '';
        const req = await this.api.axios.post(`/project/asset${queryString}`, assetData);
        return req.data;
    }

    async removeAsset(id: string): Promise<any> {
        const req = await this.api.axios.delete(`/project/asset?_id=${id}`);
        return req.data;
    }

    async removeAssets(ids: string[]): Promise<any> {
        const queryString = ids.map((id: string) => `idList[]=${id}`).join('&');
        const req = await this.api.axios.delete(`/project/asset?${queryString}`);
        return req.data;
    }

    async removeBulkAssets(ids: string[]): Promise<any> {
        const req = await this.api.axios.delete('/project/asset/ids', {
            data: ids
        });
        return req.data;
    }

    async saveAssets(assetData: any): Promise<any> {
        const req = await this.api.axios.post('/project/asset/bulk', assetData);
        return req.data;
    }

    async importAssets(assetData: any): Promise<any> {
        const req = await this.api.axios.post('/project/asset/import', assetData);
        return req.data;
    }

    async exportToExcel(query: any): Promise<any> {
        const req = await this.api.axios.post('/project/asset/export/excel', null, {
            params: query, responseType: 'blob'
        });
        return req.data;
    }

    async syncAsset(url: any, replace: any): Promise<any> {
        const req = await this.api.axios.post('/project/asset/sync', {
            url, replace
        });
        return req.data;
    }

    async postSnapshot(assetId: any, name: string): Promise<any> {
        const req = await this.api.axios.post(
            `/project/asset/${assetId}/snapshot`,
            { name },
        );
        return req.data;
    }

    async getAssetSnapshotSummaries(assetId: any): Promise<any> {
        const req = await this.api.axios.get(`/project/asset/${assetId}/snapshot/summaries`);
        return req.data;
    }

    async validateAsset(assetId: any): Promise<any> {
        const req = await this.api.axios.put(`/project/asset/${assetId}/workflow/validate`);
        return req.data;
    }

    async rejectAsset(assetId: any): Promise<any> {
        const req = await this.api.axios.put(`/project/asset/${assetId}/workflow/reject`);
        return req.data;
    }

    async requestAssetReview(assetId: any): Promise<any> {
        const req = await this.api.axios.put(`/project/asset/${assetId}/workflow/requestReview`);
        return req.data;
    }
}

export { AssetService };
