import { APIServiceManager, API } from '../..';
import axios from 'axios'
class ForgeService {
    api: API = APIServiceManager.API;;

    name() {
        return 'ForgeService';
    }

    async getToken() {
        try {
            const req = await this.api.axios.get('/auth/token');
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async addModel(projectId: string, name: string): Promise<any> {
        try {
            const req = await this.api.axios.post('/project/model', { name, projectId });
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    async removeModel(id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/project/model/${id}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }


    async uploadSingleModel(id: string, file: any): Promise<any> {
        try {
            const formData = new FormData();
            formData.append('model', file)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const req = await this.api.axios.put(`/project/forge/${id}`, formData, config);
            return req.data;

        } catch (error) {
            console.log(error);
        }
    }

    async uploadChunkedModel(id: string, file: any) {

        try {

            const chunkSize = 5 * 1024 * 1024; // Size of each chunk, set to 5 Mb (Forge API Recommandation)
            const blobSlice = File.prototype.slice;

            const blockCount = Math.ceil(file.size / chunkSize); // total number of fragments
            var axiosPromiseArray = []; // Axios Promise array

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            for (let i = 0; i < blockCount; i++) {
                const start = i * chunkSize;
                const end = Math.min(file.size, start + chunkSize);
                // Building forms
                const formData = new FormData();
                formData.append('chunkIndex', i.toString());
                formData.append('size', file.size.toString());
                formData.append('chunkSize', chunkSize.toString());
                formData.append('file', blobSlice.call(file, start, end));

                axiosPromiseArray.push(
                    this.api.axios.put(`/project/forge/${id}/chunk`, formData, config)
                )
            }

            // After all fragments are uploaded, request to convert the file
            const req = await axios.all(axiosPromiseArray);

            return req;
        } catch (error) {
            console.log(error);
        }
    }

    async getModels(thumbnail: boolean, page: number, limit: number, status: Array<string>, search?: string, projectId?: string): Promise<any> {
        try {
            var searchParams = new URLSearchParams([
                ['thumbnail', thumbnail.toString()],
                ['page', page.toString()],
                ['limit', limit.toString()],
                ...status.map(s => ['status', s])
            ]);
            if (search) {
                searchParams.append('search', search);
            }
            if (projectId) {
                searchParams.append('projectId', projectId);
            }
            const req = await this.api.axios.get(`/project/model?${searchParams.toString()}`);

            return req.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getModel(urn: string): Promise<any> {
        try {
            var searchParams = new URLSearchParams([
                ['includeThumbnail', 'true'],
                ['urn', urn]
            ]);
            const req = await this.api.axios.get(`/project/model?${searchParams.toString()}`);
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }


    async getThumbnail(urn: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/forge/urn/${urn}/thumbnail`);
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getThumbnails(urns: string[]): Promise<any> {
        try {
            const req = await this.api.axios.post('/forge/thumbnails', urns);
            return req.data;

        } catch (error) {
            console.log(error);
        }
    }

    async getManifest(urn: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/forge/urn/${urn}/manifest`);
            return req.data.data;

        } catch (error) {
            console.log(error);
        }
    }

    async uploadFile(bucket: string, name: string, file: any): Promise<any> {
        try {
            const formData = new FormData();
            formData.append('model', file)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const req = await this.api.axios.put(`/forge/${bucket}/name/${name}`, formData, config);
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }


    async removeFile(bucket: string, name: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/forge/${bucket}/name/${name}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
        }
    }


    async updateFromManifest(id: string): Promise<any> {
        try {
            const req = await this.api.axios.get(`/project/model/${id}/manifest`);
            return req.data;
        } catch (error) {
            console.log(error);
        }
    }
}

export { ForgeService };
