import React from "react";
import { AssetSnapshots } from './single-asset-snapshots.component';
import { AssetSummaryTable } from './single-asset-summary.component';
import { AssetProperties } from './single-asset-properties.component';
import { AssetHistory } from './single-asset-history.component';
import { AssetSummary } from './single-asset-types';


type SingleAssetDataProps = {
    id: string
    properties: Array<any>;
    assetSummary: AssetSummary;
    snapshotSummaries: Array<any>;
    history: Array<any>;
};

export default function SingleAssetData(props: SingleAssetDataProps) {
    const historyComponent = () => {
        if (props.history && props.history.length > 0) {
            return (<AssetHistory history={props.history} />);
        }
    }

    const snapshotComponent = () => {
        if (props.snapshotSummaries && props.snapshotSummaries.length > 0) {
            return (<AssetSnapshots snapshotSummaries={props.snapshotSummaries} />);
        }
    }

    return (
        <div>
            <AssetSummaryTable id={props.id} assetSummary={props.assetSummary} />
            <AssetProperties id={props.id} properties={props.properties} status={props.assetSummary.status} />
            {snapshotComponent()}
            {historyComponent()}
        </div>
    )
}