const THREE: any = window.THREE;

//referece:
// https://forge.autodesk.com/blog/custom-window-selection-forge-viewer-part-iii
export default class AIMBuilderExtension extends Autodesk.Viewing.UI.DockingPanel {

    DOMContent: HTMLDivElement;
    viewer: Autodesk.Viewing.GuiViewer3D;
    reactNode: any;
    _options: any;
    _visible: boolean = false;

    constructor(
        viewer: Autodesk.Viewing.GuiViewer3D,
        id: string,
        options: Autodesk.Viewing.UI.DockingPanelOptions | undefined
    ) {
        super(viewer.container as HTMLElement, id, 'Asset Structure', options);
        this._options = options
        this.viewer = viewer;
        this.container.classList.add('react-docking-panel');
        this.container.classList.add('asset-structure-panel');

        this.DOMContent = document.createElement('div');
        this.DOMContent.className = 'docking-panel docking-panel-scroll docking-panel-container-solid-color-a';
        this.container.appendChild(this.DOMContent);
    }

    initialize() {
        super.initialize();
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }



    toogleVisibility() {
        this.onClick();
    }

    async onClick() {
        const modelData = this.viewer.model.getData()
        const parentId = await this.parseTree(this.viewer);
    }

    async parseTree(viewer: Autodesk.Viewing.GuiViewer3D) {

        const instanceTree: Autodesk.Viewing.InstanceTree = viewer.model.getInstanceTree();
        const alldbIds = instanceTree.nodeAccess.dbIdToIndex

        const propSet = await viewer.model.getPropertySetAsync(alldbIds, {
            propFilter: ['Type'],
            //needsExternalId: true
        })

        const test = propSet.getValue2PropertiesMap("Item/Type")

        // viewer.model.getProperties2(alldbIds, (properties: Autodesk.Viewing.PropertyResult) => {
        //     console.log(properties);

        // }, (error: any) => console.log(error))
    }
}


// function getLeafNode(viewer: any, parent: number): number[] {
//     const instanceTree = viewer.model.getData().instanceTree;
//     const arr = getLeaf(instanceTree, parent);
//     return arr;
// }

// function getLeaf(tree: any, parent: number) {
//     let arr: number[] = [];
//     if (tree.getChildCount(parent) !== 0) {
//         tree.enumNodeChildren(
//             parent,
//             (children: number) => {
//                 const subArr = getLeaf(tree, children);
//                 arr = subArr.concat(arr);
//             },
//             false
//         );
//     } else {
//         arr.push(parent);
//     }
//     return arr;
// }
