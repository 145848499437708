import { Link } from 'react-router-dom';
import './pageNotFound.page.css';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PageNotFoundView() {
	const { t } = useTranslation(['pablo']);
	return (
		<div className="page-not-found">
			<img className="forge-hero" alt="" />
			<div className="container">
				<label>
					{t('pablo:label.pageNotFound')}
					<br />
					<br />
					{t('pablo:label.suggestedLink')}
					<br />
					<br />
					<Link to="/">{t('pablo:label.home')}</Link>
					&nbsp;
				</label>
			</div>
		</div>
	);
}