import { API, APIServiceManager } from '../..';

class PropLibraryService {
    api: API = APIServiceManager.API;

    name() {
        return 'PropLibraryService';
    }

    async getLibraries(countprop: boolean = false): Promise<[]> {
        try {
            const limitToProject = this.api._project && this.api._project._id ? this.api._project._id : null;
            const req = await this.api.axios.get('/proplibrary', { params: { countprop, limitToProject } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getProjectLibraries(projectId: string, countprop: boolean = false): Promise<[]> {
        try {
            const req = await this.api.axios.get('/proplibrary', { params: { countprop, projectId } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getStandardLibraries(countprop: boolean = false): Promise<[]> {
        try {
            const req = await this.api.axios.get('/proplibrary', { params: { countprop, isProject: false } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addLibrary(name: string, language: string, isProject: boolean): Promise<[]> {
        try {
            const projectId = this.api._project && this.api._project._id ? this.api._project._id : null;
            const lib = isProject ? {
                name, isProject, projectId, language
            } : {
                name, isProject, language
            };
            const req = await this.api.axios.post('/proplibrary', lib);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateLibrary(id: string, library: any): Promise<[]> {
        try {
            const req = await this.api.axios.put(`/proplibrary/${id}`, library);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removeLibrary(id: string): Promise<[]> {
        try {
            const req = await this.api.axios.delete(`/proplibrary/${id}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

export { PropLibraryService };