
import ReactDOM from 'react-dom';
import React from 'react';
import ForgeViewerEmitter from '../forge.event';
import { Tree } from 'src/components';

export default class AssetStructurePanelExtension extends Autodesk.Viewing.UI.DockingPanel {

    DOMContent: HTMLDivElement;
    viewer: any;
    reactNode: any;
    _options: any;
    _visible: boolean = false;

    _assetData: any;

    constructor(
        viewer: Autodesk.Viewing.GuiViewer3D,
        id: string,
        options: Autodesk.Viewing.UI.DockingPanelOptions | undefined
    ) {
        super(viewer.container as HTMLElement, id, 'Asset Structure', options);
        this._options = options
        this.viewer = viewer;
        this.container.classList.add('react-docking-panel');
        this.container.classList.add('asset-structure-panel');

        this.DOMContent = document.createElement('div');
        this.DOMContent.className = 'docking-panel docking-panel-scroll docking-panel-container-solid-color-a';
        this.container.appendChild(this.DOMContent);

        this.loadTreeData();
    }

    initialize() {
        super.initialize();
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }

    loadTreeData = async () => {
        const tree = await this._options.getAssetStructure();
        this._assetData = tree
    }

    treeNodeClick = (id: string, name?: string, code?: string) => {
        if (id) {
            ForgeViewerEmitter.emit('onAssetStructureSelected', { id, name, code });
        } else {
            ForgeViewerEmitter.emit('onAssetStructureSelected', null);
        }
    }

    setVisible(show: boolean) {
        super.setVisible(show);
        this._visible = show;
        if (show) {
            this.reactNode =
                <Tree
                    selectionCanChanged={true}
                    editable={false}
                    onClick={this.treeNodeClick}

                    data={this._assetData}
                />
            ReactDOM.render(
                this.reactNode,
                this.DOMContent
            );
        } else if (this.reactNode) {
            ReactDOM.unmountComponentAtNode(this.DOMContent);
            this.reactNode = null;
        }
    }

    toogleVisibility() {
        this.setVisible(!this._visible);
    }
}