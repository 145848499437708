import along from '@turf/along';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import distance from '@turf/distance';
import * as helpers from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { LngLat } from 'dev4bim-react-mapbox-gl';
import { NonNullableObj, NullableObj } from 'dev4bim-react-utils';

export type Loc = NullableObj<LngLat>;
export type PkLoc = NullableObj<{
    pk: number,
    dist: number,
}>;

export function pointToPk(point: Loc, alignment: GeoJSON.Feature<GeoJSON.LineString>, mode: 'classic' | 'performant' = 'performant'): NonNullableObj<PkLoc> | null {
    if (point.lng == null || point.lat == null) return null;
    const ptCoord = [point.lng, point.lat];
    const coords: [number, number][] = alignment.geometry.coordinates as any;

    if (mode == 'classic') {
        const res = nearestPointOnLine(alignment, ptCoord);
        if (!res.properties.location || !res.properties.dist) return null;
        const idx = findPkIdx(res.properties.location, coords);
        const direction = !isOnRight(ptCoord, coords[idx - 1], coords[idx], res) ? -1 : 1;
        return {
            pk: res.properties.location,
            dist: res.properties.dist * direction,
        };
    }

    const res = {
        dist: Infinity,
        pk: 0,
        pkDist: 0,
    }
    let travelled = 0;
    for (let i = 1; i < coords.length; ++i) {
        const start = coords[i - 1];
        const stop = coords[i];
        const segment = helpers.lineString([coords[i - 1], coords[i]]);
        const prevTravel = travelled;
        const segmentLength = distance(start, stop);
        travelled += segmentLength;
        const segmentPt = nearestPointOnLine(segment, ptCoord);
        if (typeof segmentPt.properties.location != 'number' || typeof segmentPt.properties.dist != 'number') continue;
        if (segmentPt.properties.location == 0 || segmentPt.properties.location == segmentLength) {
            segmentPt.properties.location += 0.00001 * (segmentPt.properties.location == 0 ? 1 : -1);
        }
        segmentPt.properties.location = segmentPt.properties.location < 0 ? 0.00001 : segmentPt.properties.location;
        const direction = !isOnRight(ptCoord, start, stop, segmentPt) ? -1 : 1;
        const pt = pkToPointWithIdx({ pk: segmentPt.properties.location, dist: segmentPt.properties.dist * direction }, segment, 1);
        const dist = distance(ptCoord, pt);
        if (dist < res.dist) {
            res.dist = dist;
            res.pk = prevTravel + segmentPt.properties.location;
            res.pkDist = segmentPt.properties.dist * direction;
        }
    }
    return {
        pk: res.pk,
        dist: res.pkDist,
    };
}

export function pkToPoint(pk: NonNullableObj<PkLoc>, alignment: GeoJSON.Feature<GeoJSON.LineString>): NonNullableObj<Loc> {
    const [lng, lat] = pkToPointWithIdx(pk, alignment, findPkIdx(pk.pk, alignment.geometry.coordinates as any));
    return { lng, lat };
}

export function findPkIdx(pk: number, coord: [number, number][]) {
    let travelled = 0;
    for (var i = 1; i < coord.length - 1; ++i) {
        travelled += distance(coord[i - 1], coord[i]);
        if (travelled >= pk) break;
    }
    return i;
}

function pkToPointWithIdx(pk: NonNullableObj<PkLoc>, alignment: GeoJSON.Feature<GeoJSON.LineString>, idx: number): [number, number] {
    const coord: [number, number][] = alignment.geometry.coordinates as any;
    const pt = along(alignment, pk.pk);

    const direction = bearing(coord[idx - 1], coord[idx]);
    var perpendicularPt = destination(pt, pk.dist, direction + 90);
    return [perpendicularPt.geometry.coordinates[0], perpendicularPt.geometry.coordinates[1]];
}

type TurfGenericCoord = Parameters<typeof bearing>[0];
function isOnRight(ptCoord: TurfGenericCoord, start: TurfGenericCoord, stop: TurfGenericCoord, toPt = start) {
    const lineAngle = bearing(start, stop);
    const correctionAngle = (lineAngle < 0 ? 180 + lineAngle * -1 : 180 - lineAngle)
    const perpendicularPtAngle = bearing(ptCoord, toPt);
    let relativeAngle = perpendicularPtAngle + correctionAngle;
    relativeAngle = relativeAngle < -180 ? relativeAngle + 360 : relativeAngle > 180 ? relativeAngle - 360 : relativeAngle;
    return relativeAngle < 0 ? false : true;
}
