import React, { useContext } from 'react';

import { APIServiceManager, ProjectService, PermissionService } from 'src/services';
import { ProjectContext } from 'src/contexts/project.context';
import { NewProject } from 'src/components';
import { UserContext } from 'src/contexts/user.context';

function NewProjectView() {
    const _projectService: ProjectService = APIServiceManager.getService('ProjectService') as ProjectService;
    const _permissionService: PermissionService = APIServiceManager.getService('PermissionService') as PermissionService;

    const projectContext = useContext(ProjectContext);
    const userContext = useContext(UserContext);
    return (
        <div>
            <h4 className="pageHeader">New Project</h4>
            <NewProject onSubmit={async (e: any) => {
                try {
                    const bucketKey = `${Date.now()}_${e.project.code}`.toLocaleLowerCase();
                    const newProject = await _projectService.add(e.project.code, e.project.name, e.project.type, e.project.source, e.project.description,
                        e.project.source === 'forge' ? bucketKey : undefined);

                    await _permissionService.addPermission({
                        userId: userContext.user.id,
                        projectId: newProject._id,
                        permissions: ['project-manager']
                    });
                    if (e.imageFile && e.imageFile.size > 0) {
                        await _projectService.putImage(newProject._id, e.imageFile);
                    }
                    projectContext.refresh();
                    return true;
                } catch (error) {
                    return error;
                }

            }} />

        </div>
    );
}

export default NewProjectView;
