import React from 'react';
import { Asset, useMapPageContext } from 'src/contexts/MapPageContext';
import { InputType } from './EditLocDialog';
import { LngLatInput } from './LngLatInput';
import { undyingTabLocation } from './utils/undyingTabLocation';

type PointLocationProps = ReturnType<typeof undyingTabLocation> & {
    asset: Asset | null,
    inputType: InputType,
};
export function PointLocation({ asset, inputType, data, setData }: PointLocationProps) {
    const { selectedAlignmentFeature } = useMapPageContext();

    return (
        <LngLatInput
            loc={data[0]}
            setLoc={(loc) => setData([loc, data[1]])}
            disabled={!asset || (inputType.modifier == 'pk' && !selectedAlignmentFeature)}
            inputType={inputType}
        />
    );
}
