import React, { useEffect, useContext, useRef } from 'react';
import './model-table.component.css'
import { Link } from 'react-router-dom';
import { Query } from 'material-table';
import { ProjectContext } from 'src/contexts/project.context';

import { useCookies } from 'react-cookie';
import { StyledMaterialTable } from 'src/components';
import { useTranslation } from 'react-i18next';

interface IForgeModelsTableProps {
    linkTo: string;
    getData: (query: Query<any>) => Promise<any>;
}

function ForgeModelsTable(props: IForgeModelsTableProps) {
    const projectContext = useContext(ProjectContext);
    const tableRef = useRef<any>(null);

    const [cookies, setCookies] = useCookies(['aimspainter_gallery_table_page_size']);

    const { t } = useTranslation(['pablo']);

    useEffect(() => {
        tableRef.current!.onQueryChange();
    }, [projectContext]);


    return (
        <StyledMaterialTable
            height={105}
            title={t('pablo:pages.model.modelList')}
            tableRef={tableRef}
            options={{
                emptyRowsWhenPaging: false,
                header: false,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: cookies.aimspainter_gallery_table_page_size ? parseInt(cookies.aimspainter_gallery_table_page_size) : 10,
            }}
            onChangeRowsPerPage={(pageSize: number) => {
                setCookies('aimspainter_gallery_table_page_size', pageSize, { path: '/' })
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: t('pablo:components.asset.emptyDataSourceMessage'),
                }
            }}
            columns={
                [
                    {
                        field: 'name',
                        title: 'Link',
                        render: (data: any, type: ('row' | 'group')) => {
                            return (
                                <div className="linkColumn">
                                    <Link state={{ urn: data.urn }} to={{
                                        pathname: props.linkTo
                                    }}>{data.name}</Link>
                                </div>

                            )
                        }
                    },
                ]}
            data={props.getData}
        />
    );
}

export default ForgeModelsTable;
