import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Outlet,
    Route,
    Routes
} from 'react-router-dom';
import { Header } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { UserContext } from 'src/contexts/user.context';
import { APIServiceManager } from 'src/services';
import { useImmer } from 'use-immer';
import {
    ErrorPage, PageNotFoundView
} from '../pages';
import AuthenticatedRoutes from './auth.routes';
import RequireAuth from './protected/AuthRoute';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex'
    },
    main: {
        height: '100%',
        width: '100%'
    },
    appContent: {
        margin: '50px 0 0 50px',
        padding: '15px 5px',
        height: 'calc(100% - 50px)',
        width: 'calc(100% - 50px)',
        // backgroundColor: theme.palette.background.default
    }
}));

export default function PabloRoutes() {
    const { t, i18n } = useTranslation(['pablo']);

    const classes = useStyles();
    const userContext = useContext(UserContext);
    const projectContext = useContext(ProjectContext);

    const defaultHeaderRoutes = [
        { title: t('pablo:label.home'), icon: 'fa fa-home', path: '/', visible: true, pathMatches: [/^\/projects$/] },
        //{ title: t('pablo:router.gallery'), icon: 'fa fa-cubes', path: '/itwin/gallery', visible: true },
        { title: t('pablo:router.gallery'), icon: 'fa fa-cubes', path: '/forge/gallery', visible: true },
        {
            title: t('pablo:router.register'), icon: 'fas fa-book', path: '/register/asset', visible: true,
            pathMatches: [/^\/map$/, /^\/register\/asset\/\w+$/]
        },
        // { title: 'Asset Register', icon: 'fa fa-book', path: '/configuration/asset-list', visible: true },
        { title: t('pablo:router.assetStructure'), icon: 'fas fa-sitemap', path: '/structure', visible: true },
        { title: t('pablo:router.objectRegister'), icon: 'fa fa-table', path: '/database', visible: true },
        { title: t('pablo:router.datagrid'), icon: 'fa fa-database', path: '/data', visible: true },
        {
            title: t('pablo:router.property'),
            icon: 'fas fa-layer-group',
            visible: true,
            path: '/prop',
            subRoutes: [
                { path: '/lib/project', visible: true },
                { path: '/set/project', visible: true, divider: true },
                { path: '/lib/standard', visible: true },
                { path: '/set/standard', visible: true },

            ],
            pathMatches: [
                /^\/prop\/(lib|set)\/\w+\/properties$/,
            ],
        },
        { title: t('pablo:label.profile'), icon: 'fa fa-user', path: '/profile', position: 'navbar', visible: true },
        { title: t('pablo:label.manage'), icon: 'fa fa-cog', path: '/manage', position: 'bottom', visible: true },
        { title: t('pablo:label.admin'), icon: 'fa fa-users-cog', path: '/admin', position: 'bottom', visible: userContext.user.access_right === 'admin' },
        { title: t('pablo:label.disconnect'), icon: 'fa fa-sign-out', position: 'navbar', path: '/disconnect', visible: true },
    ];
    const [headerRoutes, setHeaderRoutes] = useImmer(defaultHeaderRoutes);

    useEffect(() => {
        setHeaderRoutes((draft: any) => {
            let i = 0;
            draft[i++].title = t('pablo:label.home');
            draft[i++].title = t('pablo:router.gallery');
            draft[i++].title = t('pablo:router.register');
            draft[i++].title = t('pablo:router.assetStructure');
            draft[i++].title = t('pablo:router.objectRegister');
            draft[i++].title = t('pablo:router.datagrid');
            draft[i].title = t('pablo:router.property');

            draft[i].subRoutes[0].title = t('pablo:router.propertyLibProject');
            draft[i].subRoutes[1].title = t('pablo:router.propertySetProject');
            draft[i].subRoutes[2].title = t('pablo:router.propertyLibStandard');
            draft[i++].subRoutes[3].title = t('pablo:router.propertySetStandard');

            draft[i++].title = t('pablo:label.profile');
            draft[i++].title = t('pablo:label.manage');
            draft[i++].title = t('pablo:label.admin');
            draft[i++].title = t('pablo:label.disconnect');

        });
    }, [i18n.language]);

    useEffect(() => {
        if (projectContext.currentProject) {
            if (projectContext.currentProject.functionality) {
                const func = projectContext.currentProject.functionality;
                setHeaderRoutes((draft: any) => {
                    let i = 1;
                    draft[i++].visible = func.modelEnable;
                    draft[i++].visible = func.assetDatabaseEnable;
                    draft[i++].visible = func.structureEnable;
                    draft[i++].visible = func.objectDatabaseEnable;
                    draft[i++].visible = func.partDatabaseEnable;
                });
            } else {
                setHeaderRoutes((draft: any) => {
                    for (let i = 0; i < 6; i++) {
                        draft[i].visible = true;
                    }
                });
            }
        }
    }, [projectContext.currentProject]);

    useEffect(() => {
        setHeaderRoutes((draft: any) => {
            draft[draft.length - 2].visible = userContext.user.access_right === 'admin';
        });
    }, [userContext]);


    useEffect(() => {
        const path = projectContext && projectContext.currentProject && projectContext.currentProject.source === 'itwin' ?
            '/itwin/gallery' : '/forge/gallery';
        setHeaderRoutes((draft: any) => {
            draft[1].path = path;
        });
    }, [projectContext]);

    return (

        <Routes>
            <Route path="/" element={<section className={classes.main}>
                <Header routes={headerRoutes} handleDisconnect={APIServiceManager.AuthService.logout} />
                <div className={classes.appContent}>
                    <Outlet />
                </div>
            </section>}>
                <Route path='/' element={
                    <RequireAuth>
                        <Outlet />
                    </RequireAuth>
                }>
                    {AuthenticatedRoutes()}

                </Route>
                <Route path="error" element={<ErrorPage />} />
                <Route path='*' element={<PageNotFoundView />} />

            </Route>
        </Routes>
    );
}
