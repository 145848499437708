import { Box, Grid, InputBase, TableBody, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { APIServiceManager } from "src/services";
import { StyledTable, StyledTableCell } from './single-asset-styles';
import { AssetSummary } from './single-asset-types';
import { statusIsFrozen, statusToColor } from "src/core/business/asset.business";

export function AssetSummaryTable({ id, assetSummary }: { id: string, assetSummary: AssetSummary }) {

    const { t } = useTranslation(['pablo']);
    const [name, setName] = useState<string>('');

    useEffect(() => {
        setName(assetSummary.name)
    }, [assetSummary.name]);

    return (
        <Grid container>
            <Grid item xs={10}>
                <h3>{t('pablo:components.asset.single.keyPropertiesTitle')}</h3>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    p: 1,
                    bgcolor: statusToColor(assetSummary.status),
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}>
                    {assetSummary.status === 'validated' ?
                        t('pablo:label.status.validated') :
                        assetSummary.status === 'inReview' ?
                            t('pablo:label.status.inReview') :
                            t('pablo:label.status.workInProgress')}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                {t('pablo:table.headers.key')}
                            </StyledTableCell>
                            <StyledTableCell >
                                {t('pablo:table.headers.value')}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell>
                                {t('pablo:table.headers.name')}
                            </StyledTableCell>
                            <StyledTableCell>
                                <InputBase
                                    disabled={statusIsFrozen(assetSummary.status)}
                                    sx={{ ml: 1, flex: 1, marginLeft: 0 }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setName(event.target.value);
                                    }}
                                    onBlur={() => {
                                        APIServiceManager.AssetService.updateAsset(id, { name });
                                    }}
                                    value={name}
                                />
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>
                                {t('pablo:table.headers.code')}
                            </StyledTableCell>
                            <StyledTableCell>{assetSummary.code}</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>
                                {t('pablo:table.headers.fullCode')}
                            </StyledTableCell>
                            <StyledTableCell>{assetSummary.fullCode}</StyledTableCell>
                        </TableRow>
                    </TableBody>

                </StyledTable>
            </Grid>
        </Grid>
    )
}