import { Typography } from '@mui/material';
import EditableTree, { TreeData } from 'editable-tree';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { APIServiceManager } from 'src/services';
import { Status } from 'src/pablo-shared';
import { statusToColor } from 'src/core/business/asset.business';

type RelatedAssetProps = {
    id: string;
    status: string;
}

function dbTreeToTreeView(tree: any, separator: string): TreeData {
    const parseTree = (node: any, separator: string): TreeData => {
        const fullCodeWithSeparator = node.structure && node.structure.fullCode ? node.structure.fullCode.replaceAll(' ', separator) : 'unknown';
        const label = `${fullCodeWithSeparator} - ${node.name}`;
        return {
            id: node._id.toString(),
            label,
            children: node.children && node.children.length > 0 ? node.children.map((c: any) => parseTree(c, separator)) : [],
            extendedData: {
                status: node.status
            }
        }
    }
    return parseTree(tree, separator);
}

export default function RelatedAsset(props: RelatedAssetProps) {

    const { t } = useTranslation(['pablo']);

    const [tree, setTree] = useState<TreeData>({ id: '', label: '', extendedData: { status: Status.WorkInProgress } });
    const [expandedNodes, setExpandedNodes] = useState<Array<string>>([]);

    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_codeSeparator',
    ]);

    const fetchRelatedAsset = useCallback(async (id: string) => {
        const queryRelatedAsset = await APIServiceManager.AssetService.getRelatedAssetById(id);
        const separator = cookies.aimspainter_asset_structure_display_codeSeparator && cookies.aimspainter_asset_structure_display_codeSeparator.length > 0 ?
            cookies.aimspainter_asset_structure_display_codeSeparator : '-';

        const tempTree = dbTreeToTreeView(queryRelatedAsset.data.tree, separator);
        setTree(tempTree);

        setExpandedNodes(queryRelatedAsset.data.parentIds)
    }, [cookies.aimspainter_asset_structure_display_codeSeparator]);

    useEffect(() => {
        if (props.id) {
            fetchRelatedAsset(props.id)
        }
    }, [props.id, props.status]);

    const renderNodeLabel = (node: TreeData, level: number) => (
        < Typography sx={{
            color: statusToColor(node && node.extendedData && node.extendedData.status ? node.extendedData.status : Status.WorkInProgress)
        }}>
            {node.label}
        </Typography >
    );

    return (
        <div style={{ height: '80vh' }}>
            <h3>
                {t('pablo:components.asset.single.relatedAsset')}
            </h3>
            <EditableTree
                data={tree}
                expanded={expandedNodes}
                selected={props.id}
                components={{
                    label: renderNodeLabel
                }}
            />
        </div>
    )
}
