import React, { useEffect, useState, useRef, forwardRef } from 'react';
import '../../material-table.css';

import { Query, Column } from 'material-table';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { StyledMaterialTable } from 'src/components';

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

type AssetTableProps = {
    hasEditorRole: boolean
    categories: Array<{ name: string }>;
    initialColumns: Column<any>[];
    getData: (query: Query<any>) => Promise<any>;
    getColumns: () => Array<any>;
    onUpdateDisplayColumns: (categories: any) => void;
    onShowModal: () => void;

    onOpenAsset: (data: any) => void;
    onRemoveAssets: (e: any, data: any) => Promise<any>;
    onUpdateAsset: (newData: any, oldData: any) => Promise<any>;
    onRowDelete: (oldData: any) => Promise<any>;
    onBulkUpdate: (changes: any) => Promise<any>;
}

function CategoryMenu(props: any) {
    useEffect(() => {
        const arr = Array.from(props.categories, c => { return { name: c, isChecked: true } });
        setCategories(arr);
    }, [props.categories])
    const dropdownAnchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [categories, setCategories] = useState<Array<any>>([]);

    return (
        <div>
            <ViewColumnIcon ref={dropdownAnchorRef} onClick={() => setOpen(true)} />
            <Menu
                id="simple-menu"
                anchorEl={dropdownAnchorRef.current}
                keepMounted
                open={open}
                onClose={() => {
                    setOpen(false)
                    props.onClose(categories)
                }}
            >
                {
                    categories.map((category: { name: string, isChecked: boolean }, i: number) =>
                        <MenuItem
                            key={category.name + i}
                            value={category.name}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={category.isChecked}
                                        onChange={(event: any, checked: boolean) => {
                                            const newState = [...categories];
                                            newState[i].isChecked = checked;

                                            setCategories(newState);
                                        }}
                                    />
                                }
                                label={category.name}
                            />
                        </MenuItem>

                    )
                }
            </Menu >
        </div>

    )
}

function AssetTableView(props: AssetTableProps, tableRef: any) {
    const { t } = useTranslation(['pablo']);

    const [cookies, setCookies] = useCookies(['aimspainter_gallery_table_page_size']);

    const handleChangeRowsPerPage = (pageSize: number) => {
        setCookies('aimspainter_gallery_table_page_size', pageSize, { path: '/' })
    }

    return (
        <div style={{ height: '100%' }}>
            <StyledMaterialTable
                height={160}
                tableRef={tableRef}
                title="Asset List"
                columns={props.getColumns()}
                localization={{
                    body: {
                        emptyDataSourceMessage: t('pablo:components.asset.emptyDataSourceMessage'),
                    }
                }}
                actions={[
                    {
                        icon: () => (
                            <CategoryMenu categories={props.categories} onClose={(newState: any) => {
                                props.onUpdateDisplayColumns(newState);
                            }} />
                        ),
                        isFreeAction: true,
                        onClick: () => { }
                    },
                    {
                        icon: () => (<AddBoxSharpIcon />),
                        tooltip: t('pablo:tooltip.add-asset'),
                        isFreeAction: true,
                        disabled: !(props.hasEditorRole),
                        onClick: props.onShowModal
                    },
                    {
                        tooltip: t('pablo:tooltip.open-asset'),
                        icon: () => (<OpenInNewIcon />),
                        onClick: props.onOpenAsset
                    },
                    {
                        tooltip: t('pablo:tooltip.remove-asset'),
                        icon: 'delete',
                        disabled: !(props.hasEditorRole),
                        onClick: props.onRemoveAssets
                    }

                ]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                options={{
                    toolbar: true,
                    paging: true,
                    pageSizeOptions: [10, 20, 50, 100],
                    pageSize: cookies.aimspainter_gallery_table_page_size ? parseInt(cookies.aimspainter_gallery_table_page_size) : 10,
                    grouping: true,
                    tableLayout: 'auto',
                    exportButton: true,
                    selection: true,
                    emptyRowsWhenPaging: false,
                    loadingType: 'overlay',
                }}
                data={props.getData}
                editable={{
                    isEditable: rowData => props.hasEditorRole,
                    // isEditHidden: rowData => !hasEditorRole,
                    isDeletable: rowData => props.hasEditorRole,
                    // isDeleteHidden: rowData => !hasEditorRole,
                    onRowUpdate: props.onUpdateAsset,
                    onRowDelete: props.onRowDelete,
                    onBulkUpdate: props.onBulkUpdate,

                }} />
        </div >
    )
}
const AssetTable = forwardRef(AssetTableView)
export default AssetTable;