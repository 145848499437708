import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import { NavLink, Outlet, Route } from 'react-router-dom';
import { NewProjectView, ProjectsPage, PropertyLibrariesPage, UsersPage } from 'src/pages/admin';
import AdminPermissionView from 'src/pages/admin/adminpermissions/adminpermissions.page';
import ModelsView from 'src/pages/admin/models/models.page';
import RequireAdmin from './protected/AdminAuthRoutes';

export default function AdminRoutes() {
    return (
        <Route path="admin" element={
            <div style={{ display: "flex", height: "100%", flexDirection: 'column' }}>
                <div style={{ paddingBottom: 16 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <NavLink to="projects">Projects</NavLink>
                        <NavLink to="users">Users</NavLink>
                        <NavLink to="new-project">Add new project</NavLink>
                    </Breadcrumbs>
                </div>

                <div style={{ height: 'calc(100% - 35px)' }}>
                    <RequireAdmin>
                        <Outlet />
                    </RequireAdmin>
                </div>
            </div>
        }>
            <Route path="projects" element={<ProjectsPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="projects/:id/permissions" element={<AdminPermissionView />} />
            <Route path="property-lib" element={<PropertyLibrariesPage />} />
            <Route path="models" element={<ModelsView />} />
            <Route path="new-project" element={<NewProjectView />} />
        </Route>
    );
}
