import { Autocomplete } from '@mui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress, Dialog, DialogActions,
    DialogContent, FormControl,
    Grid, Icon, IconButton,
    List, ListItem, ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader, MenuItem,
    Paper, Select, Switch,
    TextField, Tooltip,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ObjectId } from 'bson';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle, CTA, DropFileDialog, SpinnerText } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { APIServiceManager, PropertyService } from 'src/services';
import PropertySetsDialog from './propertysetsmodal.component';


const useStyles = makeStyles({
    root: {

    },
    formGrid: {
        alignItems: 'center'
    },
    tooltip: {
        height: 15,
        width: 15,
        marginLeft: 10
    },
    unitGroupAccordion: {
        width: '100%',
        border: `1px solid rgba(0, 0, 0, 0.12)`,
        borderLeft: 0,
        borderRight: 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    unitGroupAccordionSummary: {
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
    unitGroupAccordionDetails: {
        borderTop: '1px solid rgba(0, 0, 0, .12)',
    },
});

type EditPropertyDialogProps = {
    open: boolean;
    // selectedProperty: any;
    selectedProperties: Array<any>;
    onClose: () => void;
    onSubmit: (newData: any, oldData: any) => void;
    i18nTitle?: string;
}

function EditPropertyDialog(props: EditPropertyDialogProps) {
    const { t, i18n } = useTranslation(['pablo']);
    const classes = useStyles();

    const projectContext = useContext(ProjectContext);
    const [units, setUnits] = useState<Array<any>>([]);
    const [categories, setCategories] = useState<Array<any>>([]);
    const [openPSetModal, setOpenPSetModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFileImport, setShowFileImport] = useState(false);
    const [importedFile, setImportedFile] = useState<any>(null);
    const [forceSwitch, setForceSwitch] = useState<boolean>(false);

    useEffect(() => {
        if (projectContext && projectContext.currentProject && projectContext.currentProject._id) {
            const fetchCategories = async () => {
                const categories: Array<any> = await APIServiceManager.CategoriesService.getCategories(projectContext.currentProject._id!);
                setCategories(categories);
            };

            const fetchUnits = async () => {
                const units: Array<any> = await APIServiceManager.UnitsService.getUnits(projectContext.currentProject._id!);
                setUnits(units);
            };
            fetchUnits();
            fetchCategories();
        }
    }, [projectContext]);

    const [propSets, setPropSets] = useState<any>([]);

    useEffect(() => {
        const fetchPropSets = async () => {
            const propSetSvc = APIServiceManager.PropSetService;
            let sets = await propSetSvc.getSets(null, false);
            sets = sets.map((s: any) => {
                return {
                    _id: s._id,
                    name: s.name,
                    projectId: s.projectId
                };
            });
            setPropSets(sets);
        };
        fetchPropSets();
    }, []);


    const propertyReducer = (state: any, action: { type: string, value: any }) => {
        switch (action.type) {
            case 'setKey':
                return {
                    ...state,
                    key: action.value,
                };
            case 'setName':
                return {
                    ...state,
                    name: action.value,
                };
            case 'setDescription':
                return {
                    ...state,
                    description: action.value,
                };
            case 'setIsTechProperty':
                return {
                    ...state,
                    isTechProperty: action.value,
                };
            case 'setUnit':
                return {
                    ...state,
                    unit: action.value,
                };
            case 'setCategory':
                return {
                    ...state,
                    category: action.value,
                };
            case 'setType':
                return {
                    ...state,
                    type: action.value,
                };
            case 'setFormat':
                return {
                    ...state,
                    format: action.value,
                };
            case 'setPossibleValues':
                return {
                    ...state,
                    possibleValues: action.value,
                };
            case 'setPropertySets':
                return {
                    ...state,
                    propertySets: action.value,
                };
            case 'setStateButUnit':
                return {
                    ...(action.value),
                    unit: state.unit
                };
            case 'setState':
                return action.value;

            default:
                throw new Error();
        }
    };

    const initialValue = {
        key: '',
        name: '',
        description: '',
        format: 'Text',
        unit: {},
        type: 'FREE',
        isTechProperty: false,
        propertySets: [],
        possibleValues: []
    };
    const [property, dispatch] = useReducer(propertyReducer, initialValue);

    useEffect(() => {
        if (projectContext && projectContext.currentProject && projectContext.currentProject._id) {
            APIServiceManager.UnitsService.getUnits(projectContext.currentProject._id!, { name: 'No unit' })
                .then((noUnit: any) => {
                    if (Array.isArray(noUnit) && noUnit.length > 0) {
                        initialValue.unit = noUnit[0];
                        dispatch({ type: 'setUnit', value: noUnit[0] });
                    }
                });
        }
    }, [projectContext]);

    useEffect(() => {
        if (props.open) {

            const propIds = props.selectedProperties.map((p: any) => p._id);
            const fetchProp = async () => {
                setLoading(true);
                const _propSvc = APIServiceManager.getService('PropertyService') as PropertyService;

                const properties: any = await _propSvc.getProperties({ ids: propIds, includepset: true });
                if (properties.count > 0) {
                    const propertiesData: Array<any> = properties.data;

                    dispatch({
                        type: 'setState', value: {
                            key:
                                propertiesData.every(p => p.key === propertiesData[0].key) ?
                                    propertiesData[0].key :
                                    '*varies',
                            name: propertiesData.every(p => p.name === propertiesData[0].name) ?
                                propertiesData[0].name :
                                '*varies',
                            description: propertiesData.every(p => p.description === propertiesData[0].description) ?
                                propertiesData[0].description :
                                '*varies',
                            category:
                                propertiesData.every(p => p.category && p.category._id && ObjectId.isValid(p.category._id))
                                    ?
                                    propertiesData.every(p => p.category && p.category._id === propertiesData[0].category._id) ?
                                        propertiesData[0].category :
                                        {
                                            _id: -1,
                                            name: '*varies',
                                            frName: 'varie'
                                        }
                                    :

                                    propertiesData.every(p => p.categoryText === propertiesData[0].categoryText)
                                        ?
                                        categories.findIndex(c => c.name === propertiesData[0].categoryText) > -1 ?
                                            categories.find(c => c.name === propertiesData[0].categoryText) :
                                            null
                                        :
                                        {
                                            _id: -1,
                                            name: '*varies',
                                            frName: 'varie'
                                        },
                            isTechProperty:
                                propertiesData.every(p => p.isTechProperty === propertiesData[0].isTechProperty) ?
                                    propertiesData[0].isTechProperty :
                                    '*varies',
                            unit:
                                propertiesData.every(p => p.unit && p.unit._id && ObjectId.isValid(p.unit._id))
                                    ?
                                    propertiesData.every(p => p.unit && p.unit._id === propertiesData[0].unit._id) ?
                                        propertiesData[0].unit :
                                        {
                                            _id: -1,
                                            name: '*varies',
                                            frName: 'varie'
                                        }
                                    :

                                    propertiesData.every(p => p.unitText === propertiesData[0].unitText)
                                        ?
                                        units.findIndex(u => u.name === propertiesData[0].unitText) > -1 ?
                                            units.find(u => u.name === propertiesData[0].unitText) :
                                            null
                                        :
                                        {
                                            _id: -1,
                                            name: '*varies',
                                            frName: 'varie'
                                        },

                            format:
                                propertiesData.every(p => p.format === propertiesData[0].format) ?
                                    propertiesData[0].format :
                                    '*varies',
                            type:
                                propertiesData.every(p => p.type === propertiesData[0].type) ?
                                    propertiesData[0].type :
                                    '*varies',
                            propertySets:
                                propertiesData.every(p => JSON.stringify(p.propertySets) === JSON.stringify(propertiesData[0].propertySets)) ?
                                    propertiesData[0].propertySets :
                                    '*varies',
                            possibleValues:
                                propertiesData.every(p => JSON.stringify(p.possibleValues) === JSON.stringify(propertiesData[0].possibleValues)) ?
                                    propertiesData[0].possibleValues :
                                    '*varies'
                        }
                    });
                    setLoading(false);
                }
            };
            if (Array.isArray(propIds) && propIds.length > 0) {
                fetchProp();
            } else {
                dispatch({ type: 'setStateButUnit', value: initialValue });
            }

        } else {
            setForceSwitch(false);
            dispatch({ type: 'setStateButUnit', value: initialValue });
        }
    }, [props.selectedProperties, props.open]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const updatedProps: any = {
            ...property,
            unit: property.unit.name,
            unitId: property.unit._id,
        };
        if (property.category && property.category._id) {
            updatedProps.category = property.category.name;
            updatedProps.categoryId = property.category._id;
        }
        if (!updatedProps.isTechProperty) {
            delete updatedProps.category;
            delete updatedProps.categoryId;
        }
        for (let key in updatedProps) {
            if (updatedProps[key] === '*varies') {
                delete updatedProps[key];
            }
        }
        props.onSubmit(updatedProps, props.selectedProperties);
        props.onClose();
    };

    const onCancel = (e: any) => {
        props.onClose();
    };

    const addPossibleValue = () => {
        if (Array.isArray(property.possibleValues)) {
            const newArr = [...property.possibleValues, { value: '', description: '' }];
            dispatch({ type: 'setPossibleValues', value: newArr });
        } else {
            dispatch({ type: 'setPossibleValues', value: [{ value: '', description: '' }] });
        }
    };

    const addPropertySet = (newSets: Array<any>) => {
        if (Array.isArray(property.propertySets)) {
            const newArr = [...property.propertySets];
            for (const set of newSets) {
                if (!property.propertySets.includes(set)) {
                    newArr.push(set);
                }
            }
            dispatch({ type: 'setPropertySets', value: newArr });
        } else {
            dispatch({ type: 'setPropertySets', value: newSets });
        }

    };

    const removePossibleValueAtIndex = (index: number) => {
        const newArr = [...property.possibleValues];
        newArr.splice(index, 1);
        dispatch({ type: 'setPossibleValues', value: newArr });
    };

    const removePossibleValues = () => {
        dispatch({ type: 'setPossibleValues', value: [] });
    };

    const removePropertySetAtIndex = (index: number) => {
        const newArr = [...property.propertySets];
        newArr.splice(index, 1);
        dispatch({ type: 'setPropertySets', value: newArr });
    };

    const setPossibleValueAtIndex = (newValue: string, index: number) => {
        const newArr = [...property.possibleValues];
        newArr[index] = newValue;
        dispatch({ type: 'setPossibleValues', value: newArr });
    };

    const setPossibleValueValueAtIndex = (newValue: string, index: number) => {
        const newArr = [...property.possibleValues];
        newArr[index].value = newValue;
        dispatch({ type: 'setPossibleValues', value: newArr });
    };

    const setPossibleValueDescriptionAtIndex = (newValue: string, index: number) => {
        const newArr = [...property.possibleValues];
        newArr[index].description = newValue;
        dispatch({ type: 'setPossibleValues', value: newArr });
    };

    const importPossibleValueList = (file: any) => {
        var reader = new FileReader();

        reader.onload = async (fe: ProgressEvent<FileReader>) => {
            if (fe.target) {
                const xlsx = await import('xlsx');
                const workbook = xlsx.read(fe.target.result, {
                    type: 'binary',
                });
                let sheetsList = workbook.SheetNames;
                const ws: any = workbook.Sheets[sheetsList[0]];
                var range = xlsx.utils.decode_range(ws['!ref']);
                range.s.r = 3;

                const rows: Array<any> = xlsx.utils.sheet_to_json(ws, { range });
                const values = rows.map((r: any) => {
                    return {
                        value: r.Value,
                        description: r.Description
                    };
                });

                dispatch({ type: 'setPossibleValues', value: values });
                setLoading(false);
            }
        };
        reader.readAsBinaryString(file);

    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth='md'>
            <form onSubmit={onSubmit}>
                <CloseDialogTitle onClose={props.onClose} title={t(`pablo:pages.propertylib.${props.i18nTitle ?? 'property'}`)} />
                <DialogContent dividers>
                    {
                        loading ?
                            <div style={{ display: 'unset', position: 'absolute', marginLeft: '50%', marginTop: '15%' }}>
                                <SpinnerText />
                            </div>
                            :
                            null
                    }
                    <Grid container className={classes.formGrid} spacing={1}>
                        <Grid item xs={3}>
                            <Typography variant='overline'>{t('pablo:pages.propertylib.code')}</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.code').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>


                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                required
                                fullWidth
                                variant='standard'
                                disabled={loading || property.key === '*varies'}
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                                value={property.key}
                                onKeyPress={(e: any) => {
                                    if (e.key === '*') {
                                        e.preventDefault();
                                        return false;
                                    }
                                }}
                                onChange={(e: any) => {
                                    dispatch({ type: 'setKey', value: e.target.value });
                                }}
                            />
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>{t('pablo:pages.propertylib.name')}</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.name').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                required
                                fullWidth
                                variant='standard'
                                disabled={loading || property.key === '*varies'}
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                                value={property.name}
                                onKeyPress={(e: any) => {
                                    if (e.key === '*') {
                                        e.preventDefault();
                                        return false;
                                    }
                                }}
                                onChange={(e: any) => {
                                    dispatch({ type: 'setName', value: e.target.value });
                                }}
                            />
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>{t('pablo:pages.propertylib.description')}</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.description').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                required
                                fullWidth
                                variant='standard'
                                disabled={loading || property.key === '*varies'}
                                margin="normal"
                                value={property.description}
                                onChange={(e: any) => {
                                    dispatch({ type: 'setDescription', value: e.target.value });
                                }}
                            />
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>{t('pablo:pages.propertylib.isTechProp')}</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.technique').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container>
                                <Grid item xs={2} onMouseDown={() => {
                                    if (property.isTechProperty === '*varies') {
                                        setForceSwitch(true);
                                        dispatch({ type: 'setIsTechProperty', value: true });
                                    }
                                }}>
                                    <Switch
                                        checked={property.isTechProperty}
                                        disabled={!forceSwitch && (loading || property.isTechProperty === '*varies')}
                                        onChange={(e: any) => {
                                            dispatch({ type: 'setIsTechProperty', value: e.target.checked });
                                        }} />
                                </Grid>
                                {
                                    property.isTechProperty ?
                                        <>
                                            <Grid item xs={2}>
                                                <Typography variant='overline'>{t('pablo:pages.propertylib.category')}</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControl variant="standard" fullWidth required={property.isTechProperty}>
                                                    <Select
                                                        value={property.category &&
                                                            property.category._id ? property.category._id : ''}
                                                        onChange={(e: any) => {
                                                            if (e.target.value) {
                                                                const newCategory = categories.find(c => c._id === e.target.value);
                                                                dispatch({ type: 'setCategory', value: newCategory });
                                                            }
                                                        }}
                                                    >
                                                        {categories.map((category) => (
                                                            <MenuItem key={category._id} value={category._id} >
                                                                {i18n.language === 'fr' ? category.frName : category.name}
                                                            </MenuItem>
                                                        )).concat([
                                                            (<MenuItem disabled style={{ visibility: 'collapse', height: 0 }}
                                                                key={'categories-varies'} value={'*varies'}>*varies</MenuItem>)
                                                        ])}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </> : null
                                }
                            </Grid>
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>{t('pablo:pages.propertylib.propertySet')}</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.propertySet').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                fullWidth
                                multiple
                                disabled={loading}
                                ChipProps={{
                                    variant: 'outlined',
                                }}
                                value={
                                    property.propertySets != '*varies' ?
                                        propSets.filter((pset: any) => {

                                            if (Array.isArray(property.propertySets) &&
                                                property.propertySets.findIndex((p: any) => p._id.toString() === pset._id.toString()) > -1) {
                                                return true;
                                            }
                                            return false;
                                        }) :
                                        [{
                                            _id: '', name: '*varies'
                                        }]

                                }
                                onChange={(event: any, newValue: any) => {
                                    dispatch({ type: 'setPropertySets', value: newValue.filter((v: any) => v._id.length > 1) });
                                }}
                                options={propSets}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="+"
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>{t('pablo:pages.propertylib.unit')}</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.unit').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9} >
                            <Autocomplete
                                fullWidth
                                disabled={loading}
                                value={property.unit}
                                onChange={(event: any, newValue: any) => {
                                    dispatch({ type: 'setUnit', value: newValue });
                                }}
                                options={units}
                                getOptionLabel={(option: any) =>
                                    i18n.language === 'fr' ?
                                        option.symbol ?
                                            `${option.frName} (${option.symbol})`
                                            : `${option.frName}`
                                        : option.symbol ?
                                            `${option.name} (${option.symbol})`
                                            : `${option.name}`
                                }
                                groupBy={(option) => option.unitType}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            '& .MuiAutocomplete-listbox': {
                                                padding: 0,
                                                '&::-webkit-scrollbar': {
                                                    width: '6px',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    borderLeft: 0,
                                                },
                                            },
                                        },
                                    },
                                }}
                                renderGroup={(params) => {
                                    return (
                                        <Accordion
                                            key={params.key}
                                            disableGutters
                                            elevation={0}
                                            square
                                            className={classes.unitGroupAccordion}
                                        >
                                            <AccordionSummary
                                                expandIcon={<Icon fontSize='small' className='fas fa-angle-right' />}
                                                className={classes.unitGroupAccordionSummary}
                                            >
                                                <Typography>{params.group}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.unitGroupAccordionDetails} >
                                                {params.children}
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                }}
                                renderInput={(params: any) => (
                                    <TextField
                                        required
                                        {...params}
                                        variant="standard"
                                        placeholder="unit"
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>Format</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.format').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9} >
                            <FormControl variant="standard" fullWidth required>
                                <Select
                                    value={property.format}
                                    disabled={loading}
                                    onChange={(e: any) => dispatch({ type: 'setFormat', value: e.target.value })}
                                >
                                    <MenuItem value="Text">{t('pablo:pages.propertylib.type.text')}</MenuItem>
                                    <MenuItem value="Number">{t('pablo:pages.propertylib.type.number')}</MenuItem>
                                    <MenuItem value="Date">Date</MenuItem>
                                    <MenuItem value="URL">Url</MenuItem>
                                    <MenuItem disabled style={{ visibility: 'collapse', height: 0 }} value="*varies">*varies</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={3}>
                            <Typography variant='overline'>Type</Typography>
                            <Tooltip title={t('pablo:pages.propertylib.tooltip.type').toString()} className={classes.tooltip}>
                                <Icon className='fas fa-question-circle' fontSize="small" />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl variant="standard" fullWidth>
                                <Select
                                    value={property.type}
                                    disabled={loading}
                                    onChange={(e: any) => dispatch({ type: 'setType', value: e.target.value })}
                                >
                                    <MenuItem value="FREE">{t('pablo:pages.propertylib.format.freeEntry')}</MenuItem>
                                    <MenuItem value="LIST">{t('pablo:pages.propertylib.format.list')}</MenuItem>
                                    <MenuItem disabled style={{ visibility: 'collapse', height: 0 }} value="*varies">*varies</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        {property.type && property.type === 'LIST' ?
                            <>
                                <Grid item xs={3}>
                                    <Typography variant='overline'>{t('pablo:pages.propertylib.possibleValue')}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Paper variant="outlined" style={{ overflow: 'overlay', height: 150 }}>
                                        <List dense
                                            style={{
                                                filter: loading ? 'blur(0.3px)' : undefined, opacity: loading ? 0.5 : undefined,
                                                overflow: 'hidden'
                                            }}
                                            subheader={
                                                <ListSubheader>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={1}>#</Grid>
                                                        <Grid item xs={3}>{t('pablo:pages.propertylib.value')}</Grid>
                                                        <Grid item xs={8}>{t('pablo:pages.propertylib.description')}</Grid>
                                                    </Grid>
                                                </ListSubheader>
                                            }
                                        >
                                            {Array.isArray(property.possibleValues) ?
                                                property.possibleValues.map((pVal: any, index: number) => (
                                                    <ListItem key={`${property._id}-pvalue-${index}`} style={{ paddingLeft: 10, height: 32 }} dense={true}>
                                                        <ListItemIcon>
                                                            {index}
                                                        </ListItemIcon>
                                                        <Grid container spacing={4} style={{ marginRight: 4 }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    style={{
                                                                        marginBottom: 0, marginTop: 0, paddingLeft: 5, paddingRight: 5,
                                                                        fontStyle: 'italic', fontSize: '12px'
                                                                    }}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    value={pVal.value}
                                                                    onChange={(e: any) => {
                                                                        setPossibleValueValueAtIndex(e.target.value, index);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <TextField
                                                                    style={{
                                                                        marginBottom: 0, marginTop: 0, paddingLeft: 5, paddingRight: 5,
                                                                        fontStyle: 'italic', fontSize: '12px'
                                                                    }}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    value={pVal.description}
                                                                    onChange={(e: any) => {
                                                                        setPossibleValueDescriptionAtIndex(e.target.value, index);
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <ListItemSecondaryAction style={{ height: 32 }}>
                                                            <IconButton onClick={() => removePossibleValueAtIndex(index)} className='fas fa-minus' />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))
                                                : <ListItem> <ListItemText
                                                    style={{
                                                        marginBottom: 0, marginTop: 0, paddingLeft: 5, paddingRight: 5,
                                                        fontStyle: 'italic', fontSize: '12px'
                                                    }}
                                                    primary={'*varies'}

                                                /></ListItem>
                                            }
                                        </List>
                                        {
                                            loading ?
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '100%'
                                                }}>
                                                    <CircularProgress />
                                                </div>
                                                : null
                                        }
                                    </Paper>
                                </Grid>
                                <Grid container item xs={1} style={{ alignSelf: 'start', textAlign: 'right' }}>
                                    <Grid item xs={12}>
                                        <IconButton onClick={addPossibleValue} className='fa fa-plus'
                                            disabled={property.possibleValues === '*varies'} />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <IconButton onClick={() => {
                                            setLoading(true);
                                            setShowFileImport(true);
                                        }} className='fa fa-file-upload' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IconButton onClick={removePossibleValues} className='fa fa-trash' disabled={property.possibleValues === '*varies'} />
                                    </Grid>
                                </Grid>
                            </>
                            : null
                        }
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <div style={{ justifyContent: 'flex-start', flexGrow: 1 }}>
                        <Typography variant='caption' style={{ fontStyle: 'italic' }}>{t('pablo:pages.propertylib.standardInfo')}</Typography>
                    </div>

                    <CTA onClick={onCancel}>{t('pablo:button.cancel')}</CTA>
                    <CTA variant='filled' type="submit" >{t('pablo:button.submit')}</CTA>
                </DialogActions>
            </form>
            <PropertySetsDialog open={openPSetModal} onClose={() => setOpenPSetModal(false)}
                onSubmit={addPropertySet} />

            <DropFileDialog
                acceptExtension={['.xlsx', '.xls']}
                onClose={() => {
                    setShowFileImport(false);
                    setLoading(false);
                }}
                fullWidth show={showFileImport}
                maxWidth="md"
                onFileSelected={(file: any) => { setImportedFile(file); }}
                templateFileName='importPropertyValueTemplate.xlsx'
                getTemplateBlob={(lng: string) => {
                    return APIServiceManager.TemplateService.getPropertyValuesImportTemplate(lng);
                }}
                disabled={importedFile === null}
                onImport={() => {
                    importPossibleValueList(importedFile);
                    setShowFileImport(false);
                    setLoading(false);
                }}
                title={t('pablo:dialog.title.importData')}
            />
        </Dialog>
    );
}

export default EditPropertyDialog;