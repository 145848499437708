import { Query } from 'material-table';
import React from 'react';
import { LinkDataList } from 'src/components';
import { APIServiceManager, LinkService } from '../../services';

function DatabasePage() {
    const getData = async (query: Query<any>) => {
        try {
            const tempLinks = await APIServiceManager.LinkService.getLinks(query);
            return {
                data: tempLinks.data,
                page: query.page,
                totalCount: tempLinks.total
            }
        } catch (error) {
            return {
                data: [],
                page: 0,
                totalCount: 0
            }
        }
    };

    return (
        <>
            <LinkDataList getData={getData} />
        </>
    )
}

export default DatabasePage;
