
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIServiceManager, PropLibraryService } from 'src/services';
import { ProjectContext } from 'src/contexts/project.context';
import { SpinnerText } from 'src/components';
import { Navigate } from 'react-router-dom';

function ProjectPropLibraryPage() {

    const projectContext = useContext(ProjectContext);

    const [projectLibId, setProjectLibId] = useState<string>();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [showRedirectionText, setShowRedirectionText] = useState<boolean>(false);

    useEffect(() => {
        let active = true;

        setTimeout(() => {
            if (active) {
                setShowRedirectionText(true)
            }

        }, 2000);

        setTimeout(() => {
            if (active) {
                setHasError(true)
            }

        }, 10000);

        return () => {
            active = false;
        }
    }, []);

    useEffect(() => {
        const fetchProjectPropLibs = async (projId: string) => {
            const _propLibSvc = APIServiceManager.getService('PropLibraryService') as PropLibraryService;
            const libs: Array<any> = await _propLibSvc.getProjectLibraries(projId, true);
            if (Array.isArray(libs) && libs.length > 0) {
                setProjectLibId(libs[0]._id);
                setRedirect(true);
            } else {
                setHasError(true);
            }
        };
        if (projectContext.currentProject && projectContext.currentProject._id && projectContext.currentProject._id.length > 0) {
            fetchProjectPropLibs(projectContext.currentProject._id!);
        }

    }, [projectContext]);

    if (projectLibId && redirect) {

        return (
            <Navigate to={`/prop/lib/${projectLibId}/properties`} state={{ library: projectContext.currentProject.name, projectId: projectContext.currentProject._id }} />
        )
    } else if (hasError) {
        return <h2>An error occured</h2>;
    } else {
        return (
            <SpinnerText text={showRedirectionText ? 'Redirection' : ''} />
        )
    }

}

export default ProjectPropLibraryPage;
