import * as helpers from '@turf/helpers';
import { simplifyGeometryToPoint } from 'dev4bim-react-mapbox-gl';
import { NonNullableObj } from 'dev4bim-react-utils';
import { AlignmentFeature } from 'src/contexts/MapPageContext';
import { v4 as uuid } from 'uuid';
import { InputType } from '../EditLocDialog';
import { pkToPoint, pointToPk } from './convertPointPk';

export type InputLocPt = { x: number | null, y: number | null, id: string };
export type InputLoc = [InputLocPt, InputLocPt, ...InputLocPt[]];
export type CheckedLoc = NonNullableObj<InputLocPt>[];

export function nullLocPt(): InputLocPt { return { x: null, y: null, id: uuid() }; }
export function nullLoc(): [InputLocPt, InputLocPt] { return [nullLocPt(), nullLocPt()]; }

export function isValidLoc(loc: InputLoc, inputType: InputType) {
    let nbNull = 0;

    const baseRes = loc.reduce((acc, { x, y }) =>
        acc
        && (
            (x != null && y != null) || (x == null && y == null && !!(++nbNull))
            || (x != null && inputType.destination == 'segment')
        ) && (inputType.modifier != 'pk' ? true : x == null || x >= 0)
        , true);

    return baseRes
        && ((inputType.destination == 'point' && loc[0].x != null)
            || loc.length - nbNull >= 2
        )
}

export function simplifyLoc(loc: InputLoc, inputType: InputType): CheckedLoc {
    if (inputType.destination == 'segment') {
        loc = loc.map((l) => ({ x: l.x, y: 0, id: l.id })) as InputLoc;
    }
    return loc.filter(({ x, y }) => x != null && y != null) as CheckedLoc;
}

export function growData(data: InputLocPt[]): InputLoc {
    if (data.length == 0) return nullLoc();
    if (data.length == 1) return [data[0], nullLocPt()];
    return data as InputLoc;
}

/**
 * prev mod != mod
 *      convert data
 * 
 * point -> line
 *      make sure there is at least two points
 * point -> segment
 *      make sure there is at least two points
 * line -> point
 *      average the points
 * segment -> point
 *      average the points
 * line -> segment
 *      take first point and the last one
 * segment -> line
 *      just take the two points
 * 
 */
export function convertData(data: InputLoc, alignment: AlignmentFeature, prev: InputType, asked: InputType): InputLoc {

    let dataNonNull = simplifyLoc(data, prev);

    if (prev.modifier != asked.modifier) {
        if (!alignment) {
            dataNonNull = dataNonNull.map(({ id }) => ({ x: 0, y: 0, id }));
        } else {
            dataNonNull = dataNonNull.map(({ x, y, id }) => {
                if (asked.modifier == 'gps') {
                    const { lng, lat } = pkToPoint({ pk: x, dist: y }, alignment);
                    return { x: lng, y: lat, id };
                } else {
                    const pk = pointToPk({ lng: x, lat: y }, alignment);
                    if (!pk) return { x: 0, y: 0, id };
                    return { x: pk.pk, y: pk.dist, id };
                }
            });
        }
    }

    let d: InputLoc;
    if (prev.destination == asked.destination) {
        return growData(dataNonNull);
    }

    if (prev.destination == 'point') {
        d = [dataNonNull.length ? dataNonNull[0] : nullLocPt(), nullLocPt()];
    } else if (asked.destination == 'point') {
        if (!dataNonNull.length) {
            d = nullLoc();
        } else if (dataNonNull.length == 1) {
            d = [dataNonNull[0], nullLocPt()];
        } else {
            const [x, y] = simplifyGeometryToPoint(helpers.lineString(dataNonNull.map(({ x, y }) => [x, y])).geometry);
            d = [{ x, y, id: dataNonNull[0].id }, nullLocPt()];
        }
    } else {
        const start = dataNonNull.length ? dataNonNull[0] : nullLocPt();
        const end = dataNonNull.length >= 2 ? dataNonNull[1] : nullLocPt();
        d = [start, end];
    }

    return d;
}
