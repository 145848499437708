import { Status } from 'src/pablo-shared';
import { Theme } from "@mui/material/styles";

export function assetIsFrozen(asset: any) {
    return !assetIsWip(asset);
}

export function assetIsWip(asset: any) {
    return statusIsWip(asset.status);
}

export function statusIsFrozen(status: string) {
    return !statusIsWip(status);
}

export function statusIsWip(status: string) {
    return !status || status === Status.WorkInProgress;
}

export const statusToColor = (status: string) => {
    return (theme: Theme) => status === Status.Validated ?
        theme.palette.success.main : status === Status.InReview ?
            theme.palette.warning.main :
            theme.palette.error.main;
}
