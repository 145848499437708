import React, { useState, useContext, useEffect, useCallback } from 'react';
import './projects.page.css';
import { Link } from 'react-router-dom';
import { Button, Link as MUILink, Snackbar } from '@material-ui/core';

import { APIServiceManager, ProjectService } from '../../../services';
import { IProject } from 'src/core';
import { ProjectContext } from 'src/contexts/project.context';
import { useTranslation } from 'react-i18next';
import StyledMaterialTable from 'src/components/ui/styled-material-table.component';
import { Alert } from '@material-ui/lab';


function ProjectsPage() {

    const { t } = useTranslation(['pablo']);
    const _projectService: ProjectService = APIServiceManager.getService('ProjectService') as ProjectService;
    const projectContext = useContext(ProjectContext);
    const [uploadResponse, setUploadResponse] = useState<{ severity: any, message: string }>({ severity: 'success', message: '' });
    const [openMessage, setOpenMessage] = useState<boolean>(false);

    const [projects, setProjects] = useState<Array<IProject>>([]);

    const loadData = useCallback(() => {
        const fetchData = async () => {
            const tempProjects = await _projectService.getProjects();
            setProjects(tempProjects);
        }
        fetchData();
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const columns: any = useCallback(() => ([
        {
            field: 'code',
            title: 'Code',
            editable: 'always'
        },
        {
            field: 'name',
            title: 'Name',
            editable: 'always'
        },
        {
            field: 'source',
            title: 'Source',
            editable: 'always',
            lookup: { 'itwin': 'Itwin', 'forge': 'Forge' },
        },
        {
            field: 'bucket',
            title: 'Autodesk Forge Bucket',
            editable: (columnDef: any, rowData: any) => rowData.source == 'forge',
        },
        {
            field: 'itwinId',
            title: 'Bentley ITwin Project Id',
            editable: (columnDef: any, rowData: any) => rowData.source == 'itwin',
        },
        {
            field: 'description',
            title: 'Description',
            editable: 'always'
        },
        {
            field: '',
            title: 'Image',
            editable: 'never',
            render: (rowData: any) => <Button component='label'>
                Upload
                <input
                    accept='image/*'
                    type='file'
                    hidden
                    onChange={async (e: any) => {
                        const response = await _projectService.putImage(rowData._id, e.target.files[0]);
                        if (response.status === 200) {
                            setUploadResponse({ severity: 'success', message: t('pablo:pages.adminProject.uploadSuccess') });
                        } else {
                            setUploadResponse({ severity: 'error', message: t('pablo:pages.adminProject.uploadError') });
                        }
                        setOpenMessage(true);
                    }}
                />
            </Button>
        },
        {
            field: '',
            title: 'Users',
            editable: 'never',
            render: (rowData: any) => <Link to={{
                pathname: `${rowData._id}/permissions`
            }} >
                Edit
            </Link>
        }
    ]), []);

    const updateProject = async (newData: any, oldData: any) => {
        await _projectService.updateProject(newData._id, {
            code: newData.code,
            name: newData.name,
            bucket: newData.bucket,
            itwinId: newData.itwinId,
            source: newData.source,
            description: newData.description
        });
        loadData();
    }

    const deleteProject = async (oldData: any) => {
        await _projectService.deleteProject(oldData._id);
        loadData();
        projectContext.refresh();
    }

    return (
        <>
            <StyledMaterialTable
                height={105}
                title={t('pablo:pages.profile.projectList')}
                columns={columns()}
                localization={{
                    body: {
                        editRow: {
                            deleteText: t('pablo:pages.profile.deleteText')
                        }
                    }
                }}
                options={{
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                data={projects}
                editable={{
                    onRowUpdate: updateProject,
                    onRowDelete: deleteProject
                }}
            />
            <Snackbar
                style={{ minWidth: 300 }}
                open={openMessage}
                autoHideDuration={6000}
                onClose={() => setOpenMessage(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setOpenMessage(false)} severity={uploadResponse.severity} style={{ minWidth: 300 }}>
                    {uploadResponse.message}
                </Alert>
            </Snackbar >
        </>
    );
}

export default ProjectsPage;
