import React, { useState, useContext, useEffect, useCallback } from 'react';
import './users.page.css';

import { APIServiceManager, UserService } from '../../../services';
import { UserContext } from 'src/contexts/user.context';
import { UserList } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';

function UsersPage() {

    const _userService: UserService = APIServiceManager.getService('UserService') as UserService;
    const userContext = useContext(UserContext);

    const [users, setUsers] = useState<Array<any>>([]);

    const loadData = useCallback(async () => {
        if (userContext.user.id) {
            const tempUsers = await _userService.getUsers();
            setUsers(tempUsers);
        }
    }, [userContext.user.id, _userService]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const updateUser = async (newData: any, oldData: any) => {
        await _userService.modifyUser(newData._id, newData.access_right);
        await loadData();
    }

    const addUser = async (newData: any) => {
        await _userService.addUser(newData);
        await loadData();
    }

    const importUser = async (newUsers: any) => {
        for (const user of newUsers) {
            await _userService.addUser(user);
        }
        await loadData();
    }

    const removeUser = async (oldData: any) => {
        await _userService.removeUser(oldData._id);
        await loadData();
    }

    return (
        <UserList
            users={users}
            editable={true}
            onAddUser={addUser}
            onRemoveUser={removeUser}
            onUpdateUser={updateUser}
            onImportUserList={importUser}
        />
    );
}

export default UsersPage;
