import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle, DropFileZone, StyledMaterialTable } from 'src/components';

interface UserListProps {
    editable: boolean;
    onImportUserList?: (newUsers: any) => Promise<any>;
    onAddUser?: (newData: any) => Promise<any>;
    onUpdateUser?: (newData: any, oldData: any) => Promise<any>;
    onRemoveUser?: (oldData: any) => Promise<any>;
    onClick?: (rowData: any) => void;
    onSelectionChange?: (rowData: any) => void;
    users: Array<any>
}

function UserList(props: UserListProps) {
    const { t } = useTranslation(['pablo']);
    const [cookies, setCookies] = useCookies(['aimspainter_admin_users_table_page_size']);
    const [show, setShow] = useState(false);
    const [importedFile, setImportedFile] = useState<any>(null);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        var reader = new FileReader();
        reader.onload = async (fe: ProgressEvent<FileReader>) => {
            if (fe.target) {
                const xlsx = await import('xlsx');
                const workbook = xlsx.read(fe.target.result, {
                    type: 'binary',
                });

                const rows = xlsx.utils.sheet_to_json(workbook.Sheets['Users']);
                if (props.onImportUserList) {
                    props.onImportUserList(rows);
                }
            }
            setShow(false);
        };
        reader.readAsBinaryString(importedFile);

    };

    return (
        <>
            <StyledMaterialTable
                height={95}
                title="Users List"
                columns={
                    [
                        {
                            field: 'firstname',
                            title: 'Firstname',
                            editable: 'onAdd',
                            validate: rowData =>
                                rowData.firstname && rowData.firstname.length < 1 ? { isValid: false, helperText: 'Firstname must be longer than 1 Chars' } : true,
                        },
                        {
                            field: 'lastname',
                            title: 'Lastname',
                            editable: 'onAdd',
                            validate: rowData =>
                                rowData.lastname && rowData.lastname.length < 2 ? { isValid: false, helperText: 'Lastname must be longer than 2 Chars' } : true,
                        },
                        {
                            field: 'name',
                            title: 'Name',
                            editable: 'onAdd',
                            validate: rowData =>
                                rowData.lastname && rowData.lastname.length < 2 ? { isValid: false, helperText: 'Lastname must be longer than 2 Chars' } : true,
                        },
                        {
                            field: 'email',
                            title: 'Email',
                            editable: 'onAdd',

                            validate: (rowData: any) =>
                                rowData.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(rowData.email) ? { isValid: false, helperText: 'Enter a valid email adress' } : true,
                        },
                        {
                            field: 'access_right',
                            title: 'User Right',
                            editable: 'always',
                            lookup: { 'admin': 'Admin', 'propManager': 'Property Manager', 'user': 'User', 'visitor': 'Visitor' },
                            initialEditValue: 'user'
                        }
                    ]
                }
                onSelectionChange={(rows) => {
                    if (props.onSelectionChange) {
                        props.onSelectionChange(rows);
                    }
                }}
                onRowClick={(evt, currentSelectedRow) => {
                    if (props.onClick) {
                        props.onClick(currentSelectedRow);
                    }
                }}
                options={{
                    toolbar: props.editable,
                    paging: true,
                    exportButton: props.editable,
                    selection: props.editable,
                    pageSize: cookies.aimspainter_admin_users_table_page_size ? parseInt(cookies.aimspainter_admin_users_table_page_size) : 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    loadingType: 'overlay'
                }}
                onChangeRowsPerPage={(pageSize: number) => {
                    setCookies('aimspainter_admin_users_table_page_size', pageSize, { path: '/' });
                }}
                data={props.users}
                actions={[
                    {
                        icon: 'upload',
                        tooltip: t('pablo:tooltip.updatePermission'),
                        isFreeAction: true,
                        onClick: (event: any, rowData: any) => {
                            setShow(true);
                        }
                    },
                ]}
                editable={{
                    isEditable: rowData => props.editable,
                    isDeletable: rowData => props.editable,
                    onRowAdd: props.editable ? props.onAddUser : undefined,
                    onRowUpdate: props.editable ? props.onUpdateUser : undefined,
                    onRowDelete: props.editable ? props.onRemoveUser : undefined,
                }} />
            <Dialog onClose={() => setShow(false)} fullWidth open={show} onSubmit={() => setShow(false)}
                maxWidth="md">
                <CloseDialogTitle onClose={() => setShow(false)} title={t('pablo:dialog.title.importData')} />
                <DialogContent dividers>
                    <DropFileZone onFileSelected={(file: any) => { setImportedFile(file); }} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus disabled={importedFile === null} onClick={handleSubmit} color="inherit">
                        {t('pablo:button.import')}
                    </Button>
                    <Button autoFocus onClick={() => setShow(false)} color="inherit">
                        {t('pablo:button.undo')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserList;
