import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Status } from 'src/pablo-shared';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateSnapshotModal } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import { assetIsWip } from 'src/core/business/asset.business';
import { APIServiceManager, FrontServiceManager } from 'src/services';
import RelatedAsset from './related-asset.component';
import SingleAssetData from './single-asset-data.component';

const emptyAsset = {
    structure: { fullCode: '', code: '' },
    name: '',
    properties: [],
    status: '',
    history: [],
};

const useStyles = makeStyles(() => ({
    button: {
        margin: '5px 10px 5px 0px',
    }
}));

export default function SingleAsset() {
    const { t } = useTranslation(['pablo']);
    const classes = useStyles();
    const projectContext = useContext(ProjectContext);

    const params = useParams<{ id: string }>();
    const [asset, setAsset] = useState(emptyAsset);
    const [snapshotWindowOpen, setSnapshotWindowOpen] = useState(false);
    const [snapshotSummaries, setSnapshotSummaries] = useState<any>([]);

    const [hasEditorRole, setHasEditorRole] = useState(false);
    const [hasValidatorRole, setHasValidatorRole] = useState(false);
    const [showValidatorButtons, setShowValidatorButtons] = useState(false);
    const [showRequestValidationButton, setShowRequestValidationButton] = useState(false);

    useEffect(() => {
        setShowValidatorButtons(asset.status === Status.InReview && hasValidatorRole);
        setShowRequestValidationButton(assetIsWip(asset) && (hasEditorRole || hasValidatorRole));
    }, [asset, hasEditorRole, hasValidatorRole]);

    useEffect(() => {
        if (params.id) {
            fetchSnapshotSummaries(params.id);
        }
    }, [asset]);

    useEffect(() => {
        setHasEditorRole(projectContext.currentProject.permissions.includes('asset-editor'));
        setHasValidatorRole(projectContext.currentProject.permissions.includes('asset-validator'));
    }, [projectContext]);

    const handleCreateSnapshotWindowSubmit = async (name: string) => {
        setSnapshotWindowOpen(false);
        const newSnapshotSummary = await FrontServiceManager.AssetSnapshotService.createSnapshot(params.id, name);
        snapshotSummaries.unshift(FrontServiceManager.AssetSnapshotService.formatAssetSummary(newSnapshotSummary));
        setSnapshotSummaries([...snapshotSummaries]);
    };

    const fetchAsset = useCallback(async (id: string) => {
        const query = await APIServiceManager.AssetService.getAssetById(id, true);
        if (Array.isArray(query.data) && query.data.length > 0) {
            const tempAsset = query.data[0];
            let properties = [...tempAsset.properties];
            properties = properties.map(p => { p.id = p._id; return p; });
            tempAsset.properties = properties;
            setAsset(tempAsset);
        }
    }, []);

    const fetchSnapshotSummaries = useCallback(async (id: string) => {
        const query = await APIServiceManager.AssetService.getAssetSnapshotSummaries(id);
        if (Array.isArray(query.data) && query.data.length > 0) {
            const summaries = query.data.map((raw: any) => FrontServiceManager.AssetSnapshotService.formatAssetSummary(raw));
            setSnapshotSummaries(summaries);
        }
    }, []);

    const updateFromQuery = (query: any) => {
        if (query.data) {
            asset.status = query.data.status;
            asset.history = query.data.history;
            setAsset({ ...asset });
        }
    };

    const validate = async () => {
        const query = await APIServiceManager.AssetService.validateAsset(params.id);
        updateFromQuery(query);
    };

    const reject = async () => {
        const query = await APIServiceManager.AssetService.rejectAsset(params.id);

        updateFromQuery(query);
    };

    const requestValidation = async () => {
        const query = await APIServiceManager.AssetService.requestAssetReview(params.id);
        updateFromQuery(query);
    };

    useEffect(() => {
        if (params.id) {
            fetchAsset(params.id);
            fetchSnapshotSummaries(params.id);
        }
    }, [params.id]);


    return (
        <Grid container spacing={2}>
            <Grid
                container
                xs={12}
                justifyContent="flex-end"
            >
                {showRequestValidationButton &&
                    <Button
                        className={classes.button}
                        variant="outlined"
                        onClick={requestValidation}
                    >
                        {t('pablo:components.asset.single.requestValidation')}
                    </Button>
                }
                {showValidatorButtons &&
                    <div>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={validate}
                        >
                            {t('pablo:components.asset.single.validate')}
                        </Button>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={reject}
                        >
                            {t('pablo:components.asset.single.reject')}
                        </Button>
                    </div>
                }

                <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={() => setSnapshotWindowOpen(true)}
                >
                    {t('pablo:components.asset.single.createSnapshot')}
                </Button>
            </Grid>
            <Grid item xs={8}>
                <SingleAssetData
                    id={params.id!}
                    assetSummary={{
                        code: asset.structure.code,
                        name: asset.name,
                        fullCode: asset.structure.fullCode,
                        status: asset.status
                    }}
                    properties={asset.properties}
                    snapshotSummaries={snapshotSummaries}
                    history={asset.history}
                />
            </Grid>
            <Grid item xs={4}>
                <RelatedAsset
                    id={params.id!}
                    status={asset.status}
                />
            </Grid>
            <CreateSnapshotModal
                open={snapshotWindowOpen}
                onSubmit={handleCreateSnapshotWindowSubmit}
                onClose={() => setSnapshotWindowOpen(false)}
                title={t('pablo:components.asset.single.createSnapshot')}
            />
        </Grid>
    );
}
