import CustomPropertyPanel from "./properties/custom-properties-panel.component";

const ExtensionId = 'AIMSPainter.CustomPropertyPanelExtension.v2';

export class CustomPropertyPanelExtension extends Autodesk.Viewing.Extension {
    panel: any;
    // constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
    //     super(viewer, options);
    // }

    load() {
        console.log(`${ExtensionId} has been loaded`);
        return true;
    }

    unload() {
        if (this.panel == null) return true;
        var ext = this.viewer.getExtension('Autodesk.PropertiesManager');
        this.panel = null;
        (ext as any).setDefaultPanel();
        return true;
    }

    onToolbarCreated = () => {
        this.panel = new CustomPropertyPanel(this.viewer, this.options);
        this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (e) => {
            if (e.extensionId !== 'Autodesk.PropertiesManager') return;
            var ext = this.viewer.getExtension('Autodesk.PropertiesManager');
            (ext as any).setPanel(this.panel);
        })
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(`${ExtensionId}`, CustomPropertyPanelExtension);
