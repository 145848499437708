// *******************************************
// Custom Property Panel
// *******************************************

import ReactDOM from "react-dom";
import React from "react";
import { Guid } from "guid-typescript";
import EditableProp from "./editable-property.component";
import { ForgeViewerExtensionOptions } from "../forge-viewer.interface";

function FindReact(dom: any, traverseUp = 0) {
    const key: any = Object.keys(dom).find(key => key.startsWith("__reactInternalInstance$"));
    const domFiber = dom[key];
    if (domFiber == null) return null;

    const GetCompFiber = (fiber: any) => {
        //return fiber._debugOwner; // this also works, but is __DEV__ only
        let parentFiber = fiber.return;
        while (typeof parentFiber.type == "string") {
            parentFiber = parentFiber.return;
        }
        return parentFiber;
    };
    let compFiber = GetCompFiber(domFiber);
    for (let i = 0; i < traverseUp; i++) {
        compFiber = GetCompFiber(compFiber);
    }
    return compFiber;
}

export default class CustomPropertyPanel extends Autodesk.Viewing.Extensions.ViewerPropertyPanel {
    _nodeId: number = -1;
    viewer: Autodesk.Viewing.GuiViewer3D;
    _options: ForgeViewerExtensionOptions;
    _linkId: string = '';

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: ForgeViewerExtensionOptions) {
        super(viewer)
        this.viewer = viewer;
        this._options = options;

    }

    setAggregatedProperties = async (propertySet: any) => {

        const dbIds = propertySet.getDbIds();
        var names = Object.keys(propertySet.getValue2PropertiesMap('Name'));

        const aimsPainterPropKeys: string[] = [];
        const aimsPainterProperties: any = [];

        await Promise.all(dbIds.map(async (dbId: number, index: number) => {
            const linksAssetProperties = await this._options.getProperties(this._options.urn, dbId.toString());

            if (linksAssetProperties.length > 0) {
                for (const assetProperties of linksAssetProperties) {
                    const assetName = assetProperties.link.asset.name;
                    const assetCode = assetProperties.link.asset.structure.code;
                    const assetId = assetProperties.link.asset._id;

                    const category = `AIMSPainter - ${assetName} Properties`;
                    aimsPainterPropKeys.push(`${category}/Asset`);
                    aimsPainterPropKeys.push(`${category}/Type of Asset`);
                    aimsPainterPropKeys.push(`${category}/Relation to Asset`);
                    // aimsPainterCategories.push(category);
                    aimsPainterProperties.push({
                        dbId: dbId,
                        displayCategory: category,
                        parentName: names[index],
                        displayName: `AIMSPainterProp;true;${assetId};Name;Asset`,
                        displayValue: assetName,
                        type: 20,
                        treeItemName: `${category}/Asset`
                    });
                    aimsPainterProperties.push({
                        dbId: dbId,
                        displayCategory: category,
                        parentName: names[index],
                        displayName: `AIMSPainterProp;false;null;null;Type of Asset`,
                        displayValue: assetCode,
                        type: 20,
                        treeItemName: `${category}/Type of Asset`
                    });
                    aimsPainterProperties.push({
                        dbId: dbId,
                        displayCategory: category,
                        parentName: names[index],
                        displayName: `AIMSPainterProp;false;null;null;Relation to Asset`,
                        displayValue: assetProperties.link.type,
                        type: 20,
                        treeItemName: `${category}/Relation to Asset`
                    });

                    for (const prop of assetProperties.properties) {
                        aimsPainterPropKeys.push(`${category}/${prop.key}`);


                        aimsPainterProperties.push({
                            dbId: dbId,
                            displayCategory: category,
                            parentName: names[index],
                            displayName: `AIMSPainterProp;true;${assetId};${prop._id};${prop.key}`,
                            displayValue: prop.value ? prop.value : 'null',
                            precision: '',
                            type: 20,
                            treeItemName: `${category}/${prop.key}`
                        })
                    }
                }
            }
        }));

        aimsPainterPropKeys.forEach((prop: string) => {
            propertySet.map[prop] = new Array(dbIds.length);
            dbIds.forEach((dbId: number, index: number) => {
                const dbIdProp = aimsPainterProperties.filter((test: any) => test.treeItemName === prop && test.dbId === dbId);
                if (dbIdProp.length > 0) {
                    propertySet.map[prop][index] = dbIdProp[0];
                } else {
                    const noValueProp = {
                        dbId: dbId,
                        displayCategory: prop.substring(0, prop.indexOf('/')),
                        parentName: names[index],
                        displayName: `${prop.substring(prop.indexOf('/') + 1)}`,
                        displayValue: 'N/A',
                    }
                    propertySet.map[prop][index] = noValueProp
                }

            });

        });

        (Autodesk.Viewing.Extensions.ViewerPropertyPanel.prototype as any).setAggregatedProperties.call(this, propertySet);
        // super.setAggregatedProperties(propertySet)
    }


    setProperties = async (properties: Array<{ displayName: string, displayValue: any }>) => {
        let _this: any = this;
        super.setProperties(properties);
        const linksAssetProperties = await this._options.getProperties(this._options.urn, _this.propertyNodeId);
        for (const assetProperties of linksAssetProperties) {
            const assetName = assetProperties.link.asset.name;
            const assetCode = assetProperties.link.asset.structure.code;
            const assetId = assetProperties.link.asset._id;

            this.addProperty(`AIMSPainterProp;true;${assetId};Name;Asset`, assetName, `AIMSPainter - ${assetName} Properties`);
            this.addProperty('AIMSPainterProp;false;null;null;Type of Asset', assetCode, `AIMSPainter - ${assetName} Properties`);
            this.addProperty('AIMSPainterProp;false;null;null;Relation to Asset', assetProperties.link.type, `AIMSPainter - ${assetName} Properties`);
            for (const prop of assetProperties.properties) {
                this.addProperty(`AIMSPainterProp;true;${assetId};${prop._id};${prop.key}`, prop.value, `AIMSPainter - ${assetName} Properties`);
            }
        }
        //Only one possible combination of urn + objectId
    }

    onPropertyClick = (property: any, event: Event) => {
        const test0 = FindReact(event.target);
        if (test0) {
            if (test0.child && test0.child.memoizedProps && test0.child.memoizedProps.id == (event.target as any).id) {
                test0.child.memoizedProps.onClick();
            }
        }
    }


    displayProperty = (property: any, parent: HTMLElement, options: Autodesk.Viewing.UI.DisplayCategoryOptions) => {
        if (property.category && property.category.startsWith('AIMSPainter - ')) {
            const propsOpts = property.name.split(';');
            const propName = propsOpts[4];

            if (propsOpts[1] === 'true') {
                var name = document.createElement('div');
                var text = propName;

                name.textContent = text;
                name.title = text;
                name.className = 'property-name';

                var separator = document.createElement('div');
                separator.className = 'separator';

                parent.appendChild(name);
                parent.appendChild(separator);

                const guid = Guid.create().toString();

                var value = document.createElement('div');
                value.id = guid;
                value.className = 'property-value';

                parent.appendChild(value);
                const reactElement = (
                    <EditableProp value={property.value === 'null' ? '' : property.value} guid={guid} onCommitValue={(newValue: string) => {
                        this._options.updateProperty(propsOpts[2], propsOpts[3], newValue);
                    }} />
                )
                ReactDOM.render(reactElement, value);


                //(Autodesk.Viewing.UI.PropertyPanel.prototype as any).onPropertyClick.call(this, this.setTryProperty);

                return [name, value]
            } else {
                property.name = propName;
                return super.displayProperty(property, parent, options);
            }
        }
        return super.displayProperty(property, parent, options);
    }



    setNodeProperties = (nodeId: number) => {
        (Autodesk.Viewing.Extensions.ViewerPropertyPanel.prototype as any).setNodeProperties.call(this, nodeId);
        this._nodeId = nodeId; // store the dbId for later use
    };
}
