import { API, APIServiceManager } from '../..';

class PropSetService {
    api: API = APIServiceManager.API;

    name() {
        return 'PropSetService';
    }

    async getSets(query: any, withProperties: boolean = false): Promise<any> {
        try {
            const limitToProject = this.api._project && this.api._project._id ? this.api._project._id : null;
            const req = await this.api.axios.get('/propset', { params: { limitToProject, withProperties, ...query } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getProjectSets(projectId: string, query: any, withProperties: boolean = false): Promise<any> {
        try {
            const req = await this.api.axios.get('/propset', { params: { projectId, isProject: true, withProperties, ...query } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getStandardsSets(query: any, withProperties: boolean = false): Promise<any> {
        try {

            const req = await this.api.axios.get('/propset', { params: { isProject: false, withProperties, ...query } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addSet(name: string, isProject: boolean): Promise<any> {
        try {
            const projectId = this.api._project && this.api._project._id ? this.api._project._id : null;
            const set = isProject ? {
                name, isProject, projectId
            } : {
                name, isProject
            }
            const req = await this.api.axios.post('/propset', set);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateSet(id: string, set: any): Promise<any> {
        try {
            const req = await this.api.axios.put(`/propset/${id}`, set);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removeSet(id: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/propset/${id}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addMultiplePropInSet(id: string, propertyIds: Array<string>): Promise<any> {
        try {
            const req = await this.api.axios.post(`/propset/${id}/properties`, propertyIds);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async addPropInSet(id: string, propertyId: string): Promise<any> {
        try {
            const req = await this.api.axios.put(`/propset/${id}/property/${propertyId}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removePropFromSet(id: string, propertyId: string): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/propset/${id}/property/${propertyId}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async removePropertiesFromSet(id: string, propertyIds: Array<string>): Promise<any> {
        try {
            const req = await this.api.axios.delete(`/propset/${id}/properties`, {
                data: propertyIds
            });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

export { PropSetService };