import { GridFilterModel } from '@mui/x-data-grid-pro';
import { Query } from 'material-table';
import { APIServiceManager } from 'src/services';

class AssetTableQueryService {

    name() {
        return 'AssetTableQueryService';
    }

    async getAsset(query: Query<any>): Promise<{
        data: Array<any>
        properties: Array<any>
        categories: Array<any>
        page: number
        totalCount: number
    }> {
        try {
            const searchTerm = query.search && query.search.length > 0 ? query.search : undefined;
            const queryResult = await APIServiceManager.AssetService.getAsset(query.page, query.pageSize, searchTerm);

            const data = queryResult.data.map((asset: any, index: number) => {
                asset.objectProperties = asset.properties.reduce((acc: any, curr: any) => {
                    acc[curr.key] = curr.value;
                    return acc;
                }, {});
                asset.colorIndex = index;
                return asset;
            });
            return {
                data: data,
                properties: queryResult.properties,
                categories: queryResult.categories,
                page: query.page,
                totalCount: queryResult.total
            };
        } catch (error) {
            return {
                data: [],
                properties: [],
                categories: [],
                page: 0,
                totalCount: 0
            };
        }
    }

    async getAssetData(page: number, pageSize: number, filter: GridFilterModel | undefined) {
        const query = {};
        const queryResult = await APIServiceManager.AssetService.getAssetList(page, pageSize, filter);
        queryResult.data.map((r: any) => { r.id = r._id; return r; });
        return queryResult;
    }
}

export { AssetTableQueryService };
