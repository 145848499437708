import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetList } from 'src/components';
import { Tab } from 'src/components/Tabs/Tab';
import { Tabs } from 'src/components/Tabs/Tabs';
import { ProjectContext } from 'src/contexts/project.context';
import MapPage from '../map/map.page';

function AssetRegisterView() {
    const { t } = useTranslation(['pablo']);
    const projectContext = useContext(ProjectContext);
    console.log(projectContext.currentProject);

    return (
        <Tabs>
            <Tab label='List' component={<AssetList />} sx={{ p: 2 }} />
            {
                projectContext.currentProject.functionality && projectContext.currentProject.functionality.mapEnable ?
                    <Tab label={t('pablo:router.map')} component={<MapPage />} sx={{ p: 0 }} /> :
                    null
            }
        </Tabs>
    );
}
export default AssetRegisterView;
