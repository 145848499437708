import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    // style rule
    root: (props: any) => ({
        display: 'flex',
        flexDirection: props.column ? 'row' : 'column',
        alignItems: props.column ? 'center' : 'flex-start',
        margin: props.margin ? props.margin : '20px'
    }),
    'pie-legend': (props: any) => ({
        marginBottom: '75px'
    }),
    arc: {
        '& .arc-hovering': {
            opacity: '0.9',
        },
        '& text': {
            fill: 'black',
            fontSize: 10
        },
    },
    table: {
        margin: '5px 10px',
        '& svg, rect': {
            width: 16,
            height: 16
        },
        '& td': {
            padding: '4px 5px',
            textAlign: 'left'
        },
    }
});

function Arc(props: any) {
    const [isHover, setIsHover] = useState(false);

    const format = d3.format('.0f');

    const arc: any = d3
        .arc()
        .innerRadius(props.innerRadius)
        .outerRadius(0.9 * props.outerRadius);

    const arcOver: any = d3
        .arc()
        .innerRadius(props.innerRadius)
        .outerRadius(props.outerRadius);


    return (
        <g className={props.class} key={props.index}
            onClick={(e: any) => {
                e.preventDefault();
                props.onClick(props.data.data);
            }}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}>
            {
                isHover ?
                    <path className="arc-hovering" d={arcOver(props.data)} fill={props.colors(props.index)} /> :
                    <path d={arc(props.data)} fill={props.colors(props.index)} />
            }
            <text
                transform={`translate(${arc.centroid(props.data)})`}
                textAnchor="middle"
                alignmentBaseline="middle"
            >
                {format(props.data.value)}
            </text>
        </g>);
}

interface PieChartProps {
    onClick: (d: any) => void;
    data: Array<any>;
    width: number;
    height: number;
    innerRadius: number;
    outerRadius: number;
    column?: boolean;
    margin?: string;
}

export default function PieChart(props: PieChartProps) {
    const ref = useRef(null);
    const classes = useStyles(props);

    const createPie = d3
        .pie()
        .value((d: any) => d.value)
        .sort(null);

    const colors = d3.scaleOrdinal(d3.schemeCategory10);
    const [data, setData] = useState<Array<any>>([]);

    useEffect(() => {
        setData(createPie(props.data));
    }, [props.data]);

    return (
        <div ref={ref} className={classes.root} >
            <svg width={props.width} height={props.height} style={{ alignSelf: 'center' }}>
                <g transform={`translate(${props.width / 2}, ${props.height / 2})`}>
                    {data.map((d: any, i: any) => (
                        <Arc
                            key={i}
                            data={d}
                            index={i}
                            innerRadius={props.innerRadius}
                            outerRadius={props.outerRadius}
                            colors={colors}
                            onClick={props.onClick}
                            class={classes.arc}
                        />
                    ))}
                </g>
            </svg>
            <table className={classes.table}>
                <tbody>
                    {data.map((d: any, i: any) => (
                        <tr key={i}>
                            <td>
                                <svg>
                                    <rect fill={colors(i)} onClick={(e: any) => {
                                        e.preventDefault();
                                        props.onClick(d.data);
                                    }} />
                                </svg>
                            </td>
                            <td>
                                {d.data.label}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
