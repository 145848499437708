import i18n from 'i18next';
import forge from './en/pablo.json';
import pabloEN from './en/pablo.json';
import pabloFR from './fr/pablo.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const resources = {
    en: {
        pablo: pabloEN,
    },
    fr: {
        pablo: pabloFR,
    },
} as const;

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
        //lng: 'fr',
        detection: options,
        ns: ['pablo', 'forge'],
        debug: false,
        resources,
        // interpolation: {
        //     escapeValue: true, // not needed for react as it escapes by default
        // },
    });