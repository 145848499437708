import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Button, Dialog, DialogActions,
    DialogContent, Fab, Grid, List, ListItem,
    Paper, Stack, Switch, TextField, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CloseDialogTitle } from 'src/components';
import { APIServiceManager, PropSetService } from 'src/services';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        display: 'inline-flex',
        flexDirection: 'column'
    },
    form: {
        minWidth: 300,
        marginLeft: 10,
    },
    paper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px'
    },
    list: {
        padding: 20,
        width: '100%',
        maxHeight: 300,
        display: 'grid'
    },
    listItem: {
        minHeight: '4rem',
        marginBottom: '0.3rem',

    },
    fab: {
        position: 'absolute',
        top: 70,
        right: 30,
    },
});

function PropSetPage() {
    const { t } = useTranslation(['pablo']);

    const [propSets, setPropSets] = useState<any>([]);
    const classes = useStyles();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [name, setName] = useState('');
    const [isLimitedToProject, setIsLimitedToProject] = useState(true);

    const handleNameChange = (event: any) => {
        setName(event.target.value);
    };

    const handleSwitchChange = (event: any) => {
        setIsLimitedToProject(event.target.checked);
    };

    const fetchPropSets = async () => {
        const propSetSvc = APIServiceManager.getService('PropSetService') as PropSetService;
        const sets = await propSetSvc.getStandardsSets(null);
        setPropSets(sets);
    };

    useEffect(() => {
        fetchPropSets();
    }, []);

    const addNewSet = async (name: string, project: boolean) => {
        const propSetSvc = APIServiceManager.getService('PropSetService') as PropSetService;
        await propSetSvc.addSet(name, project);
        await fetchPropSets();
    };

    const removeSet = async (id: string) => {
        const propSetSvc = APIServiceManager.getService('PropSetService') as PropSetService;
        await propSetSvc.removeSet(id);
        await fetchPropSets();
    };

    return (

        <div className={classes.root}>
            <Stack direction='row' justifyContent='space-between'>
                <h3>{t('pablo:pages.propSet.libTitle')}</h3>
                <Fab size="small" sx={{ mr: 4 }}>
                    <AddIcon onClick={() => setShowModal(true)} />
                </Fab>
            </Stack>
            <Grid container>
                <Grid style={{ padding: '0px 45px', marginBottom: '-15px' }} container item xs={12} spacing={1}>
                    <Grid item xs={10}>{t('pablo:table.headers.name')}</Grid>
                    <Grid item xs={1}>{t('pablo:table.headers.properties')}</Grid>
                </Grid>
                <List className={classes.list}>
                    {propSets.map((propSet: any) => {
                        const args: any = {
                            component: Link,
                            to: `/prop/set/${propSet._id}/properties`,
                            state: { propSetName: propSet.name }
                        };
                        return (
                            <ListItem className={classes.listItem} key={`item-${propSet._id}`} {...args} >
                                <Paper className={classes.paper} >
                                    <Grid container item xs={12} spacing={1} style={{ alignItems: 'center' }}>
                                        <Grid item xs={10}>
                                            <Typography variant='h6'>{propSet.name}</Typography>
                                        </Grid>

                                        <Grid item xs={1}>{propSet.propertyIds.length}</Grid>
                                        <Grid item xs={1}>
                                            <Button onClick={(event: any) => {
                                                event.preventDefault();
                                                removeSet(propSet._id);
                                            }}
                                                disabled={propSet.propertyIds.length > 0}>
                                                <DeleteOutlineIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>

            <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth='md' fullWidth>
                <CloseDialogTitle onClose={() => setShowModal(false)} title={'Add new set'} />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid container item xs={12}>
                            <Grid xs={5}>
                                <Typography variant='overline'>{t('pablo:table.headers.name')}</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <TextField value={name} fullWidth
                                    onChange={handleNameChange} />

                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid xs={5}>
                                <Typography variant='overline'>{t('pablo:pages.propertylib.limitToProject')}</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Switch checked={isLimitedToProject}
                                    onChange={handleSwitchChange} />

                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModal(false)}>{t('pablo:button.cancel')}</Button>
                    <Button autoFocus onClick={() => {
                        setShowModal(false);
                        addNewSet(name, isLimitedToProject);
                    }}>{t('pablo:button.add')}</Button>
                </DialogActions>
            </Dialog>
        </div >

    );
}

export default PropSetPage;
