import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@mui/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import fr from 'javascript-time-ago/locale/fr.json';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import withClearCache from './clear-cache';
import './index.css';
import { APIServiceManager, FrontServiceManager } from './services';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_MUI_PRO_LICENCE) {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENCE);
}

const generateClassName = createGenerateClassName({
    productionPrefix: 'pablo-',
});

APIServiceManager.registerAllService();
FrontServiceManager.registerAllService();

const ClearCacheComponent = process.env.NODE_ENV === 'development' ? App : withClearCache(App);

const themeObj: any = (prefersDarkMode: boolean) => ({
    palette: {
        type: prefersDarkMode ? 'dark' : 'light',
        background: {
            default: prefersDarkMode ? '#0d1117' : '#F5F5F5',
        },
        primary: {
            main: '#111111',
            dark: '#232c35'
        },
        secondary: {
            main: '#2C3E4E'
        },
        tertiary: {
            main: '#643c5a',
        },
        info: {
            light: '#E3E4E6',
            main: '#9E9E9E'
        },
    },
});

const StyledApp: FunctionComponent = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(() => {
        return createTheme(themeObj(prefersDarkMode));
    }, [prefersDarkMode]);

    return (
        <ThemeProvider theme={theme} >
            <StylesProvider injectFirst generateClassName={generateClassName}>
                <CssBaseline />
                {/* <React.StrictMode> */}
                <ClearCacheComponent />
                {/* </React.StrictMode> */}
            </StylesProvider>

        </ThemeProvider >
    );
};

ReactDOM.render(
    (<StyledApp />),
    document.getElementById('root')
);

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
