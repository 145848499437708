import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { cloneNode, TreeData } from 'editable-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import ExtendedCodesModal from './extended-codes-modal.component';

export default function ModalStructureNode(props: {
    data: TreeData,
    onSubmit: (event: any, oldData: TreeData, newData: TreeData) => void,
    onCancel: () => void,
}) {
    const [open, setOpen] = useState(true);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [extendedCodes, setExtendedCodes] = useState<Array<any>>([]);
    const [inherit, setInherit] = useState(true);
    const [showExtCodeModal, setShowExtCodeModal] = useState(false);
    const { t } = useTranslation(['pablo']);

    useEffect(() => {
        setCode(props.data.extendedData.code);
        setName(props.data.extendedData.name);
        setInherit(props.data.extendedData.inherit);
        if (Array.isArray(props.data.extendedData.extendedCodes)) {
            setExtendedCodes(props.data.extendedData.extendedCodes);
        }
    }, [props.data]);

    const setExtendedCodeAtIndex = (newValue: string, index: number) => {
        const newExtCodes = [...extendedCodes];
        newExtCodes[index].value = newValue;
        setExtendedCodes(newExtCodes);
    };

    const addExtendedCode = () => {
        setShowExtCodeModal(true);
    };

    const removeExtendedCodeAtIndex = (index: number) => {
        const newArr = [...extendedCodes];
        newArr.splice(index, 1);
        setExtendedCodes(newArr);
    };

    const handleClose = () => {
        props.onCancel();
        setOpen(false);
    };
    const handleSubmit = (event: any) => {
        event.preventDefault();
        setOpen(false);
        const newNode = cloneNode(props.data);
        newNode.extendedData.code = code;
        newNode.extendedData.name = name;
        newNode.extendedData.inherit = inherit;
        newNode.extendedData.extendedCodes = extendedCodes;
        props.onSubmit(event, props.data, newNode);
    };

    return (
        <Dialog open={open}
            onClose={handleClose}
            onClick={(e: any) => e.stopPropagation()}
            fullWidth={true} maxWidth='sm' >
            <CloseDialogTitle onClose={handleClose} title={t('pablo:dialog.title.editStructure')} />
            <form onSubmit={handleSubmit} >
                <DialogContent dividers>


                    <Grid container spacing={2} rowSpacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Code"
                                variant="outlined"
                                value={code}
                                inputProps={{ pattern: '[a-zA-Z0-9_-]+', title: t('pablo:components.tree.errorInCode') }}
                                onClick={(e: any) => e.preventDefault()}
                                onChange={(e: any) => setCode(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label={t('pablo:table.headers.name')}
                                variant="outlined"
                                value={name}
                                onClick={(e: any) => e.preventDefault()}
                                onChange={(e: any) => setName(e.target.value)}
                            />
                        </Grid>
                        <Divider style={{ width: '100%', margin: '8px 0px' }} />
                        <Grid item container rowSpacing={1} >
                            <Grid item container >
                                <Grid item xs={11}>
                                    <Typography>{t('pablo:table.headers.extCode')}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton size="small" onClick={addExtendedCode} className='fa fa-plus' />
                                </Grid>
                            </Grid>
                            <Grid item container rowSpacing={2}>
                                {extendedCodes.map((code: any, index: number) => (
                                    <Grid item container key={code.extendedCodeId}>
                                        <Grid item xs={11} key={index}>
                                            <TextField
                                                required
                                                fullWidth
                                                label={code.name}
                                                variant="outlined"
                                                value={code.value}
                                                onClick={(e: any) => e.preventDefault()}
                                                onChange={(e: any) => setExtendedCodeAtIndex(e.target.value, index)}
                                            />
                                        </Grid>
                                        <Grid xs={1} style={{ alignSelf: 'center' }}>
                                            <IconButton size="small" onClick={() => {
                                                removeExtendedCodeAtIndex(index);
                                            }} className='fa fa-minus' />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Divider style={{ width: '100%', margin: '8px 0px' }} />
                        <Grid item xs={12}>
                            <FormControlLabel
                                onClick={(e: any) => e.stopPropagation()}
                                control={
                                    <Checkbox
                                        checked={inherit}
                                        onChange={(event: any, checked: boolean) => {
                                            setInherit(checked);
                                        }}
                                    />
                                }
                                label={t('pablo:dialog.assetModal.inheritProp')}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('pablo:button.cancel')}
                    </Button>

                    <Button type='submit'>
                        {t('pablo:button.submit')}
                    </Button>
                </DialogActions>
            </form>
            <ExtendedCodesModal open={showExtCodeModal} onClose={() => setShowExtCodeModal(false)} onSubmit={(extendedCode: Array<any>) => {
                setShowExtCodeModal(false);
                const extCodeIds = extendedCodes.map(e => e.extendedCodeId);
                let newArr = [...extendedCodes].concat(extendedCode.map((code: any) => {
                    if (!extCodeIds.includes(code._id)) {
                        return {
                            name: code.name,
                            extendedCodeId: code._id,
                            value: ''
                        };
                    }
                })).filter(e => e != undefined);
                setExtendedCodes(newArr);
            }} />
        </Dialog >
    );
}