import { API, APIServiceManager } from '../..';
import { IUser } from 'src/core/interface/user';

class UserService {
	api: API = APIServiceManager.API;

	name() {
	    return 'UserService';
	}

	// TODO: we don't have the firstname or lastname fields
	async getUser(email: string, firstname?: string, lastname?: string): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/users', {
	            params: {
	                email, firstname, lastname
	            }
	        });
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}


	async getCurrentUser(): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/users/me');
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async getUsers(): Promise<any> {
	    try {
	        const req = await this.api.axios.get('/users');
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async modifyUser(id: string, access_right: string): Promise<any> {
	    try {
	        const req = await this.api.axios.put(`/users/${id}/${access_right}`);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async addUser(user: IUser): Promise<any> {
	    try {
	        const req = await this.api.axios.post('/users', user);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

	async removeUser(id: string): Promise<any> {
	    try {
	        const req = await this.api.axios.delete(`/users/${id}`);
	        return req.data.data;
	    } catch (error) {
	        console.log(error);
	        throw error;
	    }
	}

}

export { UserService };
