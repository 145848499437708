
import ReactDOM from 'react-dom';
import React from 'react';

import PainterEditor from '../../../painter/painter-panel.component';

import ForgeViewerEmitter from '../forge.event';

import { paintObjects, getSelectionIds, getVisibleObjectIds, showPaintedInfo, unPaintObjects } from '../viewer-helper';
import { ForgeViewerExtensionOptions } from '../forge-viewer.interface';

export default class PainterEditorPanelExtension extends Autodesk.Viewing.UI.DockingPanel {

    DOMContent: HTMLDivElement;
    viewer: any;
    reactNode: any;
    _options: ForgeViewerExtensionOptions;
    _visible: boolean = false;

    constructor(
        viewer: Autodesk.Viewing.GuiViewer3D,
        id: string,
        options: any
    ) {
        super(viewer.container as HTMLElement, id, 'AIMSPainter Editor', options);
        this._options = options
        this.viewer = viewer;

        this.container.classList.add('editor-panel');
        this.container.classList.add('react-docking-panel');
        this.DOMContent = document.createElement('div');
        this.DOMContent.className = 'docking-panel docking-panel-scroll docking-panel-container-solid-color-a';
        this.container.appendChild(this.DOMContent);
    }

    initialize() {
        super.initialize();
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }

    addNewAsset = () => {

    }

    listAsset = () => {
        this._options.AssetListPanel.toogleVisibility();
    }

    listAssetStructure = () => {
        this._options.AssetStructurePanel.toogleVisibility();
    }

    listPartStructure = () => {
        this._options.PartStructurePanel.toogleVisibility();
    }

    autoPaintChanged = () => {

    }

    paintIds = (info: Array<any>, asset: any) => {
        const ids = info.map((o) => o[0])
        paintObjects(this.viewer, ids, this._options.colorIndexes, asset.colorIndex);
        ForgeViewerEmitter.emit('onPaintChanged', true);
    }

    paint = async (info: Array<any>, asset: any, relationType: string, createAsset: boolean, structure: any) => {

        const promises = []
        if (createAsset) {
            const newAsset = await this._options.onCreateAsset(asset._id, structure.id);
            promises.push(this._options.onPaint(newAsset, 'isPartOf', info));
        } else {
            promises.push(this._options.onPaint(asset, relationType, info));
        }
        Promise.all(promises).then(() => {
            this.paintIds(info, asset);
        }).catch((error) => {
            console.log(error);
        })
    }

    paintSelection = async (asset: any, relationType: string, createAsset: boolean, structure: any) => {
        const info = await getSelectionIds(this.viewer);
        this.paint(info, asset, relationType, createAsset, structure)
    }

    paintVisible = async (asset: any, relationType: string, createAsset: boolean, structure: any) => {
        const info = await getVisibleObjectIds(this.viewer);
        this.paint(info, asset, relationType, createAsset, structure)
    }

    unPaintIds = (info: Array<any>) => {
        const ids = info.map((o) => o[0])

        this._options.onUnPaint(info);
        unPaintObjects(this.viewer, ids);

        ForgeViewerEmitter.emit('onPaintChanged', true);
    }

    unPaintSelection = async () => {
        const info = await getSelectionIds(this.viewer);
        this.unPaintIds(info);
    }



    showAllAsset = () => {
        this._options.AssetListPanel.setVisible(true);
        setTimeout(async () => {
            const paintedInfo = await this._options.getPaintedInfo(this._options.urn, undefined);
            showPaintedInfo(this.viewer, paintedInfo, this._options.colorIndexes)
        }, 200);
    }

    setVisible(show: boolean) {
        super.setVisible(show);
        this._visible = show;
        if (show) {
            this.showAllAsset();
            this.reactNode = (<PainterEditor
                style='black'
                eventEmitter={ForgeViewerEmitter}
                onListAsset={this.listAsset}
                onListAssetStructure={this.listAssetStructure}
                onAutoPaintChanged={this.autoPaintChanged}
                onPaintSelection={this.paintSelection}
                onPaintVisible={this.paintVisible}
                onUnPaintSelection={this.unPaintSelection}
            />)
            ReactDOM.render(
                this.reactNode,
                this.DOMContent
            );
        } else if (this.reactNode) {
            ReactDOM.unmountComponentAtNode(this.DOMContent);
            this.reactNode = null;
        }
    }

    toogleVisibility() {
        this.setVisible(!this._visible);
    }
}