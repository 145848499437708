import { Box } from '@mui/material';
import { simplifyFeaturesToCoords, usePointsFocus } from 'dev4bim-react-mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { useMapPageContext } from 'src/contexts/MapPageContext';
import { AssetItem } from './AssetItem';
import { ConvertDxfDialog } from './ConvertDxfDialog';
import { EditLocDialog } from './EditLocDialog/EditLocDialog';
import { NameAlignmentDialog } from './NameAlignmentDialog';
import { ToolsList } from './ToolsList';

export function MapTools() {
    const {
        assets,
        refreshAssets,
        selectedAsset,
        setSelectedAsset,
        addAlignment,
    } = useMapPageContext();
    const focus = usePointsFocus();
    const [locDialogOpen, setLocDialogOpen] = useState(false);
    const assetListBoxRef = useRef<any>();
    const assetsHTMLElemRefs = useRef<React.RefObject<any>[]>([]);
    const [newAlignmentFeatures, setNewAlignmentFeatures] = useState<GeoJSON.FeatureCollection<GeoJSON.LineString> | string>();

    if (assets.length != assetsHTMLElemRefs.current.length) {
        assetsHTMLElemRefs.current = assets.map(() => ({ current: null }));
    }

    useEffect(function autoScrollAssetList() {
        if (!selectedAsset) return;
        const idx = assets.indexOf(selectedAsset);
        if (idx == -1) return;
        const curr = assetsHTMLElemRefs.current[idx].current;
        if (!curr) return;
        const offset = curr.offsetTop
            - curr.parentElement.offsetTop
            - (curr.parentElement.clientHeight / 2)
            + (curr.clientHeight / 2);
        assetListBoxRef.current?.scrollTo({ top: offset < 0 ? 0 : offset, behavior: 'smooth' });
    }, [selectedAsset]);

    return (
        <Box sx={styles.container} >

            <Box ref={assetListBoxRef} sx={{ ...styles.assetsContainer, ...styles.subContainer }} >
                {
                    assets.map((asset, idx) => (
                        <AssetItem
                            ref={assetsHTMLElemRefs.current[idx]}
                            key={asset._id?.toString()}
                            asset={asset}
                            isSelected={selectedAsset?._id == asset._id}
                            setSelectedAsset={setSelectedAsset}
                            refreshAssets={refreshAssets}
                        />
                    ))
                }
            </Box>

            <ToolsList
                locDialogOpen={locDialogOpen}
                openLocDialog={() => setLocDialogOpen(true)}
                onImportedAlignment={setNewAlignmentFeatures}
            />

            <ConvertDxfDialog
                alignmentFeaturesDxfContent={typeof newAlignmentFeatures == 'string' ? newAlignmentFeatures : undefined}
                onClose={() => setNewAlignmentFeatures(undefined)}
                onSaved={setNewAlignmentFeatures}
            />

            <NameAlignmentDialog
                alignmentFeatures={typeof newAlignmentFeatures == 'object' ? newAlignmentFeatures : undefined}
                onClose={() => setNewAlignmentFeatures(undefined)}
                onSaved={function saveAndFocus(al) {
                    addAlignment(al);
                    focus(simplifyFeaturesToCoords(al.geojson.features));
                }}
            />

            <EditLocDialog
                open={locDialogOpen}
                onClose={() => setLocDialogOpen(false)}
            />

        </Box>
    );
}

const styles = {
    container: {
        width: '100%',
        p: 2,
        borderRadius: '10px 10px 0px 0px',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        boxShadow: '0px -5px 5px -2px rgb(0 0 0 / 45%)',
        zIndex: 1,
    },
    subContainer: {
        borderRadius: '10px',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px 0px 10px 0px rgb(0, 0, 0, 0.10)',
    },
    assetsContainer: {
        maxHeight: '144px',
        pb: 2.5,
        flex: 'auto',
        overflowY: 'scroll',
        '::-webkit-scrollbar': {
            width: '7px',
        },
        '::-webkit-scrollbar-track': {
            mt: '5px',
            mb: '5px',
        },
        '::-webkit-scrollbar-thumb': {
            borderRadius: '5px',
            borderLeft: '8px solid rgba(0, 0, 0, 0)',
            background: '#12345600',
        },
        ':hover::-webkit-scrollbar-thumb': {
            background: '#9E9E9E',
        },
    },
};
