import { TreeItem } from '@mui/lab';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {

    },
    label: {

    },
    rootLabel: {
        fontWeight: 'bold'
    },
    hidden: {
        visibility: 'collapse'
    },
    actionButton: {
        marginLeft: 10,
        '& button': {
            marginLeft: 5
        }
    }
});

function ExpandIconOnlyTreeItem(props: any) {

    const { t } = useTranslation(['pablo']);
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);

    const labelClicked = useCallback(
        (event: any) => {
            event.stopPropagation();
            if (props.onClick) {
                props.onClick(props.nodeId, props.name, props.code);
            }
        }
        , []);

    const handleAdd = (event: any) => {
        event.stopPropagation();
        if (props.onAdd) {
            props.onAdd(props.nodeId, props.name, props.code);
        }
    };

    const handleEdit = (event: any) => {
        event.stopPropagation();
        if (props.onEdit) {
            props.onEdit(props.nodeId, props.name, props.code, props.inherit);
        }
    };

    const handleRemove = (event: any) => {
        event.stopPropagation();
        if (props.onRemove) {
            props.onRemove(props.parentId, props.nodeId);
        }
    };

    const handleMoveUp = (event: any) => {
        event.stopPropagation();
        if (props.onMoveUp) {
            props.onMoveUp(props.parentId, props.nodeId);
        }
    };

    const nodeSpan = () => {
        const textSpan = (<span onClick={labelClicked} className={clsx(classes.label, { [classes.rootLabel]: props.root })}>
            {props.code} - {props.name}
        </span>);
        return (
            <span>
                {!props.selectionCanChanged ?
                    <Tooltip id='tooltip' title="Save changes before selecting new node">
                        {textSpan}
                    </Tooltip> :
                    textSpan
                }

                <span className={clsx(classes.actionButton, { [classes.hidden]: !isHover })}>
                    <IconButton onClick={handleAdd} size="small"
                        className={clsx('fas fa-plus', { [classes.hidden]: !props.canAdd })}
                    />
                    <IconButton onClick={handleEdit} size="small"
                        className={clsx('fa fa-edit', { [classes.hidden]: !props.canEdit })} />
                    <IconButton onClick={handleRemove} size="small"
                        className={clsx('fa fa-minus', { [classes.hidden]: !props.canRemove })} />
                    {/* <IconButton onClick={handleMoveUp} size="small"
                        className={clsx('fas fa-plus', { [classes.hidden]: !props.canMoveUp })} /> */}
                </span>

            </span>
        );
    };

    return (
        <TreeItem
            className={classes.root}
            nodeId={props.nodeId}
            label={
                <div
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    className={classes.label}>
                    {nodeSpan()}
                </div>
            } >
            {props.children}
        </TreeItem>
    );
}


export default ExpandIconOnlyTreeItem;