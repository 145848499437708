import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        width: '100%',
        padding: 5,
        flexDirection: 'row',
    },
    verticalDivider: {
        cursor: 'col-resize',
        flex: '0 0 auto',
        width: 5,
        height: '100%',
        backgroundColor: '#ccc',
        '&:active': {
            backgroundColor: '#bbb',
        },
        padding: '10px 0px'
    },
    firstPanel: {
        flex: '1 1 auto',
        height: '100%',
        position: 'relative',
        minWidth: (state: any) => state.firstPanelMinSize && !Number.isNaN(state.firstPanelMinSize) ? `${state.firstPanelMinSize}px` : '10px',
        padding: '0px 10px 0px 0px'
    },
    secondPanel: {
        flex: '0 0 auto',
        height: '100%',
        width: (state: any) => `${state.secondWidth}px`,
        minWidth: (state: any) => state.secondPanelMinSize && !Number.isNaN(state.secondPanelMinSize) ? `${state.secondPanelMinSize}px` : '10px',
        maxWidth: (state: any) => state.firstPanelMinSize && !Number.isNaN(state.firstPanelMinSize) ?
            `${state.width - state.firstPanelMinSize}px` :
            `${state.width - 10}px`,
        padding: '0px 0px 0px 10px'
    }
});

export interface SplitterDivProps {
    children: any;
    firstPanelMinSize?: number;
    secondPanelMinSize?: number;
    defaultSecondPanelSize?: number;
}


export default function SplitterDiv(props: SplitterDivProps) {
    const { width, ref } = useResizeDetector();
    const [secondWidth, setSecondWidth] = useState<number>(100);

    const classes = useStyles({ width, secondWidth, ...props });

    const dragRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.defaultSecondPanelSize) {
            if (ref.current) {
                let bounds = ref.current.getBoundingClientRect();
                if (props.firstPanelMinSize) {
                    if (bounds.width < props.firstPanelMinSize + props.defaultSecondPanelSize + 2 * 10 + 5) {
                        setSecondWidth(bounds.width - props.firstPanelMinSize - 2 * 10 - 5);
                    } else {
                        setSecondWidth(props.defaultSecondPanelSize);
                    }
                }
            } else {
                setSecondWidth(props.defaultSecondPanelSize);
            }
        }
    }, [props.defaultSecondPanelSize]);

    const applySecondWidth = useCallback((event: any) => {
        if (ref.current && event.clientX > 0 && width) {
            let bounds = ref.current.getBoundingClientRect();
            let x = event.clientX - bounds.left;

            setSecondWidth(Math.ceil(width - x));

        }
    }, [width]);


    const handleMouseMove = (event: any) => {
        applySecondWidth(event);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
    };

    const onDragStart = (event: any) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <Grid ref={ref} className={classes.root}>
            <Grid className={classes.firstPanel}>
                {props.children[0]}
            </Grid>
            <div
                ref={dragRef}
                draggable="true"
                className={classes.verticalDivider}
                onMouseDown={onDragStart}
                onTouchStart={onDragStart}
            ></div>
            <Grid className={classes.secondPanel}>
                {props.children[1]}
            </Grid>
        </Grid>

    );
}