import { useDrawLine, usePlaceMarker } from 'dev4bim-react-mapbox-gl';
import { useEffectPrevious, useStillMounted } from 'dev4bim-react-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Asset, useMapPageContext } from 'src/contexts/MapPageContext';
import { v4 as uuid } from 'uuid';
import { InputType, TabFunc } from '../EditLocDialog';
import { getDataFromAsset, locToAsset } from './convertDataAsset';
import { pkToPoint, pointToPk } from './convertPointPk';
import { convertData, growData, InputLoc, InputLocPt, isValidLoc, nullLoc, nullLocPt, simplifyLoc } from './locTools';

export function undyingTabLocation(
    asset: Asset | null,
    inputType: InputType,
    refreshContent: any,
    mapInteraction: boolean,
    refreshAsset: () => void,
): {
    func: TabFunc,
    data: InputLoc,
    setData: (_: InputLoc) => void,
} {
    const place = usePlaceMarker();
    const draw = useDrawLine();
    const [data, setData] = useState<InputLoc>(nullLoc());
    const { selectedAlignmentFeature, alignments } = useMapPageContext();
    const { isUnmounted } = useStillMounted();


    const close = useCallback(function pointClose() {
        setData(nullLoc());
    }, []);

    const onSubmit = useCallback(function pointSubmit() {
        if (!asset
            || (inputType.modifier == 'pk' && !selectedAlignmentFeature)) return false;
        const res = locToAsset(data, inputType, selectedAlignmentFeature, alignments);
        if (!res) return false;
        res.geojson.properties = asset.geojson?.properties ?? {
            name: asset.name,
        };
        asset.geojson = res.geojson;
        asset.geolocation = res.geolocation;
        asset.visible = true;
        return true;
    }, [asset, data, inputType, selectedAlignmentFeature, alignments]);


    const func = useMemo<TabFunc>(function pointFuncObject() {
        return {
            close,
            onSubmit,
            isValid: () => isValidLoc(data, inputType),
            startMapInteraction: function launchMapInteraction(success, cancel) {
                const mapInteractionStarter = inputType.destination == 'point' ? place : draw;
                function changeType(type: 'normal' | 'discreate') {
                    if (asset && asset.geojson) {
                        asset.geojson.properties = asset.geojson.properties ?? {};
                        asset.geojson.properties.type = type;
                        refreshAsset();
                    }
                }
                changeType('discreate');
                let initialLoc: Parameters<typeof mapInteractionStarter>[2];
                if (isValidLoc(data, inputType)) {
                    const checkedData = simplifyLoc(data, inputType);
                    if (inputType.modifier == 'gps') {
                        initialLoc = checkedData.map(({ x, y }) => ({ lng: x, lat: y }));
                    } else if (selectedAlignmentFeature) {
                        initialLoc = checkedData.map(({ x, y }) => pkToPoint({ pk: x, dist: y }, selectedAlignmentFeature));
                    }
                    if (initialLoc && inputType.destination == 'point') {
                        initialLoc = (initialLoc as any)[0];
                    }
                }
                mapInteractionStarter(function onInteractionSaved(l) {
                    changeType('normal');
                    if (isUnmounted()) return;
                    let res: InputLocPt[] = [];
                    if (inputType.modifier == 'pk' && selectedAlignmentFeature) {
                        if (!Array.isArray(l)) {
                            const newPk = pointToPk(l, selectedAlignmentFeature);
                            res = [
                                { x: newPk?.pk ?? null, y: newPk?.dist ?? null, id: uuid() },
                                nullLocPt(),
                            ];
                        } else {
                            res = l.map((pt) => {
                                const newPk = pointToPk(pt, selectedAlignmentFeature);
                                return { x: newPk?.pk ?? null, y: newPk?.dist ?? null, id: uuid() };
                            });
                        }
                    } else {
                        if (!Array.isArray(l)) {
                            res = [{ x: l.lng, y: l.lat, id: uuid() }];
                        } else {
                            res = l.map((pt) => ({ x: pt.lng, y: pt.lat, id: uuid() }));
                        }
                    }
                    if (inputType.destination == 'segment' && res.length >= 2
                        && res[0].x != null && res[1].x != null
                        && res[0].x > res[1].x) {
                        const tmp = res[0];
                        res[0] = res[1];
                        res[1] = tmp;
                    }
                    setData(growData(res));
                    success();
                }, function onInteractionCanceled() {
                    changeType('normal');
                    if (isUnmounted()) return;
                    cancel();
                }, initialLoc as any);
            }
        };
    }, [close, onSubmit, data, inputType, selectedAlignmentFeature]);


    useEffectPrevious(function convertPointsToPk(prevArr) {
        if (!prevArr) return;
        const [prev] = prevArr;

        const newData = convertData(data, selectedAlignmentFeature, prev, inputType);
        setData(newData);

    }, [inputType]);

    useEffect(function retrieveDataFromAsset() {
        if (mapInteraction) return;
        const res = getDataFromAsset(asset);
        if (!res) {
            setData(nullLoc());
            return;
        }
        const newData = convertData(res.data, selectedAlignmentFeature, res.type, inputType);
        setData(newData);
    }, [refreshContent]);

    useEffectPrevious(function calculateDataOnAlignmentChanged(prevArr) {
        if (!prevArr || inputType.modifier != 'pk') return;
        const [prevRefreshing, prev] = prevArr;
        if (refreshContent != prevRefreshing) return;
        if (prev == selectedAlignmentFeature) return;
        if (!selectedAlignmentFeature) {
            setData(growData(data.map(({ id }) => ({ x: null, y: null, id }))));
            return;
        }
        if (!prev) {
            const res = getDataFromAsset(asset);
            if (!res) {
                setData(nullLoc());
                return;
            }
            const dataNonNull = simplifyLoc(res.data, inputType);
            const pks = dataNonNull
                .map(({ x, y }) => pointToPk({ lng: x, lat: y }, selectedAlignmentFeature))
                .filter((val) => val != null) as NonNullable<ReturnType<typeof pointToPk>>[]

            const newData: InputLocPt[] = pks.map(({ pk, dist }) => ({ x: pk, y: dist, id: uuid() }));
            setData(growData(newData));
            return;
        }
        const dataNonNull = simplifyLoc(data, inputType);
        const pts = dataNonNull.map(({ x, y }) => pkToPoint({ pk: x, dist: y }, prev));
        const pks = pts
            .map((pt) => pointToPk(pt, selectedAlignmentFeature))
            .filter((val) => val != null) as NonNullable<ReturnType<typeof pointToPk>>[]

        const newData: InputLocPt[] = pks.map(({ pk, dist }) => ({ x: pk, y: dist, id: uuid() }));
        setData(growData(newData));
    }, [refreshContent, selectedAlignmentFeature]);


    return {
        func,
        data,
        setData,
    };
}
