import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDeck } from 'src/components';


function AllProjectsPage() {
    const { t } = useTranslation(['pablo']);

    return (
        <div
            style={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <h3>{t('pablo:pages.home.header')}</h3>
            <ProjectDeck />
        </div >
    );
}

export default AllProjectsPage;
