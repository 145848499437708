import { Link as MuiLink } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { saveFileLocally } from 'src/utils/file';

const baseStyle: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#525252',
    borderStyle: 'dashed solid',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle: React.CSSProperties = {
    borderColor: '#2196f3'
};

const acceptStyle: React.CSSProperties = {
    borderColor: '#00e676'
};

const rejectStyle: React.CSSProperties = {
    borderColor: '#ff1744'
};

export default function DropFileZone(props: {
    acceptExtensions?: Array<string>,
    onFileSelected: (file: any) => void,
    templateFileName?: string,
    getTemplateBlob?: (lng: string) => Promise<Blob>,
}) {
    const { t, i18n } = useTranslation(['pablo']);
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: props.acceptExtensions, maxFiles: 1 });

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            props.onFileSelected(acceptedFiles[0])
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            alert(t('pablo:components.properties.dropFileAlert'))
        }
    }, [fileRejections]);

    const acceptedFileItem = acceptedFiles.map((file: any) => (
        <div key={file.path} style={{ display: 'flex', placeContent: 'flex-end', alignContent: 'center' }}>
            <h6>Selected file: </h6>
            <h6 style={{ marginLeft: 10 }}>
                {file.path}
            </h6>
        </div>
    ));

    const style: React.CSSProperties = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);


    const handleDownload = async (fileName?: string) => {
        if (props.getTemplateBlob && fileName) {
            const blob = await props.getTemplateBlob(i18n.language);
            if (blob) {
                saveFileLocally(blob, fileName);
            }
        }
    }

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>{t('pablo:components.properties.drag&drop')}</p>
            </div>
            <aside>
                <div style={{ height: 30 }}>
                    {acceptedFileItem}
                </div>
            </aside>
            {
                props.getTemplateBlob ?
                    <MuiLink
                        component="button"
                        variant="body2"
                        onClick={() => handleDownload(props.templateFileName)}
                    >
                        {t('pablo:label.download')} Template
                    </MuiLink> : null
            }
        </div>
    );
}
