import { Typography } from '@material-ui/core';
import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, Table, TableCell, TableHead, TableRow
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseDialogTitle } from 'src/components';
import { APIServiceManager } from 'src/services';

export default function ExtendedCodesModal(props: {
    open: boolean;
    onSubmit: (extendedCode: Array<any>) => void;
    onClose: () => void;
}) {

    const { t } = useTranslation(['pablo']);
    const [extendedCodes, setExtendedCodes] = useState<Array<any>>([]);
    const [checkedIndex, setCheckedIndex] = useState<Array<boolean>>([]);

    const loadExtendedCodes = useCallback(() => {
        APIServiceManager.ExtendedCodeService.getExtendedCodes().then((extCodes: Array<any>) => {
            setCheckedIndex(Array(extCodes.length).fill(false));
            setExtendedCodes(extCodes);
        });
    }, []);

    useEffect(() => {
        loadExtendedCodes();
    }, [loadExtendedCodes]);

    return (
        <Dialog
            disableEnforceFocus
            fullWidth={true} maxWidth="sm"
            open={props.open}
            onClose={props.onClose}
        >
            <CloseDialogTitle onClose={props.onClose} title={t('pablo:dialog.title.extendedCodes')} />
            <DialogContent>
                <Grid container spacing={2} justifyContent="space-evenly">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox onChange={(event: any, checked: boolean) => {
                                        setCheckedIndex(Array(extendedCodes.length).fill(checked));
                                    }} />
                                </TableCell>
                                <TableCell>
                                    {t('pablo:table.headers.name')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {extendedCodes.map((extCode: any, index: number) => (
                            <TableRow key={extCode._id}>
                                <TableCell>
                                    <Checkbox checked={checkedIndex[index]}
                                        onChange={(event: any, checked: boolean) => {
                                            const newArr = [...checkedIndex];
                                            newArr[index] = checked;
                                            setCheckedIndex(newArr);
                                        }} />
                                </TableCell>
                                <TableCell>
                                    <Typography>{extCode.name}</Typography>
                                </TableCell>
                            </TableRow>

                        ))}
                    </Table>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose} color="inherit">
                    {t('pablo:button.cancel')}
                </Button>
                <Button autoFocus onClick={() => {
                    const checkExtendedCode = [];
                    for (let i = 0; i < checkedIndex.length; i++) {
                        if (checkedIndex[i]) {
                            checkExtendedCode.push(extendedCodes[i]);
                        }
                    }
                    props.onSubmit(checkExtendedCode);
                }} color="inherit">
                    {t('pablo:button.submit')}
                </Button>
            </DialogActions>
        </Dialog >
    );
}