import { Button, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Query } from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { ForgeModels, ForgeModelsTable } from '../../components';
import { APIServiceManager, ForgeService } from '../../services';
const useStyles = makeStyles({
    // style rule
    root: {
        display: 'inline-flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    icons: {
        marginRight: '35px',
        padding: '20px 0px',
        flexDirection: 'row',
        width: 100,
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignSelf: 'flex-end',
    },
    gallery: {
        height: 'calc(100% - 90px)'
    }
});

function GalleryPage() {
    const classes = useStyles();
    const viewerLink = '/forge/viewer';
    const _forgeService: ForgeService = APIServiceManager.getService('ForgeService') as ForgeService;

    const [galleryMode, setGalleryMode] = useState(false);
    const [cookies, setCookies] = useCookies(['aimspainter_gallery_mode']);
    const startAtQuery = useRef<Array<number>>([0]);

    const history = useNavigate();

    useEffect(() => {
        setGalleryMode(cookies.aimspainter_gallery_mode === 'true');
    }, [cookies]);

    const getData = async (query: Query<any>, withThumbnail: boolean) => {
        let search = query.search && query.search.length > 0 ? query.search : undefined;
        const result = await _forgeService.getModels(withThumbnail, startAtQuery.current[query.page], query.pageSize, ['complete', 'ok'], search);
        return {
            data: result.data,
            page: query.page,
            totalCount: result.total
        };
    };

    const onClick = (name: string, urn: string) => {
        const modelHistory = JSON.parse(localStorage.getItem('forge_aimspainter_history')!);
        if (modelHistory && Array.isArray(modelHistory)) {
            modelHistory.shift();
            modelHistory.push({ name: name, urn: urn });
            localStorage.setItem('forge_aimspainter_history', JSON.stringify(modelHistory));
        } else {
            localStorage.setItem('forge_aimspainter_history', JSON.stringify([{ name: name, urn: urn }]));
        }
        history(viewerLink, { state: { urn } });
    };

    return (
        <div className={classes.root}>
            <div className={classes.icons}>
                <Button className="icon-opt" onClick={() => {
                    localStorage.setItem('forge_aimspainter_gallery_mode', 'false');
                    setCookies('aimspainter_gallery_mode', false, { path: '/' });
                    setGalleryMode(false);
                }} >
                    <Icon className="fa fa-list" />
                </Button>
                <Button className="icon-opt" onClick={() => {
                    localStorage.setItem('forge_aimspainter_gallery_mode', 'true');
                    setCookies('aimspainter_gallery_mode', true, { path: '/' });
                    setGalleryMode(true);
                }} >
                    <Icon className="fas fa-th-large" />
                </Button>
            </div>
            <div className={classes.gallery}>
                {galleryMode ?
                    <ForgeModels
                        onClick={(name: string, urn: string) => onClick(name, urn)}
                        getData={(query: any) => {
                            return getData(query, true);
                        }}
                    /> : <ForgeModelsTable
                        linkTo={viewerLink}
                        getData={(query: any) => {
                            return getData(query, false);
                        }}
                    />
                }
            </div >

        </div>
    );
}
export default GalleryPage;
