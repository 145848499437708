import { API, APIServiceManager } from '../..';

class UnitsService {
    api: API = APIServiceManager.API;

    name() {
        return 'UnitsService';
    }

    async getUnits(limitToProject: string, query?: any): Promise<[]> {
        try {
            const req = await this.api.axios.get('/units', { params: { limitToProject, ...query } });
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateUnit(id: string, unit: any): Promise<[]> {
        try {
            const req = await this.api.axios.put(`/units/${id}`, unit);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async deleteUnit(id: string): Promise<[]> {
        try {
            const req = await this.api.axios.delete(`/units/${id}`);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    async addUnit(unit: any): Promise<[]> {
        try {
            const req = await this.api.axios.post('/units', unit);
            return req.data.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

}
export { UnitsService };