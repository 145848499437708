import { Card, CardHeader } from '@material-ui/core';
import { TreeData } from 'editable-tree';
import React, { useEffect, useState } from 'react';
import { StructureTreeProperty } from 'src/components';
import { IAssetProp } from 'src/core';
import { APIServiceManager } from 'src/services';

type StructurePropertyViewProps = {
    onChange: (change: boolean) => void;
    selectedTreeNode: TreeData;
}

function StructurePropertyView(props: StructurePropertyViewProps) {
    const [canAct, setCanAct] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [selectedAssetStructureId, setSelectedAssetStructureId] = useState<string>('');
    const [selectedAssetStructureCode, setSelectedAssetStructureCode] = useState<string>('');
    const [selectedAssetStructureFullCode, setSelectedAssetStructureFullCode] = useState<string>('');
    const [selectedAssetStructureName, setSelectedAssetStructureName] = useState<string>('');

    const [selectedAssetProperties, setSelectedAssetProperties] = useState<IAssetProp[]>([]);

    useEffect(() => {
        if (props.selectedTreeNode.extendedData && props.selectedTreeNode.extendedData.code && props.selectedTreeNode.extendedData.name) {
            setSelectedAssetStructureId(props.selectedTreeNode.id);
            setSelectedAssetStructureCode(props.selectedTreeNode.extendedData.code);
            setSelectedAssetStructureName(props.selectedTreeNode.extendedData.name);
            if (props.selectedTreeNode.extendedData.fullCode) {
                setSelectedAssetStructureFullCode(props.selectedTreeNode.extendedData.fullCode)
            }
        }
    }, [props.selectedTreeNode.id]);

    const loadData = () => {
        setIsLoading(true);
        setSelectedAssetProperties([]);
        setTimeout(async () => {
            if (props.selectedTreeNode.id) {
                if (props.selectedTreeNode.id.startsWith('tempid_')) {
                    setSelectedAssetProperties([]);
                    return;
                }
                const getProps = await APIServiceManager.StructurePropertyService.getPropertiesOf(props.selectedTreeNode.id);
                if (getProps) {
                    setSelectedAssetProperties(getProps);
                    setHasError(false);
                    setCanAct(true);
                } else {
                    setHasError(true);
                }
            }
            setIsLoading(false);
        }, 500);
    };

    useEffect(() => {
        loadData();
    }, [props.selectedTreeNode.id])


    const onPropertyRemoved = async (propId: string) => {
        await APIServiceManager.StructurePropertyService.removePropertyToStrcture(props.selectedTreeNode.id, propId)
    }

    const onStopInheritance = async (propId: string) => {
        await APIServiceManager.StructurePropertyService.stopInheritanceOfPropertyForStructure(props.selectedTreeNode.id, propId)
    }


    const onAddProperties = async (propIds: Array<string>) => {
        await Promise.all(propIds.map(propId => APIServiceManager.StructurePropertyService.addPropertyToStructure(props.selectedTreeNode.id, propId)));
    }

    return (
        <div style={{ flexDirection: 'column', height: '100%', display: 'flex' }}>
            {
                hasError ?
                    <Card className="card-table">
                        <CardHeader title={`${selectedAssetStructureCode} has no properties yet`} />
                    </Card> :
                    <StructureTreeProperty
                        editable={canAct}
                        structureId={selectedAssetStructureId}
                        isLoading={isLoading}
                        onPropertyRemoved={onPropertyRemoved}
                        onStopInheritance={onStopInheritance}
                        onAddProperties={onAddProperties}
                        data={selectedAssetProperties}
                        code={selectedAssetStructureCode}
                        fullCode={selectedAssetStructureFullCode}
                        name={selectedAssetStructureName}
                    />


            }
        </div>
    );
}
export default StructurePropertyView;