import { Alert } from '@material-ui/lab';
import {
    Box, IconButton,
    Snackbar, Tooltip
} from '@mui/material';
import { parseTreeDelete, parseTreeGetParent, TreeData } from 'editable-tree';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { CreateSnapshotModal, DropFileDialog, ExportTreeModal, SnapshotSelect, SnapshotTreeModal, StructureTree, StructureTreeSettings } from 'src/components';
import { ProjectContext } from 'src/contexts/project.context';
import {
    APIServiceManager,
    FrontServiceManager
} from 'src/services';
import { saveFileLocally } from 'src/utils/file';

type StructureNodeViewProps = {
    defaultSelectedNodeId: string;
    selectionCanChanged: boolean;
    onNodeClick: (node: TreeData) => void;
}

const styles = {
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
    },
    toolbarRight: {
        margin: '0px 15px',
        textAlign: 'right'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '40px',
        marginLeft: '10px'
    },
    typo: {
        width: '100%',
        fontStyle: 'italic',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': {
            overflow: 'scroll',
        }
    },
};

function StructureNodeView(props: StructureNodeViewProps) {

    const { t } = useTranslation(['pablo']);
    const initialData: TreeData = {
        id: '',
        label: '',
        extendedData: {
            code: '',
            name: '',
        },
        // children: [],
    };

    const projectContext = useContext(ProjectContext);

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [data, setData] = useState<TreeData>(initialData);
    const [snapshots, setSnapshots] = useState<any>([]);

    const [hasEditorRole, setHasEditorRole] = useState(false);
    const [importWindowOpen, setImportWindowOpen] = useState(false);
    const [saveMessageOpen, setSaveMessageOpen] = useState(false);
    const [saveMessage, setSaveMessage] = useState<any>({});

    const [showSettings, setShowSettings] = useState(false);

    const [snapshotWindowOpen, setSnapshotWindowOpen] = useState(false);
    const [snapshotTreeWindowOpen, setSnapshotTreeWindowOpen] = useState(false);
    const [snapshotTree, setSnapshotTree] = useState<any>({ label: '', id: '', tree: {} });
    const [exportWindowOpen, setExportWindowOpen] = useState(false);

    const [importedFile, setImportedFile] = useState<any>(null);
    const [cookies, setCookies] = useCookies([
        'aimspainter_asset_structure_display_level',
        'aimspainter_asset_structure_display_fullCode',
        'aimspainter_asset_structure_display_rootCode',
        'aimspainter_asset_structure_display_codeSeparator',
    ]);

    useEffect(() => {
        if (projectContext && projectContext.currentProject && Array.isArray(projectContext.currentProject.permissions)) {
            setHasEditorRole(projectContext.currentProject.permissions.includes('structure-editor'));
        }
    }, [projectContext]);

    const backgroundLoadTreeByLevel = () => {
        loadTreeData(1);
        loadTreeData(3);
        loadTreeData(5);
        loadTreeData(-1);
    };

    useEffect(() => {
        //if no default node is specified, select root node
        if (props.defaultSelectedNodeId === '') {
            const rootObj = {
                id: data.id,
                label: data.label,
                extendedData: data.extendedData
            };
            props.onNodeClick(rootObj);
        }
    }, [props.defaultSelectedNodeId, data.id]);

    useEffect(() => {
        if (projectContext && projectContext.currentProject && projectContext.currentProject._id) {
            backgroundLoadTreeByLevel();
            loadSnapshots();
        }
    }, [projectContext]);

    const loadSnapshots = async () => {
        const raw = await APIServiceManager.StructureSnapshotApiService.getSnapshotSummaries();
        setSnapshots(FrontServiceManager.StructureSnapshotService.formatSummariesForSelect(raw));
    };

    const loadTreeData = async (level: number) => {
        try {
            const strData = await FrontServiceManager.StructureQueryService.getStructureTree(true, level);
            if (strData) {
                setData(strData);
                setHasError(false);

            } else {
                setHasError(true);
            }
        } catch (error) {
            const errorObject: any = error;
            setErrorMessage(errorObject && errorObject.message ? errorObject.message : 'Something went wrong.');
            setHasError(true);
        }
    };

    const onShowSettings = async () => {
        setShowSettings(true);
    };

    const handleImportWindowSubmit = async () => {
        await APIServiceManager.StructureService.importFile(importedFile);
        backgroundLoadTreeByLevel();
        setImportWindowOpen(false);
    };

    const handleCreateSnapshotWindowSubmit = async (name: string) => {
        const newSnapshotSummary = await FrontServiceManager.StructureSnapshotService.createSnapshot(name);
        snapshots.unshift(FrontServiceManager.StructureSnapshotService.formatSnapshotForSelect(newSnapshotSummary));
        setSnapshots([...snapshots]);
        setSnapshotWindowOpen(false);
    };

    const handleDeleteSnapshot = async (id: string) => {
        await APIServiceManager.StructureSnapshotApiService.deleteSnapshot(id);
        const index = snapshots.findIndex((snapshot: any) => snapshot.id.toString() === id.toString());
        snapshots.splice(index, 1);
        setSnapshots([...snapshots]);
    };

    const loadSnapshot = async (id: string) => {
        const formattedSnapshot: any = await FrontServiceManager.StructureSnapshotService.formatSnapshotForTree(id);
        setSnapshotTree(formattedSnapshot);
        setSnapshotTreeWindowOpen(true);
    };

    const handleSnapshotSelectSubmit = (id: string) => {
        loadSnapshot(id);
    };

    const handleCloseSnackbar = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveMessageOpen(false);
    };

    const userMessage = (content: string, severity: string) => {
        setSaveMessage({ content: content, severity: severity });
        setSaveMessageOpen(true);
    };

    const onNodeBeforeEdit = (root: TreeData, editedNode: TreeData) => {
        const parent = parseTreeGetParent(root, editedNode.id);
        if (parent) {
            verifyCodeIsUnique(parent, editedNode);
            editedNode.extendedData.fullCode = parent.extendedData.fullCode ?
                `${parent.extendedData.fullCode} ${editedNode.extendedData.code}`
                : editedNode.extendedData.code;
        }
    };

    const onNodeEdit = (tree: TreeData, editNode: TreeData) => {
        APIServiceManager.StructureService.updateStructure(editNode.id, {
            id: editNode.id,
            ...editNode.extendedData,
        }).then(result => {

        }).catch(err => {
            // TODO: The edit is not rolled back locally if there is a network error
            userMessage(t('pablo:pages.assetStr.errorMessage'), 'error');
        });
    };

    const onNodeBeforeAdd = (parent: TreeData, newNode: TreeData) => {
        newNode.extendedData.fullCode = `${newNode.extendedData.fullCode} ${newNode.extendedData.code}`;
        verifyCodeIsUnique(parent, newNode);
    };

    const onNodeAdd = (tree: TreeData, newNode: TreeData, parentId: string, order?: number) => {
        newNode.extendedData.root = false;

        APIServiceManager.StructureService.addStructure({
            _id: newNode.id,
            ...newNode.extendedData,
            order,
            parentId,
            editable: true,
        }).then(result => {

        }).catch(err => {
            rollbackLocalAdd(newNode);
        });

        const rollbackLocalAdd = (addedNode: TreeData) => {
            parseTreeDelete(tree, addedNode);
            setData(tree);
        };
    };

    const onNodeDrop = (tree: TreeData, dropNodeId: string, parentId: string, newOrder?: number) => {
        APIServiceManager.StructureService.updateStructureOrder([{
            id: dropNodeId,
            parentId,
            newOrder,
        }]).then(result => {

        }).catch(err => {
            // TODO: rollback ?
            userMessage(t('pablo:pages.assetStr.errorMessage'), 'error');
        });
    };
    const onNodeDropAfter = (tree: TreeData, dropNodeId: string, parentId: string, previousId: string, newOrder?: number) => {
        onNodeDrop(tree, dropNodeId, parentId, newOrder);
    };

    const onNodeBeforeDrop = (newTree: TreeData, sourceNode: TreeData, parentNode: TreeData, order?: number) => {
        verifyCodeIsUnique(parentNode, sourceNode);
        sourceNode.extendedData.fullCode = `${parentNode.extendedData.fullCode} ${sourceNode.extendedData.code}`;
    };

    const calculateCanDeleteNode = (node: TreeData) => {
        if (node.extendedData && node.extendedData.root) {
            return false;
        }
        return true;
    };

    const onNodeBeforeDelete = (tree: TreeData, deleteNode: TreeData) => {
        if (!deleteNode.extendedData.deletable) {
            return confirm(t('pablo:pages.assetStr.confirmDelete'));
        }
        return true;
    };

    const onNodeDelete = (tree: TreeData, deleteNode: TreeData) => {
        APIServiceManager.StructureService.removeStructure(deleteNode.id, true)
            .then(result => {

            }).catch(err => {
                // TODO: rollback ?
                userMessage(t('pablo:pages.assetStr.errorMessage'), 'error');
            });
    };

    const verifyCodeIsUnique = (parentNode: TreeData, child: TreeData) => {
        if (!FrontServiceManager.StructureQueryService.childCodeIsUnique(parentNode, child)) {
            const errMessage = t('pablo:pages.assetStr.structure.fullCodeMustBeUnique');
            userMessage(errMessage, 'error');
            throw errMessage;
        }
    };

    const onExportTree = (newSnapshotId: string, oldSnapshotId: string, options: any) => {
        setExportWindowOpen(false);
        if (oldSnapshotId) {
            APIServiceManager.StructureSnapshotApiService.exportDiff(newSnapshotId, oldSnapshotId, options)
                .then(blob => saveFileLocally(blob, 'out.xlsx'));
        } else {
            if (newSnapshotId) {
                APIServiceManager.StructureSnapshotApiService.exportSnapshot(newSnapshotId, options)
                    .then(blob => saveFileLocally(blob, 'out.xlsx'));

            } else {
                APIServiceManager.StructureSnapshotApiService.exportDataModel(options)
                    .then(blob => saveFileLocally(blob, 'out.xlsx'));
            }
        }
    };

    if (hasError) {
        setTimeout(() => {
            return <h1>{errorMessage}</h1>;
        }, 0);
    }

    return (
        <Box sx={styles.root}>
            <DropFileDialog
                acceptExtension={['.xlsx', '.xls']}
                onClose={() => setImportWindowOpen(false)} fullWidth show={importWindowOpen}
                maxWidth="md"
                onFileSelected={(file: any) => { setImportedFile(file); }}
                templateFileName='ImportStructureTemplate.xlsx'
                getTemplateBlob={(lng: string) => { return APIServiceManager.TemplateService.getStructureImportTemplate(lng); }}
                disabled={importedFile === null}
                onImport={handleImportWindowSubmit}
                title={t('pablo:dialog.title.importData')}
            />
            <ExportTreeModal
                open={exportWindowOpen}
                onClose={() => setExportWindowOpen(false)}
                onExport={onExportTree}
                snapshotSummaries={snapshots}
            />
            <CreateSnapshotModal
                open={snapshotWindowOpen}
                onSubmit={handleCreateSnapshotWindowSubmit}
                onClose={() => setSnapshotWindowOpen(false)}
                title={t('pablo:dialog.title.createSnapshot')}
            />
            <SnapshotTreeModal
                open={snapshotTreeWindowOpen}
                onHide={() => setSnapshotTreeWindowOpen(false)}
                treeData={snapshotTree}
                onDeleteSnapshot={handleDeleteSnapshot}
            />
            <Box sx={styles.toolbar}>
                <SnapshotSelect
                    data={snapshots}
                    onChange={handleSnapshotSelectSubmit}
                />
                <Box sx={styles.toolbarRight}>
                    <Tooltip title={`${t('pablo:button:snapshot')}`}>
                        <IconButton onClick={() => { setSnapshotWindowOpen(true); }} className="fa fa-camera" />
                    </Tooltip>
                    <Tooltip title={`${t('pablo:button:settings')}`}>
                        <IconButton onClick={onShowSettings} className="fa fa-cog" />
                    </Tooltip>
                    <Tooltip title={`${t('pablo:button:import')}`}>
                        <IconButton disabled={!hasEditorRole} onClick={() => setImportWindowOpen(true)} className="fa fa-file-import" />
                    </Tooltip>
                    <Tooltip title={`${t('pablo:button:export')}`}>
                        <IconButton disabled={!hasEditorRole} onClick={() => setExportWindowOpen(true)} className="fa fa-file-export" />
                    </Tooltip>
                </Box>
            </Box>

            <StructureTree
                defaultSelectedNodeId={props.defaultSelectedNodeId}
                onNodeBeforeAdd={onNodeBeforeAdd}
                onNodeAdd={onNodeAdd}
                onNodeBeforeDelete={onNodeBeforeDelete}
                onNodeDelete={onNodeDelete}
                calculateCanDeleteNode={calculateCanDeleteNode}
                onNodeBeforeEdit={onNodeBeforeEdit}
                onNodeEdit={onNodeEdit}
                onNodeBeforeDrop={onNodeBeforeDrop}
                onNodeDrop={onNodeDrop}
                onNodeDropAfter={onNodeDropAfter}
                onNodeClick={props.onNodeClick}
                showNodeLevel={cookies.aimspainter_asset_structure_display_level === 'true'}
                showNodeFullCode={cookies.aimspainter_asset_structure_display_fullCode === 'true'}
                showNodeRootCode={cookies.aimspainter_asset_structure_display_rootCode === 'true'}
                editable={hasEditorRole}
                separator={cookies.aimspainter_asset_structure_display_codeSeparator}
                data={data}
            />

            <Snackbar
                style={{ minWidth: 300 }}
                open={saveMessageOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert onClose={() => setSaveMessageOpen(false)} severity={saveMessage.severity} style={{ minWidth: 300 }}>
                    {saveMessage.content}
                </Alert>
            </Snackbar>
            <StructureTreeSettings show={showSettings} onClose={() => setShowSettings(false)} />
        </Box>
    );
}

export default StructureNodeView;
