import React, { useEffect, useRef, useState } from 'react';
import 'react-splitter-layout/lib/index.css';
import './new-viewer.page.css';

import { NewForgeViewer } from 'src/components';
import { AssetObjectsLink, AssetWithColor } from 'src/core';
import { APIServiceManager, AssetService, LinkService, StructureService } from 'src/services';

import { useLocation, useNavigate } from 'react-router-dom';


const colorArray: any = [
    // '#FF0000',
    // '#00FF00',
    // '#0000FF',
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF'
];


function ViewerPage() {

    const [urn, setUrn] = useState<string | null>(null);
    const location = useLocation();
    const history = useNavigate();

    useEffect(() => {
        if (location && location.state) {
            const state = location.state as any;
            if (state.urn) {
                setUrn(state.urn);
            } else {
                history('home');
            }
        } else {
            history('home');
        }
    }, [location.state]);

    const _assetListService: AssetService = APIServiceManager.AssetService;
    const _linkService: LinkService = APIServiceManager.LinkService;
    const _assetStrService: StructureService = APIServiceManager.StructureService;

    const assetWithColor = useRef<AssetWithColor[]>([]);


    const getPainted = async (urn?: string, assetId?: string): Promise<AssetObjectsLink[]> => {
        let response;
        if (assetId) {
            response = await _linkService.getAllLinks(urn, assetId);
        } else {
            response = await _linkService.getAllLinks(urn);
        }
        const assetList = await _assetListService.getAssetList(0, 10, {});
        const arr: Array<AssetWithColor> = assetList.data.map((a, i) => ({
            colorIndex: i,
            ...a
        }));
        assetWithColor.current = arr;
        return response.map((link: AssetObjectsLink) => {
            const asset = assetWithColor.current.find((asset: AssetWithColor) => asset._id === link.assetId);
            if (asset) {
                link.colorIndex = asset.colorIndex;
            }
            return link;
        });
    };

    const getPaintedCount = (urn?: string): Promise<any> => {
        return _linkService.getLinkCount(urn);
    };

    const getAssetStructure = async () => {
        const structureRequest = await _assetStrService.getStructureTree(false);
        return structureRequest;
    };

    const paintObject = async (asset: any, relationType: string, ids: any) => {
        const links = ids.map((id: any) => {
            return {
                assetId: asset._id,
                objectId: id[0],
                ifcId: id[1],
                extId: id[2],
                urn: urn,
                type: relationType
            };
        });
        return await _linkService.createLink(links);
    };

    const unPaintObject = async (ids: Array<any>) => {
        const links = ids.map((id) => {
            return {
                urn: urn,
                objectId: id[0],
            };
        });
        _linkService.removeLink(links);
    };

    const createAsset = async (parentAssetId: string, structureId: string) => {
        const newAsset = await _assetListService.addAsset({
            structureId: structureId,
            name: 'Auto-generated Asset',
            parentId: parentAssetId,
        }, true);
        return newAsset.data;
    };

    const createPart = async (parentAssetId: string, structureId: string) => {
        const newAsset = await _assetListService.addAsset({
            structureId: structureId,
            name: 'Auto-generated Asset',
            parentId: parentAssetId,
            type: 'auto'
        }, true);
        return newAsset.data;
    };

    const getProperties = async (urn: string, forgeNodeId: string) => {
        const links = await _linkService.getLink(urn, forgeNodeId);
        const objProps = [];

        for (const link of links) {
            if (link.assetId) {
                const assetReq = await _assetListService.getAssetById(link.assetId, true);
                const properties = assetReq.data[0].properties;
                objProps.push({
                    link, properties
                });
            }
        }
        return objProps;
    };

    const updateProperty = async (id: string, propertyInstId: string, propertyValue: string) => {
        if (propertyInstId === 'Name') {
            _assetListService.updateAsset(id, { name: propertyValue });
        } else {
            _assetListService.updateAssetProperty(id, propertyInstId, propertyValue);
        }
        // _assetListService.updateAssetProperty(id, propertyName, propertyValue);
    };

    const redirectToHomePage = () => {
        history('home');
    };

    return (
        <div>
            <NewForgeViewer
                urn={`urn:${urn}`}
                colorIndexes={colorArray}
                onRedirect={redirectToHomePage}
                getAssetStructure={getAssetStructure}
                getPaintedInfo={getPainted}
                getPaintedCount={getPaintedCount}
                onPaint={paintObject}
                onCreateAsset={createAsset}
                onCreatePart={createPart}
                onUnPaint={unPaintObject}
                getProperties={getProperties}
                updateProperty={updateProperty}
            />
        </div >
    );
}

export default ViewerPage;
